import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState, useContext, useEffect } from "react";
import { myContext } from "App";
import plus from "assets/images/dashboard/plus.svg";
import useGetServiceList from "containers/services/Data/useGetServicesList";
import usePostWorkOrder from "containers/privateRoutes/Orders/data/usePostWorkOrder";
import ComboBox from "containers/privateRoutes/Dashboard/searchComponent";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";

export default function OrderDialog({getOrderDetails}) {
  const [open, setOpen] = useState(false);
  const [listArr, setListArr] = useState([]);
  const [req, setReq] = useState("");
  const [reqListState, setRequiredListState] = useState([]);

  const [
    servicesListdata,
    servicesListerror,
    servicesListIsLoading,
    getServicesList,
  ] = useGetServiceList();
  const [searchClicked, setSearchedClick] = useState(false);

  const [submitClicked, setSubmitClicked] = useState(false);

  useEffect(() => {
    getServicesList();
  }, []);

  useEffect(() => {
    if (servicesListdata) {
      setListArr(servicesListdata.data.results);
    }
  }, [servicesListdata]);

  var reqList;
  const change = (e) => {
    reqList = listArr.filter((data) => {
      return (
        data &&
        data.service_title &&
        data.service_title.toLowerCase().includes(e)
      );
    });
    setRequiredListState(reqList);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [serviceName, setServiceName] = useState();
  const [serviceId, setServiceId] = useState();

  const forServiceId = () => {
    var value = document.getElementById("forOptionId");
    var optionId = value.value;
    setServiceId(optionId);
  };
  const [
    workOrderData,
    workOrderError,
    workOrderIsLoading,
    postWorkOrder,
  ] = usePostWorkOrder();

  useEffect(() => {
    if (workOrderData) {
      showSuccessToast("Ordered successfully")
      getOrderDetails()
    }
  }, [workOrderData]);

  useEffect(() => {
    if (workOrderError) {
      showErrorToast("","Something went wrong")
    }
  }, [workOrderError]);

  useEffect(() => {
    if (submitClicked) {
      postWorkOrder({
        service_id: serviceId,
        service_name: req,
      });
      setSubmitClicked(false);
    }
  }, [submitClicked]);

  // reqListState.length === 0 ?  <p>no</p>:
  // console.log(reqListState)

  return (
    <>
      <button
        className=" bg-purple-dark-shade text-white flex gap-2 justify-center items-center px-4 rounded-md"
        onClick={handleClickOpen}
      >
        <img src={plus} alt="" />
        <span className="font-[500] text-[.8rem] leading-[1.4rem]">
          {" "}
          Create Service Request
        </span>
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "50%",
            height: "50%",
          },
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogTitle>Services provided by us</DialogTitle>
        <DialogContent className="flex flex-col gap-1">
          <div className=" relative text-center">
            <i
              class={`fa-solid fa-magnifying-glass absolute  ${
                searchClicked ? "top-3 ml-2" : "top-3 left-52"
              } transition-all duration[500]`}
              style={{ color: "#ebebeb" }}
            ></i>
            <input
              style={{ fontFamily: "" }}
              onClick={() => {
                setSearchedClick(true);
              }}
              onChange={(e) => {
                change(e.target.value);
                setReq(e.target.value);
              }}
              value={req}
              className="px-[2rem] w-[100%] font-[400] text-[1rem] leading-[1.5rem] text-white rounded-lg placeholder:text-center placeholder:text-white whitespace-nowrap py-[0.5rem] bg-purple-dark-shade outline-none decoration-0"
              placeholder={` ${searchClicked ? "" : "Search"}`}
            />
          </div>
          {/* <input className="w-[100%] p-2 rounded-md outline-0 border-1 placeholder:text-center" placeholder="Search" value={req} onChange={(e)=>{setReq(e.target.value);change(e.target.value)}}/> */}
          <div className=" bg-white p-2 rounded-md overflow-y-scroll">
            {req.length > 0 ? (
              reqListState.length === 0 ? (
                <p>No Services found</p>
              ) : (
                reqListState.map((data) => {
                  return (
                    <>
                      <p
                        onClick={() => {
                          setServiceId(data.id);
                          setReq(data.service_title);
                        }}
                        className="m-0 cursor-pointer"
                      >
                        {data.service_title}
                      </p>
                    </>
                  );
                })
              )
            ) : (
              servicesListdata &&
              listArr.map((data) => {
                return (
                  <p
                    onClick={() => {
                      setServiceId(data.id);
                      setReq(data.service_title);
                    }}
                    className="m-0 cursor-pointer"
                  >
                    {data.service_title}
                  </p>
                );
              })
            )}
          </div>
          {/* {servicesListdata && 
            
         <ComboBox list={listArr}/>
        } */}
          {/* <select
            id="forOptionId"
            className=" p-2 h-[40%] rounded-lg shadow-sm outline-none"
            value={serviceName}
            onChange={(e) => {
              setServiceName(e.target.value);

              forServiceId();
            }}
          >
            {servicesListdata && (
              <>
                <option>Make a New request</option>
                {servicesListdata &&
                  listArr.map((data) => {
                    return (
                      <option value={data.id}>{data.service_title}</option>
                    );
                  })}
              </>
            )}
          </select> */}
        </DialogContent>
        <DialogActions>
          <button className="mx-2 my-4" onClick={handleClose}>
            Cancel
          </button>
          {/* <Button type="submit">Submit</Button> */}
          <button
            className="mx-6 my-4 hover:cursor-pointer"
            onClick={() => {
              setSubmitClicked(true);
            }}
          >
            Submit
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}
