import React, { useEffect, useState } from "react";
import AssetsHeading from "../AssetsHeading";
import grayCircle from "assets/images/assets/grayCircle.svg";
import grayBox from "assets/images/assets/grayBoxRectangle.svg";
import grayArt from "assets/images/assets/grayArtPic.svg";
import WhiteButton from "components/Button/WhiteButton";
import { Link, useLocation } from "react-router-dom";
import { ASSETS_PERSONAL_ASSET_DETAILED_URL, ASSETS_TYPE_URL } from "constants/routes";
import AddNewPersonalAsset from "./AddNewPersonalAsset";
import useGetAssetTypeOverview from "../data/useGetAssetTypeOverview";


const PersonalAsset = () => { 
  const location = useLocation()
  const [personalAssetData, setPersonalAssetData]=useState({})
  const [getData, getrror, getIsLoading, getRealEstate] =
    useGetAssetTypeOverview();
  const assetId=location.state?.assetId
    useEffect(() => {
      getRealEstate("personal-assets");
    }, []);

    useEffect(()=>{
      if(getData?.data){
        setPersonalAssetData(getData.data)
      }
    },[getData])
  return ( 
    <div className="pb-[6rem] px-[1rem] bg-gray-100 ">
      <AssetsHeading asset={"Personal Assets"}/>
      <div className=" mt-[1.5rem] border bg-white rounded-xl  grid grid-cols-12 divide-x">
        <div className=" p-[1rem] col-span-3">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Total Personal Assets Value
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">₹{personalAssetData.total_assets_value}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Total Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">{personalAssetData.total_assets}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Active Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">{personalAssetData.active_assets}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Idle Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">{personalAssetData.idle_assets}</h4>
        </div>
        <div className="flex justify-center items-center p-[1rem] col-span-3">
          <AddNewPersonalAsset />
        </div>
      </div>

      {/* Vehicle */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1.1rem] m-0">
              Vehicles
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
            {personalAssetData?.Vehicle?.[0]?.total_vehicle_assets_value} Rs.
            </h4>
          </div>

          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Add New" />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1.5rem]">
          {
            personalAssetData?.Vehicle?.length > 0 ? <>
                      <Link
            to={`${ASSETS_TYPE_URL.replace(
              ":type",
              "personal-asset-vehicle"
            )}/?id=${assetId.Vehicle}`}
            className="no-underline"
          >
            <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
              <img src={grayCircle} alt="circle" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">Vehicles</h5>
              <div className="flex justify-end mt-[1.8rem]">
                <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                  ₹ {personalAssetData?.Vehicle?.[0]?.total_vehicle_assets_value}
                </h4>
              </div>{" "}
            </div>
          </Link>
            </>:<>
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={grayCircle} alt="grayCircle" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">Vehicles</h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                  Add New
                </button>
              </div>{" "}
            </div>
            </>
          }
        </div>
      </div>

      {/* gold */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1.1rem] m-0">
            Gold and Jewellery
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
            {personalAssetData?.GoldAndJewellery?.[0]?.total_gold_jewellery_value} Rs.
            </h4>
          </div>
          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Add New" />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1.2rem]">
          {
            personalAssetData?.GoldAndJewellery?.length > 0 ? <>
                       <Link
            to={`${ASSETS_TYPE_URL.replace(
              ":type",
              "personal-asset-gold"
            )}/?id=${assetId["Gold And Jewellery"]}`}
            className="no-underline"
          >
            <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
              <img src={grayBox} alt="grayBox" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">Gold and Jewellery</h5>
              <div className="flex justify-end mt-[1.8rem]">
                <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                  ₹ {personalAssetData?.GoldAndJewellery?.[0]?.total_gold_jewellery_value}
                </h4>
              </div>{" "}
            </div>
          </Link>
            </>:<>
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={grayBox} alt="grayBox" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">Gold and Jewellery</h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                  Add New
                </button>
              </div>{" "}
            </div>
            </>
          }
        </div>
      </div>

      {/* Furniture */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1.1rem] m-0">
            Furniture and Art
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
              {personalAssetData?.ArtAndCollectibles?.[0]?.total_furniture_art_value} Rs.
            </h4>
          </div>
          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Add New" />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1.2rem]">
          {
            personalAssetData?.ArtAndCollectibles?.length > 0 ? <>
          <Link
            to={`${ASSETS_TYPE_URL.replace(
              ":type",
              "personal-asset-furniture"
            )}/?id=${assetId["Furniture And Art"]}`}
            className="no-underline"
          >
            <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
              <img src={grayArt} alt="grayArt" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">Furniture and Art</h5>
              <div className="flex justify-end mt-[1.8rem]">
                <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                  ₹ {personalAssetData?.ArtAndCollectibles?.[0]?.total_furniture_art_value}
                </h4>
              </div>{" "}
            </div>
          </Link>
            </>:<>
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={grayArt} alt="grayArt" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">Furniture and Art</h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                  Add New
                </button>
              </div>{" "}
            </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default PersonalAsset;
