import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useEffect, useRef, useState } from "react";
import "../../../Liabilities/liabilities.css";
import Button from "components/Button/BtnOrange";
import RedButton from "components/Button/RedButton";
import { useParams } from "react-router-dom";
import RemoveAsset from "../../RemoveAsset";
import upload from "assets/images/liabilities/upload.svg";
import trash from "assets/images/assets/trash-2.svg";
import usePatchDomainDetails from "../../data/usePatchDomainDetails";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";

const EditDomain = ({ editValue, detailData, setIsReload }) => {
  const { estate_type } = useParams();
  const removeBtnRef = useRef(null);
  const [name, setName] = useState("");
  const [errors, setErrors]= useState({})
  const [isClose, setIsClose]= useState(false)
  const [assetType, setAssetType] = useState("active");
   
  const [
    patchDomainData,
    patchDomainError,
    patchDomainIsLoading,
    patchhDomainDetails,
  ] = usePatchDomainDetails();

  useEffect(()=>{
    if(patchDomainData){
      showSuccessToast("Data updated successfully")
      setIsReload(true)
      setIsClose(true)
    }
    if(patchDomainError){
      showErrorToast("Error in Updating")
    }
  },[patchDomainData, patchDomainError])
  
const [domainForm, setDomainForm] = useState({
  domain_name: "",
  registrar: "",
  purchase_price: "",
  estimated_sale_value: "",
  purchase_date: "",
  expiry_date: "",
  auto_renewal: false,
  upload_invoice: null,
  set_remainder: null,
  domain_commercial_information: [
    {
      estimated_income: "",
      payout_frequency: "",
      associated_business_name: "",
      agreement: null
    }
  ]
});
useEffect(()=>{
  if(detailData){
     const {total_active_assets, total_assets, total_assets_amount, total_idle_assets,repayment, user, ...rest}=detailData;
     setDomainForm(rest)
  }
},[detailData])

  useEffect(() => {
    if (editValue) {
      switch (editValue) {
        case "domainValue":
          setName("Edit Domain Vlaue");
          break;
        case "domainDetails":
          setName("Edit License Details");
          break;
        case "domainCommercial":
          setName("Edit Commercial User");
          break;
        default:
          break;
      }
    }
  }, [editValue]);
  const toggleAssetType = (type) => {
    setAssetType(type);
  };

  const handleDomainSubmit = () => {
    if (!validateDomainForm()) return;
  
    const formData = new FormData();
     formData.append("encoded_domain_id",domainForm.id)
    if(editValue === "domainValue"){
      formData.append("purchase_price", domainForm.purchase_price);
      formData.append("estimated_sale_value", domainForm.estimated_sale_value);
    }else if(editValue === "domainDetails"){
 
          // Append simple fields
      formData.append("domain_name", domainForm.domain_name);
      formData.append("registrar", domainForm.registrar);
      formData.append("purchase_date", domainForm.purchase_date);
      formData.append("expiry_date", domainForm.expiry_date);
      formData.append("auto_renewal", domainForm.auto_renewal);
      if(typeof(domainForm.set_remainder)==="string"){

      }else{
        formData.append("set_remainder", domainForm.set_remainder);
      }
      if(typeof(domainForm.upload_invoice)==="string"){

      }else{
               // Append file field if it exists
      if (domainForm.upload_invoice) {
        formData.append("upload_invoice", domainForm.upload_invoice);
      }
      }
    }else if(editValue === "domainCommercial"){
          // Append domain commercial information as array data
    // domainForm.domain_commercial_information.forEach((info, index) => {
    //   formData.append(`domain_commercial_information[${index}][estimated_income]`, info.estimated_income);
    //   formData.append(`domain_commercial_information[${index}][payout_frequency]`, info.payout_frequency);
    //   formData.append(`domain_commercial_information[${index}][associated_business_name]`, info.associated_business_name);
  
    //   // Append file in domain commercial information if it exists
    //   if (info.agreement) {
    //     formData.append(`domain_commercial_information[${index}][agreement]`, info.agreement);
    //   }
    // });
    }

    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
  
    console.log('domainForm', domainForm)
  
    // Send formData to your API
   patchhDomainDetails(formData, domainForm.asset)
  };
  
  const validateDomainForm = () => {
    const newErrors = {};
    const numberPattern = /^[0-9]*$/;
    const filePattern = /\.(pdf|jpg|jpeg|png)$/i;
    let hasDomainCommercialErrors = false;

    if(editValue === "domainValue"){
      if (!domainForm.purchase_price || !numberPattern.test(domainForm.purchase_price)) {
        newErrors.purchase_price = "Purchase price is required and should be a number.";
      }
    
      if (!domainForm.estimated_sale_value || !numberPattern.test(domainForm.estimated_sale_value)) {
        newErrors.estimated_sale_value = "Estimated sale value is required and should be a number.";
      }
    }else if(editValue === "domainDetails"){
      console.log("iammm here")
      if (!domainForm.domain_name) {
        newErrors.domain_name = "Domain name is required.";
      }
    
      if (!domainForm.registrar) {
        newErrors.registrar = "Registrar is required.";
      }
      if (!domainForm.purchase_date) {
        newErrors.purchase_date = "Purchase date is required.";
      }
    
      if (!domainForm.expiry_date) {
        newErrors.expiry_date = "Expiry date is required.";
      }
      if(typeof(domainForm.upload_invoice)==="string"){

      }else{
        if(!domainForm.upload_invoice){
          newErrors.upload_invoice="Invoice is Required"
        }else if(!filePattern.test(domainForm.upload_invoice.name)){
          newErrors.upload_invoice="Invoice should be a valid file format (PDF, JPG, or PNG)."
        }
      }
    }else if(editValue === "domainCommercial"){
            // Validate each domain commercial information entry
    newErrors.domain_commercial_information = domainForm.domain_commercial_information.map((info, index) => {
      const infoErrors = {};
      if (!info.estimated_income || !numberPattern.test(info.estimated_income)) {
        infoErrors.estimated_income = `Estimated income is required for domain commercial information ${index + 1} and should be a number.`;
      }
  
      if (!info.payout_frequency) {
        infoErrors.payout_frequency = `Payout frequency is required for domain commercial information ${index + 1}.`;
      }
  
      if (info.agreement && !filePattern.test(info.agreement.name)) {
        infoErrors.agreement = `Agreement for domain commercial information ${index + 1} should be a valid file format (PDF, JPG, or PNG).`;
      }
  
      if (Object.keys(infoErrors).length > 0) {
        hasDomainCommercialErrors = true;
        return infoErrors;
      }
  
      return null;
    }).filter(Boolean);
  
    if (!hasDomainCommercialErrors) {
      delete newErrors.domain_commercial_information;
    }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0 && !hasDomainCommercialErrors;
  };
  
  const handleDomainChange = (e) => {
    let { name, value, type, files } = e.target;
    if(type==="radio"){
       const bool = value==="true" ? true : value === "false"? false : null ;
       value = bool 
    }
    setDomainForm((prevForm) => ({
      ...prevForm,
      [name]: type === "file" ? files[0] : value
    }));
  };
  
  const handleDomainCommercialChange = (index, e) => {
    const { name, value, files, type } = e.target;
    const fieldName = name.split(".")[1];
    const updatedDomainCommercialInfo = domainForm.domain_commercial_information.map((info, i) => (
      i === index ? { ...info, [fieldName]: type === "file" ? files[0] : value } : info
    ));
    setDomainForm({ ...domainForm, domain_commercial_information: updatedDomainCommercialInfo });
  };
  
  const addDomainCommercialInfo = () => {
    setDomainForm((prevForm) => ({
      ...prevForm,
      domain_commercial_information: [
        ...prevForm.domain_commercial_information,
        {
          estimated_income: "",
          payout_frequency: "",
          associated_business_name: "",
          agreement: null
        }
      ]
    }));
  };

  const handleRemoveEstate = () => {
    removeBtnRef.current.click();
  };

  const removeCommercialItem =(index)=>{
    const updatedCommercial= domainForm.domain_commercial_information.filter((el,i)=> i !== index)
    const updatedDomainForm={...domainForm, domain_commercial_information:updatedCommercial}
    setDomainForm(updatedDomainForm)
  }

  const handleViewDocument =()=>{
    window.open(URL.createObjectURL(domainForm.upload_invoice), "_blank")
  }

  const handleViewCommercialPdf=(pdf)=>{
    window.open(URL.createObjectURL(pdf), "_blank")
  }

  const getContent = () => {
    switch (editValue) {
      case "domainValue":
        return (
          <>
            <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
              <div className="grid grid-cols-12 gap-3 mt-2">
                <div className="col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Purchase Price
                  </label>
                  <div className="w-[100%] rounded-md border-[1px] flex justify-center items-center focus-within:border-customPurple">
                    <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                      Rs.
                    </span>
                    <InputField
                        type="number"
                        name="purchase_price"
                        value={domainForm.purchase_price}
                        onChange={handleDomainChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                  </div>
                  {errors.purchase_price && <p className="text-red-500 text-[0.75rem]">{errors.purchase_price}</p>}
                </div>

                <div className="col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Estimated Sale Value
                  </label>
                  <div className="w-[100%] rounded-md border-[1px] flex justify-center items-center focus-within:border-customPurple">
                    <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                      Rs.
                    </span>
                    <InputField
                        type="number"
                        name="estimated_sale_value"
                        value={domainForm.estimated_sale_value}
                        onChange={handleDomainChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                  </div>
                  {errors.estimated_sale_value && <p className="text-red-500 text-[0.75rem]">{errors.estimated_sale_value}</p>}
                </div>
              </div>
            </div>
            <div
              className={`rounded-md bg-[#F1F5F9] mt-[0.8rem] px-[1rem] py-[0.7rem] mb-3`}
            >
              <h4 className="text-[0.9rem] font-[500] text-darkGray">
                Remove this Domain from the Assets?
              </h4>
              <div className="w-[20%]" onClick={handleRemoveEstate}>
                <RedButton
                  name={`Remove`}
                  px={"px-[0.4rem]"}
                  py={"py-[0.4rem]"}
                />
              </div>
            </div>
          </>
        );
      case "domainDetails":
        return (
          <>
            <div className="rounded-md bg-[#F1F5F9] mt-[1rem] mb-[1.5rem]">
              <div className="grid grid-cols-12 px-3 py-2.5 gap-3">
                <div className="flex flex-col gap-1 col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Domain Name
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={domainForm.domain_name}
                    onChange={handleDomainChange}
                    name="domain_name"
                    placeholder="Domain Name"
                  />
                  {errors.domain_name && <p className="text-red-500 text-[0.75rem]">{errors.domain_name}</p>}
                </div>
                <div className="flex flex-col gap-1 col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Registrar
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={domainForm.registrar}
                    onChange={handleDomainChange}
                    name="registrar"
                    placeholder="Registrar"
                  />
                  {errors.registrar && <p className="text-red-500 text-[0.75rem]">{errors.registrar}</p>}
                </div>
              </div>
              <div className="grid grid-cols-12 px-3 py-2.5 gap-3">
                <div className="col-span-6">
                  <div className="cursor-pointer px-4 py-1.5 flex items-center justify-center border-[1px] border-[#94A3B8] rounded-lg gap-1.5 bg-white" onClick={()=>document.getElementById(`file-upload-invoice`).click()} >
                    <img src={upload} alt="uploadPic" />
                    <p className="text-[#334155] m-0 font-[600]">
                      Upload Invoice
                    </p>
                  </div>
                  {errors.upload_invoice && <p className="text-red-500 text-[0.75rem]">{errors.upload_invoice}</p>}
                </div>
                <InputField 
                  id={`file-upload-invoice`}
                  type="file"
                  onChange={handleDomainChange}
                  className="hidden"
                  name="upload_invoice"
                />
              </div>
            </div>
            <div className="rounded-md bg-[#F1F5F9] mt-[1rem] mb-3 p-2">
              <div className="grid grid-cols-12 gap-3 px-3 py-2 mt-1.5">
                <div className="input-container w-[100%] group col-span-6 flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Purchase Date
                  </label>
                  <InputField
                      type="date"
                      className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[97%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={domainForm.purchase_date}
                      name="purchase_date"
                      onChange={handleDomainChange}
                    />
                    {errors.purchase_date && <p className="text-red-500 text-[0.75rem]">{errors.purchase_date}</p>}
                </div>
                <div className="input-container w-[100%] group col-span-6 flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Expiry Date
                  </label>
                  <InputField
                    type="date"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[97%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={domainForm.expiry_date}
                    name="expiry_date"
                    onChange={handleDomainChange}
                  />
                    {errors.expiry_date && <p className="text-red-500 text-[0.75rem]">{errors.expiry_date}</p>}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3 px-3">
                <div className="input-container">
                  <label className="input-label">Auto Renewal</label>
                  <div className="flex gap-3 items-center mt-[0.5rem]">
                    <label className="input-label cursor-pointer focus-within:text-customPurple">
                    <InputField
                      type="radio"
                      value={true}
                      name="auto_renewal"
                      checked={domainForm.auto_renewal===true}
                      onChange={handleDomainChange}
                      className="mr-2 custom-radio cursor-pointer"
                    />
                      Yes
                    </label>
                    <label className="input-label cursor-pointer focus-within:text-customPurple">
                    <InputField
                      type="radio"
                      value={false}
                      name="auto_renewal"
                      checked={domainForm.auto_renewal===false}
                      onChange={handleDomainChange}
                      className="mr-2 custom-radio cursor-pointer"
                    />
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div onClick={()=>document.getElementById(`file-upload-reminder`).click()} className="ml-3 mt-3 w-[37%] px-3 py-1.5 flex items-center border-[1px] border-[#94A3B8] rounded-lg gap-1.5 bg-white">
                <img src={upload} alt="uploadPic" />
                <p className="text-[#334155] m-0 font-[600]">Set Reminder</p>
              </div>
              <InputField 
                id={`file-upload-reminder`}
                type="file"
                onChange={handleDomainChange}
                className="hidden"
                name="set_remainder"
              />
            </div>
          </>
        );
      case "domainCommercial":
        return (
          <>
            <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
              <div className="flex gap-3 items-center mt-3">
                <div className="flex flex-col gap-1  w-[45%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Name of Associated Business
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    // value={el.relation}
                    // onChange={(e)=>handleInputChange(i,e)}
                    // name="relation"
                    placeholder="Acme"
                  />
                </div>
                <div className="input-container w-[35%] flex flex-col gap-1 group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Payout Frequency
                  </label>
                  <InputField
                    type="select"
                    className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                    options={[
                      { value: "", label: "Select Type" },
                      { value: "SECURED", label: "SECURED" },
                      { value: "UNSECURED", label: "UNSECURED" },
                      { value: "HAND", label: "HAND" },
                    ]}
                    // name="loan_type"
                    // value={el.loan_type}
                    // onChange={(e)=>handleInputChange(i,e)}
                  />
                </div>
              </div>
              <div className="flex gap-3 items-center mt-3">
                <div className="w-[40%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Estimated Income
                  </label>
                  <div className="w-[100%] rounded-md border-[1px] flex justify-center items-center focus-within:border-customPurple">
                    <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                      Rs.
                    </span>
                    <InputField
                      type="number"
                      // name="amount_borrowed"
                      //   value={el.amount_borrowed}
                      //   onChange={(e)=>handleInputChange(i,e)}
                      className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                    />
                  </div>
                </div>
                <div className="w-[40%] px-3 py-1.5 flex items-center  border-[1px] rounded-lg gap-1.5 bg-white mt-4">
                  <img src={upload} alt="uploadPic" />
                  <p className="text-[#334155] m-0 font-[600]">Set Reminder</p>
                </div>
                <div className="mt-[19px]">
                  <img src={trash} alt="trashPic" />
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <DialogBox
        triggerElement={
          <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Edit" />
        }
        heading={name}
        content={(handleClose) => (
          <>
            {getContent()}
            <div className="flex justify-end gap-[1rem] ">
              <WhiteButton
                onClick={handleClose}
                name="Cancel"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
              <Button name={"Save"} px={"px-[0.6rem]"} py={"py-[0.4rem]"} onClick={handleDomainSubmit}/>
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
      <RemoveAsset
        heading="Loan"
        assetId={domainForm.asset}
        deleteId={`${domainForm.asset}/?id=${domainForm.id}&model_type=domain`}
        triggerElement={
          <button ref={removeBtnRef} className="hidden">
            Remove
          </button>
        }
      />
    </div>
  );
};

export default EditDomain;
