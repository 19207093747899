import React, { useEffect, useState } from "react";
import AssetsHeading from "../AssetsHeading";
import info1 from "assets/images/incomeTax/info1.svg";
import WhiteButton from "components/Button/WhiteButton";
import EditLoan from "./Edit/EditLoan";
import { useLocation } from "react-router-dom";
import useGetLoanDetails from "../data/useGetLoanDetails";

const LoanDetailView = () => {
  const location = useLocation();
  const assetId = location.state?.assetId;
  const itemId = location.state?.itemId;
  const itemName = location.state?.itemName;
  const [isReload, setIsReload]= useState(false)
  const [getLoanData, getLoanError, getLoanIsLoading, getLoan] =
  useGetLoanDetails();
  const [detailData, setDetailData]=useState({})
  useEffect(()=>{
    getLoan(`${assetId}/?id=${itemId}`)
  },[])
 useEffect(()=>{
  if(isReload){
    getLoan(`${assetId}/?id=${itemId}`)
    setIsReload(false)
  }
 },[isReload])

  useEffect(()=>{
    if(getLoanData?.data){
      setDetailData(getLoanData.data)
      console.log("getLoanData", getLoanData)
    }
  },[getLoanData])
  
  const handleViewPdf=()=>{
    if(detailData.loan_agreement){
      window.open(detailData.loan_agreement, "_blank")
    }
  }
  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 ">
      <AssetsHeading asset={"Other Assets"} asset_type={"Loan Given to Family-Friends"} asset_detail={itemName} assetId={assetId}/>
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between items-center">
          <h4 className="text-darkGray font-[500] text-[1rem] m-0">
            Loan Value
          </h4>
        </div>
        <div className="flex justify-between mt-[1rem]">
          <div className="">
            <h4 className="text-customPurple font-[500] text-[1.7rem] ">
              ₹55 Lakhs
            </h4>
            <div className="p-[0.5rem] border-1 border-customPurple bg-[#FAF8FC] rounded-md">
              <h4 className="text-[0.8rem] font-[600] text-customPurple m-0">
                10 % of Total Assets (₹50.86 Lakhs)
              </h4>
            </div>
          </div>

          <div className="bg-[#F8FAFC] border rounded-md w-[25%] flex items-center gap-9">
            <div className="p-[1rem]">
              <h4 className="text-lightGray font-[500] text-[0.8rem]">
                Asset Status
              </h4>
              <div className="flex gap-1">
                <p className="text-darkGray font-[500] text-[1.1rem] m-0">
                  Idle Asset
                </p>
                <img src={info1} alt="info" />
              </div>
            </div>
            <div className="border-l h-[100%] flex justify-center items-center px-3">
              {/* <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Edit" /> */}
              <EditLoan editValue="loanValue" detailData={detailData} setIsReload={setIsReload}/>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-2 h-[40%] mt-3">
        <div className="flex flex-col gap-2 w-[45%]">
          <div className="border bg-white rounded-xl p-[1rem]">
            <div className="flex justify-between items-center">
              <h4 className="text-darkGray font-[500] text-[1rem] m-0">
                Borrower Details
              </h4>
              {/* <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Edit" /> */}
              <EditLoan editValue="loanDetails" detailData={detailData} setIsReload={setIsReload}/>
            </div>
            <div className="flex items-center gap-20 mt-2.5">
              <div className="flex flex-col gap-1">
                <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                  Name
                </p>
                <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                  {detailData.borrower_name}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                  Relation
                </p>
                <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.relation}
                </p>
              </div>
            </div>
          </div>
          <div className="border bg-white rounded-xl p-[1rem]">
            <div className="flex justify-between items-center">
              <h4 className="text-darkGray font-[500] text-[1rem] m-0">
                Interest Details
              </h4>
              {/* <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Edit" /> */}
              <EditLoan editValue="loanInterest"  detailData={detailData} setIsReload={setIsReload}/>
            </div>
            <div className="flex items-center gap-10 mt-2.5">
              <div className="flex items-center gap-4">
                <div className="flex flex-col gap-1">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Interest Rate
                  </p>
                  <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                  {detailData.interest_rate}%
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Payout
                  </p>
                  <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                  {detailData.payout_period}
                  </p>
                </div>
              </div>
              <div>
                <div className="flex flex-col gap-1">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                  Estimated Interest
                  </p>
                  <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                  {detailData.estimated_interest}%
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[55%] border bg-white rounded-xl p-[1rem]">
          <div className="flex justify-between items-center">
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
              Loan Details
            </h4>
            <div className="flex gap-1">
              <WhiteButton
                px={"px-[0.8rem]"}
                py={"py-[0.3rem]"}
                name="View Agreement"
                onClick={handleViewPdf}
              />
              {/* <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Edit" /> */}
              <EditLoan editValue="loanInfo" detailData={detailData} setIsReload={setIsReload}/>
            </div>
          </div>
          <div className="mt-2.5 flex items-center gap-10">
            <div className="flex flex-col gap-1 w-[25%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Type
              </p>
              <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
              {detailData.loan_type}
              </p>
            </div>
            <div className="flex flex-col gap-1 w-[25%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Mode of Transaction
              </p>
              <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                {detailData.mode_of_transaction}
              </p>
            </div>
            <div className="flex flex-col gap-1 w-[25%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Start Date
              </p>
              <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                {detailData.loan_start_date}
              </p>
            </div>
          </div>
          <div className="mt-3 flex items-center gap-10">
            <div className="flex flex-col gap-1 w-[25%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Due Date
              </p>
              <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                {detailData.loan_due_date}
              </p>
            </div>
            <div className="flex flex-col gap-1 w-[25%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Period
              </p>
              <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                {detailData.period} Years
              </p>
            </div>
            <div className="flex flex-col gap-1 w-[25%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                End Date
              </p>
              <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                {detailData.loan_end_date}
              </p>
            </div>
          </div>
          <div className="mt-3 flex items-center gap-10">
            <div className="flex flex-col gap-1 w-[25%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Amount Borrowed
              </p>
              <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                {detailData.amount_borrowed} Rs.
              </p>
            </div>
            <div className="flex flex-col gap-1 w-[25%]">
              <p className="text-[#9065B4] m-0 font-[500] text-[0.75rem]">
                Payment Received
              </p>
              <p className="text-[#9065B4] m-0 text-[1.1rem] font-[500]">
                {detailData.amount_received} Rs.
              </p>
            </div>
            <div className="flex flex-col gap-1 w-[25%]">
              <p className="text-[#9065B4] m-0 font-[500] text-[0.75rem]">
                Balance Amount
              </p>
              <p className="text-[#9065B4] m-0 text-[1.1rem] font-[500]">
                {detailData.balance_amount} Rs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 border bg-white rounded-xl p-[1rem]">
        <h4 className="text-darkGray font-[500] text-[1rem] m-0">
          Repayment Details
        </h4>
        <div className="rounded-lg border mt-2.5">
          <table className="min-w-full border-collapse">
            <thead className="text-[#64748B] rounded-tl-lg">
              <tr>
                <th className="p-2.5 border-r border-b rounded-tl-lg bg-[#F8FAFC] font-[500] text-[0.8rem] w-[24%]">
                  Transaction ID
                </th>
                <th className="p-2.5 border-r border-b bg-[#F8FAFC] font-[500] text-[0.8rem] w-[24%]">
                  Date
                </th>
                <th className="p-2.5 border-r border-b bg-[#F8FAFC] font-[500] text-[0.8rem] w-[24%]">
                  Amount Paid
                </th>
                <th className="p-2.5 border-b bg-[#F8FAFC] rounded-tr-lg font-[500] text-[0.8rem] w-[27%]">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-2.5 border-r text-[#334155] font-[600]">
                  98765432100
                </td>
                <td className="p-2.5 border-r text-[#334155] font-[600]">
                  03/10/2024
                </td>
                <td className="p-2.5 border-r text-[#334155] font-[600]">
                  1.00,000 Rs.
                </td>
                <td className="p-2.5 text-[#334155] font-[600] flex items-center gap-4">
                  <WhiteButton
                    px={"px-[0.8rem]"}
                    py={"py-[0.3rem]"}
                    name="View Invoice"
                  />
                  <WhiteButton
                    px={"px-[0.8rem]"}
                    py={"py-[0.3rem]"}
                    name="Edit"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LoanDetailView;
