import React, { useState, createContext, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Blogs from "containers/blogs/Blogs.jsx";
import NavBar from "components/navBar/NavBar.js";
import Footer from "components/Footer/Footer.js";
import ServicesPage from "containers/services/ServicesPage.jsx";
import Home from "containers/home/Home.jsx";
import {
  ASSETS_DEPOSITS_DETAILED_URL,
  ASSETS_OTHER_ASSET_DETAILED_URL,
  ASSETS_PERSONAL_ASSET_DETAILED_URL,
  ASSETS_REAL_ESTATE_DETAILED_URL,
  ASSETS_TYPE_URL,
  ASSETS_URL,
  BANK_ACCOUNTS_URL,
  BUSINESS_INCOME_TAX_PROFILE_INFORMATION_URL,
  BUSINESS_INVITES_URL,
  BUSINESS_PROFILE_URL,
  FAMILY_LOAN_DETAILED_VIEW,
  GET_SERVICE_URL,
  GOOGLE_SIGNIN_SUCCESS_PAGE_URL,
  GOVT_IDS_URL,
  INCOME_TAX_PROFILE_INFORMATION_URL,
  INCOME_TAX_PROFILE_URL,
  INCOME_TAX_SUMMARY_URL,
  INCOME_TAX_SUMMARY_USER_DETAILS,
  INCOME_TAX_SUMMARY_USER_PAGE_URL,
  INVITES_URL,
  LIABILITIES_FAMILY_LOAN,
  LIABILITIES_PERSONAL_LOAN,
  LIABILITIES_PERSONAL_LOAN_DETAILS,
  LIABILITIES_REVOLVING_LOAN,
  LIABILITIES_REVOLVING_LOAN_DETAILED_URL,
  LIABILITIES_URL,
  REPORTS_URL,
  STAKEHOLDER_LIST,
  USER_INCOME_SUMMARY_PAGE_URL,
} from "constants/routes";
import ToShowNavAndFooter from "components/showNavFooter/ToShowNavAndFooter";
import Dashboard from "containers/privateRoutes/Dashboard/Dashboard";
import OrdersList from "containers/privateRoutes/Orders/OrdersList";
import Profile from "containers/privateRoutes/Profile/Profile";
import Registration from "containers/Registration/Registration";
import BlogDetails from "containers/blogs/BlogDetails";
import GoogleLoginLoader from "containers/LoginAndSignUp/GoogleLoginLoader";

import SetPrivateRoute from "components/settingPrivateRoute/SetPrivateRoute";
import GoogleSuccessLoginRedirect from "containers/LoginAndSignUp/GoogleSuccessLogin";
import LoginPrivateRoute from "components/settingPrivateRoute/LoginPrivateRoute";
import IncomeTax from "containers/privateRoutes/IncomeTax/IncomeTax";
import SignupSuccessModal from "components/mobileModals/SignupSuccessModal";
import OrderPlacedModal from "components/mobileModals/OrderPlacedModal";
import { BLOG_DETAILS_URL } from "constants/routes";
import ForgotPassword from "containers/LoginAndSignUp/ForgotPassword";
import Otp from "containers/LoginAndSignUp/Otp";
import SetNewPswd from "containers/LoginAndSignUp/SetNewPswd";
import IncomeTaxProfile from "containers/privateRoutes/IncomeTax/IncomeTaxProfile";
import useWindowSize from "components/navBar/useWindowResize";
import IncomeTaxServices from "containers/IncomeTaxServices/IncomeTaxServices";
import IncomeTaxSummary from "containers/privateRoutes/IncomeTax/IncomeTaxSummary/IncomeTaxSummary";
import UserIncomeSummaryPage from "containers/privateRoutes/IncomeTax/IncomeTaxSummary/UserIncomeSummaryPage";
import UserDetailsSummaryPage from "containers/privateRoutes/IncomeTax/IncomeTaxSummary/UserDetailsSummaryPage";
import Layout from "containers/privateRoutes/Profile/Layout";
import SummaryUserPage from "containers/privateRoutes/IncomeTax/IncomeTaxSummary/SummaryUserPage";
import Reports from "containers/privateRoutes/Reports/Reports";
import GovtIds from "containers/privateRoutes/Tax Profile/GovtIds";
import Accounts from "containers/privateRoutes/Tax Profile/Accounts";
import Invites from "containers/privateRoutes/Tax Profile/Invites";
import IncomeTaxProfileInformation from "containers/privateRoutes/IncomeTax/IncomeTaxProfileInformation";
import BusinessProfile from "containers/privateRoutes/BusinessProfile/BusinessProfile";
import BusinessMembersAndInvites from "containers/privateRoutes/BusinessProfile/BusinessMembersAndInvites";
import LoginSignUpBusiness from "containers/BusinessLoginSignUp.js/LoginSignUpBusiness";
import SignupBusiness from "containers/BusinessLoginSignUp.js/SignupBusiness";
import SignupMobileVerification from "containers/BusinessLoginSignUp.js/SignupMobileVerification";
import BusinessDetails from "containers/BusinessLoginSignUp.js/BusinessDetails";
import StakeHolderList from "containers/privateRoutes/Dashboard/StakeHolderList";
import SampleStakeholder from "containers/privateRoutes/Tax Profile/SampleStakeholder";
import InitialLiabilitiesPage from "containers/privateRoutes/Liabilities/InitialLiabilitiesPage";
import PersonalLoan from "containers/privateRoutes/Liabilities/UnsecuredLoans/PersonalLoan";
import PersonalLoanDetailed from "containers/privateRoutes/Liabilities/UnsecuredLoans/PersonalLoanDetailed";
import FamilyLoanDetailedView from "containers/privateRoutes/Liabilities/FamilyAndInformalLoan/FamilyLoanDetailedView";
import FamilyLoan from "containers/privateRoutes/Liabilities/FamilyAndInformalLoan/FamilyLoan";
import RevolvingLoan from "containers/privateRoutes/Liabilities/RevolvingCredit/RevolvingLoan";
import RevolvingLoanDetailed from "containers/privateRoutes/Liabilities/RevolvingCredit/RevolvingLoanDetailed";
import Assets from "containers/privateRoutes/Assets/AssetsLandingPage";
import RealEstateDetailedView from "containers/privateRoutes/Assets/RealEstate/RealEstateDetailedView";
import AssetsComponets from "containers/privateRoutes/Assets/AssetsComponents";
import PersonalAssetDetailedView from "containers/privateRoutes/Assets/Personal Assets/PersonalAssetDetailedView";
import DepositsDetailedView from "containers/privateRoutes/Assets/Deposits/DepositsDetailedView";
import AssetsLandingPage from "containers/privateRoutes/Assets/AssetsLandingPage";
import OtherAssetDetailedView from "containers/privateRoutes/Assets/Other Assets/OtherAssetDetailedView";

export let myContext = createContext();
export let otpContext = createContext();
export let loaderContext = createContext();
export let registrationSuccessModalContext = createContext();
export let orderPlacedSuccessModalContext = createContext();

function App() {
  const [isAccessToken, setIsAccessToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [registrationSuccessModal, setRegistrationSuccessModal] =
    useState(false);
  const [orderPlacedSuccessModal, setOrderPlacedSuccessModal] = useState(false);
  var expiry = localStorage.getItem("expiry");

  var currentDate = new Date();
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  useEffect(() => {
    if (localStorage.getItem("token") && expiry) {
      var dateParts = expiry.split(" ");
      var dateComponents = dateParts[0]?.split("-");
      var timeComponents = dateParts[1]?.split(":");

      var year = parseInt(dateComponents[2]);
      var month = parseInt(dateComponents[1]) - 1;
      var day = parseInt(dateComponents[0]);
      var hour = parseInt(timeComponents[0]);
      var minute = parseInt(timeComponents[1]);

      if (dateParts[2].toLowerCase() === "pm" && hour !== 12) {
        hour += 12;
      } else if (dateParts[2].toLowerCase() === "am" && hour === 12) {
        hour = 0;
      }

      var givenDate = new Date(year, month, day, hour, minute);

      if (givenDate > currentDate) {
        setIsLoggedIn(true);
      } else if (givenDate < currentDate) {
        setIsLoggedIn(false);
      } else {
        console.log("The given date is the same as the current date and time.");
      }
    }
  }, [isLoggedIn]);

  return (
    <>
      {loading && (
        <div className="h-screen w-screen flex justify-center items-center">
          {" "}
          <div className="loader"></div>
        </div>
      )}
      {loading && (
        <div className="h-screen w-screen flex justify-center items-center">
          {" "}
          <div className="loader"></div>
        </div>
      )}

      <myContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
        <loaderContext.Provider value={{ loading, setLoading }}>
          <registrationSuccessModalContext.Provider
            value={{ registrationSuccessModal, setRegistrationSuccessModal }}
          >
            <orderPlacedSuccessModalContext.Provider
              value={{ orderPlacedSuccessModal, setOrderPlacedSuccessModal }}
            >
              <BrowserRouter>
                <ToShowNavAndFooter>
                  <NavBar />
                </ToShowNavAndFooter>
                <SignupSuccessModal />
                <OrderPlacedModal />

                <Routes>
                  <Route
                    path={`${GET_SERVICE_URL}/:servicesSlug`}
                    element={<ServicesPage />}
                  />
                  <Route path={BLOG_DETAILS_URL} element={<BlogDetails />} />
                  <Route path="/Incometax" element={<IncomeTaxServices />} />

                  <Route element={<SetPrivateRoute WIN_WIDTH={WIN_WIDTH} />}>
                    <Route element={<Layout />}>
                      <Route element={<Dashboard />} path="/dashboard" />
                      <Route element={<OrdersList />} path="/orders" />
                      <Route element={<Profile />} path="/profile" />
                      <Route element={<IncomeTax />} path="/income-tax" />

                      <Route
                        element={<UserDetailsSummaryPage />}
                        path={INCOME_TAX_SUMMARY_USER_DETAILS}
                      />

                      <Route
                        element={<IncomeTaxProfile />}
                        path={INCOME_TAX_PROFILE_URL}
                      />
                      <Route
                        element={<IncomeTaxSummary />}
                        path={INCOME_TAX_SUMMARY_URL}
                      />
                      <Route
                        element={<SummaryUserPage />}
                        path={INCOME_TAX_SUMMARY_USER_PAGE_URL}
                      />
                      <Route
                        element={<UserIncomeSummaryPage />}
                        path={USER_INCOME_SUMMARY_PAGE_URL}
                      />
                      <Route element={<Reports />} path={REPORTS_URL} />
                      <Route element={<GovtIds />} path={GOVT_IDS_URL} />
                      <Route element={<Accounts />} path={BANK_ACCOUNTS_URL} />
                      <Route element={<Invites />} path={INVITES_URL} />
                      <Route
                        element={<IncomeTaxProfileInformation />}
                        path={INCOME_TAX_PROFILE_INFORMATION_URL}
                      />
                      <Route
                        element={<BusinessProfile />}
                        path={BUSINESS_PROFILE_URL}
                      />
                      <Route
                        element={<IncomeTaxProfileInformation />}
                        path={BUSINESS_INCOME_TAX_PROFILE_INFORMATION_URL}
                      />
                      <Route
                        element={<BusinessMembersAndInvites />}
                        path={BUSINESS_INVITES_URL}
                      />
                      <Route
                        element={<SampleStakeholder />}
                        path={"/sample-stakeholder"}
                      />

                      <Route
                        element={<StakeHolderList />}
                        path={STAKEHOLDER_LIST}
                      />
                      <Route
                        element={<PersonalLoan />}
                        path={LIABILITIES_PERSONAL_LOAN}
                      />
                      <Route
                        element={<PersonalLoanDetailed />}
                        path={LIABILITIES_PERSONAL_LOAN_DETAILS}
                      />
                      <Route
                        element={<RevolvingLoan />}
                        path={LIABILITIES_REVOLVING_LOAN}
                      />
                      <Route
                        element={<RevolvingLoanDetailed />}
                        path={LIABILITIES_REVOLVING_LOAN_DETAILED_URL}
                      />
                      <Route
                        element={<InitialLiabilitiesPage />}
                        path={LIABILITIES_URL}
                      />

                      <Route
                        element={<FamilyLoanDetailedView />}
                        path={FAMILY_LOAN_DETAILED_VIEW}
                      />

                      <Route
                        element={<FamilyLoan />}
                        path={LIABILITIES_FAMILY_LOAN}
                      />
                      <Route
                        element={<AssetsLandingPage />}
                        path={ASSETS_URL}
                      />

                      <Route
                        element={<AssetsComponets />}
                        path={ASSETS_TYPE_URL}
                      />
                      <Route
                        element={<RealEstateDetailedView />}
                        path={ASSETS_REAL_ESTATE_DETAILED_URL}
                      />
                      <Route
                        element={<PersonalAssetDetailedView />}
                        path={ASSETS_PERSONAL_ASSET_DETAILED_URL}
                      />
                      <Route
                        element={<DepositsDetailedView />}
                        path={ASSETS_DEPOSITS_DETAILED_URL}
                      />
                      <Route
                        element={<OtherAssetDetailedView />}
                        path={ASSETS_OTHER_ASSET_DETAILED_URL}
                      />
                    </Route>
                  </Route>

                  <Route
                    path="/googleLoginLoading"
                    element={<GoogleLoginLoader />}
                  />
                  <Route
                    path={GOOGLE_SIGNIN_SUCCESS_PAGE_URL}
                    element={<GoogleSuccessLoginRedirect />}
                  />
                  <Route path="/" element={<Home />} />
                  <Route path="/blogs" element={<Blogs />} />
                  <Route path="/registrationPage" element={<Registration />} />

                  <Route element={<LoginPrivateRoute />}>
                    <Route path="/newLogin" element={<LoginSignUpBusiness />} />
                    <Route path="/newSignup" element={<SignupBusiness />} />
                    <Route
                      path="/businessDetails"
                      element={<BusinessDetails />}
                    />
                    <Route
                      path="/signUpMobileVerification"
                      element={<SignupMobileVerification />}
                    />

                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route path="/otp" element={<Otp />} />
                    <Route path="/set-password" element={<SetNewPswd />} />
                  </Route>
                </Routes>
                {/* </Layout> */}
                {/* {WIN_WIDTH < 768 && <FooterNav />} */}

                <ToShowNavAndFooter>
                  {loading === false && <Footer />}
                </ToShowNavAndFooter>
              </BrowserRouter>
            </orderPlacedSuccessModalContext.Provider>
          </registrationSuccessModalContext.Provider>
        </loaderContext.Provider>
        {/* </otpContext.Provider> */}
      </myContext.Provider>
    </>
  );
}

export default App;
