import React, { useRef, useState, useEffect } from "react";
import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import Button from "components/Button/BtnOrange";
import RedButton from "components/Button/RedButton";
import { useParams } from "react-router-dom";
import RemoveAsset from "../../RemoveAsset";

const EditLandValue = ({
  deleteId,
  assetType,
  ownershipPercent,
  setassetType,
  setownershipPercent,
  updateDetails,
  estimatedMarketValue,
  setestimatedMarketValue,
  valuationReport,
  setvaluationReport,
  assetId,
}) => {
  const { estate_type } = useParams();
  const removeBtnRef = useRef(null);

  // Local state for validation and errors
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [errors, setErrors] = useState({
    ownershipPercent: "",
    assetType: "",
    valuationReport: "",
    estimatedMarketValue: "",
  });

  // Toggle asset type
  const toggleAssetType = (type) => {
    setassetType(type);
  };

  // Validate fields
  useEffect(() => {
    const newErrors = { ownershipPercent: "", assetType: "" };

    // Validate ownership percent
    if (ownershipPercent <= 0 || ownershipPercent > 100) {
      newErrors.ownershipPercent = "Ownership must be between 1 and 100.";
    }

    // Validate asset type selection
    if (!assetType) {
      newErrors.assetType = "Please select an asset type.";
    }
    {
      estate_type === "building" && (
        <>
          {!estimatedMarketValue &&
            (newErrors.estimatedMarketValue = "value is required.")}
          {!valuationReport &&
            (newErrors.valuationReport = "Report is required.")}
        </>
      );
    }

    setErrors(newErrors);

    // Enable save if no errors
    setIsSaveEnabled(
      !newErrors.ownershipPercent &&
        !newErrors.assetType &&
        !newErrors.estimatedMarketValue &&
        !newErrors.valuationReport
    );
  }, [ownershipPercent, assetType, estimatedMarketValue, valuationReport]);

  const handleRemoveEstate = () => {
    removeBtnRef.current.click();
  };

  return (
    <div>
      <DialogBox
        triggerElement={
          <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Edit" />
        }
        heading={`Edit ${estate_type === "land" ? "Land" : "Building"} Value`}
        content={(handleClose) => (
          <>
            <div className="rounded-md bg-[#F1F5F9] mt-[0.8rem] mb-[1rem] px-[1rem] py-[0.7rem]">
              <div className="grid grid-cols-2 ">
                <div>
                  <label className="input-label">Type of Asset</label>
                  <div
                    className={`w-[90%] p-1 flex gap-1 items-center border bg-white rounded-md overflow-hidden${
                      assetType === "idle" ? "w-[20%]" : "w-[100%]"
                    }`}
                  >
                    <button
                      onClick={() => toggleAssetType("active")}
                      className={`px-2 py-1 text-[0.8rem] ${
                        assetType === "active"
                          ? "bg-gray-800 text-white"
                          : "bg-white text-gray-500"
                      } rounded-md`}
                    >
                      Active Asset
                    </button>
                    <button
                      onClick={() => toggleAssetType("idle")}
                      className={`px-2 py-1 text-[0.8rem]  ${
                        assetType === "idle"
                          ? "bg-gray-800 text-white"
                          : "bg-white text-gray-500"
                      } rounded-md`}
                    >
                      Idle Asset
                    </button>
                  </div>
                  {errors.assetType && (
                    <p className="text-red-500 text-[0.7rem]">
                      {errors.assetType}
                    </p>
                  )}
                  <label className="text-[0.7rem] font-[500] text-customPurple">
                    {assetType === "active"
                      ? "This asset is generating income."
                      : "This asset is idle and not generating income."}
                  </label>
                </div>

                <div className="input-container">
                  <label className="input-label">Percentage Of Ownership</label>
                  <InputField
                    value={ownershipPercent}
                    onChange={(e) => setownershipPercent(e.target.value)}
                    className="input-box border bg-white"
                    type="number"
                    min="0"
                    max="100"
                  />
                  {errors.ownershipPercent && (
                    <p className="text-red-500 text-[0.7rem]">
                      {errors.ownershipPercent}
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* Market value */}
            {estate_type === "building" ? (
              <div className="rounded-md bg-[#F1F5F9] px-[1rem] py-[0.7rem]">
                <h4 className="text-[#334155] font-[500] text-[1rem]">
                  Market Value
                </h4>
                <div className="w-[25%]">
                  <InputField
                    type="file"
                    accept=".pdf"
                    id={`file-input-valuation`}
                    className="hidden"
                    onChange={(e) => setvaluationReport(e.target.files[0])}
                  />
                  <WhiteButton
                    onClick={() =>
                      document.querySelector(`#file-input-valuation`).click()
                    }
                    name={"Add Report"}
                    px={"px-[0.4rem"}
                    py={"py-[0.3rem]"}
                  />
                  {errors.valuationReport && (
                    <p className="text-red-500 text-[0.7rem]">
                      {errors.valuationReport}
                    </p>
                  )}
                </div>
                <div className="w-[50%] input-container mt-[1rem]">
                  <label className="input-label">Estimated Market Value</label>
                  <InputField
                    value={estimatedMarketValue}
                    onChange={(e) => setestimatedMarketValue(e.target.value)}
                    className="input-box bg-white border"
                    type="number"
                  />
                  {errors.estimatedMarketValue && (
                    <p className="text-red-500 text-[0.7rem]">
                      {errors.estimatedMarketValue}
                    </p>
                  )}
                </div>
                <label className="input-label">As per Valuation Report</label>
              </div>
            ) : (
              ""
            )}

            <div
              className={`rounded-md bg-[#F1F5F9] mt-[0.8rem] px-[1rem] py-[0.7rem] ${
                estate_type === "building" ? "mb-1" : "mb-[10rem]"
              }`}
            >
              <h4 className="text-[0.9rem] font-[500] text-darkGray">
                Remove this {estate_type === "land" ? "Land" : "Building"}?
              </h4>
              <h4 className="text-[0.7rem] font-[500] text-lightGray">
                This action is permanent and may affect your financial records.
              </h4>
              <div className="w-[30%]" onClick={handleRemoveEstate}>
                <RedButton
                  name={`Remove ${estate_type}`}
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
              </div>
            </div>
            {!isSaveEnabled && (
              <p className="text-red-500 text-[0.7rem] mt-2">
                Please fix the errors before saving.
              </p>
            )}
            <div className="flex justify-end gap-[1rem] ">
              <WhiteButton
                onClick={handleClose}
                name="Cancel"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
              <Button
                onClick={updateDetails}
                name={"Save"}
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
                disabled={!isSaveEnabled} // Disable button if fields are invalid
              />
            </div>
          </>
        )}
      />
      <RemoveAsset
        assetId={assetId}
        deleteId={deleteId}
        heading={estate_type}
        triggerElement={
          <button ref={removeBtnRef} className="hidden">
            Remove
          </button>
        }
      />
    </div>
  );
};

export default EditLandValue;
