import Button from "components/Button/BtnOrange";
import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useRef, useState } from "react";
import "../../../Liabilities/liabilities.css";
import InputField from "components/input/InputField";
import usePostLoan from "../../data/usePostLoan";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";
import { ASSETS_TYPE_URL } from "constants/routes";
import { useNavigate } from "react-router-dom";

const AddNewLoan = ({
  triggerElement,
  estateType,
  isAddLoan,
  setIsAddLoan,
  categoryId,
  assetId,
  setIsReload,
}) => {
  const [postLoanData, postLoanError, postLoanIsLoading, postLoan] =
    usePostLoan();
  const [currentStep, setCurrentStep] = useState(0);
  const [visitedSteps, setVisitedSteps] = useState([]);

  const [assetType, setAssetType] = useState("active"); // Default state
  const [errors, setErrors]= useState({})
  const [isClose, setIsClose]= useState(false)
  const navigate = useNavigate()
  useEffect(()=>{
    if(postLoanData){
     showSuccessToast("Posted Successfully")
     if (window.location.href.includes("other-asset-loan")) {
      setIsReload(true);
    }else{
      navigate(
        `${ASSETS_TYPE_URL.replace(":type", "other-asset-loan")}/?id=${categoryId}`
      );
    }
     setIsClose(true)
    }
    if (postLoanError) {
      showErrorToast("Error in Posting");
    }
  }, [postLoanData, postLoanError]);

  const [loanForm, setLoanForm] = useState({
    borrower_name: "",
    relation: "",
    loan_type: "",
    loan_purpose: "",
    loan_agreement: null,
    loan_start_date: "",
    loan_end_date: "",
    loan_due_date: "",
    period: "",
    mode_of_transaction: "",
    amount_borrowed: "",
    amount_received: "",
    balance_amount: "",
    interest_rate: "",
    payout_period: "",
    estimated_interest: "",
    interest_charged: false,
  });

  const resetState = () => {
    setLoanForm([
      {
        borrower_name: "",
        relation: "",
        loan_type: "",
        loan_purpose: "",
        loan_agreement: null,
        loan_start_date: "",
        loan_end_date: "",
        loan_due_date: "",
        period: "",
        mode_of_transaction: "",
        amount_borrowed: "",
        amount_received: "",
        balance_amount: "",
        interest_rate: "",
        payout_period: "",
        estimated_interest: "",
        interest_charged: false,
      },
    ]);
    setCurrentStep(0);
    setErrors({});
    setVisitedSteps([]);
  };

  const handleInputChange = (e) => {
    let { name, value, type, files } = e.target;
    if (type === "radio") {
      const bool = value === "true" ? true : value === "false" ? false : null;
      value = bool;
    }
    setLoanForm((prevForm) => ({
      ...prevForm,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  const validateLoanForm = () => {
    const newErrors = {};
    // Borrower Name: Required, only letters and spaces allowed
    const namePattern = /^[a-zA-Z\s]+$/;
    if (!loanForm.borrower_name) {
      newErrors.borrower_name = "Borrower name is required.";
    } else if (!namePattern.test(loanForm.borrower_name)) {
      newErrors.borrower_name =
        "Borrower name should only contain letters and spaces.";
    }

    // Relation: Required field
    if (!loanForm.relation) {
      newErrors.relation = "Relation is required.";
    }

    if (currentStep === 1) {
      // Loan Type: Required field
      if (!loanForm.loan_type) {
        newErrors.loan_type = "Loan type is required.";
      }

      // Loan Purpose: Required field
      if (!loanForm.loan_purpose) {
        newErrors.loan_purpose = "Loan purpose is required.";
      }

      // Loan Agreement: Required, must be a valid file format (e.g., PDF, JPG, JPEG, PNG)
      const filePattern = /\.(pdf|jpg|jpeg|png)$/i;
      if (!loanForm.loan_agreement) {
        newErrors.loan_agreement = "Loan agreement file is required.";
      } else if (!filePattern.test(loanForm.loan_agreement.name)) {
        newErrors.loan_agreement =
          "Loan agreement should be a valid file format (PDF, JPG, or PNG).";
      }

      // Loan Start Date: Required, should be a valid date
      if (!loanForm.loan_start_date) {
        newErrors.loan_start_date = "Loan start date is required.";
      } else if (isNaN(new Date(loanForm.loan_start_date).getTime())) {
        newErrors.loan_start_date = "Loan start date should be a valid date.";
      }

      // Loan End Date: Required, should be a valid date and after Loan Start Date
      if (!loanForm.loan_end_date) {
        newErrors.loan_end_date = "Loan end date is required.";
      } else if (isNaN(new Date(loanForm.loan_end_date).getTime())) {
        newErrors.loan_end_date = "Loan end date should be a valid date.";
      } else if (
        new Date(loanForm.loan_end_date) <= new Date(loanForm.loan_start_date)
      ) {
        newErrors.loan_end_date =
          "Loan end date should be after the start date.";
      }

      // Loan Due Date: Required, should be a valid date and after Loan End Date
      if (!loanForm.loan_due_date) {
        newErrors.loan_due_date = "Loan due date is required.";
      } else if (isNaN(new Date(loanForm.loan_due_date).getTime())) {
        newErrors.loan_due_date = "Loan due date should be a valid date.";
      } else if (
        new Date(loanForm.loan_due_date) <= new Date(loanForm.loan_end_date)
      ) {
        newErrors.loan_due_date = "Loan due date should be after the end date.";
      }

      // Period: Required, positive integer
      if (!loanForm.period || isNaN(loanForm.period)) {
        newErrors.period = "Period is required and should be a number.";
      } else if (loanForm.period <= 0) {
        newErrors.period = "Period must be a positive number.";
      }

      // Mode of Transaction: Required field
      if (!loanForm.mode_of_transaction) {
        newErrors.mode_of_transaction = "Mode of transaction is required.";
      }

      // Amount Borrowed: Required, numeric, and positive
      if (!loanForm.amount_borrowed || isNaN(loanForm.amount_borrowed)) {
        newErrors.amount_borrowed =
          "Amount borrowed is required and should be a number.";
      } else if (loanForm.amount_borrowed <= 0) {
        newErrors.amount_borrowed = "Amount borrowed must be greater than 0.";
      }

      // Amount Received: Required, numeric, and positive
      if (!loanForm.amount_received || isNaN(loanForm.amount_received)) {
        newErrors.amount_received =
          "Amount received is required and should be a number.";
      } else if (loanForm.amount_received <= 0) {
        newErrors.amount_received = "Amount borrowed must be greater than 0.";
      }

      // Balance Amount: Required, numeric, must be positive, and match Amount Borrowed - Amount Received
      const calculatedBalance =
        loanForm.amount_borrowed - (loanForm.amount_received || 0);

      if (
        loanForm.balance_amount === "" ||
        loanForm.balance_amount === undefined
      ) {
        newErrors.balance_amount = "Balance amount is required.";
      } else if (isNaN(loanForm.balance_amount)) {
        newErrors.balance_amount = "Balance amount should be a number.";
      } else if (loanForm.balance_amount <= 0) {
        newErrors.balance_amount = "Balance amount must be greater than 0.";
      }
    }

    if (currentStep === 2) {
      // Interest Rate: Required, numeric, between 0 and 100
      if (!loanForm.interest_rate || isNaN(loanForm.interest_rate)) {
        newErrors.interest_rate = "*required and number";
      } else if (loanForm.interest_rate < 0 || loanForm.interest_rate > 100) {
        newErrors.interest_rate = "Interest rate should be between 0 and 100.";
      }

      // Payout Period: Required field
      if (!loanForm.payout_period) {
        newErrors.payout_period = "Payout period is required.";
      }
      // Estimated Interest: Optional, numeric, positive
      if (
        loanForm.estimated_interest === "" ||
        loanForm.estimated_interest === undefined
      ) {
        // No error because it's optional
        newErrors.estimated_interest = "Estimated interest is required.";
      } else if (
        isNaN(loanForm.estimated_interest) ||
        loanForm.estimated_interest < 0
      ) {
        newErrors.estimated_interest =
          "Estimated interest should be a positive number.";
      }
    }
    // Set error state and check if no errors remain
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateLoanForm()) return;

    // Use FormData to submit file and form data
    const formData = new FormData();
    formData.append("borrower_name", loanForm.borrower_name);
    formData.append("relation", loanForm.relation);
    formData.append("loan_type", loanForm.loan_type);
    formData.append("loan_purpose", loanForm.loan_purpose);
    formData.append("loan_agreement", loanForm.loan_agreement);
    formData.append("loan_start_date", loanForm.loan_start_date);
    formData.append("loan_end_date", loanForm.loan_end_date);
    formData.append("loan_due_date", loanForm.loan_due_date);
    formData.append("period", loanForm.period);
    formData.append("mode_of_transaction", loanForm.mode_of_transaction);
    formData.append("amount_borrowed", loanForm.amount_borrowed);
    formData.append("amount_received", loanForm.amount_received);
    formData.append("balance_amount", loanForm.balance_amount);
    formData.append("interest_charged", loanForm.interest_charged);
    formData.append("interest_rate", loanForm.interest_rate);
    formData.append("payout_period", loanForm.payout_period);
    formData.append("estimated_interest", loanForm.estimated_interest);

    console.log("Form Data Submitted:", formData);
    console.log("form in sub", loanForm);
    // You can now send formData to your API
    postLoan(formData, categoryId);
  };

  const progressSteps = [
    {
      label: "Borrower Information",
      active: currentStep === 0,
      visited: visitedSteps.includes(1),
    },
    {
      label: `Loan Information`,
      active: currentStep === 1,
      visited: visitedSteps.includes(2),
    },
    {
      label: `Interest Details`,
      active: currentStep === 2,
      visited: visitedSteps.includes(3),
    },
  ];

  useEffect(() => {
    if (!visitedSteps.includes(currentStep)) {
      setVisitedSteps((prev) => [...prev, currentStep]);
    }
  }, [currentStep, visitedSteps]);

  const handleNext = () => {
    if (currentStep === 3) {
      handleSubmit();
    }
    if (currentStep < 3) {
      if (validateLoanForm()) {
        setCurrentStep(currentStep + 1);
      }
    } else {
      console.log("Reached the last step");
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const viewPdf = () => {
    if (loanForm.loan_agreement) {
      window.open(URL.createObjectURL(loanForm.loan_agreement), "_blank");
    }
  };

  const getContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Borrower Information
            </h4>

            {/* form */}
            <div className="rounded-md bg-[#F1F5F9] mt-[1rem] mb-[1.5rem]">
              <div className="grid grid-cols-12 px-3 py-2.5 gap-3">
                <div className="flex flex-col gap-1 col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Borrower Name
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={loanForm.borrower_name}
                    onChange={handleInputChange}
                    name="borrower_name"
                    placeholder="Borrower Name"
                  />
                  {errors.borrower_name && (
                    <p className="text-red-500 text-[0.75rem]">
                      {errors.borrower_name}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-1 col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Relation
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={loanForm.relation}
                    onChange={handleInputChange}
                    name="relation"
                    placeholder="Relation"
                  />
                  {errors.relation && (
                    <p className="text-red-500 text-[0.75rem]">
                      {errors.relation}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Loan Information
            </h4>
            <div className="overflow-y-auto max-h-[300px] scrollbar-hide">
              {/* purchase details */}
              <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
                <h4 className="text-[#334155] font-[600] text-[0.9rem]">
                  Type
                </h4>
                <div className="grid grid-cols-12 py-1 gap-3">
                  <div className="input-container flex flex-col gap-1 col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Loan Type
                    </label>
                    <InputField
                      type="select"
                      className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                      options={[
                        { value: "", label: "Select Type" },
                        { value: "Secured", label: "SECURED" },
                        { value: "Unsecured", label: "UNSECURED" },
                        { value: "Hard", label: "Hard Loan" },
                      ]}
                      name="loan_type"
                      value={loanForm.loan_type}
                      onChange={handleInputChange}
                    />
                    {errors.loan_type && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.loan_type}
                      </p>
                    )}
                  </div>

                  <div className="input-container col-span-4 mt-2.5 w-[100%] group">
                    <WhiteButton
                      name="Upload Agreement"
                      px={"px-[0.2rem]"}
                      py={"py-[0.5rem]"}
                      text={"text-[0.75rem]"}
                      mt={"mt-[12px]"}
                      onClick={() =>
                        document.getElementById("file-upload").click()
                      }
                    />
                    {errors.loan_agreement && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.loan_agreement}
                      </p>
                    )}
                  </div>
                  <InputField
                    id={`file-upload`}
                    type="file"
                    onChange={handleInputChange}
                    className="hidden"
                    name="loan_agreement"
                  />
                </div>
                <div className="grid grid-cols-12 py-1 gap-3 mt-2.5">
                  <div className="input-container flex flex-col gap-1 col-span-6 w-[100%] group">
                    <InputField
                      value={loanForm.loan_purpose}
                      type="text"
                      name="loan_purpose"
                      onChange={handleInputChange}
                      placeholder="Mention Purpose (If Any)"
                      className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    />
                    {errors.loan_purpose && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.loan_purpose}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
                <h4 className="text-[#334155] font-[600] text-[0.9rem]">
                  Duration
                </h4>
                <div className="grid grid-cols-12 gap-2 mt-3">
                  <div className="input-container w-[100%] group col-span-6 flex flex-col gap-1">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Start Date
                    </label>
                    <InputField
                      type="date"
                      className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={loanForm.loan_start_date}
                      name="loan_start_date"
                      onChange={handleInputChange}
                    />
                    {errors.loan_start_date && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.loan_start_date}
                      </p>
                    )}
                  </div>
                  <div className="input-container col-span-6 w-[100%] group flex flex-col gap-1">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Period
                    </label>
                    <InputField
                      type="text"
                      className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={loanForm.period}
                      onChange={handleInputChange}
                      name="period"
                      placeholder="period"
                    />
                    {errors.period && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.period}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-2 mt-3">
                  <div className="input-container w-[100%] group col-span-6 flex flex-col gap-1">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Due Date
                    </label>
                    <InputField
                      type="date"
                      className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={loanForm.loan_due_date}
                      name="loan_due_date"
                      onChange={handleInputChange}
                    />
                    {errors.loan_due_date && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.loan_due_date}
                      </p>
                    )}
                  </div>
                  <div className="input-container w-[100%] group col-span-6 flex flex-col gap-1">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      End Date
                    </label>
                    <InputField
                      type="date"
                      className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={loanForm.loan_end_date}
                      name="loan_end_date"
                      onChange={handleInputChange}
                    />
                    {errors.loan_end_date && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.loan_end_date}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
                <h4 className="text-[#334155] font-[600] text-[0.9rem]">
                  Amount
                </h4>
                <div className="grid grid-cols-12 py-1 gap-3 mt-3">
                  <div className="input-container flex flex-col gap-1 col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Mode of Transaction
                    </label>
                    <InputField
                      type="select"
                      className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                      options={[
                        { value: "", label: "Select Type" },
                        { value: "bank_transfer", label: "Bank Transfer" },
                        { value: "cash", label: "Cash" },
                        { value: "cheque", label: "Cheque" },
                        { value: "upi", label: "UPI" },
                        { value: "online_payment", label: "Online Payment" },
                      ]}
                      name="mode_of_transaction"
                      value={loanForm.mode_of_transaction}
                      onChange={handleInputChange}
                    />
                    {errors.mode_of_transaction && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.mode_of_transaction}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-3 mt-2">
                  <div className="col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Amount Borrowed
                    </label>
                    <div className="w-[100%] rounded-md border-[1px] flex justify-center items-center focus-within:border-customPurple">
                      <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name="amount_borrowed"
                        value={loanForm.amount_borrowed}
                        onChange={handleInputChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                    </div>
                    {errors.amount_borrowed && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.amount_borrowed}
                      </p>
                    )}
                  </div>

                  <div className="col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Payments Received
                    </label>
                    <div className="w-[100%] rounded-md border-[1px] flex justify-center items-center focus-within:border-customPurple">
                      <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name="amount_received"
                        value={loanForm.amount_received}
                        onChange={handleInputChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                    </div>
                    {errors.amount_received && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.amount_received}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-3 mt-2">
                  <div className="col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Balance Amount
                    </label>
                    <div className="w-[100%] rounded-md border-[1px] flex justify-center items-center focus-within:border-customPurple">
                      <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name="balance_amount"
                        value={loanForm.balance_amount}
                        onChange={handleInputChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                    </div>
                    {errors.balance_amount && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.balance_amount}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1rem]">
              Interest Details
            </h4>
            <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
              <div className="grid grid-cols-2 gap-3">
                <div className="input-container mt-[0.6rem]">
                  <label className="input-label">
                    Interest Charged on this loan?
                  </label>
                  <div className="flex gap-3 items-center mt-[0.5rem]">
                    <label className="input-label focus-within:text-customPurple">
                      <InputField
                        type="radio"
                        name="interest_charged"
                        value={true}
                        className="mr-2 custom-radio"
                        onChange={handleInputChange}
                        checked={loanForm.interest_charged === true}
                      />
                      Yes
                    </label>
                    <label className="input-label focus-within:text-customPurple">
                      <InputField
                        type="radio"
                        name="interest_charged"
                        value={false}
                        onChange={handleInputChange}
                        checked={loanForm.interest_charged === false}
                        className="mr-2 custom-radio"
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex gap-3 items-center mt-3">
                <div className="flex flex-col gap-1  w-[20%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Interest Rate
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={loanForm.interest_rate}
                    onChange={handleInputChange}
                    name="interest_rate"
                    placeholder="Interest Rate"
                  />
                  {errors.interest_rate && (
                    <p className="text-red-500 text-[0.75rem]">
                      {errors.interest_rate}
                    </p>
                  )}
                </div>
                <div className="input-container w-[35%] flex flex-col gap-1 group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Payout
                  </label>
                  <InputField
                    type="select"
                    className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                    options={[
                      { value: "", label: "Select Type" },
                      { value: "monthly", label: "Monthly" },
                      { value: "quarterly", label: "Quarterly" },
                      { value: "annually", label: "Annually" },
                    ]}
                    name="payout_period"
                    value={loanForm.payout_period}
                    onChange={handleInputChange}
                  />
                  {errors.payout_period && (
                    <p className="text-red-500 text-[0.75rem]">
                      {errors.payout_period}
                    </p>
                  )}
                </div>
                <div className="w-[45%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Estimated Interest
                  </label>
                  <div className="w-[100%] rounded-md border-[1px] flex justify-center items-center focus-within:border-customPurple">
                    <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                      Rs.
                    </span>
                    <InputField
                      type="number"
                      name="estimated_interest"
                      value={loanForm.estimated_interest}
                      onChange={handleInputChange}
                      className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                    />
                  </div>
                  {errors.estimated_interest && (
                    <p className="text-red-500 text-[0.75rem]">
                      {errors.estimated_interest}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Review your details
            </h4>
            <div className="flex justify-between items-center mb-2">
              <div>
                <p className="m-0 text-[#334155] text-[0.9rem] font-[600]">
                  Borrower Details
                </p>
              </div>
              <div className="flex gap-2.5">
                <WhiteButton
                  px={"px-[0.8rem]"}
                  py={"py-[0.3rem]"}
                  name="Edit"
                  onClick={() => setCurrentStep(0)}
                />
              </div>
            </div>
            <div className="border bg-white rounded-xl p-[1rem] mb-3 w-[80%]">
              <div className="flex items-center gap-20">
                <div className="flex flex-col gap-1">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Name
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {loanForm.borrower_name}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Relation
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {loanForm.relation}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center mb-2">
              <div>
                <p className="m-0 text-[#334155] text-[0.9rem] font-[600]">
                  Loan Details
                </p>
              </div>
              <div className="flex gap-2.5">
                <WhiteButton
                  px={"px-[0.8rem]"}
                  py={"py-[0.3rem]"}
                  name="Edit"
                  onClick={() => setCurrentStep(1)}
                />
              </div>
            </div>
            <div className="border bg-white rounded-xl p-[1rem] mb-3 w-[80%]">
              <div className="mb-3">
                <p className="m-0 text-[#334155] text-[0.9rem] font-[600]">
                  Type
                </p>
              </div>
              <div className="flex items-center gap-20">
                <div className="flex flex-col gap-1">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Type
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {loanForm.loan_type}
                  </p>
                </div>
                <div className="flex items-end gap-1">
                  <WhiteButton
                    px={"px-[0.8rem]"}
                    py={"py-[0.3rem]"}
                    name="View Invoice"
                    onClick={viewPdf}
                  />
                </div>
              </div>
            </div>
            <div className="border bg-white rounded-xl p-[1rem] mb-2.5 w-[80%]">
              <div className="mb-3">
                <p className="m-0 text-[#334155] text-[0.9rem] font-[600]">
                  Loan Amount
                </p>
              </div>
              <div className="flex items-center gap-20 mb-3">
                <div className="flex flex-col gap-1 w-[38%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Mode of Transaction
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {loanForm.mode_of_transaction}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-20 mb-3">
                <div className="flex flex-col gap-1 w-[35%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Amount Borrowed
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {loanForm.amount_borrowed}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Payment Received
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {loanForm.amount_received}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-20 mb-3">
                <div className="flex flex-col gap-1 ">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Balance Amount
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {loanForm.balance_amount}
                  </p>
                </div>
              </div>
            </div>
            <div className="border bg-white rounded-xl p-[1rem] mb-2.5 w-[80%]">
              <div className="mb-3">
                <p className="m-0 text-[#334155] text-[0.9rem] font-[600]">
                  Loan Duration
                </p>
              </div>
              <div className="flex items-center gap-20 mb-3">
                <div className="flex flex-col gap-1 w-[35%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Start Date
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {loanForm.loan_start_date}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Due Date
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {loanForm.loan_due_date}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-20 mb-3">
                <div className="flex flex-col gap-1 w-[35%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Period
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {loanForm.period}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    End Date
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {loanForm.loan_end_date}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center mb-2">
              <div>
                <p className="m-0 text-[#334155] text-[0.9rem] font-[600]">
                  Interest Details
                </p>
              </div>
              <div className="flex gap-2.5">
                <WhiteButton
                  px={"px-[0.8rem]"}
                  py={"py-[0.3rem]"}
                  name="Edit"
                  onClick={() => setCurrentStep(2)}
                />
              </div>
            </div>
            <div className="border bg-white rounded-xl p-[1rem] mb-2.5 w-[80%]">
              <div className="flex items-center gap-10 ">
                <div className="flex flex-col gap-1">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Interest Rate
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {loanForm.interest_rate}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Payout
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {loanForm.payout_period}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Estimated Interest
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {loanForm.estimated_interest}
                  </p>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <DialogBox
        onClose={resetState}
        resetClose={setIsAddLoan}
        isNotDefault={isAddLoan}
        triggerElement={triggerElement}
        progressSteps={progressSteps}
        heading={
          <h4 className="font-[600] text-xl text-[#334155]">
            Add <span className="text-[#9065B4]">Loans & Advances</span> to your
            Assets
          </h4>
        }
        content={(handleClose) => (
          <>
            {getContent()}
            <div className={``}>
              <div className="flex justify-end gap-[1rem] ">
                <WhiteButton
                  onClick={handleBack}
                  name="Back"
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
                {currentStep < 3 ? (
                  <Button
                    onClick={handleNext}
                    name={currentStep == 2 ? "Preview & Save" : "Next"}
                    px={"px-[0.6rem]"}
                    py={"py-[0.4rem]"}
                  />
                ) : (
                  <Button
                    onClick={handleNext}
                    name={"Add to Assets"}
                    px={"px-[0.6rem]"}
                    py={"py-[0.4rem]"}
                  />
                )}
              </div>
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
    </div>
  );
};

export default AddNewLoan;
