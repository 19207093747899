import React, { useEffect, useState } from "react";
import AssetsHeading from "../AssetsHeading";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import WhiteButton from "components/Button/WhiteButton";
import InputField from "components/input/InputField";
import "../../Liabilities/liabilities.css";
import EditAccountDetails from "./Edit/EditAccountDetails";
import EditValue from "./Edit/EditValue";
import useGetSukanyaDetail from "../data/usegetSukanyaDetail";
import usePatchSukanyaDetails from "../data/usePatchSukanyaDetails";
import { showSuccessToast } from "components/toaster/toastHelper";

const DepositsDetailedView = () => {
  const location = useLocation();
  const { deposit_type } = useParams();
  const [accHolderName, setAccHolderName] = useState("");
  const [accNo, setaccNo] = useState("");
  const [uploadStatement, setuploadStatement] = useState("");
  const [uploadProofBirth, setuploadProofBirth] = useState("");
  const [depositAmt, setdepositAmt] = useState("");
  const [depositDate, setdepositDate] = useState("");
  const [uploadDepositProof, setuploadDepositProof] = useState("");
  const [balanceToDate, setbalanceToDate] = useState("");
  const [totalBalance, settotalBalance] = useState("");
  const [interestRate, setinterestRate] = useState("");
  const [redemptionDate, setredemptionDate] = useState("");
  const [maturityAmt, setmaturityAmt] = useState("");
  const [isClose, setIsClose] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const id = decodeURIComponent(queryParams.get("id"));

  const assetId = location.state?.assetId;
  const itemId = location.state?.itemId;
  const assetCategory = location.state?.assetCategory;
  const asset_type = location.state?.asset_type;
  const removeId = `${assetId}/?id=${itemId}&model_type=${"sukanya_samriddhi_yojana"}`;


  const [getData, getError, getIsLoading, getSukanyaDetails] =
    useGetSukanyaDetail();

  const [patchData, patchError, patchIsLoading, patchSukanyaDetails] =
    usePatchSukanyaDetails();

  useEffect(() => {
    getSukanyaDetails(id);
  }, []);

  useEffect(() => {
    if (getData?.data) {
      const data = getData?.data;
      setAccHolderName(data?.account_holder_name);
      setaccNo(data?.account_number);
      setdepositAmt(data?.deposit_amount);
      setdepositDate(data?.deposit_date);
      settotalBalance(data?.total_balance);
      setuploadDepositProof(data?.deposit_proof);
      setinterestRate(data?.interest_rate);
      setbalanceToDate(data?.balance_to_date);
      setredemptionDate(data?.redemption_date);
      setmaturityAmt(data?.expected_maturity_amount);
      setuploadStatement(data?.statement_certificate);
      setuploadProofBirth(data?.birth_proof_document);
      // setuploadProofBirth(data?.birth_proof_document?.toString());
    }
  }, [getData]);

  useEffect(() => {
    if (patchData) {
      showSuccessToast("Details Updated");
      setIsClose(true);
      setTimeout(() => {
        setIsClose(false); // Reset isClose after a small delay
      }, 500);
    }
  }, [patchData]);

  const updateSukanyaDetails = () => {
    const formData = new FormData();
    formData.append("id", itemId);
    formData.append("account_holder_name", accHolderName);
    formData.append("account_number", accNo);
    formData.append("deposit_amount", depositAmt);
    formData.append("deposit_date", depositDate);
    formData.append("total_balance", totalBalance);
    formData.append("interest_rate", interestRate);
    formData.append("redemption_date", redemptionDate);
    formData.append("expected_maturity_amount", maturityAmt);
    formData.append("balance_to_date", balanceToDate);
    formData.append("deposit_proof", uploadDepositProof);
    formData.append("birth_proof_document", uploadProofBirth);

    patchSukanyaDetails(formData, assetId);
  };

  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 h-screen">
      <AssetsHeading asset={"Deposits"} asset_detail={assetCategory} asset_type={asset_type} assetId={assetId}/>
      <div className="grid grid-cols-12 mt-[1.5rem] gap-[1rem]">
        {/* Value */}
        <div
          className={`border bg-white rounded-xl p-[1rem] ${
            deposit_type === "provident-fund" ? "col-span-5" : "col-span-6"
          }`}
        >
          <div className="flex justify-between items-center">
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
              {deposit_type} Value
            </h4>
            {!isClose && (
              <EditValue
              assetId={assetId}
              deleteId={removeId}
                update={updateSukanyaDetails}
                interestRate={interestRate}
                redemptionDate={redemptionDate}
                maturityAmt={maturityAmt}
                setinterestRate={setinterestRate}
                setredemptionDate={setredemptionDate}
                setmaturityAmt={setmaturityAmt}
              />
            )}
          </div>

          <div className="w-[60%] my-[1rem]">
            <h4 className="text-customPurple font-[500] text-[1.6rem] ">
              ₹{totalBalance} Lakhs
            </h4>
            <div className="p-[0.5rem] border-1 border-customPurple bg-[#FAF8FC] rounded-md">
              <h4 className="text-[0.8rem] font-[600] text-customPurple m-0">
                10 % of Total Assets (₹50.86 Lakhs)
              </h4>
            </div>
          </div>

          <div className="grid grid-cols-3 mt-[2rem] mb-[1rem]">
            <div className="input-container group flex flex-col">
              <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                Interest Rate
              </label>
              <InputField
                value={interestRate}
                className=" w-[70%] text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                readOnly
              />
            </div>

            {deposit_type === "sukanya-samridhi-yojana" && (
              <>
                <div className="input-container group flex flex-col">
                  <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                    Redemption Date
                  </label>
                  <InputField
                    className=" w-[70%] text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                    value={redemptionDate}
                    readOnly
                  />
                </div>

                <div className="input-container group flex flex-col">
                  <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                    Expected Maturity Amount
                  </label>
                  <InputField
                    className=" w-[70%] text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                    value={maturityAmt}
                    readOnly
                  />
                </div>
              </>
            )}

            {deposit_type === "provident-fund" && (
              <>
                <div className="input-container group flex flex-col">
                  <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                    Interest Accrued
                  </label>
                  <InputField
                    className=" w-[70%] text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                    value="125 Rs"
                    readOnly
                  />
                </div>

                <div className="input-container group flex flex-col">
                  <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                    Total Balance
                  </label>
                  <InputField
                    className=" w-[70%] text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                    value="125 Rs"
                    readOnly
                  />
                </div>
              </>
            )}
          </div>
        </div>

        {/* Account Details */}
        <div
          className={`border bg-white rounded-xl p-[1rem] ${
            deposit_type === "provident-fund" ? "col-span-7" : "col-span-6"
          }`}
        >
          <div className="flex justify-between items-center">
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
              Account Details
            </h4>
            <div className="flex gap-2 ">
              <WhiteButton
                onClick={() => {
                  if (uploadStatement) {
                    window.open(uploadStatement, "_blank");
                  }
                }}
                name={"View Statement"}
                px={"px-[0.5rem]"}
                py={"py-[0.3rem]"}
              />
              {!isClose && (
                <EditAccountDetails
                  uploadDepositProof={uploadDepositProof}
                  setuploadDepositProof={setuploadDepositProof}
                  uploadProofBirth={uploadProofBirth}
                  setuploadProofBirth={setuploadProofBirth}
                  update={updateSukanyaDetails}
                  accHolderName={accHolderName}
                  accNo={accNo}
                  depositDate={depositDate}
                  depositAmt={depositAmt}
                  balanceToDate={balanceToDate}
                  totalBalance={totalBalance}
                  setAccHolderName={setAccHolderName}
                  setaccNo={setaccNo}
                  setdepositDate={setdepositDate}
                  setdepositAmt={setdepositAmt}
                  setbalanceToDate={setbalanceToDate}
                  settotalBalance={settotalBalance}
                />
              )}
            </div>
          </div>

          <div
            className={`grid  mt-[2rem] mb-[1rem] ${
              deposit_type === "provident-fund" ? "grid-cols-3" : "grid-cols-2"
            }`}
          >
            {deposit_type === "provident-fund" && (
              <>
                <div className="input-container group flex flex-col">
                  <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                    Balance To Date
                  </label>
                  <InputField
                    className=" w-[70%] text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                    value="2,00,000"
                    readOnly
                  />
                </div>

                <div className="input-container group flex flex-col">
                  <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                    Type
                  </label>
                  <InputField
                    className=" w-[70%] text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                    value="Public"
                    readOnly
                  />
                </div>

                <div></div>

                <div className="input-container group flex flex-col mt-[1rem]">
                  <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                    Account Holder
                  </label>
                  <InputField
                    className=" w-[70%] text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                    value="Self"
                    readOnly
                  />
                </div>
              </>
            )}
            <div className="input-container group flex flex-col mt-[1rem]">
              <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                Account Holder Name
              </label>
              <InputField
                className=" w-[70%] text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                value={accHolderName}
                readOnly
              />
            </div>

            <div className="input-container group flex flex-col mt-[1rem]">
              <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                Account Number
              </label>
              <InputField
                className=" w-[70%] text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                value={accNo}
                readOnly
              />
            </div>

            {deposit_type === "sukanya-samridhi-yojana" && (
              <>
                <div className="input-container group flex flex-col mt-[1rem]">
                  <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                    Deposit Amount
                  </label>
                  <InputField
                    className=" w-[70%] text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                    value={depositAmt}
                    readOnly
                  />
                </div>

                <div className="input-container group flex flex-col mt-[1rem]">
                  <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                    Deposit Date
                  </label>
                  <InputField
                    className=" w-[70%] text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                    value={depositDate}
                    readOnly
                  />
                </div>

                <div className="input-container group flex flex-col mt-[1rem]">
                  <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                    Balance to Date
                  </label>
                  <InputField
                    className=" w-[70%] text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                    value={balanceToDate}
                    readOnly
                  />
                </div>

                <div className="input-container group flex flex-col mt-[1rem]">
                  <label className="text-lightGray text-[0.7rem] group-hover:text-customPurple">
                    Total Balance
                  </label>
                  <InputField
                    className=" w-[70%] text-[1rem] mt-[0.4rem] text-darkGray font-[500] focus:outline-none group-hover:text-customPurple"
                    value={totalBalance}
                    readOnly
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositsDetailedView;
