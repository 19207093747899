import React, { useState } from "react";

function Button(props) {
  return (
    <div className="cursor-pointer">
      <button
        disabled={props.disabled || false} 
        className={`flex w-[100%] items-center whitespace-nowrap bg-purple-dark-shade rounded text-white cursor-pointer ${props.px} ${props.py}`}
        onClick={props.onClick}
      >
        <p className="text-center w-[100%] text-white m-0">{props.name}</p>
      </button>
    </div>
  );
}

export default Button;
