import React, { useEffect, useState } from "react";
import logo from "assets/images/orders/image 27.svg"; 
import homeLogo from "assets/images/orders/icon.svg";
import profile from "assets/images/orders/profile.svg";
import logoutLogo from "assets/images/orders/logOut.svg";
import { Link } from "react-router-dom";

import OrderDialog from "./PostOrderDialog";
import LogoutDialog from "./LogoutDialog";

function LoginProfileNav() {
  
  return (
    <>
      <div className="flex  bg-white-body justify-between py-2">
        <div className="flex">
          <img src={logo} className="  px-4" alt="beyondTax logo" />
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={"/orders"}
          >
            <div className=" flex hover:cursor-pointer  ">
              <img className=" pr-3 " src={homeLogo} alt="home logo" />
              <b className="py-3 pr-5">Orders</b>
            </div>
          </Link>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to="/profile"
          >
            <div className=" flex hover:cursor-pointer  ">
              <img className=" pr-3" src={profile} alt="profile" />{" "}
              <b className="py-3 pr-5">My Profile</b>
            </div>
          </Link>
          <OrderDialog />
        </div>

        <div
          // onClick={() => {
          //   setLogOutClicked(true);
          // }}
          className=" flex hover:cursor-pointer "
        >
          <img className=" pr-3 " src={logoutLogo} alt="profile" />
          <b className="py-3 pr-5">
            <LogoutDialog />
          </b>
        </div>
      </div>
    </>
  );
}

export default LoginProfileNav;
