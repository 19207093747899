import React, { useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import TotalIncome from "./TotalIncome";
import Deductions from "./Deductions";
import TaxesPaid from "./TaxesPaid";
import { USER_INCOME_SUMMARY_PAGE_URL } from "constants/routes";
import TaxSummary from "./TaxSummary";
import { ProfileContext } from "containers/privateRoutes/Profile/Layout";
import Computation from "./Computation";

const UserIncomeSummaryPage = () => {
  const { type } = useParams();
  const { income_tax_return_id } = useParams();
  const {returnYear}= useContext(ProfileContext)
  let isDisabled=false;
  const permissionType = sessionStorage.getItem('permissionType');
  if (permissionType === 'Read') {
    isDisabled=true;
  } else if (permissionType === 'Edit') {
    isDisabled=false;
  } else if (permissionType === 'Download') {
    isDisabled=true;
  } else if (permissionType === 'Read & Download') {
    isDisabled=false;
  }
  let displayText = "";
  switch (type) {
    case "total":
      displayText = <TotalIncome/>;
      break;
    case "deductions":
      displayText = <Deductions isDisabled={isDisabled}/>;
      break;
    case "taxes-paid":
      displayText = <TaxesPaid isDisabled={isDisabled}/>;
      break;
    case "summary":
      displayText = <TaxSummary isDisabled={isDisabled}/>;
      break;
    case "computation":
      displayText = <Computation isDisabled={isDisabled}/>;
      break;
    case "proceed-to-file":
      displayText = "Proceed to file";
      break; 
    default:
      displayText = "Unknown";
  }

  return (
    <div className="grid grid-cols-12 bg-gray-100">
      <div className="col-span-12 md:col-span-12 md:px-8 px-3 pt-2 md:block">
        <h4 className="text-[#334155] font-[600] text-[1.4rem] leading-[2.7rem] pt-2 md:m-0 ml-2 mt-2 mb-4 md:block hidden">
          Tax Summary&nbsp;&nbsp;
          <span className="text-[#64748B] text-[1.2rem] font-[500]">
            {returnYear  }
          </span>
        </h4>

        <div className="md:flex justify-between  hidden">
          <div className="font-[600] text-[1rem]">
            <button className="mr-2">
              <Link
                to={USER_INCOME_SUMMARY_PAGE_URL.replace(
                  ":income_tax_return_id",
                  income_tax_return_id
                ).replace(":type", "total")}
                className={`transition-colors duration-300 ${
                  type === "total"
                    ? "no-underline bg-white text-[#9065B4] border-b-2 border-b-[#9065B4] "
                    : "no-underline text-[#64748B]"
                } p-2 `}
              >
                Total Income
              </Link>
            </button>
            <button className="mr-2">
              <Link
                to={USER_INCOME_SUMMARY_PAGE_URL.replace(
                  ":income_tax_return_id",
                  income_tax_return_id
                ).replace(":type", "deductions")}
                className={`transition-colors duration-300 ${
                  type === "deductions"
                    ? "no-underline bg-white text-[#9065B4] border-b-2 border-b-[#9065B4]"
                    : "no-underline text-[#64748B]"
                } p-2 `}
              >
                Deductions
              </Link>
            </button>
            <button className="mr-2">
              <Link
                to={USER_INCOME_SUMMARY_PAGE_URL.replace(
                  ":income_tax_return_id",
                  income_tax_return_id
                ).replace(":type", "taxes-paid")}
                className={`transition-colors duration-300 ${
                  type === "taxes-paid"
                    ? "no-underline bg-white text-[#9065B4] border-b-2 border-b-[#9065B4]"
                    : "no-underline text-[#64748B]"
                } p-2 `}
              >
                Taxes Paid
              </Link>
            </button>
            <button className="mr-2">
              <Link
                to={USER_INCOME_SUMMARY_PAGE_URL.replace(
                  ":income_tax_return_id",
                  income_tax_return_id
                ).replace(":type", "summary")}
                className={`transition-colors duration-300 ${
                  type === "summary"
                    ? "no-underline bg-white text-[#9065B4] border-b-2 border-b-[#9065B4]"
                    : "no-underline text-[#64748B]"
                } p-2 `}
              >
                Summary
              </Link>
            </button>
            <button className="mr-2">
              <Link
                to={USER_INCOME_SUMMARY_PAGE_URL.replace(
                  ":income_tax_return_id",
                  income_tax_return_id
                ).replace(":type", "computation")}
                className={`transition-colors duration-300 ${
                  type === "computation"
                    ? "no-underline bg-white text-[#9065B4] border-b-2 border-b-[#9065B4]"
                    : "no-underline text-[#64748B]"
                } p-2 `}
              >
                Computation
              </Link>
            </button>
            <button className="mr-2">
              <Link
                to={USER_INCOME_SUMMARY_PAGE_URL.replace(
                  ":income_tax_return_id",
                  income_tax_return_id
                ).replace(":type", "proceed-to-file")}
                className={`transition-colors duration-300 ${
                  type === "proceed-to-file"
                    ? "no-underline bg-white text-[#9065B4] border-b-2 border-b-[#9065B4]"
                    : "no-underline text-[#64748B]"
                } p-2 `}
              >
                Proceed to File
              </Link>
            </button>
          </div>
          {/* <h4 className="text-[0.9rem] text-[#64748B] font-[500] ">
            Saved Jul 5, 2024, 13:10
          </h4> */}
        </div>

        <div className="mt-2">{displayText}</div>
      </div>
    </div>
  );
};

export default UserIncomeSummaryPage;
