import React, { useEffect, useState } from "react";
import "assets/images/login/Section.svg";
import { useNavigate } from "react-router-dom";
import backIcon from "assets/images/login/backIcon.png";
import Input from "components/input/Input";
import setPswd from "assets/images/login/setPswd.png";
import NormalLoginSheet from "./NormalLoginSheet";

//API's
import usePostSetPswd from "containers/LoginAndSignUp/Data/usePostSetPswd";
import MobileLogin from "./MobileLogin";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";

const MobileSetPassword = (props) => {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const [data, error, isLoading, postSetPswd] = usePostSetPswd();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (data) {
      showSuccessToast("Password reseted")
      setRedirectToLogin(true);
    }
  }, [data, navigate]);

  useEffect(() => {
    if (submitClicked && password && confirmPassword) {
      setSubmitClicked(false);
    }
  }, [submitClicked]);

  useEffect(() => {
    if (error) {
      let desc = error.message || "Something went wrong. Please try again."
      showErrorToast("",desc)
    }
  }, [error]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const validatePswd = {
      otp_id: props.otpSessionId,
      password: password,
      confirm_password: confirmPassword,
    };

    if (password.length < 8) {
      showErrorToast("","Password must be at least 8 characters long.")
      return;
    }
    if (password !== confirmPassword) {
      showErrorToast("","Passwords do not match.")
      return;
    }
    setSubmitClicked(true);
    postSetPswd(validatePswd);
  };

  if (redirectToLogin) {
    return <MobileLogin />;
  }
  return (
    <div className="grid">
      <div className="">
        <div className="flex justify-center items-center">
          <img src={setPswd} alt="set pswd" className="mb-4" />
        </div>
        <h2 className=" font-[700] leading-[2.1rem] text-[1.4rem] flex justify-center items-center">
          Set new password
        </h2>
        <p className=" text-gray-500 font-[400] text-[1rem] leading-[1.5rem] flex justify-center items-center">
          Your new password must be different to previously used passwords.
        </p>
        <Input
          label={"Password"}
          id={"password"}
          placeholder={"Enter your password"}
          value={password}
          setValue={setPassword}
        />
        <p className="text-[#667085] mb-4">Must be at least 8 characters.</p>
        <Input
          label={"Confirm Password"}
          id={"confirmPassword"}
          placeholder={"Enter your Confirm Password"}
          type={"password"}
          value={confirmPassword}
          setValue={setConfirmPassword}
        />
      </div>
      <br />
      <button
        onClick={handleSubmit}
        className=" purpleBtn p-2 rounded  w-[100%] text-white"
      >
        Reset password
      </button>

      <div className="text-[#667085] flex justify-center my-2 items-center cursor-pointer">
        <div
          onClick={() => {
            setOpen(false);
          }}
        >
          <NormalLoginSheet
           isDialogOpen='fpswd'
            element={
              <p className=" m-0 flex gap-3">
                <img src={backIcon} alt="backIcon" className="py-1" />
                Back to log in
              </p>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MobileSetPassword;
