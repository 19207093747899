import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "components/Button/BtnOrange";
import SummarySidebar from "./SummarySidebar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { INCOME_TAX_RETURNS_URL, INCOME_TAX_SUMMARY_USER_PAGE_URL } from "constants/routes";
import { toast } from "react-toastify";

// images
import summary_download from "assets/images/incomeTax/summary_download.png";
import tax_deduction from "assets/images/incomeTax/tax-deduction.png";
import tax_payment from "assets/images/incomeTax/tax-payment.png";
import income_details from "assets/images/incomeTax/income-details.png";
import previousBtn from "assets/images/incomeTax/previousBtn.svg";


// API's
import usePostUploadAIS from "./Data/usePostUploadAIS";
import usePostUpload26AS from "./Data/usePostUpload26AS";
import { showRedirectToast } from "components/toaster/toastHelper";
import { ProfileContext } from "containers/privateRoutes/Profile/Layout";
import InputField from "components/input/InputField";
 
const SummaryMainPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { income_tax_return_id } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const isReturnYear = queryParams.get('isReturnYear'); 

  const {returnYear, getReturnYear}= useContext(ProfileContext)

  const [countdown, setCountdown] = useState(0);

  const [postData, postError, postUploadAISLoading, postUploadAIS] =
    usePostUploadAIS();
  const [ASData, ASError, ASIsLoading, postUpload26AS] = usePostUpload26AS();

  const aisFileInputRef = useRef(null);
  const form26ASFileInputRef = useRef(null);

  const handleAISFileUploadClick = () => {
    aisFileInputRef.current.click();
  };

  const handle26ASFileUploadClick = () => {
    form26ASFileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("ais_pdf", file);
      postUploadAIS(income_tax_return_id, formData);
    }
  };

  const handle26ASFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("tds_pdf", file);
      postUpload26AS(income_tax_return_id, formData);
    }
  };

  useEffect(()=>{
    if(isReturnYear === null){
      const val=JSON.parse(localStorage.getItem('user')).current_income_tax_return_year_name
      if(val){
        getReturnYear(val)
      }else{
        getReturnYear("")
      }
    }
  },[isReturnYear])

  useEffect(() => {
    if (postData) {
      showRedirectToast(
        "AIS file uploaded successfully.",
        navigate,
        INCOME_TAX_SUMMARY_USER_PAGE_URL.replace(
          ":income_tax_return_id",
          income_tax_return_id
        )
      );
    }
    if (ASData) {
      showRedirectToast(
        "26AS file uploaded successfully.",
        navigate,
        INCOME_TAX_SUMMARY_USER_PAGE_URL.replace(
          ":income_tax_return_id",
          income_tax_return_id
        )
      );
    }
  }, [postData, ASData]);

  useEffect(() => {
    if (postError) {
      toast.error("Error in uploading AIS file");
    }
    if (ASError) {
      toast.error("Error in uploading 26AS file");
    }
  }, [postError, ASError]);

  const handleImportClick = () => {
    navigate(
      INCOME_TAX_SUMMARY_USER_PAGE_URL.replace(
        ":income_tax_return_id",
        income_tax_return_id
      )
    );
  };

  const handlePrevious = () => {
    navigate("/income-tax");
  };

  return (
      <div className="bg-gray-100">
        <div className="pt-2 mb-4 md:px-8 pl-3  md:block flex md:gap-0 gap-2">
        <button onClick={handlePrevious}>
            <img src={previousBtn} alt="" className="w-4 h-4 md:hidden " />
          </button> 
          <h4 className="text-[#334155] font-[600] text-[1.4rem] leading-[2.7rem] mt-2">
            Tax Summary&nbsp;&nbsp;
            <span className="text-[#64748B] text-[1.2rem] font-[500]">
              {returnYear}
            </span>
          </h4>
        </div>

        <div className="grid grid-cols-10 gap-4">
          <div className="md:col-span-7 md:pl-8 col-span-10 px-[1rem] md:px-0">
            <div className="bg-white rounded-lg p-8 shadow">
              <img
                src={summary_download}
                alt="summary_download"
                className="mb-4"
              />
              <h4 className="font-[600] text-[1.3rem]">
                Auto-Fill Your Tax Informtion
              </h4>
              <p className="text-[#64748B] font-[500] text[1rem] mb-4">
                Save time by importing details associated with your PAN:
              </p>
              <div className=" font-[500] text[0.8rem] text-[#64748B] mb-4">
                <p className="flex gap-4">
                  <img src={income_details} alt="income_detials" />
                  Income details
                </p>
                <p className="flex gap-4">
                  <img src={tax_deduction} alt="tax-deduction" />
                  Tax deductions (TDS/TCS)
                </p>
                <p className="flex gap-4">
                  <img src={tax_payment} alt="tax-payment" />
                  Tax payments (Advance/Self-assessment)
                </p>
              </div>
              <div className="md:flex md:gap-4 mt-5 md:justify-end text-center ">
                <Button
                  px={"px-[1rem]"}
                  py={"py-[0.5rem]"}
                  name="Import Now"
                  onClick={handleImportClick}
                />
                <p className="text-[#334155] font-[500] text[0.8rem] md:mt-0 mt-2">
                  OR
                </p>
                <div className="flex gap-2 items-center justify-center">
                  <div>
                    {/* <input
                      type="file"
                      ref={aisFileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange} 
                    /> */}
                    <InputField
                      type="file"
                      ref={aisFileInputRef}  /* Pass ref here */
                      // style={{ display: "none" }}
                      className='hidden'
                      onChange={handleFileChange}
                    />
                    <button
                      onClick={handleAISFileUploadClick}
                      className=" border-1 border-[#9065B4] md:px-[1rem] md:py-[0.5rem] px-[0.6rem] py-[0.3rem] rounded text-[#9065B4] font-[500] text-[1rem] leading-[1.5rem]"
                    >
                      Upload AIS
                    </button>
                  </div>
                  <div>
                    {/* <input
                      type="file"
                      ref={form26ASFileInputRef}
                      style={{ display: "none" }}
                      onChange={handle26ASFileChange}
                    /> */}
                    <InputField
                      type="file"
                      ref={form26ASFileInputRef}  /* Pass ref here */
                      // style={{ display: "none" }}
                      className='hidden'
                      onChange={handle26ASFileChange}
                    />
                    <button
                      onClick={handle26ASFileUploadClick}
                      className=" border-1 border-[#9065B4] md:px-[1rem] md:py-[0.5rem] px-[0.6rem] py-[0.3rem] rounded text-[#9065B4] font-[500] text-[1rem] leading-[1.5rem]"
                    >
                      Upload Form 26AS
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:col-span-3 md:pr-2 md:mb-[5rem] col-span-10 px-[1rem] md:px-0 mb-[7rem]">
            <SummarySidebar layout="vertical" />
          </div>
        </div>
      </div>
  );
};

export default SummaryMainPage;
