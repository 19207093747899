import React, { useEffect, useRef, useState } from "react";
import AssetsHeading from "../AssetsHeading";
import AddNewRealEstate from "./AddNewRealEstate";
import landGray from "assets/images/assets/landGray.svg";
import buildingGray from "assets/images/assets/buildingGray.svg";
import WhiteButton from "components/Button/WhiteButton";
import { Link, useLocation } from "react-router-dom";
import {
  ASSETS_REAL_ESTATE_DETAILED_URL,
  ASSETS_TYPE_URL,
} from "constants/routes";
import AddNewLand from "./Add/AddNewLand";
import useGetAssetTypeOverview from "../data/useGetAssetTypeOverview";

const RealEstate = () => {
  const location = useLocation();
  const addNewBtnRef = useRef(null);
  const [selectedEstate, setSelectedEstate] = useState("");
  const [selectedAssetId, setSelectedAssetId] = useState("");

  const landId = location.state?.landId;
  const buildingId = location.state?.buildingId;
  const asset_type = location.state?.asset_type;

  const [getData, getrror, getIsLoading, getRealEstate] =
    useGetAssetTypeOverview();

  const handleAddNew = (estateType, assetId) => {
    setSelectedEstate(estateType);
    setSelectedAssetId(assetId);
    addNewBtnRef.current.click();
  };

  useEffect(() => {
    getRealEstate("real-estate"); 
  }, []);

  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 ">
      <AssetsHeading asset={"Real Estate"} />
      <div className="flex mt-[1.5rem] border bg-white rounded-xl  grid grid-cols-12 divide-x">
        <div className=" p-[1rem] col-span-3">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Total Assets Value
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">
            ₹{getData?.data?.total_assets_amount}
          </h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Total Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">
            {getData?.data?.total_assets}
          </h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Active Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">
            {getData?.data?.total_active_assets}
          </h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Idle Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">
            {getData?.data?.total_idle_assets}
          </h4>
        </div>
        <div className="flex justify-center items-center p-[1rem] col-span-3">
          <AddNewRealEstate />
        </div>
      </div>

      {/* lands */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1.1rem] m-0">
              Lands
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
              {getData?.data?.lands[0]?.total_land_assets_value} Rs.
            </h4>
          </div>

          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <div>
              <WhiteButton
                px={"px-[0.8rem]"}
                py={"py-[0.3rem]"}
                name="Add New"
                onClick={() => handleAddNew("Land", landId)}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1.5rem]">
          {getData ? (
            <Link
              to={`${ASSETS_TYPE_URL.replace(
                ":type",
                "real-estate-land"
              )}?id=${landId}`}
              className="no-underline"
            >
              <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
                <img src={landGray} alt="land" className="w-7 h-7 mb-2" />
                <h5 className="text-lightGray font-[600] text-[0.9rem]">
                  Land
                </h5>
                <div className="flex justify-end mt-[1.8rem]">
                  <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                    ₹ {getData?.data?.lands[0]?.total_land_assets_value}
                  </h4>
                </div>{" "}
              </div>
            </Link>
          ) : (
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={landGray} alt="land" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">Land</h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                  Add New
                </button>
              </div>{" "}
            </div>
          )}
        </div>
      </div>

      {/* building */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1.1rem] m-0">
              Buildings
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
              {getData?.data?.buildings[0]?.total_assets_value} Rs.
            </h4>
          </div>
          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <WhiteButton
              px={"px-[0.8rem]"}
              py={"py-[0.3rem]"}
              name="Add New"
              onClick={() => handleAddNew("Buildings", buildingId)}
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1.2rem]">
          {getData ? (
            <Link
              to={`${ASSETS_TYPE_URL.replace(  
                ":type",
                "real-estate-building"
              )}?id=${buildingId}`}
              className="no-underline"
            >
              <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
                <img
                  src={buildingGray}
                  alt="building"
                  className="w-7 h-7 mb-2"
                />
                <h5 className="text-lightGray font-[600] text-[0.9rem]">
                  Building
                </h5>
                <div className="flex justify-end mt-[1.8rem]">
                  <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                    ₹ {getData?.data?.buildings[0]?.total_assets_value}
                  </h4>
                </div>{" "}
              </div>
            </Link>
          ) : (
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={buildingGray} alt="building" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
                Building
              </h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                  Add New
                </button>
              </div>{" "}
            </div>
          )}
        </div>
      </div>

      <AddNewLand
        assetId={selectedAssetId}
        estateType={selectedEstate}
        triggerElement={
          <button ref={addNewBtnRef} className="hidden">
            Add New
          </button>
        }
      />
    </div>
  );
};

export default RealEstate;
