import React, { useEffect, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import useGetBlogDetails from "./Data/useGetBlogPost";
import "./Blogs.css";
import { loaderContext } from "App";
import BlogsList from "./BlogsList";

function BlogDetails() {
  const location = useLocation();
  const [blogNumber, setBlogNumber] = useState();
  const [content, setContent] = useState();
  const [date, setDate] = useState("");
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const { loading, setLoading } = useContext(loaderContext);

  // console.log(location.state)

  const [
    BlogDetailsData,
    BlogDetailsError,
    BlogDetailsIsLoading,
    getBlogDetails,
    setSuccessBlogDetailsData,
    setBlogDetailsError,
  ] = useGetBlogDetails();

  if (BlogDetailsIsLoading) {
    setLoading(true);
  }

  useEffect(() => {
    if (blogNumber) {
    console.log('blogNumber', blogNumber)
      getBlogDetails({ id: blogNumber });
    }
  }, [blogNumber]);
  useEffect(() => {
    if (BlogDetailsData) {
      setContent(BlogDetailsData.data.content);
      //  console.log(typeof(JSON.parse(content)))
      setLoading(false);
      console.log(BlogDetailsData.data);
      setDate(BlogDetailsData.data.created_at.split(" ")[0]);
      setHeading(BlogDetailsData.data.title);
      setDescription(BlogDetailsData.data.description);
    }
  }, [BlogDetailsData]);

  useEffect(() => {
    // if (location.state.page === 1) {
    //   setBlogNumber(location.state.id);
    // } else {
    //   setBlogNumber(
    //     (location.state.page - 1) * location.state.noOfBlogs + location.state.id
    //   );
    // }
    if (location.state && location.state.id) {
      setBlogNumber(location.state.id); // Use the blog ID directly
    }
  }, [location]);

  return (
    <>
      {BlogDetailsData && (
        <div className="lg:pt-[10rem] md:pt-[10rem] pt-[5rem] px-4">
          <p className=" text-center font-[700] text-[1rem] leading-[1.5rem] text-[#9065B4] my-4">
            {" "}
            Published on {date}
          </p>
          <h1 className=" lg:px-[4rem] md:px-[4rem] text-center lg:font-[700] lg:text-[3.5rem] lg:leading-[4rem] md:font-[700] md:text-[3.5rem] md:leading-[4rem] font-[600] text-[2.3rem] leading-[2.7rem] text-[#272727] my-4">
            {heading}
          </h1>
          <p className=" text-center lg:font-[500] lg:text-[1rem] lg:leading-[1.3rem] md:font-[500] md:text-[1rem] md:leading-[1.3rem] font-[400] text-[1.1rem] leading-[1.7rem] text-[#9092A3] my-4 lg:px-[8rem] md:px-[8rem]">
            {description}
          </p>
          <div className="forBlogs lg:mx-[20rem] md:mx-[20rem]">
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
          <BlogsList />
        </div>
      )}
    </>
  );
}

export default BlogDetails;
