import React, { useEffect, useState } from "react";
import AddYourLiability from "../AddYourLiability";
import "../liabilities.css";

// images
import BarLineChart from "assets/images/PersonalLoan/BarLineChart.svg";
import RemoveLiability from "../RemoveLiability";
import WhiteButton from "components/Button/WhiteButton";
import InputField from "components/input/InputField";
import useGetFamilyLoanDetailedView from "../data/useGetFamilyLoanDetailedView";
import { useLocation } from "react-router-dom";
import useDeleteIndividualLoan from "../data/useDeleteIndividualLoan";
import LiabilitiesHeading from "../LiabilitiesHeading";
import LineGraph from "../LineGraph";
import usePatchFamilyLoans from "../data/usePatchFamilyLoans";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";

const FamilyLoanDetailedView = () => {
  const [bankName, setBankName] = useState("");
  const [errors,setErrors]=useState([{}])
  const [individualView, setIndividualView]=useState({})
  const [fieldToRead, setFieldToRead]= useState(true)
  const [getDetailedData, getDetailedError, getDetailedIsLoading, getFamilyLoanDetailedView ] = useGetFamilyLoanDetailedView();
  const [getDeletedData, getDeletedError, getDeletedIsLoading, getDeleteIndividualLoan ] = useDeleteIndividualLoan();
  const [patchFamilyData, patchFamilyError, patchFamilyIsLoading, patchFamilyLoanDetails] = usePatchFamilyLoans();
  const location = useLocation();
  const loan_type  = location.state.loan_type || {};
  const queryParams = new URLSearchParams(location.search);
  const id =decodeURIComponent(queryParams.get('id'));
  const loan_id = decodeURIComponent(queryParams.get('loan_id'));
  const sendingId=`${id}/?loan_id=${loan_id}`
  const [loans, setLoans] = useState([
    {
      uniqueKey: Date.now(),
      lender_name: "",
      relation: "",
      amount_borrowed: "",
      agreement_file: "",
      start_date: "",
      period_years: "",
      payment_date: "",
      end_date: "",
      interest_charged: false,
      interest_rate: '',
      amount_repaid: "",
      balance_amount: "",
      payable_per_month: "",
      loan_type:""
    }
  ])
  useEffect(()=>{
   getFamilyLoanDetailedView({},id,{"loan_id":loan_id})
  },[])
  useEffect(()=>{
    if(patchFamilyData){
     showSuccessToast("Data Updated Successfully")
    }
   },[patchFamilyData])

   useEffect(()=>{
    if(patchFamilyError){
     showErrorToast("Error in Updating Data")
    }
   },[patchFamilyError])
  useEffect(()=>{
    if(getDetailedData?.data){
      setIndividualView(getDetailedData.data)
      getDetailedData.data.uniqueKey=loans[0].uniqueKey
      setLoans([getDetailedData.data])
    }
  },[getDetailedData])
  const validateLoans = () => {
    const newErrors = loans.map((loan, index) => {
      const error = {};
      
      // Lender Name: only letters and spaces allowed
      const namePattern = /^[a-zA-Z\s]+$/;
      if (!loan.lender_name) {
        error.lender_name = "Lender Name is required";
      } else if (!namePattern.test(loan.lender_name)) {
        error.lender_name = "Lender Name should not contain special characters or numbers";
      }
  
      if (!loan.relation) {
        error.relation = "Relation Name is required";
      } else if (!namePattern.test(loan.relation)) {
        error.relation = "Relation Name should not contain special characters or numbers";
      }

      if (!loan.loan_type) {
        error.loan_type = "Loan Type is required";
      }
      // Amount Borrowed: only whole numbers allowed, must be > 0
      if (!loan.amount_borrowed || !/^\d+$/.test(loan.amount_borrowed)) {
        error.amount_borrowed = "Valid Amount Borrowed is required (whole numbers only)";
      } else if (loan.amount_borrowed <= 0) {
        error.amount_borrowed = "Amount Borrowed must be greater than 0";
      }

      if (!loan.agreement_file) {
        error.agreement_file = "Agreement File is required";
      }

      // Start Date and End Date validation
      if (!loan.start_date) {
        error.start_date = "Start Date is required";
      }
      if (!loan.end_date) {
        error.end_date = "End Date is required";
      }
      // Period: must be a number between 0 and 12 years
      if (!loan.period_years) {
        error.period_years = "Period is required";
      }
      if (!loan.payment_date) {
        error.payment_date = "Payment Date is required";
      }


    // Interest Rate: between 0 and 10
      if (!loan.interest_rate || Number(loan.interest_rate) < 0 || Number(loan.interest_rate) > 10) {
        error.interest_rate = "Valid Interest Rate (0-10) is required";
      }
      // Amount Repaid: only whole numbers allowed
      if (!loan.amount_repaid || !/^\d+$/.test(loan.amount_repaid)) {
        error.amount_repaid = "Valid Amount Repaid is required (whole numbers only)";
      }
  
      // Balance Amount: only whole numbers allowed
      if (!loan.balance_amount || !/^\d+$/.test(loan.balance_amount)) {
        error.balance_amount = "Valid Balance Amount is required (whole numbers only)";
      }
  
      // Payable per Month: only whole numbers allowed
      if (!loan.payable_per_month || !/^\d+$/.test(loan.payable_per_month)) {
        error.payable_per_month = "Valid Payable per Month is required (whole numbers only)";
      }
      return error;
    });
  
    setErrors(newErrors);
    return newErrors.every(error => Object.keys(error).length === 0); // returns true if no errors
  };
  const handleInputChange = (index, e) => {
    const { name, value, type, files } = e.target;
  
    // Check if the input type is a file
    if (type === "file") {
      // If it's a file input, take the first file and set it in the loans state
      const updatedLoans = [...loans];
      updatedLoans[index][name] = files[0]; // Store the file object
      console.log('File updatedLoans', updatedLoans);
      setLoans(updatedLoans);
    } else {
      // For text inputs
      const updatedLoans = [...loans];
      const splitName = name.split('_');
      const baseName = splitName.slice(0, -1).join('_');
        // Ensure boolean for interest_charged
      if (baseName === "interest_charged") {
        updatedLoans[index][name] = value === "true";  // Convert string "true" to boolean true
      } else {
        updatedLoans[index][name] = value;
      }
      setLoans(updatedLoans);
    }
  };
  const handleSubmit=()=>{
    const formData = new FormData();
    // Loop through each loan in the loans array
      loans.forEach((loan, index) => {
        const {
          id,
          user,
          loan_status,
          uniqueKey,
          agreement_file, // Could be a link (existing) or a new file (object)
          ...remainingData
        } = loan;


        // Append each field from remainingData into FormData
        for (let key in remainingData) {
          formData.append(`loans[${index}][${key}]`, remainingData[key]);
        }

        // Append the encoded loan ID
        formData.append(`loans[${index}][encoded_loan_id]`, id);

        // Check if `agreement_file` is a new file or a URL
        if (agreement_file instanceof File) {
          // If it's a file object, append it
          formData.append(`loans[${index}][agreement_file]`, agreement_file);
        } else if (!agreement_file) {
          // Optionally, if there's no file (new or old), you might skip appending
          // formData.append(`loans[${index}][agreement_file]`, ""); // Uncomment if you need to clear the file
        }
        // Otherwise, do not append anything, and let the backend retain the old URL.
      }); 
      if(validateLoans()){
        patchFamilyLoanDetails(formData, id)
      }
    }
  return (
    <div className= "px-[1rem] bg-gray-100">
      <LiabilitiesHeading
      loan_type_id={id}
       loan_type={loan_type}
       loan_type_details={individualView?.lender_name}
      />
      <div className="bg-white flex justify-end mt-4">
        <AddYourLiability />
      </div>

      {/* Total loan Ammount */}
      <div className="py-[1rem] px-[1.5rem] mt-[1rem] border rounded-xl grid grid-cols-12 gap-[1rem] bg-white">
        <div className="col-span-4">
          <div className="p-[1rem] border rounded-xl mb-[1rem]">
            <h4 className="text-[1.1rem] font-[600] text-darkGray">
              Total Loan Amount
            </h4>

            <h4 className="flex justify-end text-customPurple font-[600] text-[1.3rem]">
              ₹ {individualView?.amount_borrowed}
            </h4>
          </div>

          <div className="p-[0.5rem] border border-customPurple bg-[#FAF8FC] rounded-md">
            <h4 className="text-[0.8rem] font-[600] text-customPurple m-0">
              10 % of Total Liabilities (₹50.86 Lakhs)
            </h4>
          </div>
        </div>

        <div className="col-span-8 p-[1rem] border rounded-xl">
          <h4 className="text-[1rem] font-[600] text-darkGray">
            {loan_type}
          </h4>
          <LineGraph/>
        </div>
      </div>

      {/* Loan Details */}
      <div className="py-[1rem] px-[1.5rem] mt-[1rem] border rounded-xl bg-white">
        <div className="flex justify-between ">
          <h4 className="font-[600] text-[1rem] text-darkGray">Loan Details</h4>
          <div className="flex gap-[1rem]">
            <RemoveLiability 
              remove_id={sendingId}
              loan_name={individualView?.lender_name}
            />
            {/* <WhiteButton px={"px-[0.9rem]"} py={"py-[0.3rem]"} name="Edit" /> */}
            <button onClick={()=>{
              if(!fieldToRead){
                handleSubmit()
              }
              if(validateLoans() || fieldToRead){
                setFieldToRead(!fieldToRead)
              }
              }} 
              className={`py-1.5 px-2.5 border-[1px] border-[#D9D9D9] rounded-md text-[0.8rem] ${fieldToRead?"text-[#334155]":"bg-[#9065B4] text-white"}`}>
                  {fieldToRead ? "Edit":"Done"}
            </button>
          </div>
        </div>

        {/* form */}
        {
            loans.map((el,i)=>{
               return(
                <div>
                <div className="grid grid-cols-4">
                  <div>
                    <label className="input-label">Lender Name</label>
                    {/* <input className="input-box" value={individualView?.lender_name} readOnly/> */}
                    <InputField 
                      type="text"
                      className="input-box"
                      value={el.lender_name}
                      onChange={(e)=>handleInputChange(i,e)}
                      name="lender_name"
                      readOnly={fieldToRead}
                    />
                    {errors[i]?.lender_name && <p className="text-red-500 text-[0.8rem]">{errors[i].lender_name}</p>}
                  </div>
        
                  <div>
                    <label className="input-label">Relation</label>
                    {/* <input className="input-box" value={individualView?.relation} readOnly/> */}
                    <InputField 
                      type="text"
                      className="input-box"
                      value={el.relation}
                      onChange={(e)=>handleInputChange(i,e)}
                      name="relation"
                      readOnly={fieldToRead}
                    />
                    {errors[i]?.relation && <p className="text-red-500 text-[0.8rem]">{errors[i].relation}</p>}
                  </div>
        
                  <div>
                    <label className="input-label">Loan Type</label>
                    {/* <input className="input-box" value={individualView?.loan_type} readOnly/> */}
                    <InputField
                      type="select"
                      className="input-box"
                      options={[
                        { value: "", label: "Select an Option" },
                        { value: "SECURED", label: "SECURED" },
                        { value: "UNSECURED", label: "UNSECURED" }
                      ]}
                      name="loan_type"
                      value={el.loan_type}
                      onChange={(e)=>handleInputChange(i,e)}
                      readOnly={fieldToRead}
                    />
                    {errors[i]?.loan_type && <p className="text-red-500 text-[0.8rem]">{errors[i].loan_type}</p>}
                  </div>
        
                  <div>
                    <label className="input-label">Amount Borrowed</label>
                    {/* <input className="input-box" value={individualView?.amount_borrowed} readOnly/> */}
                    <InputField 
                      type="number"
                      className="input-box"
                      value={el.amount_borrowed}
                      onChange={(e)=>handleInputChange(i,e)}
                      name="amount_borrowed"
                      readOnly={fieldToRead}
                    />
                    {errors[i]?.amount_borrowed && <p className="text-red-500 text-[0.8rem]">{errors[i].amount_borrowed}</p>}
                  </div>
                </div>
                <div className="grid grid-cols-4 my-[1.5rem]">
                  <div>
                    <label className="input-label">Start Date</label>
                    {/* <input className="input-box" value={individualView?.start_date} readOnly/> */}
                    <InputField 
                      type="date"
                      className="input-box"
                      value={el.start_date}
                      onChange={(e)=>handleInputChange(i,e)}
                      name="start_date"
                      readOnly={fieldToRead}
                    />
                    {errors[i]?.start_date && <p className="text-red-500 text-[0.8rem]">{errors[i].start_date}</p>}
                  </div>
        
                  <div>
                    <label className="input-label">End Date</label>
                    {/* <input className="input-box" value={individualView?.end_date} readOnly/> */}
                    <InputField 
                      type="date"
                      className="input-box"
                      value={el.end_date}
                      onChange={(e)=>handleInputChange(i,e)}
                      name="end_date"
                      readOnly={fieldToRead}
                    />
                    {errors[i]?.end_date && <p className="text-red-500 text-[0.8rem]">{errors[i].end_date}</p>}
                  </div>
        
                  <div>
                    <label className="input-label">Period</label>
                    {/* <input className="input-box" value={individualView?.period_years} readOnly/> */}
                    <InputField
                      type="select"
                      value={el.period_years}
                      name="period_years"
                      onChange={(e)=>handleInputChange(i,e)}
                      className="input-box"
                      readOnly={fieldToRead}
                      options={[
                        { value: "", label: "Select an Option" },
                        { value: 1, label: "1 years" },
                        { value: 2, label: "2 years" },
                        { value: 3, label: "3 years" },
                      ]}
                    />
                    {errors[i]?.period_years && <p className="text-red-500 text-[0.8rem]">{errors[i].period_years}</p>}
                  </div>
        
                  <div>
                    <label className="text-[0.7rem] font-[500] text-customPurple">Amount Repaid</label>
                    {/* <input className="input-box text-customPurple bg-[#F3EFF8]" value={individualView?.amount_repaid} readOnly/> */}
                    <InputField
                      type="text"
                      className="input-box"
                      value={el.amount_repaid}
                      name="amount_repaid"
                      onChange={(e)=>handleInputChange(i,e)}
                      readOnly={fieldToRead}
                    />
                    {errors[i]?.amount_repaid && <p className="text-red-500 text-[0.8rem]">{errors[i].amount_repaid}</p>}
                  </div>
                </div>
                <div className="grid grid-cols-4 ">
                  <div>
                    <label className="input-label">Payment Date</label>
                    {/* <input className="input-box" value={individualView?.payment_date} readOnly/> */}
                    <InputField
                      type="date"
                      className="input-box"
                      value={el.payment_date}
                      name="payment_date"
                      onChange={(e)=>handleInputChange(i,e)}
                      readOnly={fieldToRead}
                    />
                    {errors[i]?.payment_date && <p className="text-red-500 text-[0.8rem]">{errors[i].payment_date}</p>}
                  </div>
        
                  <div>
                    <label className="input-label">Payable Amount per month</label>
                    {/* <input className="input-box" value={individualView?.payable_per_month} readOnly/> */}
                      <InputField
                        type="number"
                        value={el.payable_per_month}
                        name="payable_per_month"
                        onChange={(e)=>handleInputChange(i,e)}
                        className="input-box"
                        readOnly={fieldToRead}
                      />
                      {errors[i]?.payable_per_month && <p className="text-red-500 text-[0.8rem]">{errors[i].payable_per_month}</p>}
                  </div>
        
                  <div>
                    <label className="input-label">Interest Rate</label>
                    {/* <input className="input-box" value={individualView?.interest_rate} readOnly/> */}
                    <InputField
                      type="number"
                      className="input-box"
                      value={el.interest_rate}
                      name="interest_rate"
                      onChange={(e)=>handleInputChange(i,e)}
                      readOnly={fieldToRead}
                    />
                    {errors[i]?.interest_rate && <p className="text-red-500 text-[0.8rem]">{errors[i].interest_rate}</p>}
                  </div>
        
                  <div>
                    <label className="text-[0.7rem] font-[500] text-customPurple">Balance Payable</label>
                    {/* <input className="input-box text-customPurple bg-[#F3EFF8]" value={individualView?.balance_amount} readOnly/> */}
                      <InputField
                        type="number"
                        value={el.balance_amount}
                        name="balance_amount"
                        onChange={(e)=>handleInputChange(i,e)}
                        className="input-box"
                        readOnly={fieldToRead}
                      />
                      {errors[i]?.balance_amount && <p className="text-red-500 text-[0.8rem]">{errors[i].balance_amount}</p>}
                  </div>
                </div>
                </div>
               )
            })
        }
      </div>
    </div>
  );
};

export default FamilyLoanDetailedView;
