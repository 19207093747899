import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import previousBtn from "assets/images/taxProfile/arrow-left.svg";
import { LIABILITIES_FAMILY_LOAN, LIABILITIES_PERSONAL_LOAN, LIABILITIES_REVOLVING_LOAN, LIABILITIES_URL } from "constants/routes";

const LiabilitiesHeading = ({ loan_type, loan_type_details, loan_type_id }) => {
  const location = useLocation(); 
  const navigate = useNavigate();
  const isDetailedView = location.pathname.includes("detailed-view");

  const handleLiabilitiesClicked = () => {
    navigate(LIABILITIES_URL);
  };

  const handleLiabilitiesType = () => {
    // navigate(`${LIABILITIES_PERSONAL_LOAN}?id=${loan_type_id}`, {state: {loan_type: loan_type}});
    if (location.pathname.includes("family-loan")) {
      navigate(`${LIABILITIES_FAMILY_LOAN}?id=${loan_type_id}`, {state: {loan_type: loan_type}});
    } else if (location.pathname.includes("personal-loan")) {
      navigate(`${LIABILITIES_PERSONAL_LOAN}?id=${loan_type_id}`, {state: {loan_type: loan_type}});
    } else if (location.pathname.includes("revolving-loan")) {
      navigate(`${LIABILITIES_REVOLVING_LOAN}?id=${loan_type_id}`, {state: {loan_type: loan_type}});
    }
  };

  const handlePrevious = () => {
    if (isDetailedView) {
      if (location.pathname.includes("family-loan")) {
        navigate(`${LIABILITIES_FAMILY_LOAN}?id=${loan_type_id}`, {state: {loan_type: loan_type}});
      } else if (location.pathname.includes("personal-loan")) {
        navigate(`${LIABILITIES_PERSONAL_LOAN}?id=${loan_type_id}`, {state: {loan_type: loan_type}});
      } else if (location.pathname.includes("revolving-loan")) {
        navigate(`${LIABILITIES_REVOLVING_LOAN}?id=${loan_type_id}`, {state: {loan_type: loan_type}});
      }
    } else {
      navigate(LIABILITIES_URL);
    }
  };
  

  return (
    <div className="pt-[2rem] flex gap-[1rem] items-center">
      <button onClick={handlePrevious}>
        <img src={previousBtn} alt="" className="w- h-" />
      </button>
      {isDetailedView ? (
        <h4 className="font-[600] text-darkGray text-[1.5rem] m-0">
          <span onClick={handleLiabilitiesClicked} className="cursor-pointer">
            {" "}
            Liabilities
          </span>
          <span onClick={handleLiabilitiesType} className="cursor-pointer">
            {" "}
            {`/ ${loan_type}`}
          </span>

          {`/ ${loan_type_details}`}
        </h4>
      ) : (
        <h4 className="font-[600] text-darkGray text-[1.5rem] m-0">
          <span onClick={handleLiabilitiesClicked} className="cursor-pointer">
            {" "}
            Liabilities
          </span>
          <span onClick={handleLiabilitiesType} className="cursor-pointer">
            {" "}
            {`/ ${loan_type}`}
          </span>
        </h4>
      )}
    </div>
  );
};

export default LiabilitiesHeading;
