import React, { useEffect, useState } from "react";
import Input from "components/input/Input";
import logo from "assets/images/dashboard/logo.svg";
import "assets/images/login/Section.svg";
import { Link, useNavigate } from "react-router-dom";
import backIcon from "assets/images/login/backIcon.png";

//API's 
import usePostSendOTP from "./Data/usePostSendOTP";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);

  const [data, error, isLoading, postOtp] = usePostSendOTP();
  const navigate = useNavigate();

  useEffect(() => {
    if (submitClicked && email) {
      postOtp({ email: email  });
      setSubmitClicked(false);
    }
  }, [submitClicked, email]);

  useEffect(() => {
    if (data) {
      showSuccessToast("OTP sent successfully")
      navigate("/otp", { state: { email: email, otp_id: data.data.otp_id }});
      console.log("SendOtpData id", data.data.otp_id);
      console.log("SendOtpData", data);
    
    }
  }, [data]); 

  useEffect(() => {
    if (error) {
      let desc = error.message || "Something went wrong. Please try again."
      showErrorToast('',desc)
    }
  }, [error]); 

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitClicked(true);
  }; 

  return (
    <div className="slightDarkBlack grid grid-cols-12 bg-white-body h-screen">
      <div className="col-span-6 p-4">
      <Link to={"/"}>
            {" "}
            <img src={logo} alt="" />
          </Link>
        <div className="flex flex-col items-center h-full justify-center ">
          <div className=" w-[60%]">
            <h2 className=" font-[700] leading-[2.1rem] text-[1.9rem] mb-8">
              Forgot password
            </h2>

            <Input
              label={"Email"}
              placeholder={"Enter your email"}
              type={"email"}
              value={email}
              setValue={setEmail}
            />
            <p className="font-[600] flex justify-center items-center">or</p>
            <Input
              label={"Mobile Number"}
              placeholder={"Enter your Mobile Number"}
              value={mobileNumber}
              setValue={setMobileNumber}
            />
          </div>
          <br />
          <button
            onClick={handleSubmit}
            className=" purpleBtn p-2 rounded  w-[60%] text-white"
          >
            Reset password
          </button>
          <div className="flex justify-between my-2 items-center">
            <Link
              to="/login"
              style={{ textDecoration: "none", color: "#667085" }}
            >
              <button className="m-0 flex gap-3">
                <img src={backIcon} alt="backIcon" className="py-1" />
                Back to log in
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className='bg-[url("assets/images/login/Section.png")] w-[100%] bg-cover bg-center rounded-bl-[10%] rounded-tl-[10%] col-span-6'></div>
    </div>
  );
}

export default ForgotPassword;
