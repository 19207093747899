import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React from "react";
import "../../../Liabilities/liabilities.css";
import Button from "components/Button/BtnOrange";
import filePic from "assets/images/assetsAndLiabilities/file-text.svg"


const AddBuildingPhoto = ({ landPhoto, setlandPhoto, updateDetails }) => {

  const handleFileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files; // Get dropped files
    handleDrop(files); // Call the handleDrop method
  };

  const handleDrop = (files) => {
    // const updatedLoans = [...loans];
    // updatedLoans[index].agreement_file = files[0]; // Assuming you're only handling one file
    // console.log('Dropped file updatedLoans', updatedLoans);
    setlandPhoto(files[0]);
  };
  return (
    <div>
      <DialogBox
        triggerElement={
          <button className="py-[0.3rem] px-[0.4rem] border-1 border-customPurple bg-[#FAF8FC] rounded-md text-[0.8rem] font-[600] text-customPurple m-0">
            Add Photo of the Asset
          </button>
        }
        heading={
          <h4 className="font-[600] text-xl text-[#334155]">
            Add <span className="text-[#9065B4]">Building photo</span> to your
            Assets
          </h4>
        }
        content={(handleClose) => (
          <>
            <div className="mt-3 mx-3 mb-[10rem]">
              <div
                className="p-14 border-[3px] border-dashed rounded-lg"
                onDrop={(e) => handleFileDrop(e)}
                onDragOver={(e) => e.preventDefault()} // Prevent default to allow drop
              >
                {landPhoto ? (
                  <div className="text-center flex flex-col items-center">
                    <img src={filePic} alt="File Icon" />

                    <p className="text-center text-[#334155] font-[600] m-0">
                      {landPhoto.name}
                    </p>
                  </div>
                ) : (
                  <p className="text-center text-[#334155] font-[600] m-0">
                    Drag & Drop Here to upload
                  </p>
                )}
              </div>
              <div className="mt-4 flex flex-col gap-2.5 items-center">
                <p className="text-[#334155] text-[1rem] font-[500] m-0">
                  Select the files Manually From your PC
                </p>

                <InputField
                  type="file"
                  accept=".jpeg, .png, .jpg"
                  id={`file-input-land-photo`}
                  className="hidden"
                  onChange={(e) => setlandPhoto(e.target.files[0])}
                />
                <WhiteButton
                  onClick={() =>
                    document.querySelector(`#file-input-land-photo`).click()
                  }
                  name="Open Folder"
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
              </div>
            </div>

            <div className="flex justify-end gap-[1rem]">
              <WhiteButton
                onClick={handleClose}
                name="Cancel"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
              <Button
                onClick={updateDetails}
                name={"Save"}
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
            </div>
          </>
        )}
      />
    </div>
  );
};

export default AddBuildingPhoto;
