import React, { useEffect, useState } from "react";
import usePostSendPanOTP from "./Data/usePostSendPanOTP";
import usePostValidatePanOTP from "./Data/usePostValidatePanOTP";
import PanSuccessDialog from "./PanSuccessDialog";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";

export default function PanCardOtpDialog(props) {
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [showSuccess, setShowSuccess] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));

  const [validateData, validateError, validateIsLoading, postValidateOtp] =
    usePostValidatePanOTP();
  const [data, error, isLoading, sendPanOtp] = usePostSendPanOTP();

  useEffect(() => {
    if (validateData) {
      showSuccessToast("OTP validated successfully")
      setShowSuccess(true); 
    }
  }, [validateData]);


  const handleSubmit = () => {
    const validateOtp = {
      otp_id: props.otp_id,
      otp: otp.join(""),
      new_pan_number: props.pan_Number,
    };
    postValidateOtp(validateOtp);
  };

  useEffect(() => {
    if (validateError) {
      let desc= validateError.err.response.data.data.message;
      showErrorToast("", desc)
    }
  }, [validateError]);

  const handleChange = (element, index) => {
    const value = element.value;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value === "") {
      const prevSibling = document.getElementById(`otp-${index - 1}`);
      if (prevSibling !== null) {
        prevSibling.focus();
      }
    } else if (/^[0-9]$/.test(value) && index < 3) {
      const nextSibling = document.getElementById(`otp-${index + 1}`);
      if (nextSibling !== null) {
        nextSibling.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      const prevSibling = document.getElementById(`otp-${index - 1}`);
      if (prevSibling !== null) {
        prevSibling.focus();
      }
    }
  };

  const handleResend = () => {
    if (user && user.email) {
      sendPanOtp({ pan_number: user.pan_no });
    }
  };

  useEffect(() => {
    if (data) {
      showSuccessToast("OTP re-sent successfully")
    }
  }, [data]);

  return (
    // <Dialog
    //   open={props.open}
    //   // onClose={props.onClose}
    //   PaperProps={{
    //     style: { width: "45%", height: "80%" },
    //   }}
    // >
      <div className="">
        {showSuccess ? (
          <PanSuccessDialog
            open={showSuccess}
          />
        ) : (
          <>
            <h2 className="font-[700] leading-[2.1rem] text-[1.4rem] flex">
              OTP Verify
            </h2>
            <p className="text-gray-500 font-[400] text-[1rem] leading-[1.5rem] flex">
              An OTP has been sent to {user?.email}. Confirming OTP will reset
              your Income tax password.
            </p>
            <div className="flex space-x-2">
              {otp.map((data, index) => (
                <input
                  key={index}
                  id={`otp-${index}`}
                  type="text"
                  maxLength="1"
                  className="w-20 h-20 text-center border rounded-md text-[1.9rem] font-medium text-[#9065B4] focus:outline-[#D6BBFB]"
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              ))}
            </div>
            <div className="flex justify-between items-center">
              <p className="text-gray-400 mt-3">Resend OTP in 30s</p>
              <button onClick={handleResend} className="text-purple-500">
                Resend OTP
              </button>
            </div>
            <button
              onClick={handleSubmit}
              className="purpleBtn p-2 rounded w-[100%] text-white"
            >
              Confirm OTP
            </button>
          </>
        )}
      </div>
    // </Dialog>
  );
}
