import React, { useEffect, useRef, useState } from "react";
import Glide from "@glidejs/glide";
import next from 'assets/images/dashboard/nextDoc.svg'
import prev from 'assets/images/dashboard/prevDoc.svg'

const DocSlider = (props) => {
  const glideRef = useRef(null);
const [imgArr,setImgArr] = useState([])
const [ready,setReady] = useState(false)

useEffect(()=>{
    if(props.imgArr){
        setImgArr(props.imgArr.data.results);
    setReady(true)}
},[])
  const ITListNames = [
    "Salaried Income Returns",
    "Business/Freelancing Income Returns",
    "Refund Re-Issue/Status",
    "Reply to Notice/Order",
  ];
// console.log(imgArr)
  useEffect(() => {
    if(ready){
    // Initialize Glide.js when the component mounts
    const glide = new Glide(glideRef.current, {
      //   type: 'slider', // Type of the slider (carousel, slider, carousel-3d, or slider-3d)
      perView: 1.5, // Number of slides to show per view
        focusAt: 'center', // Set where the slider should focus
      gap: 16, // Space between slides (in pixels)
      autoplay: 3000,
      
    });

    glide.mount(); // Mount the Glide.js instance

    // Clean up the Glide.js instance when the component unmounts
    return () => {
      glide.destroy();
    };
}}); // Run this effect only once when the component mounts

  return (
   <>
   {imgArr.length > 0 && <div className="glide  " ref={glideRef}>
     
     <div className="glide__track" data-glide-el="track">
       <ul className="glide__slides">
      
         {ready && imgArr.map((i) => {
           return (

             <li className="glide__slide ">
               {" "}
             <div className="bg-[#3BC5C599] h-60 p-3 flex   mr-[2%] rounded-2xl ">
{/* <h3 className=" not-italic pl-[5%] font-[700] text-gray-700 text-[2.2rem] leading-[3rem] pr-[3rem]	">{i}</h3>*/}
<img className=" w-[100%] mx-auto rounded" alt="" src={i.document_url} />
               </div>
             </li>
           );
         })}
       </ul>
     </div>
     <div className="glide__arrows flex flex-row-reverse px-[2%] " data-glide-el="controls">
      <button className="glide__arrow glide__arrow--right  mr-[.5rem] my-2" data-glide-dir=">">
      <img src={next} alt=""/>
       </button>
       <button className="glide__arrow glide__arrow--left mr-[.5rem] my-2" data-glide-dir="<">
      <img src={prev} alt=""/>
       </button>
     
     </div>
    
   </div>}
   
    </>
  );
};

export default DocSlider;
