import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useRef, useState } from "react";
import AddLoanTypeDialog from "./AddLoanTypeDialog";
import Button from "components/Button/BtnOrange";
import useGetLiabilities from "./data/useGetLiabilities";

const AddYourLiability = ({ initial }) => {
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [loanId, setLoanId] = useState(null);
  const loanButtonRef = useRef(null);
  const [getData, error, isLoading, getLiabilities] = useGetLiabilities();

  useEffect(() => {
    getLiabilities();
  }, []);

  const handleLoanClick = (loanType, loanId) => {
    setSelectedLoan(loanType);
    setLoanId(loanId);
    // setOpenLoanTypeDilog(true)
    loanButtonRef.current.click();
  };
  

  return (
    <div>
      <DialogBox
        triggerElement={
          initial ?<Button
          px={"px-1.5"}
          py={"py-1"}
          name="+ Add Your First Liability"
        />:<Button
        px={"px-[0.9rem]"}
        py={"py-[0.3rem]"}
        name="+ Add New Liability"
      />
        }
        heading="Add to your Liabilities"
        content={(handleClose) => (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Choose a Liability type
            </h4>

            <div className="flex gap-2">
              <div className=" mt-2 w-[100%]">
                {/* secured loans */}
                <div className="border rounded-lg py-2 px-[0.8rem] w-[100%] bg-white mb-2">
                  <h4 className="text-[#64748B] font-[600] text-[0.9rem]">
                    Secured Loans
                  </h4>
                  {getData?.data
                    ?.filter((item) => item.loan_category === "SECURED") // Filter by loan_category
                    .map((item, index) => (
                      <div
                        key={item.id} // Adding key for list rendering
                        className="border-1 mb-2 rounded-md p-1 bg-[#FAF8FC] border-[#EAE1F3] hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                      >
                        <div
                          className="flex gap-2 items-center cursor-pointer"
                          onClick={() => {
                            handleLoanClick(item.loan_name, item.id);
                            handleClose();
                          }}
                        >
                          <img src={item.loan_image} alt=""/>
                          <h4 className="m-0 text-[#334155] font-[400] text-[0.9rem]">
                            {item.loan_name}
                          </h4>
                        </div>
                      </div>
                    ))}
                </div>

                {/* Emergency loans */}
                <div className="border rounded-lg py-2 px-[0.8rem] w-[100%] mb-2">
                  <h4 className="text-[#64748B] font-[600] text-[0.9rem] mb-1">
                    Emergency Loans
                  </h4>
                  {getData?.data
                    ?.filter((item) => item.loan_category === "EMERGENCY") // Filter by loan_category
                    .map((item, index) => (
                      <div
                        key={index} // Adding key for list rendering
                        className="border-1 mb-2 rounded-md p-1 bg-[#FAF8FC] border-[#EAE1F3] hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                      
                      >
                        <div
                          className="flex gap-2 items-center cursor-pointer"
                          onClick={() => {
                            handleLoanClick(item.loan_name, item.id);
                            handleClose();
                          }}
                        >
                          <img src={item.loan_image} alt=""/>
                          <h4 className="m-0 text-[#334155] font-[400] text-[0.9rem]">
                            {item.loan_name}
                          </h4>
                        </div>
                      </div>
                    ))}
                </div>

                {/* Revolving Credit */}
                <div className="border rounded-lg py-2 px-[0.8rem] w-[100%] ">
                  <h4 className="text-[#64748B] font-[600] text-[0.9rem]">
                    Revolving Credit
                  </h4>
                  {getData?.data
                    ?.filter(
                      (item) => item.loan_category === "REVOLVING_CREDIT"
                    ) // Filter by loan_category
                    .map((item, index) => (
                      <div
                        key={index} // Adding key for list rendering
                        className="border-1 mb-2 rounded-md p-1 bg-[#FAF8FC] border-[#EAE1F3] hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                      >
                        <div
                          className="flex gap-2 items-center cursor-pointer"
                          onClick={() => {
                            handleLoanClick(item.loan_name, item.id);
                            handleClose();
                          }}
                        >
                          <img src={item.loan_image} alt=""/>
                          <h4 className="m-0 text-[#334155] font-[400] text-[0.9rem]">
                            {item.loan_name}
                          </h4>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div className="mt-2 w-[100%]">
                {/* unsecured loans */}
                <div className="border rounded-lg py-2 px-[0.8rem] w-[100%] mb-2">
                  <h4 className="text-[#64748B] font-[600] text-[0.9rem]">
                    UnSecured Loans
                  </h4>
                  {getData?.data
                    ?.filter((item) => item.loan_category === "UNSECURED") // Filter by loan_category
                    .map((item, index) => (
                      <div
                        key={index} // Adding key for list rendering
                        className="border-1 mb-2 rounded-md p-1 bg-[#FAF8FC] border-[#EAE1F3] hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                      >
                        <div
                          className="flex gap-2 items-center cursor-pointer"
                          onClick={() => {
                            handleLoanClick(item.loan_name, item.id);
                            handleClose();
                          }}
                        >
                          <img src={item.loan_image} alt=""/>
                          <h4 className="m-0 text-[#334155] font-[400] text-[0.9rem]">
                            {item.loan_name}
                          </h4>
                        </div>
                      </div>
                    ))}
                </div>

                {/* Family */}
                <div className="border rounded-lg py-2 px-[0.8rem] w-[100%]">
                  <h4 className="text-[#64748B] font-[600] text-[0.9rem]">
                    Family & Informal Loans
                  </h4>
                  {getData?.data
                    ?.filter((item) => item.loan_category === "FAMILY_INFORMAL") // Filter by loan_category
                    .map((item, index) => (
                      <div
                        key={index} // Adding key for list rendering
                        className="border-1 mb-2 rounded-md p-1 bg-[#FAF8FC] border-[#EAE1F3] hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                      >
                        <div
                          className="flex gap-2 items-center cursor-pointer"
                          onClick={() => {
                            handleLoanClick(item.loan_name, item.id);
                            handleClose();
                          }}
                        >
                          <img src={item.loan_image} alt=""/>
                          <h4 className="m-0 text-[#334155] font-[400] text-[0.9rem]">
                            {item.loan_name}
                          </h4>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}
      />
      
    {/* {
      openLoanTypeDilog &&  <AddLoanTypeDialog
      loanTypeId={loanId}
      loanType={selectedLoan}
      triggerElement={
        <button ref={loanButtonRef} className="hidden">
          Open Loan Dialog
        </button>
      }
    />
    } */}
    <AddLoanTypeDialog
      loanTypeId={loanId}
      loanType={selectedLoan}
      triggerElement={
        <button ref={loanButtonRef} className="hidden">
          Open Loan Dialog
        </button>
      }
    />
    </div>
  );
};

export default AddYourLiability;
