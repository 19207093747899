import React, { useEffect, useRef, useState } from "react";
import AssetsHeading from "../AssetsHeading";
import { Link, useLocation } from "react-router-dom";
import { ASSETS_OTHER_ASSET_DETAILED_URL} from "constants/routes";
import AddNewLoan from "./Add/AddNewLoan";
import useGetLoanDetails from "../data/useGetLoanDetails";
import friendsPic from "assets/images/assets/Friends.svg";
import Button from "components/Button/BtnOrange";

const Loan = () => {
  const location = useLocation();
  const [isAddLoan, setIsAddLoan]= useState(false)
  const queryParams = new URLSearchParams(location.search);
  const addLoanBtnRef= useRef(null)
  const [reload, setIsReload]= useState(false)

  const loanId =
    location.state?.landId || decodeURIComponent(queryParams.get("id"));

  const [getLoanData, getLoanError, getLoanIsLoading, getLoan] =
    useGetLoanDetails();


  useEffect(() => {
    getLoan(loanId);
  }, []);

  useEffect(()=>{
    if(reload){
      getLoan(loanId)
    }
  },[reload])

  useEffect(()=>{
    if(isAddLoan){
      addLoanBtnRef.current.click()
    }
  },[isAddLoan])
 
  const openHandleForm =()=>{
    setIsAddLoan(true)
  }

  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 h-screen">
      <AssetsHeading asset={"Other Assets"} asset_type={"Loan Given to Family-Friends"}/>
      <div className="flex mt-[1.5rem] border bg-white rounded-xl  grid grid-cols-12 divide-x">
        <div className=" p-[1rem] col-span-3">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
          Total Loans Value
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">₹{getLoanData?.data[0]?.total_loans_value}</h4>
        </div>  

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Total Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">{getLoanData?.data[0]?.total_assets}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Active Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">{getLoanData?.data[0]?.active_assets}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Idle Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">{getLoanData?.data[0]?.idle_assets}</h4>
        </div>
        <div className="flex justify-center items-center p-[1rem] col-span-3">
          <Button onClick={openHandleForm} name={"+ Add New Loan"} px={"px-[0.6rem]"} py={"py-[0.4rem]"} />
        </div>
      </div>

      {/* lands */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
       
        <div className="grid grid-cols-4 gap-2 ">
          {getLoanData ? (
            getLoanData?.data?.map((item, index) => (
              <Link
                to={`${ASSETS_OTHER_ASSET_DETAILED_URL.replace(
                  ":other_asset_type",
                  "LoansToFamilyFriends"
                )}?id=${loanId}/?loan_id=${item.id}`}
                state={{ assetId: loanId, itemId: item.id, itemName: item?.borrower_name }}
                key={item.id}
                className="no-underline"
              >
                <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
                  <img src={friendsPic} alt="land" className="w-7 h-7 mb-2" />
                  <h5 className="text-lightGray font-[600] text-[0.9rem]">
                   {item?.borrower_name}
                  </h5>
                  <div className="flex justify-end mt-[1.8rem]">
                    <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                      ₹ {item?.amount_borrowed}
                    </h4>
                  </div>{" "}
                </div>
              </Link>
            ))
          ) : (
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={friendsPic} alt="land" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">Loans and Advances</h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                  Add New
                </button>
              </div>{" "}
            </div>
          )}
        </div>
      </div>
      {isAddLoan && (
        <AddNewLoan
          categoryId={loanId}
          setIsAddLoan={setIsAddLoan}
          isAddLoan={isAddLoan}
          setIsReload={setIsReload}
          triggerElement={
            <button ref={addLoanBtnRef} className="hidden">
              Add Land
            </button>
          }
        />
      )}
    </div>
  );
};

export default Loan;
