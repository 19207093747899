import React, { useState, useContext, useEffect } from "react";
import { myContext } from "App";
import DocSlider from "./DocSlider";
import useGetDocsListDownload from "./data/useGetDownloadList";
import useGetDueDate from "./data/useGetDueDate";
import useGetUserBasic from "./data/getUserbasicDetails";
import SampleDocsSlider from "./SampleDocsSlider";

function ProfileAndCertificate() {
  const [name, setName] = useState("");
  const { isLoggedIn} = useContext(myContext);
  const [docImages, setDocImages] = useState([]);
  const [dueDateArr, setDueDateArr] = useState([]);
  const [businessName, setBusinessName] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));

  const [
    docsListDownloadData,
    docsListDownloadError,
    docsListDownloadIsLoading,
    getDocsListDownload,
  ] = useGetDocsListDownload();

  const [dueDateData, dueDateError, dueDateIsLoading, getDueDate] =
    useGetDueDate();

  const [UserBasicData, userBasicError, userBasicIsLoading, getUserBasic] =
    useGetUserBasic();

  const [firstLetter, setFirstLetter] = useState("");
  const [profilePic, setProfilePic] = useState(user?.profile_picture || "");

  const [showPP, setShopPP] = useState(!!profilePic);

  const [dataFetched, setDataFetched] = useState(false);
  const [date, setDate] = useState(new Date());
  const [filteredDates, setFilteredDates] = useState([]);

  useEffect(() => {
    if (isLoggedIn && !dataFetched) {
      getDocsListDownload();
      getDueDate();
      setDataFetched(true);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (UserBasicData) {
      setName(
        UserBasicData?.data?.full_name
          .split(" ")
          ?.map(
            (word) =>
              word?.charAt(0)?.toUpperCase() + word?.substr(1)?.toLowerCase()
          )
          ?.join(" ")
      );
      setFirstLetter(UserBasicData?.data?.profile_title);
      setProfilePic(UserBasicData?.data?.profile_picture);
      setBusinessName(
        UserBasicData?.data?.business_name
          .split(" ")
          ?.map(
            (word) =>
              word?.charAt(0)?.toUpperCase() + word?.substr(1)?.toLowerCase()
          )
          ?.join(" ")
      );

      if (UserBasicData?.data?.profile_picture?.length > 0) {
        setShopPP(true);
      }
    }
    if (docsListDownloadData) {
      setDocImages(docsListDownloadData.data.results);
    }
    if (dueDateData) {
      const flattenedData = [];
      Object.keys(dueDateData.data).forEach((month) => {
        dueDateData.data[month].forEach((item) => {
          flattenedData.push(item);
        });
      });
      setDueDateArr(flattenedData);
    }
  }, [UserBasicData, docsListDownloadData, dueDateData]);

  useEffect(() => {
    const currentMonth = date.getMonth();
    const currentYear = date.getFullYear();

    const filtered = dueDateArr.filter((data) => {
      const eventDate = new Date(data.date);
      return (
        eventDate.getMonth() === currentMonth &&
        eventDate.getFullYear() === currentYear
      );
    });
    setFilteredDates(filtered);
  }, [date, dueDateArr]);


  return (
    <>
      {docsListDownloadIsLoading || dueDateIsLoading || userBasicIsLoading ? (
        <div className="bg-gray-100">
          <div role="status" class="max-w-full animate-pulse pt-20 pb-4">
            <div class="h-screen  w-full bg-[#f5eef5] rounded dark:bg-[#FFFBFF]   mb-4"></div>

            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className=" bg-purple-50 h-screen fixed py-[6%]">
          {docImages?.length > 0 ? (
            <>
              <h5 className="font-[500] text-[1.5rem] leading-[2.3rem] m-0 p-[2%]">
                My Certificates
              </h5>
              <DocSlider imgArr={docsListDownloadData} />
            </>
          ) : (
            <>
              <div className=" w-80 overflow-x-hidden">
                <p className=" text-center">Sample Documents</p>

                <SampleDocsSlider />
              </div>
            </>
          )}
          <div className="p-[4%]">
            <h4 className="font-[500] text-[1.5rem] leading-[2.4rem]">
              Upcoming Due Dates
            </h4>
            <div className="bg-[#E4E1EA] overflow-y-scroll h-[14.5rem] p-3 rounded-xl">
              {filteredDates.length > 0 ? (
                filteredDates.map((data, index) => (
                  <div key={index}>
                    <span className="bg-[#D3C1E1] px-2 py-1 rounded-md text-[#344054] font-[500] text-[.8rem] leading-[.9rem]">
                      {data.date}
                    </span>
                    <ul>
                      <li className="list-disc text-[#344054] font-[500] text-[.9rem] leading-[1.5rem]">
                        {data.compliance_activity}
                      </li>
                      <li className="list-disc text-[#344054] font-[500] text-[.9rem] leading-[1.5rem]">
                        {data.penalty_fine_interest}
                      </li>
                    </ul>
                  </div>
                ))
              ) : (
                <p>No important dates for this month.</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProfileAndCertificate;
