import React from "react";
import data_lock from "assets/images/incomeTax/data_lock.png";
import savings from "assets/images/incomeTax/savings.png";
import time_saving from "assets/images/incomeTax/time-saving.png";

const SummarySidebar = ({ layout }) => {
  const cards = [
    {
      icon: time_saving,
      heading: "Time-Saving Tip",
      content:
        "Import Now: Instantly fetch your details from the income Tax portal",
    },
    {
      icon: data_lock,
      heading: "Your Data: Safe and Secure",
      content:
        "We prioritize your privacy. Our secure import process uses advanced encryption to protect your sensitive tax information at every step.",
    },
    {
      icon: savings,
      heading: "Tax Savings Analyzer",
      content:
        "Discover potential deductions and credits you may have missed. Our smart tool analyzes your imported data to maximize your refund",
    },
  ];
  return (
    <div className={`grid gap-4 ${layout === 'horizontal' ? 'grid-cols-3' : 'grid-cols-1'}`}>
      {cards.map((card) => (
        <div key={card.id} className="bg-white border rounded-md shadow px-4 py-3">
          <img src={card.icon} alt={card.heading} className="mb-4"/>
          <h4 className=" font-[600] text-[1.2rem]">{card.heading}</h4>
          <p className=" text-[#64748B] font-[400] text-[0.9rem]">
            {card.content}
          </p>
        </div>
      ))}
    </div>
  );
};

export default SummarySidebar;
