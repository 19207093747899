import React, { useEffect, useRef, useState } from "react";
import AssetsHeading from "../AssetsHeading";
import grayBox from "assets/images/assets/grayBoxRectangle.svg";
import { Link, useLocation } from "react-router-dom";
import { ASSETS_PERSONAL_ASSET_DETAILED_URL} from "constants/routes";
import useGetArtDetails from "../data/useGetArtDetails";
import AddNewFurniture from "./Add/AddNewFurniture";
import Button from "components/Button/BtnOrange";

const Furniture = () => {
  const location = useLocation();
  const [selectedEstate, setSelectedEstate] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const [isArt, setIsArt]= useState(false)
  const addArtButtonRef = useRef(null)
  const [reload, setIsReload]= useState(false)

  const artId =
    location.state?.landId || decodeURIComponent(queryParams.get("id"));

  const [getArtData, getArtError, getArtIsLoading, getArt] =
    useGetArtDetails();

  useEffect(() => {
    getArt(artId);
  }, []);

  useEffect(()=>{
    if(reload){
      getArt(artId)
    }
  },[reload])

  const openHandleForm = ()=>{
    setIsArt(true)
  }

  useEffect(()=>{
    if(isArt){
      addArtButtonRef.current.click()
    }
  },[isArt])

  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 h-screen">
      <AssetsHeading asset={"Personal Asset"} asset_type={"Art & Collectibles"}/>
      <div className="flex mt-[1.5rem] border bg-white rounded-xl  grid grid-cols-12 divide-x">
        <div className=" p-[1rem] col-span-3">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
          Total Furniture and Art Value
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">₹{getArtData?.data?.[0].total_furniture_art_value}</h4>
        </div>  

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Total Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">{getArtData?.data?.[0].total_assets}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Active Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">{getArtData?.data?.[0].active_assets}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Idle Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">{getArtData?.data?.[0].idle_assets}</h4>
        </div>
        <div className="flex justify-center items-center p-[1rem] col-span-3">
        <Button onClick={openHandleForm} name={"+ Add New Furniture/Art"} px={"px-[0.6rem]"} py={"py-[0.4rem]"} />
        </div>
      </div>

      {/* lands */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
       
        <div className="grid grid-cols-4 gap-2 ">
          {getArtData ? (
            getArtData?.data?.map((item, index) => (
              <Link
                to={`${ASSETS_PERSONAL_ASSET_DETAILED_URL.replace(
                  ":personal_asset_type",
                  "FurnitureAndArt"
                )}?id=${artId}/?art_id=${item.id}`}
                state={{ assetId: artId, itemId: item.id, itemName: item.item_name }}
                key={item.id}
                className="no-underline"
              >
                <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
                  <img src={grayBox} alt="land" className="w-7 h-7 mb-2" />
                  <h5 className="text-lightGray font-[600] text-[0.9rem]">
                   {item.item_name}
                  </h5>
                  <div className="flex justify-end mt-[1.8rem]">
                    <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                      ₹ {item.purchase_price}
                    </h4>
                  </div>{" "}
                </div>
              </Link>
            ))
          ) : (
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={grayBox} alt="land" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">Land</h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                  Add New
                </button>
              </div>{" "}
            </div>
          )}
        </div>
      </div>
      {
        isArt && <AddNewFurniture
        categoryId={artId}
        setIsArt={setIsArt}
        isArt={isArt}
        setIsReload={setIsReload}
        triggerElement={
         <button ref={addArtButtonRef} className="hidden">
           Add Art
         </button>
       }
        />
      }
    </div>
  );
};

export default Furniture;
