import Button from "components/Button/BtnOrange";
import React, { useEffect, useRef, useState } from "react";
import AddYourLiability from "../AddYourLiability";

// images
import personalLoanHeart from "assets/images/PersonalLoan/personalLoanHeart.svg";
import filePersonalLoan from "assets/images/PersonalLoan/filePersonalLoan.svg";
import { Link, useLocation, useParams } from "react-router-dom";
import { FAMILY_LOAN_DETAILED_VIEW} from "constants/routes";
import useGetIndividualLoansOverView from "../data/useGetIndividualLoansOverView";
import AddLoanTypeDialog from "../AddLoanTypeDialog";
import LiabilitiesHeading from "../LiabilitiesHeading";

const FamilyLoan = () => {
  const [getData, getError, getIsLoading, getLiabilitiesOverview] =
  useGetIndividualLoansOverView();
  const [initialState, setInitialState]=useState(false)
  const [loanDetails, setLoanDetails]=useState([])
  const loanButtonRef=useRef(null)
  const location = useLocation();
  // Extract the query parameters
  const queryParams = new URLSearchParams(location.search);
  // Get the `id` from the query parameters
  const id =decodeURIComponent(queryParams.get('id'));
  const name = decodeURIComponent(queryParams.get('name'));
  const loan_type = location.state?.loan_type;

  useEffect(()=>{
    getLiabilitiesOverview({},id)
  },[])
  useEffect(()=>{
   if(getData?.data.length>0){
     setInitialState(true)
     setLoanDetails(getData.data)
   }
  },[getData])
  const handleOpenLoanTypeModal=()=>{
    loanButtonRef.current.click();
  }
  const BankDetails = [
    {
      id: 1,
      bankName: "Dheeraj Rao",
      amount: "₹40 Thousand",
    },
  ];

  return (
    <div className="relative">
      {getIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
        )}
     <div className="px-[1rem] bg-gray-100 h-screen">
      <LiabilitiesHeading 
        loan_type={loan_type}
      />
      <div className="border bg-white rounded-xl p-[1rem] mt-3">
        <div className="flex justify-between">
          <h4 className="text-darkGray text-[1.2rem] font-[600]">Family Loans</h4>

          <AddYourLiability />
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1.5rem]">
          {/* <div className={`rounded-md border-2 border-dashed p-[1rem] ${initialState?"hidden":""}`}>
            <img src={personalLoanHeart} alt="heart" />
            <h5 className="text-lightGray font-[600] text-[0.9rem]">
              Family Loan
            </h5>
            <div className="flex justify-end mt-[2.5rem]">
              <button onClick={handleOpenLoanTypeModal} className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                Add New
              </button>
            </div>{" "}
          </div> */}

          {/* when get data is present */}
          {loanDetails.map((item, index) => (
            <>
              <Link
              className={`no-underline ${initialState? "":"hidden"}`}
              state={{ loan_type: loan_type }}
              to={{
                pathname: `${FAMILY_LOAN_DETAILED_VIEW}`,
                search: `?loan_id=${encodeURIComponent(item.id)}&id=${encodeURIComponent(id)}`,
                // state: { loan_type: name },  // Pass the state here
              }}
            //   to={LIABILITIES_PERSONAL_LOAN_DETAILS.replace(":loan_details", item.bankName)} // Dynamic URL with bankName
              key={index} // Move key to the outer element
              >
                <div className="rounded-md border p-[1rem]" >
                  <img
                    src={filePersonalLoan}
                    alt="heart"
                    className="w-10 h-10"
                  />
                  <h5 className="text-darkGray font-[500] text-[1rem] mt-1">
                    {item.lender_name}
                  </h5>
                  <div className="flex justify-end mt-[1.2rem]">
                    <h4 className="text-customPurple font-[600] text-[1.3rem]">
                    <span>&#8377;</span> {item.amount_borrowed}
                    </h4>
                  </div>{" "}
                </div>
              </Link>
            </>
          ))}
        </div>
      </div>
      <AddLoanTypeDialog
        loanType={name}
        loanTypeId={id}
        triggerElement={
          <button ref={loanButtonRef} className="hidden">
            Open Loan Dialog
          </button>
        }
      />
    </div>
      </div>
  );
};

export default FamilyLoan;
