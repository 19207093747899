import React, { useEffect, useRef, useState } from "react";
import barChart from "assets/images/assetsAndLiabilities/BarLineChart.svg";
import landingPic from "assets/images/assetsAndLiabilities/liabilitiesLandingPic.svg";
import trackingPic from "assets/images/assetsAndLiabilities/TrackLiabilities.svg";
import alarmPic from "assets/images/assetsAndLiabilities/alarm-check.svg";
import rocketPic from "assets/images/assetsAndLiabilities/rocket.svg";
import homePic from "assets/images/assetsAndLiabilities/Home.svg";
import carPic from "assets/images/assetsAndLiabilities/Car.svg";
import loanProperty from "assets/images/assetsAndLiabilities/Loan Property.svg";
import goldPic from "assets/images/assetsAndLiabilities/Gold.svg";
import medicalVanPic from "assets/images/assetsAndLiabilities/MedicalVan.svg";
import emergency from "assets/images/assetsAndLiabilities/Emergency.svg";
import heartPic from "assets/images/assetsAndLiabilities/Heart.svg";
import businessLoanPic from "assets/images/assetsAndLiabilities/BusinessLoan.svg";
import educationPic from "assets/images/assetsAndLiabilities/Education.svg";
import friendshipPic from "assets/images/assetsAndLiabilities/Friendship.svg";
import diamondPic from "assets/images/assetsAndLiabilities/Diamond.svg";
import dots from "assets/images/incomeTax/3dots.svg";
import forwardArrow from "assets/images/incomeTax/forward.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { LIABILITIES_FAMILY_LOAN, LIABILITIES_PERSONAL_LOAN, LIABILITIES_REVOLVING_LOAN } from "constants/routes";
import AddLoanTypeDialog from "./AddLoanTypeDialog";
import AddYourLiability from "./AddYourLiability";
import trashPic from "assets/images/assetsAndLiabilities/trash-2.svg";
import editPen from "assets/images/incomeTax/edit-3.svg"
import RemoveLiability from "./RemoveLiability";
import LineGraph from "./LineGraph";
import { Troubleshoot } from "@mui/icons-material";

const LiabilitiesViewPage = ({ getDataToView, individualId, getLiabilitiesOverview }) => {
  const [dataToView, setDataToView] = useState(null);
  const [idWithLoanType, setIdWithLoanType] = useState(null);
  const [selectedLoan, setSelectedLoan]=useState(null);
  const [individualLoanId, setIndividualLoanId]= useState(null)
  const [openMenuIdUnSecured, setOpenMenuIdUnSecured] = useState(null);
  const [openMenuIdSecured, setOpenMenuIdSecured] = useState(null);
  const [openMenuIdEmergency, setOpenMenuIdEmergency] = useState(null);
  const [openMenuIdFamily, setOpenMenuIdFamily] = useState(null);
  const [openMenuIdCredit, setOpenMenuIdCredit] = useState(null);
  const [sendingToRemove, setSendingToRemove] = useState({});
  const [isRelaodOverview, setIsReloadOverview]=useState(false)
  const [openLoanTypeDilog, setOpenLoanTypeDilog]=useState(false);
  const navigate=useNavigate();
  const location = useLocation()
  const loanButtonRef=useRef(null)
  const removeButtonRef=useRef(null)
  // Use useEffect to update the state when the prop changes
  const reloadValue = location.state?.value;
  useEffect(() => {
    if (getDataToView) {
      setDataToView(getDataToView);
    }
  }, [getDataToView]); // This will run whenever getDataToView changes

  useEffect(() => {
    setIdWithLoanType(individualId);
  }, [individualId]);

  useEffect(()=>{
    if(isRelaodOverview || reloadValue){
      getLiabilitiesOverview()
    }
  },[isRelaodOverview,location.state])

    const manuallyLoanTypes = [
      "Car Loan",
      "Loan Against Property",
      "Gold Loan",
      "Home Loan",
      "Personal Loan",
      "Business Loan",
      "Education Loan",
      "Medical Loan",
      "Emergency Personal Loan"      
    ];
  
    const manuallyFamilyLoanTypes = [
      "Family Loan",
      "Loan from Friends",
      "Loan for Wedding Expense"
    ];

    const manuallyCreditCardDebts = [
      "Credit Card Debt"
    ]

    const handleOpenLoanTypeModal=(name)=>{
      setOpenLoanTypeDilog(true)
      loanButtonRef.current.click();
      setSelectedLoan(name)
      const renamedObj = {
        ...idWithLoanType,
        // Renaming the keys by creating new ones
        "Loan from Friends": idWithLoanType["Loan From Friends"],
        "Loan for Wedding Expense": idWithLoanType["Loan For Wedding Expense"],
        "Loan against Property":idWithLoanType["Loan Against Property"]
      };
      
      // Deleting the old keys
      delete renamedObj["Loan From Friends"];
      delete renamedObj["Loan For Wedding Expense"];
      delete renamedObj["Loan Against Property"]
      const loanId = renamedObj[name];
      setIndividualLoanId(loanId)
    }
    const handleNavigate = (name) => {
      const renamedObj = {
        ...idWithLoanType,
        // Renaming the keys by creating new ones
        "Loan from Friends": idWithLoanType["Loan From Friends"],
        "Loan for Wedding Expense": idWithLoanType["Loan For Wedding Expense"]
      };
      
      // Deleting the old keys
      delete renamedObj["Loan From Friends"];
      delete renamedObj["Loan For Wedding Expense"];
      const loanId = renamedObj[name];
      if (loanId) {
          // Encode both the loanId and name to make sure they're safe for URL usage
          const encodedLoanId = encodeURIComponent(loanId);
          const encodedName = encodeURIComponent(name);

          // Add both id and name in query params
          // const queryParams = `?id=${encodedLoanId}&name=${encodedName}`;  
          const queryParams = `?id=${encodedLoanId}`;  


          if (manuallyLoanTypes.includes(name)) {
          navigate(`${LIABILITIES_PERSONAL_LOAN}${queryParams}`,{
            state: { loan_type: name },
          });
          } else if (manuallyFamilyLoanTypes.includes(name)) {
          navigate(`${LIABILITIES_FAMILY_LOAN}${queryParams}`,{
            state: { loan_type: name },
          });
          }else if (manuallyCreditCardDebts.includes(name)) {
            navigate(`${LIABILITIES_REVOLVING_LOAN}${queryParams}`,{
              state: { loan_type: name },
            });
            }
      } else {
        console.error(`No loanId found for loan type: ${name}`);
      }
    };

  const securedLoans = [
    {
      img: homePic,
      text: "Home Loan",
    },
    {
      img: carPic,
      text: "Car Loan",
    },
    {
      img: loanProperty,
      text: "Loan against Property",
    },
    {
      img: goldPic,
      text: "Gold Loan",
    },
  ];
  const unSecuredLoans = [
    {
      img: heartPic,
      text: "Personal Loan",
    },
    {
      img: businessLoanPic,
      text: "Business Loan",
    },
    {
      img: educationPic,
      text: "Education Loan",
    },
  ];
  const emergencyLoans = [
    {
      img: medicalVanPic,
      text: "Medical Loan",
    },
    {
      img: emergency,
      text: "Emergency Personal Loan",
    },
  ];
  const familyLoans = [
    {
      img: friendshipPic,
      text: "Family Loan",
    },
    {
      img: friendshipPic,
      text: "Loan from Friends",
    },
    {
      img: diamondPic,
      text: "Loan for Wedding Expense",
    },
  ];
  const revolvingCredit = [
    {
      img: homePic,
      text: "Credit Card Debt",
    },
  ];

  const mergeLoans = (frontendLoans, backendLoans, category) => {
    return frontendLoans.map((frontendLoan) => {
      // Find matching loan from the backend based on the loan category and text (loan name)
      const backendLoan = backendLoans.find(
        (loan) =>
          loan.loan_category === category &&
          loan.loan_name === frontendLoan.text
      );

      if (backendLoan) {
        // If there's matching backend loan data, return it merged with frontend data
        return {
          ...frontendLoan,
          total_loan_amount: backendLoan.total_loan_amount,
          next_due_in_no_of_days: backendLoan.next_due_in_no_of_days,
        };
      } else {
        // If no match, just return the frontend data (will show "Add" button in UI)
        return frontendLoan;
      }
    });
  };

  // Example: Combine backend data with emergency loans
  const updatedEmergencyLoans = mergeLoans(
    emergencyLoans,
    dataToView?.loans || [],
    "EMERGENCY"
  );
  const updatedFamilyLoans = mergeLoans(
    familyLoans,
    dataToView?.loans || [],
    "FAMILY"
  );
  const updatedSecuredLoans = mergeLoans(
    securedLoans,
    dataToView?.loans || [],
    "SECURED"
  );
  const updateUnsecuredLoans = mergeLoans(
    unSecuredLoans,
    dataToView?.loans || [],
    "UNSECURED"
  );
  const updatedRevolvingCredit = mergeLoans(
    revolvingCredit,
    dataToView?.loans || [],
    "CREDIT CARD"  
  );


  // Function to toggle the menu for a specific card
  const toggleMenu = (id, name) => {
    if(name === "unsecured"){
      setOpenMenuIdUnSecured((prevId) => (prevId === id ? null : id)); // Toggle logic
    }else if(name === "secured"){
      setOpenMenuIdSecured((prevId) => (prevId === id ? null : id)); // Toggle logic
    }else if(name === "emergency"){
      setOpenMenuIdEmergency((prevId) => (prevId === id ? null : id)); // Toggle logic
    }else if(name === "family"){
      setOpenMenuIdFamily((prevId) => (prevId === id ? null : id)); // Toggle logic
    }else if(name === "credit"){
      setOpenMenuIdCredit((prevId) => (prevId === id ? null : id)); // Toggle logic
    }

  };

  const handleDelete=(name)=>{
    const renamedObj = {
      ...idWithLoanType,
      // Renaming the keys by creating new ones
      "Loan from Friends": idWithLoanType["Loan From Friends"],
      "Loan for Wedding Expense": idWithLoanType["Loan For Wedding Expense"]
    };
    // Deleting the old keys
    delete renamedObj["Loan From Friends"];
    delete renamedObj["Loan For Wedding Expense"];
    const loanId = `${renamedObj[name]}/`;
    setSendingToRemove({name:name,loanId:loanId});
    removeButtonRef.current.click()
  }

  return (
    <div className="bg-gray-100 md:px-8 px-6 pt-4 py-4">
      <h4 className="text-[#334155]">Liabilities</h4>
      <div className="mt-10 flex justify-end">
        <div className="cursor-pointer">
         <AddYourLiability />
        </div>
      </div>
      <div className="mt-4 flex gap-3">
        <div className="border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl w-[59%] p-2">
          <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
            All Liabilities
          </p>
          {/* <img src={barChart} alt="barLineChart" /> */}
          <LineGraph/>
        </div>
        <div className="border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl w-[39%] p-4">
          <div className="border-[1px] border-[#D9D9D9] rounded-xl flex">
            <div className="py-2 px-2 flex flex-col items-end border-r-[1px] border-[#D9D9D9] w-[50%]">
              <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
                Total Liabilities Value
              </p>
              <p className="m-0 text-[#9065B4] font-[600] text-[1.6rem]">
              ₹{dataToView?.total_liabilities_value}
              </p>
              <p className="m-0 text-[#9065B4] font-[600] text-[1.6rem]">
                Lakhs
              </p>
            </div>
            <div className="py-2 px-2 flex flex-col items-end border-[#D9D9D9] w-[50%]">
              <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
                Total Active Liabilities
              </p>
              <p className="m-0 text-[#9065B4] font-[600] text-[1.6rem]">{dataToView?.active_liabilities_count}</p>
              <p className="m-0 text-[#9065B4] font-[600] text-[1.6rem]">
                Liabilities
              </p>
            </div>
          </div>
          <div className="border-[1px] border-[#D9D9D9] rounded-xl mt-4 py-4 px-2">
            <p className="m-0 text-[#334155] font-[600] text-[0.9rem] px-4">
              Total Closed Liabilities
            </p>
            <p className="m-0 text-[#9065B4] font-[600] text-[1.6rem] px-16">
            {dataToView?.closed_liabilities_count} Liabilities
            </p>
          </div>
        </div>
      </div>
      <div className="border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl mt-3.5 p-2.5">
        <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
          Secured loans{" "}
          <span className="text-[#9065B4] font-[600] text-[0.9rem]">
          {dataToView?.total_secured_loan_amount} Rs.
          </span>
        </p>
        <div className="mt-2 w-[100%] flex gap-2">
          {updatedSecuredLoans.map((item, index) => {
            return (
              <div
                className={`w-[24%] rounded-lg p-3 flex flex-col gap-y-2.5 ${
                  item.total_loan_amount
                    ? "border-[1px] border-[#D9D9D9] bg-[#FAF8FC]"
                    : "border-[2px] border-dashed border-[#D9D9D9]"
                }`}
              >
                <div className="flex justify-between items-center">
                  <div>
                    <img src={item.img} alt="homePic" height="20px" />
                    <p className="m-0 text-[#64748B] text-[0.8rem] font-[600]">
                      {item.text}
                    </p>
                  </div>
                  <div className={`relative ${item.total_loan_amount ? "" : "hidden"}`}>
                    {/* <img src={dots} height="20px" /> */}
                <img
                src={dots}
                height="20px"
                className="cursor-pointer"
                onClick={() => toggleMenu(index, "secured")} // Toggle the specific card's menu
              />
              {/* Dropdown Menu for specific card */}
              {openMenuIdSecured === index && (
                <div className="absolute top-8 right-0 bg-[#FFFFFF] border-[1px] border-[#D9D9D9] rounded-md shadow-md w-[90px] px-2 z-10">
                  <div
                    className="flex justify-center flex-col gap-2 px-2 py-1 rounded-md"
                  >
                   <div onClick={() => handleDelete(item.text)} className="cursor-pointer flex items-center justify-between gap-1">
                   <img
                      src={trashPic} // Adjust the path of your delete icon
                      alt="Delete"
                      className="h-4 w-4 text-red-600"
                    />
                    <span className="text-[#334155] text-[0.8rem] font-[600]">Delete</span>
                   </div>
                   <div className="cursor-pointer flex items-center justify-between gap-1" onClick={()=>handleNavigate(item.text)}>
                   <img
                      src={editPen} // Adjust the path of your delete icon
                      alt="Edit"
                      className="h-4 w-4 text-[#334155]"
                    />
                    <span className="text-[#334155] text-[0.8rem] font-[600]">Edit</span>
                   </div>
                  </div>
                </div> )}
  
                  </div>
                </div>
                {item.total_loan_amount ? (
                  <div className="flex flex-col items-end cursor-pointer" onClick={()=>handleNavigate(item.text)}>
                    <h4 className="m-0 text-[#334155] font-[500] text-[1.3rem]">
                      {item.total_loan_amount}
                    </h4>
                    <p className="m-0 text-[#64748B] text-[0.7rem] font-[500]">
                    Next Due in {item.next_due_in_no_of_days} Days
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col items-end" onClick={()=>handleOpenLoanTypeModal(item.text)}>
                      <button className="text-[#64748B] px-2 py-1 rounded-md border-[1px] border-[#D9D9D9] text-[0.9rem] font-[500]">
                        Add New
                      </button>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl mt-3.5 p-2.5">
        <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
          Unsecured loans{" "}
          <span className="text-[#9065B4] font-[600] text-[0.9rem]">
          {dataToView?.total_unsecured_loan_amount} Rs.
          </span>
        </p>
        <div className="mt-2 w-[100%] flex gap-2">
          {updateUnsecuredLoans.map((item, index) => {
            return (
              <div className={`w-[24%] rounded-lg p-3 flex flex-col gap-y-2.5 ${
                item.total_loan_amount
                  ? "border-[1px] border-[#D9D9D9] bg-[#FAF8FC]"
                  : "border-[2px] border-dashed border-[#D9D9D9]"
              }`}>
                <div className="flex justify-between items-center">
                  <div>
                    <img src={item.img} alt="homePic" height="20px" />
                    <p className="m-0 text-[#64748B] text-[0.8rem] font-[600]">
                      {item.text}
                    </p>
                  </div>
                  <div className={`relative ${item.total_loan_amount ? "" : "hidden"}`}>
                    {/* <img src={dots} height="20px" /> */}
                    <img
                src={dots}
                height="20px"
                className="cursor-pointer"
                onClick={() => toggleMenu(index, "unsecured")} // Toggle the specific card's menu
              />
              {/* Dropdown Menu for specific card */}
              {openMenuIdUnSecured === index && (
                <div className="absolute top-8 right-0 bg-[#FFFFFF] border-[1px] border-[#D9D9D9] rounded-md shadow-md w-[90px] px-2 z-10">
                  <div
                    className="flex justify-center flex-col gap-2 px-2 py-1 rounded-md"
                  >
                   <div onClick={() => handleDelete(item.text)} className="cursor-pointer flex items-center justify-between gap-1">
                   <img
                      src={trashPic} // Adjust the path of your delete icon
                      alt="Delete"
                      className="h-4 w-4 text-red-600"
                    />
                    <span className="text-[#334155] text-[0.8rem] font-[600]">Delete</span>
                   </div>
                   <div onClick={()=>handleNavigate(item.text)}  className="cursor-pointer flex items-center justify-between gap-1">
                   <img
                      src={editPen} // Adjust the path of your delete icon
                      alt="Edit"
                      className="h-4 w-4 text-[#334155]"
                    />
                    <span className="text-[#334155] text-[0.8rem] font-[600]">Edit</span>
                   </div>
                  </div>
                </div> )}
                  </div>
                </div>
                {item.total_loan_amount ? (
                  <div className="flex flex-col items-end cursor-pointer" onClick={()=>handleNavigate(item.text)}>
                    <h4 className="m-0 text-[#334155] font-[500] text-[1.3rem]">
                    {item.total_loan_amount}
                    </h4>
                    <p className="m-0 text-[#64748B] text-[0.7rem] font-[500]">
                    Next Due in {item.next_due_in_no_of_days} Days
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col items-end" onClick={()=>handleOpenLoanTypeModal(item.text)}>
                      <button className="text-[#64748B] px-2 py-1 rounded-md border-[1px] border-[#D9D9D9] text-[0.9rem] font-[500]">
                        Add New
                      </button>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl mt-3.5 p-2.5">
        <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
          Emergency Loans{" "}
          <span className="text-[#9065B4] font-[600] text-[0.9rem]">
          {dataToView?.total_emergency_loan_amount} Rs.
          </span>
        </p>
        <div className="mt-2 w-[100%] flex gap-2">
          {updatedEmergencyLoans.map((item, index) => {
            return (
              <div className={`w-[24%] rounded-lg p-3 flex flex-col gap-y-2.5 ${
                item.total_loan_amount
                  ? "border-[1px] border-[#D9D9D9] bg-[#FAF8FC]"
                  : "border-[2px] border-dashed border-[#D9D9D9]"
              }`}>
                <div className="flex justify-between items-center">
                  <div>
                    <img src={item.img} alt="homePic" height="20px" />
                    <p className="m-0 text-[#64748B] text-[0.8rem] font-[600]">
                      {item.text}
                    </p>
                  </div>
                  <div className={`relative ${item.total_loan_amount ? "" : "hidden"}`}>
                    {/* <img src={dots} height="20px" /> */}
                    <img
                      src={dots}
                      height="20px"
                      className="cursor-pointer"
                      onClick={() => toggleMenu(index, 'emergency')} // Toggle the specific card's menu
                    />
              {/* Dropdown Menu for specific card */}
              {openMenuIdEmergency === index && (
                <div className="absolute top-8 right-0 bg-[#FFFFFF] border-[1px] border-[#D9D9D9] rounded-md shadow-md w-[90px] px-2 z-10">
                  <div
                    className="flex justify-center flex-col gap-2 px-2 py-1 rounded-md"
                    
                  >
                   <div onClick={() => handleDelete(item.text)} className="cursor-pointer flex items-center justify-between gap-1">
                   <img
                      src={trashPic} // Adjust the path of your delete icon
                      alt="Delete"
                      className="h-4 w-4 text-red-600"
                    />
                    <span className="text-[#334155] text-[0.8rem] font-[600]">Delete</span>
                   </div>
                   <div onClick={()=>handleNavigate(item.text)} className="cursor-pointer flex items-center justify-between gap-1">
                   <img
                      src={editPen} // Adjust the path of your delete icon
                      alt="Edit"
                      className="h-4 w-4 text-[#334155]"
                    />
                    <span className="text-[#334155] text-[0.8rem] font-[600]">Edit</span>
                   </div>
                  </div>
                </div> )}
                  </div>
                </div>
                {item.total_loan_amount ? (
                  <div className="flex flex-col items-end cursor-pointer" onClick={()=>handleNavigate(item.text)}>
                    <h4 className="m-0 text-[#334155] font-[500] text-[1.3rem]">
                    {item.total_loan_amount}
                    </h4>
                    <p className="m-0 text-[#64748B] text-[0.7rem] font-[500]">
                    Next Due in {item.next_due_in_no_of_days} Days
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col items-end" onClick={()=>handleOpenLoanTypeModal(item.text)}>
                      <button className="text-[#64748B] px-2 py-1 rounded-md border-[1px] border-[#D9D9D9] text-[0.9rem] font-[500]">
                        Add New
                      </button>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div> 
      <div className="border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl mt-3.5 p-2.5">
        <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
          Family Loans{" "}
          <span className="text-[#9065B4] font-[600] text-[0.9rem]">
          {dataToView?.total_family_and_informal_loan_amount} Rs.
          </span>
        </p>
        <div className="mt-2 w-[100%] flex gap-2">
          {updatedFamilyLoans.map((item, index) => {
            return (
              <div className={`w-[24%] rounded-lg p-3 flex flex-col gap-y-2.5  ${
                item.total_loan_amount
                  ? "border-[1px] border-[#D9D9D9] bg-[#FAF8FC]"
                  : "border-[2px] border-dashed border-[#D9D9D9]"
              }`}>
                <div className="flex justify-between items-center">
                  <div>
                    <img src={item.img} alt="homePic" height="20px" />
                    <p className="m-0 text-[#64748B] text-[0.8rem] font-[600]">
                      {item.text}
                    </p>
                  </div>
                  <div className={`relative ${item.total_loan_amount ? "" : "hidden"}`}>
                    {/* <img src={dots} height="20px" /> */}
                    <img
                src={dots}
                height="20px"
                className="cursor-pointer"
                onClick={() => toggleMenu(index, "family")} // Toggle the specific card's menu
              />
              {/* Dropdown Menu for specific card */}
              {openMenuIdFamily === index && (
                <div className="absolute top-8 right-0 bg-[#FFFFFF] border-[1px] border-[#D9D9D9] rounded-md shadow-md w-[90px] px-2 z-10">
                  <div
                    className="flex justify-center flex-col gap-2 px-2 py-1 rounded-md"
                    
                  >
                   <div onClick={() => handleDelete(item.text)} className="cursor-pointer flex items-center justify-between gap-1">
                   <img
                      src={trashPic} // Adjust the path of your delete icon
                      alt="Delete"
                      className="h-4 w-4 text-red-600"
                    />
                    <span className="text-[#334155] text-[0.8rem] font-[600]">Delete</span>
                   </div>
                   <div onClick={()=>handleNavigate(item.text)} className="cursor-pointer flex items-center justify-between gap-1">
                   <img
                      src={editPen} // Adjust the path of your delete icon
                      alt="Edit"
                      className="h-4 w-4 text-[#334155]"
                    />
                    <span className="text-[#334155] text-[0.8rem] font-[600]">Edit</span>
                   </div>
                  </div>
                </div> )}
                  </div>
                </div>
                {item.total_loan_amount ? (
                  <div className="flex flex-col items-end cursor-pointer" onClick={()=>handleNavigate(item.text)}>
                    <h4 className="m-0 text-[#334155] font-[500] text-[1.3rem]">
                    {item.total_loan_amount}
                    </h4>
                    <p className="m-0 text-[#64748B] text-[0.7rem] font-[500]">
                    Next Due in {item.next_due_in_no_of_days} Days
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col items-end" onClick={()=>handleOpenLoanTypeModal(item.text)}>
                      <button className="text-[#64748B] px-2 py-1 rounded-md border-[1px] border-[#D9D9D9] text-[0.9rem] font-[500]">
                        Add New
                      </button>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="border-[1px] border-[#D9D9D9] bg-[#FFFFFF] rounded-xl mt-3.5 p-2.5">
        <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
          Revolving Credit{" "}
          <span className="text-[#9065B4] font-[600] text-[0.9rem]">
          {dataToView?.total_credit_card_debt_amount} Rs.
          </span>
        </p>
        <div className="mt-2 w-[100%] flex gap-2">
          {updatedRevolvingCredit.map((item, index) => {
            return (
              <div className={`w-[24%] rounded-lg p-3 flex flex-col gap-y-2.5 ${
                item.total_loan_amount
                  ? "border-[1px] border-[#D9D9D9] bg-[#FAF8FC]"
                  : "border-[2px] border-dashed border-[#D9D9D9]"
              }`}>
                <div className="flex justify-between items-center">
                  <div>
                    <img src={item.img} alt="homePic" height="20px" />
                    <p className="m-0 text-[#64748B] text-[0.8rem] font-[600]">
                      {item.text}
                    </p>
                  </div>
                  <div className={`relative ${item.total_loan_amount ? "" : "hidden"}`}>
                    {/* <img src={dots} height="20px" /> */}
                    <img
                src={dots}
                height="20px"
                className="cursor-pointer"
                onClick={() => toggleMenu(index, "credit")} // Toggle the specific card's menu
              />
              {/* Dropdown Menu for specific card */}
              {openMenuIdCredit === index && (
                <div className="absolute top-8 right-0 bg-[#FFFFFF] border-[1px] border-[#D9D9D9] rounded-md shadow-md w-[90px] px-2 z-10">
                  <div
                    className="flex justify-center flex-col gap-2 px-2 py-1 rounded-md"
                    
                  >
                   <div onClick={() => handleDelete(item.text)} className="cursor-pointer flex items-center justify-between gap-1">
                   <img
                      src={trashPic} // Adjust the path of your delete icon
                      alt="Delete"
                      className="h-4 w-4 text-red-600"
                    />
                    <span className="text-[#334155] text-[0.8rem] font-[600]">Delete</span>
                   </div>
                   <div onClick={()=>handleNavigate(item.text)} className="cursor-pointer flex items-center justify-between gap-1">
                   <img
                      src={editPen} // Adjust the path of your delete icon
                      alt="Edit"
                      className="h-4 w-4 text-[#334155]"
                    />
                    <span className="text-[#334155] text-[0.8rem] font-[600]">Edit</span>
                   </div>
                  </div>
                </div> )}
                  </div>
                </div>
                {item.total_loan_amount ? (
                  <div className="flex flex-col items-end cursor-pointer" onClick={()=>handleNavigate(item.text)}>
                    <h4 className="m-0 text-[#334155] font-[500] text-[1.3rem]">
                    {item.total_loan_amount}
                    </h4>
                    <p className="m-0 text-[#64748B] text-[0.7rem] font-[500]">
                    Next Due in {item.next_due_in_no_of_days} Days
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col items-end" onClick={()=>handleOpenLoanTypeModal(item.text)}>
                      <button className="text-[#64748B] px-2 py-1 rounded-md border-[1px] border-[#D9D9D9] text-[0.9rem] font-[500]">
                        Add New
                      </button>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    <AddLoanTypeDialog
      loanType={selectedLoan}
      loanTypeId={individualLoanId}
      triggerElement={
        <button ref={loanButtonRef} className="hidden">
          Open Loan Dialog
        </button>
      }
    />

      <RemoveLiability
        setIsReloadOverview={setIsReloadOverview}
        remove_id={sendingToRemove.loanId}
        loan_name={sendingToRemove.name}
        triggerElement={<button ref={removeButtonRef} className="hidden">
          open Remove Dilog
        </button>}
      />
    </div>
  );
};

export default LiabilitiesViewPage;
