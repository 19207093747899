import React, { useEffect, useState } from "react";
import Button from "components/Button/BtnOrange";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import useGetDeductions from "./Data/useGetDeductions";
import usePostDeductions from "./Data/usePostDeductions";
import usePatchDeductions from "./Data/usePatchDeductions";
import "./ITI.css";
import {
  INCOME_TAX_SUMMARY_URL,
  INCOME_TAX_SUMMARY_USER_PAGE_URL,
  USER_INCOME_SUMMARY_PAGE_URL,
} from "constants/routes";

// images
import parents from "assets/images/incomeTax/parents.png";
import self from "assets/images/incomeTax/self.png";
import previousBtn from "assets/images/incomeTax/previousBtn.svg";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import InputField from "components/input/InputField";

const Deductions = ({ isDisabled }) => {
  const navigate = useNavigate();
  const { income_tax_return_id } = useParams();
  const [selectedParentOption, setSelectedParentOption] = useState("");
  const [selectedSelfOption, setSelectedSelfOption] = useState("");
  const path = USER_INCOME_SUMMARY_PAGE_URL.replace(
    ":income_tax_return_id",
    income_tax_return_id
  ).replace(":type", "taxes-paid");

  const [
    deductionsData,
    deductionsError,
    deductionsIsLoading,
    postDeductionsData,
  ] = usePostDeductions();
  const [getData, getError, getIsLoading, getDeductionsData] =
    useGetDeductions();
  const [patchData, patchError, patchIsLoading, patchDeductionsData] =
    usePatchDeductions();

  const form = useForm();
  const { register, handleSubmit, formState, setValue, watch, getValues } =
    form;
  const { errors } = formState;

  useEffect(() => {
    getDeductionsData(income_tax_return_id);
  }, [income_tax_return_id]);
  useEffect(() => {
    if (getData) {
      setValue("life_insurance", getData.data?.life_insurance);
      setValue("provident_fund", getData.data?.provident_fund);
      setValue("elss_mutual_fund", getData.data?.elss_mutual_fund);
      setValue("home_loan_repayment", getData.data?.home_loan_repayment);
      setValue("tution_fees", getData.data?.tution_fees);
      setValue("stamp_duty_paid", getData.data?.stamp_duty_paid);
      setValue("others", getData.data?.others);
      setValue("contribution_by_self", getData.data?.contribution_by_self);
      setValue(
        "contribution_by_employeer",
        getData.data?.contribution_by_employeer
      );
      setValue("medical_insurance_self", getData.data?.medical_insurance_self);
      setValue(
        "medical_preventive_health_checkup_self",
        getData.data?.medical_preventive_health_checkup_self
      );
      setValue(
        "medical_expenditure_self",
        getData.data?.medical_expenditure_self
      );
      setValue("senior_citizen_self", getData.data?.senior_citizen_self);
      setSelectedSelfOption(getData.data?.senior_citizen_self.toString());
      setValue(
        "senior_citizen_self",
        getData.data.senior_citizen_self.toString()
      );

      setValue(
        "medical_insurance_parents",
        getData.data?.medical_insurance_parents
      );
      setValue(
        "medical_preventive_health_checkup_parents",
        getData.data?.medical_preventive_health_checkup_parents
      );
      setValue(
        "medical_expenditure_parents",
        getData.data?.medical_expenditure_parents
      );
      setValue("senior_citizen_parents", getData.data?.senior_citizen_parents);
      // setSelectedParentOption(getData.data?.senior_citizen_parents);
      setSelectedParentOption(getData.data.senior_citizen_parents.toString());
      setValue(
        "senior_citizen_parents",
        getData.data.senior_citizen_parents.toString()
      );

      setValue("education_loan", getData.data?.education_loan);
      setValue(
        "electronic_vehicle_loan",
        getData.data?.electronic_vehicle_loan
      );
      setValue("home_loan_taken_year", getData.data?.home_loan_taken_year);
      setValue("home_loan_amount", getData.data?.home_loan_amount);
      setValue("interest_income", getData.data?.interest_income);
      setValue("royality_on_books", getData.data?.royality_on_books);
      setValue("income_on_patients", getData.data?.income_on_patients);
      setValue(
        "income_on_bio_degradable",
        getData.data?.income_on_bio_degradable
      );
      setValue("rent_paid", getData.data?.rent_paid);
      setValue(
        "contribution_to_agnipath",
        getData.data?.contribution_to_agnipath
      );
      setValue(
        "donation_to_political_parties",
        getData.data?.donation_to_political_parties
      );
      setValue("donation_others", getData.data?.donation_others);
    }
  }, [getData]);

  const validateTotal = () => {
    const medicalInsurance = parseFloat(getValues("medical_insurance_self")) || 0;
    const healthCheckup = parseFloat(getValues("medical_preventive_health_checkup_self")) || 0;
    const medicalExpenditure = parseFloat(getValues("medical_expenditure_self")) || 0;
  
    const total = medicalInsurance + healthCheckup + medicalExpenditure;
    return total <= 50000 || "Total amount must not exceed 50,000.";
  };
  

  const handleSelfRadioChange = (event) => {
    const value = event.target.value;
    setSelectedSelfOption(value);
    setValue("senior_citizen_self", value);
  };

  const handleParentRadioChange = (event) => {
    const value = event.target.value;
    setSelectedParentOption(value);
    setValue("senior_citizen_parents", value);
  };

  const handleGoBack = () => {
    navigate(
      INCOME_TAX_SUMMARY_URL.replace(
        ":income_tax_return_id",
        income_tax_return_id
      )
    );
  };

  useEffect(() => {
    if (deductionsData) {
      showSuccessToast("Deductions Details success");
      navigate(path);
    }
    if (patchData) {
      showSuccessToast("Deductions Details Updated");
      navigate(path);
    }
  }, [deductionsData, patchData]);

  useEffect(() => {
    if (deductionsError) {
      showErrorToast("", "something went wrong");
    }
    if (patchError) {
      showErrorToast("", "something went wrong");
    }
  }, [deductionsError, getError, patchError]);

  const handleSaveAndNext = (data) => {
    if (getData && getData.data?.id) {
      patchDeductionsData(income_tax_return_id, data);
    } else {
      postDeductionsData(income_tax_return_id, data);
    }
  };

  const handlePrevious = () => {
    navigate(
      INCOME_TAX_SUMMARY_USER_PAGE_URL.replace(
        ":income_tax_return_id",
        income_tax_return_id
      )
    );
  };

  return (
    <div className="relative">
      {getIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <div className="flex gap-2 md:hidden">
        <button onClick={handlePrevious}>
          <img src={previousBtn} alt="" className="w-4 h-4 mb-2" />
        </button>
        <h4 className=" text-[#334155] font-[500] text-[1.2rem] leading-[2.7rem]">
          Deductions
        </h4>
      </div>
      <div className="md:bg-white md:border md:border-[#E2E8F0] rounded md:p-3 mb-[6rem] ">
        <div className="bg-white border border-[#E2E8F0] rounded py-4 px-3">
          <div className="md:flex md:justify-between">
            <h4 className="font-[600] text-[1.2rem] text-[#334155] ">
              Tax Saving Investments
              <span className="ml-2 bg-[#64748B] text-white rounded px-2 py-1 font-[500] text-[0.9rem]">
                80C
              </span>
            </h4>
            <button
              style={{ cursor: "default", pointerEvents: "none" }}
              className="md:text-[#9065B4] md:bg-[#EAE1F3] bg-[#F1F5F9] rounded py-2 px-3 font-[500] text-[0.9rem]"
            >
              Upto 1.5 Lakhs
            </button>
          </div>
          <div className="grid grid-cols-12 mt-[2rem]">
            <div className="input-container md:col-span-3 col-span-12">
              <h6 className="input-label">Life Insurance</h6>
              <InputField
                type="number"
                className="input-box"
                name="life_insurance"
                register={register}
                validation={{
                  required: {
                    value: true,
                    message: "Life Insurance is required",
                  },
                }}
              />

              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.life_insurance?.message}
              </p>
            </div>
            <div className="input-container md:col-span-3 col-span-12">
              <h6 className="input-label">Provident Fund</h6>
              <InputField
                type="number"
                className="input-box"
                name="provident_fund"
                register={register}
                validation={{
                  required: {
                    value: true,
                    message: "Provident Fund is required",
                  },
                }}
              />

              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.provident_fund?.message}
              </p>
            </div>
            <div className="input-container md:col-span-3 col-span-12">
              <h6 className="input-label">ELSS Mutual Funds</h6>
              <InputField
                type="number"
                className="input-box"
                name="elss_mutual_fund"
                register={register}
                validation={{
                  required: {
                    value: true,
                    message: "ELSS Mutual Funds is required",
                  },
                }}
              />

              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.elss_mutual_fund?.message}
              </p>
            </div>
            <div className="input-container md:col-span-3 col-span-12">
              <h6 className="input-label">Home Loan Repayment</h6>
              <InputField
                type="number"
                className="input-box"
                name="home_loan_repayment"
                register={register}
                validation={{
                  required: {
                    value: true,
                    message: "Home Loan is required",
                  },
                }}
              />

              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.home_loan_repayment?.message}
              </p>
            </div>
            <div className="input-container md:col-span-3 col-span-12 mt-3">
              <h6 className="input-label">Tution Fees</h6>
              <InputField
                type="number"
                className="input-box"
                name="tution_fees"
                register={register}
                validation={{
                  required: {
                    value: true,
                    message: "Tution Fees is required",
                  },
                }}
              />

              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.tution_fees?.message}
              </p>
            </div>
            <div className="input-container md:col-span-3 col-span-12 mt-3">
              <h6 className="input-label">Stamp Duty Paid</h6>
              <InputField
                type="number"
                className="input-box"
                name="stamp_duty_paid"
                register={register}
                validation={{
                  required: {
                    value: true,
                    message: "Stamp Duty is required",
                  },
                }}
              />

              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.stamp_duty_paid?.message}
              </p>
            </div>
            <div className="input-container md:col-span-3 col-span-12 mt-3">
              <h6 className="input-label">Others</h6>
              <InputField
                type="number"
                className="input-box"
                name="others"
                register={register}
                validation={{
                  required: {
                    value: true,
                    message: "Others is required",
                  },
                }}
              />

              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.others?.message}
              </p>
            </div>
          </div>

          <hr className="my-[2rem]" />
          <h4 className="font-[600] text-[1.2rem] text-[#334155]">
            Contribution to NPS
            <span className="ml-2 bg-[#64748B] text-white rounded px-2 py-1 font-[500] text-[0.9rem]">
              80CCD
            </span>
          </h4>

          <div className=" grid grid-cols-12 mt-[2rem]">
            <div className="group md:col-span-3 col-span-12">
              <h6 className="input-label group-focus-within:text-customPurple">
                By Self/Employee
              </h6>
              <div className=" flex items-center border-1 rounded md:w-[90%] w-[100%] focus-within:border-customPurple">
                <span className="p-2 text-[#64748B] bg-[#F1F5F9] flex items-center justify-center border-1 border-y-[#94A3B8] border-l-[#94A3B8] rounded-l group-focus-within:text-customPurple">
                  Rs.
                </span>
                <InputField
                  type="number"
                  className="text-[#667085] w-[100%] md:w-[95%] p-2 md:p-1 px-3 py-[0.7rem] border-1 border-y-[#94A3B8] border-r-[#94A3B8] focus:outline-none rounded-r"
                  name="contribution_by_self"
                  register={register}
                  validation={{
                    required: {
                      value: true,
                      message: "By Self/Employee is required",
                    },
                  }}
                />
              </div>
              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.contribution_by_self?.message}
              </p>
            </div>
            <div className="group md:col-span-3 col-span-12 ">
              <h6 className="input-label group-focus-within:text-customPurple">
                By Employer
              </h6>
              <div className=" flex items-center border-1 rounded md:w-[90%] w-[100%] focus-within:border-customPurple">
                <span className="p-2 text-[#64748B] bg-[#F1F5F9] flex items-center justify-center border-1 border-y-[#94A3B8] border-l-[#94A3B8] rounded-l group-focus-within:text-customPurple">
                  Rs.
                </span>
                <InputField
                  type="number"
                  className="text-[#667085] w-[100%] md:w-[95%] p-2 md:p-1 px-3 py-[0.7rem] border-1 border-y-[#94A3B8] border-r-[#94A3B8] focus:outline-none rounded-r"
                  name="contribution_by_employeer"
                  register={register}
                  validation={{
                    required: {
                      value: true,
                      message: "By Employee is required",
                    },
                  }}
                />
              </div>{" "}
              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.contribution_by_employeer?.message}
              </p>
            </div>
          </div>

          <hr className="my-[2rem]" />

          <div className="md:flex md:justify-between">
            <h4 className="font-[600] text-[1.2rem] text-[#334155]">
              Medical Insurance
              <span className="ml-2 bg-[#64748B] text-white rounded px-2 py-1 font-[500] text-[0.9rem]">
                80D
              </span>
            </h4>
            <button
              style={{ cursor: "default", pointerEvents: "none" }}
              className="md:text-[#9065B4] md:bg-[#EAE1F3] bg-[#F1F5F9] rounded py-2 px-3 font-[500] text-[0.9rem]"
            >
              Upto 50,000 Rs.
            </button>
          </div>
          <div className="flex md:gap-3 gap-4 mt-3">
            <img src={self} className="w-[3rem] h-[3rem]" />
            <div>
              <h4 className="font-[600] text-[1rem] text-[#334155]">
                Self & Family
              </h4>

              <div className="md:flex gap-3 ">
                <h6 className="font-[500] text-[#64748B] text-[0.8rem]">
                  Any Senior Citizen?{" "}
                </h6>
                {/* self radios */}
                <div className="flex gap-3">
                  <label className="input-label focus-within:text-customPurple">
                    <InputField
                      name="senior_citizen_self"
                      type="radio"
                      value="true"
                      checked={selectedSelfOption == "true"}
                      className="mr-2 custom-radio"
                      register={register}
                      validation={{
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      }}
                      onChange={handleSelfRadioChange}
                    />
                    Yes
                  </label>

                  <label className="input-label focus-within:text-customPurple">
                    <InputField
                      name="senior_citizen_self"
                      type="radio"
                      value="false"
                      checked={selectedSelfOption == "false"}
                      className="mr-2 custom-radio"
                      register={register}
                      validation={{
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      }}
                      onChange={handleSelfRadioChange}
                    />
                    No
                  </label>
                  <p className="text-red-600 font-[500] text-[0.8rem]">
                    {errors.senior_citizen_self?.message}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 mt-[2rem]">
            <div className="group md:col-span-3 col-span-12">
              <h6 className="input-label group-focus-within:text-customPurple">
                Medical Insurance
              </h6>
              <div className=" flex items-center border-1 rounded md:w-[90%] w-[100%] focus-within:border-customPurple">
                <span className="p-2 text-[#64748B] bg-[#F1F5F9] flex items-center justify-center border-1 border-y-[#94A3B8] border-l-[#94A3B8] rounded-l group-focus-within:text-customPurple">
                  Rs.
                </span>
                <InputField
                  type="number"
                  className="text-[#667085] w-[100%] md:w-[95%] p-2 md:p-1 px-3 py-[0.7rem] border-1 border-y-[#94A3B8] border-r-[#94A3B8] focus:outline-none rounded-r"
                  name="medical_insurance_self"
                  register={register}
                  validation={{
                    required: {
                      validate: () => validateTotal(),
                      value: true,
                      message: "Medical Insurance is required",
                    },
                  }}
                />
              </div>{" "}
              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.medical_insurance_self?.message}
              </p>
            </div>
            <div className="group md:col-span-3 col-span-12">
              <h6 className="input-label group-focus-within:text-customPurple">
                Preventive Health Check-Ups
              </h6>
              <div className=" flex items-center border-1 rounded md:w-[90%] w-[100%] focus-within:border-customPurple">
                <span className="p-2 text-[#64748B] bg-[#F1F5F9] flex items-center justify-center border-1 border-y-[#94A3B8] border-l-[#94A3B8] rounded-l group-focus-within:text-customPurple">
                  Rs.
                </span>
                <InputField
                  type="number"
                  className="text-[#667085] w-[100%] md:w-[95%] p-2 md:p-1 px-3 py-[0.7rem] border-1 border-y-[#94A3B8] border-r-[#94A3B8] focus:outline-none rounded-r"
                  name="medical_preventive_health_checkup_self"
                  register={register}
                  validation={{
                    required: {
                      value: true,
                      message: "Preventive Health is required",
                      validate: () => validateTotal(),
                    },
                  }}
                />
              </div>{" "}
              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.medical_preventive_health_checkup_self?.message}
              </p>
            </div>
            <div className="group md:col-span-3 col-span-12">
              <h6 className="input-label group-focus-within:text-customPurple">
                Medical Expenditure
              </h6>
              <div className=" flex items-center border-1 rounded md:w-[90%] w-[100%] focus-within:border-customPurple">
                <span className="p-2 text-[#64748B] bg-[#F1F5F9] flex items-center justify-center border-1 border-y-[#94A3B8] border-l-[#94A3B8] rounded-l group-focus-within:text-customPurple">
                  Rs.
                </span>
                <InputField
                  type="number"
                  className="text-[#667085] w-[100%] md:w-[95%] p-2 md:p-1 px-3 py-[0.7rem] border-1 border-y-[#94A3B8] border-r-[#94A3B8] focus:outline-none rounded-r"
                  name="medical_expenditure_self"
                  register={register}
                  validation={{
                    required: {
                      value: true,
                      message: "Medical expenditure is required",
                      validate: () => validateTotal(),
                    },
                  }}
                />
              </div>{" "}
              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.medical_expenditure_self?.message}
              </p>
              
            </div>
          </div>
          <hr className="my-[2rem]" />

          <div className="flex justify-between">
            <div className="flex gap-3">
              <img src={parents} className="w-[3rem] h-[3rem]" />
              <div>
                <h4 className="font-[600] text-[1rem] text-[#334155]">
                  Parents
                </h4>

                <div className="md:flex gap-3 ">
                  <h6 className="font-[500] text-[#64748B] text-[0.8rem]">
                    Any Senior Citizen?{" "}
                  </h6>
                  <div className="flex gap-3">
                    <label className="input-label focus-within:text-customPurple">
                      <InputField
                        name="senior_citizen_parents"
                        type="radio"
                        value="true"
                        checked={selectedParentOption == "true"}
                        className="mr-2 custom-radio"
                        register={register}
                        validation={{
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          // onChange: handleParentRadioChange,
                        }}
                        onChange={handleParentRadioChange}
                      />
                      Yes
                    </label>
                    <label className="input-label focus-within:text-customPurple">
                      <InputField
                        name="senior_citizen_parents"
                        type="radio"
                        value="false"
                        checked={selectedParentOption == "false"}
                        className="mr-2 custom-radio"
                        register={register}
                        validation={{
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          // onChange: handleSelfRadioChange,
                        }}
                        onChange={handleParentRadioChange}
                      />
                      No
                    </label>
                    <p className="text-red-600 font-[500] text-[0.8rem]">
                      {errors.senior_citizen_parents?.message}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <button
              style={{ cursor: "default", pointerEvents: "none" }}
              className="text-[#9065B4] bg-[#EAE1F3] rounded px-3 font-[500] text-[0.9rem] md:block hidden"
            >
              Upto 75,000 Rs.
            </button>
          </div>

          <div className="grid grid-cols-12 mt-[2rem]">
            <div className="group md:col-span-3 col-span-12">
              <h6 className="input-label group-focus-within:text-customPurple">
                Medical Insurance
              </h6>
              <div className=" flex items-center border-1 rounded md:w-[90%] w-[100%] focus-within:border-customPurple">
                <span className="p-2 text-[#64748B] bg-[#F1F5F9] flex items-center justify-center border-1 border-y-[#94A3B8] border-l-[#94A3B8] rounded-l group-focus-within:text-customPurple">
                  Rs.
                </span>
                <InputField
                  type="number"
                  className="text-[#667085] w-[100%] md:w-[95%] p-2 md:p-1 px-3 py-[0.7rem] border-1 border-y-[#94A3B8] border-r-[#94A3B8] focus:outline-none rounded-r"
                  name="medical_insurance_parents"
                  register={register}
                  validation={{
                    required: {
                      value: true,
                      message: "Medical Insurance is required",
                    },
                  }}
                />
              </div>
              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.medical_insurance_parents?.message}
              </p>
            </div>
            <div className="group md:col-span-3 col-span-12">
              <h6 className="input-label group-focus-within:text-customPurple">
                Preventive Health Check-Ups
              </h6>
              <div className=" flex items-center border-1 rounded md:w-[90%] w-[100%]focus-within:border-customPurple">
                <span className="p-2 text-[#64748B] bg-[#F1F5F9] flex items-center justify-center border-1 border-y-[#94A3B8] border-l-[#94A3B8] rounded-l group-focus-within:text-customPurple">
                  Rs.
                </span>
                <InputField
                  type="number"
                  className="text-[#667085] w-[100%] md:w-[95%] p-2 md:p-1 px-3 py-[0.7rem] border-1 border-y-[#94A3B8] border-r-[#94A3B8] focus:outline-none rounded-r"
                  name="medical_preventive_health_checkup_parents"
                  register={register}
                  validation={{
                    required: {
                      value: true,
                      message: "PreventiveHealth is required",
                    },
                  }}
                />
              </div>{" "}
              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.medical_preventive_health_checkup_parents?.message}
              </p>
            </div>
            <div className="group md:col-span-3 col-span-12">
              <h6 className="input-label group-focus-within:text-customPurple">
                Medical Expenditure
              </h6>
              <div className=" flex items-center border-1 rounded md:w-[90%] w-[100%]focus-within:border-customPurple">
                <span className="p-2 text-[#64748B] bg-[#F1F5F9] flex items-center justify-center border-1 border-y-[#94A3B8] border-l-[#94A3B8] rounded-l group-focus-within:text-customPurple">
                  Rs.
                </span>
                <InputField
                  type="number"
                  className="text-[#667085] w-[100%] md:w-[95%] p-2 md:p-1 px-3 py-[0.7rem] border-1 border-y-[#94A3B8] border-r-[#94A3B8] focus:outline-none rounded-r"
                  name="medical_expenditure_parents"
                  register={register}
                  validation={{
                    required: {
                      value: true,
                      message: "Medical expenditure is required",
                    },
                  }}
                />
              </div>{" "}
              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.medical_expenditure_parents?.message}
              </p>
            </div>
          </div>
          <hr className="my-[2rem]" />
          <h4 className="font-[600] text-[1.2rem] text-[#334155]">
            Interest Paid on Loans
          </h4>

          <div className="grid grid-cols-12 mt-[2rem]">
            <div className="input-container md:col-span-3 col-span-12">
              <h6 className="input-label">Education Loan</h6>
              <InputField
                type="number"
                className="input-box"
                name="education_loan"
                register={register}
                validation={{
                  required: {
                    value: true,
                    message: "Education Loan is required",
                  },
                }}
              />

              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.education_loan?.message}
              </p>
            </div>
            <div className="input-container md:col-span-3 col-span-12">
              <h6 className="input-label">Electronic Vehicle Loan</h6>
              <InputField
                type="number"
                className="input-box"
                name="electronic_vehicle_loan"
                register={register}
                validation={{
                  required: {
                    value: true,
                    message: "Electronic Loan is required",
                  },
                }}
              />

              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.electronic_vehicle_loan?.message}
              </p>
            </div>
          </div>
          <div>
            <h6 className="input-label mt-3">Home Loan</h6>
            <div className="flex flex-col md:w-[90%] gap-2">
              <label className="input-label focus-within:text-customPurple">
                <InputField
                  type="radio"
                  value="Taken B/W Apr'16 - Mar'17"
                  className="mr-2 custom-radio"
                  name="home_loan_taken_year"
                  register={register}
                  validation={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  }}
                />
                Taken b/w Apr'16 - Mar'17
              </label>
              <label className="input-label focus-within:text-customPurple">
                <InputField
                  type="radio"
                  value="Taken B/W Apr'19 - Mar'22"
                  className="mr-2 custom-radio"
                  name="home_loan_taken_year"
                  register={register}
                  validation={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  }}
                />
                Taken b/w Apr'19 - Mar'22
              </label>

              <InputField
                type="number"
                className="focus-within:border-customPurple focus:outline-none border-1 border-[#94A3B8] md:w-[25%] w-[100%] p-2 md:p-1 rounded-md"
                name="home_loan_amount"
                register={register}
                validation={{
                  required: {
                    value: true,
                    message: "Home Loan is required",
                  },
                }}
              />

              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.home_loan_taken_year?.message}
              </p>
            </div>
          </div>

          <hr className="my-[2rem]" />
          <h4 className="font-[600] text-[1.2rem] text-[#334155]">
            Other Deductions
          </h4>

          <div className="grid grid-cols-12 mt-[2rem]">
            <div className="input-container md:col-span-3 col-span-12">
              <h6 className="input-label">Interest Income</h6>
              <InputField
                type="number"
                className="input-box"
                name="interest_income"
                register={register}
                validation={{
                  required: {
                    value: true,
                    message: "Interest Loan is required",
                  },
                }}
              />
              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.interest_income?.message}
              </p>
            </div>
            <div className="input-container md:col-span-3 col-span-12">
              <h6 className="input-label">Royalty on Books</h6>
              <InputField
                type="number"
                className="input-box"
                name="royality_on_books"
                register={register}
                validation={{
                  required: {
                    value: true,
                    message: "Royalty on Books is required",
                  },
                }}
              />
              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.royality_on_books?.message}
              </p>
            </div>
            <div className="input-container md:col-span-3 col-span-12">
              <h6 className="input-label">Income on Patents</h6>
              <InputField
                type="number"
                className="input-box"
                name="income_on_patients"
                register={register}
                validation={{
                  required: {
                    value: true,
                    message: "Income on Patents is required",
                  },
                }}
              />
              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.income_on_patients?.message}
              </p>
            </div>
            <div className="input-container md:col-span-3 col-span-12">
              <h6 className="input-label">
                Income from Bio-Degradable waste Business
              </h6>
              <InputField
                type="number"
                className="input-box"
                name="income_on_bio_degradable"
                register={register}
                validation={{
                  required: {
                    value: true,
                    message:
                      "Income from Bio-Degradable waste Business is required",
                  },
                }}
              />
              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.income_on_bio_degradable?.message}
              </p>
            </div>
            <div className="input-container md:col-span-3 col-span-12">
              <h6 className="input-label">Rent Paid (Not Claming HRA)</h6>
              <InputField
                type="number"
                className="input-box"
                name="rent_paid"
                register={register}
                validation={{
                  required: {
                    value: true,
                    message: "Rent Paid is required",
                  },
                }}
              />
              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.rent_paid?.message}
              </p>
            </div>
            <div className="input-container md:col-span-3 col-span-12">
              <h6 className="input-label">Contribution to Agnipath Scheme</h6>
              <InputField
                type="number"
                className="input-box"
                name="contribution_to_agnipath"
                register={register}
                validation={{
                  required: {
                    value: true,
                    message: "Contribution to Agnipath Scheme is required",
                  },
                }}
              />
              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.contribution_to_agnipath?.message}
              </p>
            </div>
            <div className="input-container md:col-span-3 col-span-12">
              <h6 className="input-label">Donation to Political Party</h6>
              <InputField
                type="number"
                className="input-box"
                name="donation_to_political_parties"
                register={register}
                validation={{
                  required: {
                    value: true,
                    message: "Donation to Political Party is required",
                  },
                }}
              />
              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.donation_to_political_parties?.message}
              </p>
            </div>
            <div className="input-container md:col-span-3 col-span-12">
              <h6 className="input-label">Donation - Others</h6>
              <InputField
                type="number"
                className="input-box"
                name="donation_others"
                register={register}
                validation={{
                  required: {
                    value: true,
                    message: "Donation - Others is required",
                  },
                }}
              />
              <p className="text-red-600 font-[500] text-[0.8rem]">
                {errors.donation_others?.message}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-row-reverse gap-4 mt-[1rem]">
          <Button
            px={"px-[1rem]"}
            py={"py-[0.5rem]"}
            name="Save and next"
            onClick={handleSubmit(handleSaveAndNext)}
            noValidate
            disabled={isDisabled}
          />

          <button
            onClick={handleGoBack}
            className="font-[500] px-[2%] py-[0.5%] rounded-md bg-[#E8EAED]"
          >
            Back to tax Summary
          </button>
        </div>
      </div>
    </div>
  );
};

export default Deductions;
