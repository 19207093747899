import React, { useEffect, useState } from "react";
import AddYourLiability from "../AddYourLiability";
import "../liabilities.css";
import RemoveLiability from "../RemoveLiability";
import WhiteButton from "components/Button/WhiteButton";
import InputField from "components/input/InputField";
import useGetBankingLoans from "../data/useGetBankingLoans";
import { useLocation, useParams } from "react-router-dom";
import LiabilitiesHeading from "../LiabilitiesHeading";
import LineGraph from "../LineGraph";
import usePatchBankingLoans from "../data/usePatchBankingLoans";
import { showSuccessToast } from "components/toaster/toastHelper";
import Button from "components/Button/BtnOrange";

const PersonalLoanDetailed = () => {
  const location = useLocation();
  const [bankName, setBankName] = useState("");
  const [amtBorr, setAmtBorr] = useState("");
  const [loanPurpose, setLoanPurpose] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [period, setPeriod] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [emi, setEmi] = useState("");
  const [amtPaid, setAmtPaid] = useState("");
  const [balance, setBalance] = useState("");
  const [isEditClicked, setisEditClicked] = useState(false);
  const [errors, setErrors] = useState({});

  const [getData, getError, getIsLoading, getBankingLoans] =
    useGetBankingLoans();
  const [
    patchBankData,
    patchBankError,
    patchBankIsLoading,
    patchBankLoanDetails,
  ] = usePatchBankingLoans();

  const queryParams = new URLSearchParams(location.search);
  const id = decodeURIComponent(queryParams.get("id"));
  const loan_type = location.state.loan_type;
  const loan_type_id = location.state.loanTypeID;

  useEffect(() => {
    getBankingLoans(id);
  }, []);

  useEffect(() => {
    if (patchBankData) {
      showSuccessToast("Bank Details Updated");
    }
  }, [patchBankData]);

  const validateInputs = () => {
    const newErrors = {};
    const namePattern = /^[a-zA-Z\s]+$/; // allows only letters and spaces

    if (!bankName) {
      newErrors.bankName = "Bank Name is required";
    } else if (!namePattern.test(bankName)) {
      newErrors.bankName =
        "Bank Name should not contain special characters or numbers";
    }

    // Check for valid amount borrowed: only whole numbers
    if (!amtBorr || !/^\d+(\.\d+)?$/.test(amtBorr)) {
      newErrors.amtBorr =
        "Valid Amount Borrowed is required (positive number only)";
    } else if (parseFloat(amtBorr) <= 0) {
      newErrors.amtBorr = "Amount Borrowed must be greater than 0";
    }

    if (!loanPurpose) {
      newErrors.loanPurpose = "Loan Purpose is required";
    } else if (!namePattern.test(loanPurpose)) {
      newErrors.loanPurpose =
        "Loan Purpose should not contain special characters or numbers";
    }

    // Check for valid interest rate: between 0 and 10
    if (
      !interestRate ||
      isNaN(interestRate) ||
      interestRate < 0 ||
      interestRate > 100
    ) {
      newErrors.interestRate = "Valid Interest Rate (0-100) is required";
    }

    if (!startDate) {
      newErrors.startDate = "Start Date is required";
    }
    // Check for required dates
    if (!endDate) newErrors.endDate = "End Date is required";

    // Check for valid period: from 0 to 12
    if (!period) newErrors.period = "Period is required";

    // Check for required due date
    if (!dueDate) newErrors.dueDate = "Due Date is required";

    if (!emi || !/^\d+(\.\d+)?$/.test(emi)) {
      newErrors.emi = "Valid emi is required (positive number only)";
    } else if (parseFloat(emi) <= 0) {
      newErrors.emi = "emi must be greater than 0";
    }

    if (!amtPaid || !/^\d+(\.\d+)?$/.test(amtPaid)) {
      newErrors.amtPaid =
        "Valid Amount Paid is required (positive number only)";
    } else if (parseFloat(amtPaid) <= 0) {
      newErrors.amtPaid = "Amount Paid must be greater than 0";
    }

    if (!balance || !/^\d+(\.\d+)?$/.test(balance)) {
      newErrors.balance = "Valid Balance is required (positive number only)";
    } else if (parseFloat(balance) <= 0) {
      newErrors.balance = "Balance must be greater than 0";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // returns true if there are no errors
  };

  useEffect(() => {
    if (getData) {
      const data = getData?.data;
      setBankName(data.bank_name);
      setAmtBorr(data.amount_borrowed);
      setInterestRate(data.interest_rate);
      setLoanPurpose(data.loan_purpose);
      setStartDate(data.start_date);
      setEndDate(data.end_date);
      setPeriod(data.period);
      setDueDate(data.due_date);
      setEmi(data.emi_amount);
      setAmtPaid(data.amount_paid);
      setBalance(data.balance_amount);
    }
  }, [getData]);

  const handleUpdateDetails = () => {
    const encodedID = getData?.data?.id;
    if (validateInputs()) {
      const updatedData = {
        encoded_loan_id: encodedID,
        bank_name: bankName,
        amount_borrowed: amtBorr,
        interest_rate: interestRate,
        loan_purpose: loanPurpose,
        start_date: startDate,
        end_date: endDate,
        due_date: dueDate,
        emi_amount: emi,
        amount_paid: amtPaid,
        balance_amount: balance,
        period: period,
      };

      console.log("updated Data:", updatedData);
      setisEditClicked(false);
      patchBankLoanDetails(updatedData, loan_type_id);
    }
  };

  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100">
      <LiabilitiesHeading
        loan_type_id={loan_type_id}
        loan_type={loan_type}
        loan_type_details={getData?.data?.bank_name}
      />

      <div className="mt-[1.5rem] bg-white flex justify-end">
        <AddYourLiability />
      </div>

      {/* Total loan Ammount */}
      <div className="py-[1rem] px-[1.5rem] mt-[1rem] border rounded-xl grid grid-cols-12 gap-[1rem] bg-white">
        <div className="col-span-4">
          <div className="p-[1rem] border rounded-xl mb-[1rem]">
            <h4 className="text-[1.1rem] font-[600] text-darkGray">
              Total Loan Amount
            </h4>

            <h4 className="flex justify-end text-customPurple font-[600] text-[1.3rem]">
              ₹ {getData?.data?.amount_borrowed}
            </h4>
          </div>

          <div className="p-[0.5rem] border border-customPurple bg-[#FAF8FC] rounded-md">
            <h4 className="text-[0.8rem] font-[600] text-customPurple m-0">
              10 % of Total Liabilities (₹50.86 Lakhs)
            </h4>
          </div>
        </div>

        <div className="col-span-8 p-[1rem] border rounded-xl">
          <h4 className="text-[1rem] font-[600] text-darkGray">{loan_type}</h4>
          <LineGraph />
        </div>
      </div>

      {/* Loan Details */}
      <div className="py-[1rem] px-[1.5rem] mt-[1rem] border rounded-xl bg-white">
        <div className="flex justify-between ">
          <h4 className="font-[600] text-[1rem] text-darkGray">Loan Details</h4>
          <div className="flex gap-[1rem]">
            <RemoveLiability
              remove_id={id}
              loan_name={getData?.data?.bank_name}
            />
            {isEditClicked ? (
              <Button
                onClick={handleUpdateDetails}
                px={"px-[0.9rem]"}
                py={"py-[0.3rem]"}
                name="Save"
              />
            ) : (
              <WhiteButton
                onClick={() => setisEditClicked(true)}
                px={"px-[0.9rem]"}
                py={"py-[0.3rem]"}
                name="Edit"
              />
            )}
          </div>
        </div>

        {/* form  */}
        {isEditClicked ? (
          // edit form
          <>
            <div className="grid grid-cols-4">
              <div>
                <label className="input-label">Bank Name</label>
                <InputField
                  className="input-box"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                />
                <br></br>
                {errors.bankName && (
                  <span className="text-red-500 text-[0.7rem]">
                    {errors.bankName}
                  </span>
                )}
              </div>

              <div>
                <label className="input-label">Amount Borrowed</label>
                <InputField
                  className="input-box"
                  value={amtBorr}
                  onChange={(e) => setAmtBorr(e.target.value)}
                />
                <br></br>
                {errors.amtBorr && (
                  <span className="text-red-500 text-[0.7rem]">
                    {errors.amtBorr}
                  </span>
                )}
              </div>

              <div>
                <label className="input-label">Loan Purpose</label>
                <InputField
                  className="input-box"
                  value={loanPurpose}
                  onChange={(e) => setLoanPurpose(e.target.value)}
                />
                <br></br>
                {errors.loanPurpose && (
                  <span className="text-red-500 text-[0.7rem]">
                    {errors.loanPurpose}
                  </span>
                )}
              </div>
            </div>

            <div className="grid grid-cols-4 my-[1.5rem]">
              <div>
                <label className="input-label">Start Date</label>
                <InputField
                  type="date"
                  className="input-box"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <br></br>
                {errors.startDate && (
                  <span className="text-red-500 text-[0.7rem]">
                    {errors.startDate}
                  </span>
                )}
              </div>

              <div>
                <label className="input-label">End Date</label>
                <InputField
                  type="date"
                  className="input-box"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
                <br></br>
                {errors.endDate && (
                  <span className="text-red-500 text-[0.7rem]">
                    {errors.endDate}
                  </span>
                )}
              </div>

              <div>
                <label className="input-label">Period</label>
                <InputField
                  className="input-box"
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                  type="select"
                  options={[
                    { value: "", label: "Select Period" },
                    { value: "3 Months", label: "3 Months" },
                    { value: "6 Months", label: "6 Months" },
                    { value: "9 Months", label: "9 Months" },
                    { value: "12 Months", label: "12 Months" },
                    { value: "18 Months", label: "18 Months" },
                    { value: "24 Months", label: "24 Months" },
                  ]}
                />
                <br></br>
                {errors.period && (
                  <span className="text-red-500 text-[0.7rem]">
                    {errors.period}
                  </span>
                )}
              </div>

              <div>
                <label className="text-[0.7rem] font-[500] text-customPurple">
                  Amount Paid
                </label>
                <InputField
                  className="input-box text-customPurple bg-[#F3EFF8]"
                  value={amtPaid}
                  onChange={(e) => setAmtPaid(e.target.value)}
                />
                <br></br>
                {errors.amtPaid && (
                  <span className="text-red-500 text-[0.7rem]">
                    {errors.amtPaid}
                  </span>
                )}
              </div>
            </div>

            <div className="grid grid-cols-4 ">
              <div>
                <label className="input-label">EMI Due Date</label>
                <InputField
                  type="date"
                  className="input-box"
                  value={dueDate}
                  onChange={(e) => setDueDate(e.target.value)}
                />
                <br></br>
                {errors.dueDate && (
                  <span className="text-red-500 text-[0.7rem]">
                    {errors.dueDate}
                  </span>
                )}
              </div>

              <div>
                <label className="input-label">EMI Amount</label>
                <InputField
                  className="input-box"
                  value={emi}
                  onChange={(e) => setEmi(e.target.value)}
                />
                <br></br>
                {errors.emi && (
                  <span className="text-red-500 text-[0.7rem]">
                    {errors.emi}
                  </span>
                )}
              </div>

              <div>
                <label className="input-label">Interest Rate</label>
                <InputField
                  className="input-box"
                  value={interestRate}
                  onChange={(e) => setInterestRate(e.target.value)}
                />
                <br></br>
                {errors.interestRate && (
                  <span className="text-red-500 text-[0.7rem]">
                    {errors.interestRate}
                  </span>
                )}
              </div>

              <div>
                <label className="text-[0.7rem] font-[500] text-customPurple">
                  Balance Payable
                </label>
                <InputField
                  className="input-box text-customPurple bg-[#F3EFF8]"
                  value={balance}
                  onChange={(e) => setBalance(e.target.value)}
                />
                <br></br>
                {errors.balance && (
                  <span className="text-red-500 text-[0.7rem]">
                    {errors.balance}
                  </span>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            {/* readOnly form */}
            <div className="grid grid-cols-4">
              <div>
                <label className="input-label">Bank Name</label>
                <InputField className="input-box" value={bankName} readOnly />
              </div>

              <div>
                <label className="input-label">Amount Borrowed</label>
                <InputField className="input-box" value={amtBorr} readOnly />
              </div>

              <div>
                <label className="input-label">Loan Purpose</label>
                <InputField
                  className="input-box"
                  value={loanPurpose}
                  readOnly
                />
              </div>
            </div>

            <div className="grid grid-cols-4 my-[1.5rem]">
              <div>
                <label className="input-label">Start Date</label>
                <InputField className="input-box" value={startDate} readOnly />
              </div>

              <div>
                <label className="input-label">End Date</label>
                <InputField className="input-box" value={endDate} readOnly />
              </div>

              <div>
                <label className="input-label">Period</label>
                <InputField className="input-box" value={period} readOnly />
              </div>

              <div>
                <label className="text-[0.7rem] font-[500] text-customPurple">
                  Amount Paid
                </label>
                <InputField
                  className="input-box text-customPurple bg-[#F3EFF8]"
                  value={amtPaid}
                  readOnly
                />
              </div>
            </div>

            <div className="grid grid-cols-4 ">
              <div>
                <label className="input-label">EMI Due Date</label>
                <InputField className="input-box" value={dueDate} readOnly />
              </div>

              <div>
                <label className="input-label">EMI Amount</label>
                <InputField className="input-box" value={emi} readOnly />
              </div>

              <div>
                <label className="input-label">Interest Rate</label>
                <InputField
                  className="input-box"
                  value={interestRate}
                  readOnly
                />
              </div>

              <div>
                <label className="text-[0.7rem] font-[500] text-customPurple">
                  Balance Payable
                </label>
                <InputField
                  className="input-box text-customPurple bg-[#F3EFF8]"
                  value={balance}
                  readOnly
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PersonalLoanDetailed;
