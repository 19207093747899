import Button from "components/Button/BtnOrange";
import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useRef, useState } from "react";
import AddNewLoan from "./Add/AddNewLoan";
import AddNewIntellectual from "./Add/AddNewIntellectual";
import AddNewDomain from "./Add/AddNewDomain";
import useGetOtherCategoryAssets from "../data/useGetOtherCategoryAssets";

const AddOtherAsset = () => {
  const [getData, getError, getIsLoading, getOtherCategoryAssets] =
  useGetOtherCategoryAssets();
  const [isAddLoan, setIsAddLoan] = useState(false);
  const [isAddIntellectual, setIsAddIntellectual] = useState(false);
  const [isAddDomain, setIsAddDomain] = useState(false);
  const addLoanBtnRef = useRef(null);
  const addIntellectualBtnRef = useRef(null);
  const addDomainBtnRef = useRef(null);
  const [categoryData, setCategoryData]= useState([])
  const [id, setId]= useState("")

  useEffect(()=>{
    getOtherCategoryAssets()
  },[])

  useEffect(()=>{
    if(getData?.data){
      setCategoryData(getData.data)
    }
  },[getData])

  const handleOpenDilog = (type, ID) => {
    setId(ID)
    if (type === "Loans To Family/Friends") {
      setIsAddLoan(true);
    }
    if (type === "Intellectual Property") {
      setIsAddIntellectual(true);
    }
    if (type === "Domain") {
      setIsAddDomain(true);
    }
  };

  useEffect(() => {
    if (isAddLoan) {
      addLoanBtnRef.current.click();
    }
    if (isAddIntellectual) {
      addIntellectualBtnRef.current.click();
    }
    if (isAddDomain) {
      addDomainBtnRef.current.click();
    }
  }, [isAddLoan, isAddIntellectual, isAddDomain]);

  return (
    <div>
      <DialogBox
        triggerElement={
          <Button
            px={"px-[0.9rem]"}
            py={"py-[0.3rem]"}
            name="+ Add Other Asset"
          />
        }
        heading={
          <h4 className="text-lightGray font-[500] text-[1rem]">
            Add to your Assets
          </h4>
        }
        content={(handleClose) => (
          <>
            <h4 className="text-[#334155] font-[600] text-[1.1rem] m-0">
              Choose Other Asset type
            </h4>
            <div className="border rounded-md mt-[1rem] mb-[0.6rem]">
              <div className="flex flex-wrap gap-2 p-[0.8rem]">
                {
                  categoryData.map((el,i)=>{
                    return(
                      <div
                      key={i}
                      onClick={() => {
                        handleOpenDilog(el.asset_name, el.id);
                        handleClose();
                      }}
                      className="bg-[#FAF8FC] border-1 border-[#EAE1F3] rounded-sm py-4 px-2 cursor-pointer w-[49%] flex justify-center items-center"
                    >
                      <div className="flex flex-col gap-1 items-center">
                        <img
                          src={el.asset_image}
                          alt={el.asset_name}
                          className="w-5 h-5"
                        />
    
                        <p className="text-[#334155] text-[0.75rem] font-[600] m-0">
                          {el.asset_name}
                        </p>
                      </div>
                    </div>
                    )
                  })
                }
              </div>
            </div>
          </>
        )}
      />

      {isAddLoan && (
        <AddNewLoan
          categoryId={id}
          setIsAddLoan={setIsAddLoan}
          isAddLoan={isAddLoan}
          triggerElement={
            <button ref={addLoanBtnRef} className="hidden">
              Add Land
            </button>
          }
        />
      )}
      {isAddIntellectual && (
        <AddNewIntellectual
          categoryId={id}
          setIsAddIntellectual={setIsAddIntellectual}
          isAddIntellectual={isAddIntellectual}
          triggerElement={
            <button ref={addIntellectualBtnRef} className="hidden">
              Add Intellectual
            </button>
          }
        />
      )}
      {isAddDomain && (
        <AddNewDomain
          categoryId={id}
          setIsAddDomain={setIsAddDomain}
          isAddDomain={isAddDomain}
          triggerElement={
            <button ref={addDomainBtnRef} className="hidden">
              Add Domain
            </button>
          }
        />
      )}
    </div>
  );
};

export default AddOtherAsset;
