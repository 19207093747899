// This page is called by backend with a token. We need to save the token in local storage.
import { myContext } from "App";
import { DASHBOARD_PAGE_URL } from "constants/routes";
import WelcomeModal from "containers/BusinessLoginSignUp.js/WelcomeModal";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const GoogleSuccessLoginRedirect = () => {
  let [searchParams] = useSearchParams();
  const token = searchParams?.get("token");
  const navigate = useNavigate();
  console.log("Inside Google SIgnin component", token);

  const [isOpen, setIsOpen] = useState(true); // Assuming you want it to be open initially

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleGoToNextPage = () => {
    setIsOpen(false);
    navigate("/dashboard");
  };

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      // navigate(DASHBOARD_PAGE_URL);
    }
  }, []);

  if (token) {
    return (
      <WelcomeModal
        isOpen={isOpen}
        handleGoToNextPage={handleGoToNextPage}
        closeModal={closeModal}
      />
    );
  }

  return <></>;
};

export default GoogleSuccessLoginRedirect;
