import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { myContext } from "App";
import FooterNav from "components/navBar/FooterNav";

function SetPrivateRoute({ WIN_WIDTH }) {
  const { isLoggedIn } = useContext(myContext);

  if (localStorage.getItem("token")?.length > 0) {
    return (
      <div>
        <Outlet />
        {WIN_WIDTH < 768 && <FooterNav />}
      </div>
    );
  } else {
    return <Navigate to={`/newLogin`} />;
  }
}

export default SetPrivateRoute;
