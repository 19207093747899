import React, { useEffect, useState } from "react";
import AssetsHeading from "../AssetsHeading";
import WhiteButton from "components/Button/WhiteButton";
import friendsPic from "assets/images/assets/Friends.svg";
import domainPic from "assets/images/assets/domain2.svg";
import intellectualPic from "assets/images/assets/intellectual.svg";
import { Link, useLocation } from "react-router-dom";
import {
  ASSETS_OTHER_ASSET_DETAILED_URL,
  ASSETS_PERSONAL_ASSET_DETAILED_URL,
  ASSETS_TYPE_URL,
} from "constants/routes";
import AddOtherAsset from "./AddOtherAsset";
import useGetAssetTypeOverview from "../data/useGetAssetTypeOverview";

const OtherAsset = () => {
  const [otherAssetData, setOtherAssetData]= useState({})
  const [getData, getrror, getIsLoading, getRealEstate] =
  useGetAssetTypeOverview();
  const location = useLocation()
  const assetId=location.state?.assetId
  useEffect(() => {
    getRealEstate("other-assets");
  }, []);

  useEffect(()=>{
    if(getData?.data){
      setOtherAssetData(getData.data)
    }
  },[getData])
  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 ">
      <AssetsHeading asset={"Other Assets"} />
      <div className=" mt-[1.5rem] border bg-white rounded-xl  grid grid-cols-12 divide-x">
        <div className=" p-[1rem] col-span-3">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Total Other Assets Value
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">₹{otherAssetData?.total_assets_value}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Total Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">{otherAssetData?.total_assets}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Active Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">{otherAssetData?.active_assets}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Idle Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">{otherAssetData?.idle_assets}</h4>
        </div>
        <div className="flex justify-center items-center p-[1rem] col-span-3">
          <AddOtherAsset />
        </div>
      </div>

      {/* Vehicle */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1.1rem] m-0">
              Loans and Advances
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
              {otherAssetData?.LoanAndAdvance?.[0]?.total_loans_value} Rs.
            </h4>
          </div>

          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Add New" />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1.5rem]">
          {
            otherAssetData?.LoanAndAdvance?.length>0 ? <>
                      <Link
            to={`${ASSETS_TYPE_URL.replace(
              ":type",
              "other-asset-loan"
            )}/?id=${assetId["Loans To Family/Friends"]}`}
            className="no-underline"
          >
            <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
              <img src={friendsPic} alt="friendsPic" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
              Loans and Advances
              </h5>
              <div className="flex justify-end mt-[1.8rem]">
                <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                  ₹ {otherAssetData?.LoanAndAdvance?.[0]?.total_loans_value}
                </h4>
              </div>{" "}
            </div>
          </Link>
            </>:<>
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={friendsPic} alt="friendsPic" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">Loans and Advances</h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                  Add New
                </button>
              </div>{" "}
            </div>
            </>
          }
        </div>
      </div>

      {/* gold */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1.1rem] m-0">
              Intellectual Property
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
              {otherAssetData?.IntellectualProperty?.[0]?.total_intellectual_property_value} Rs.
            </h4>
          </div>
          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Add New" />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1.2rem]">
          {
            otherAssetData?.IntellectualProperty?.length>0 ? <>
                         <Link
              to={`${ASSETS_TYPE_URL.replace(
                ":type",
                "other-asset-intellectual"
              )}/?id=${assetId["Intellectual Property"]}`}
            className="no-underline"
          >
            <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
              <img
                src={intellectualPic}
                alt="intellectualPic"
                className="w-7 h-7 mb-2"
              />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
              Intellectual Property
              </h5>
              <div className="flex justify-end mt-[1.8rem]">
                <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                  ₹ {otherAssetData?.IntellectualProperty?.[0]?.total_intellectual_property_value}
                </h4>
              </div>{" "}
            </div>
          </Link>
            </>:<>
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={intellectualPic} alt="intellectualPic" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">Trademark</h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                  Add New
                </button>
              </div>{" "}
            </div>
            </>
          }
        </div>
      </div>

      {/* Furniture */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1.1rem] m-0">
              Domain
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
              {otherAssetData?.domain?.[0]?.total_domains_value} Rs.
            </h4>
          </div>
          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Add New" />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1.2rem]">
          {
            otherAssetData?.domain?.length > 0 ? <>
                        <Link
              to={`${ASSETS_TYPE_URL.replace(
                ":type",
                "other-asset-domain"
              )}/?id=${assetId["Domain"]}`}
            className="no-underline"
          >
            <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
              <img src={domainPic} alt="domainPic" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
              Domain
              </h5>
              <div className="flex justify-end mt-[1.8rem]">
                <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                  ₹ {otherAssetData?.domain?.[0]?.total_domains_value}
                </h4>
              </div>{" "}
            </div>
          </Link>
            </>:<>
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={domainPic} alt="domainPic" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">Domain</h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                  Add New
                </button>
              </div>{" "}
            </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default OtherAsset;
