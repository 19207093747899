import React, { useEffect, useState } from "react";
import LiabilitiesLandingPage from "./LiabilitiesLandingPage";
import useGetLiabilitiesoverview from "./data/useGetLiabilitiesOverview";
import LiabilitiesViewPage from "./LiabilitiesViewPage";

const InitialLiabilitiesPage = () => {
  const [showLandingPage, setShowLandingPage] = useState(false);
  const [getDataToView, setGetDataToView] = useState(null);
  const [loading, setLoading] = useState(true);
  const [individualId, setIndividualId] = useState(null);
  const [getData, getError, getIsLoading, getLiabilitiesOverview] =
    useGetLiabilitiesoverview();

  useEffect(() => {
    getLiabilitiesOverview();
  }, []);
  
  useEffect(() => {
    if (getData?.data?.data) {
      if(getData.data.data.loans.length>0){
        setShowLandingPage(true);
      }
      setLoading(false);
      setGetDataToView(getData.data.data);
      console.log("dataOverview", getData?.data);
    } else {
      setLoading(false);
    }
  }, [getData]);

  return (
    <div className="grid grid-cols-12 relative">
      {loading ? (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      ) : (
        <>
          <div className={`col-span-12 ${showLandingPage ? "hidden" : ""}`}>
            <LiabilitiesLandingPage setIndividualId={setIndividualId} />
          </div>
          <div className={`col-span-12 ${showLandingPage ? "" : "hidden"}`}>
            <LiabilitiesViewPage
              getLiabilitiesOverview={getLiabilitiesOverview}
              getDataToView={getDataToView}
              individualId={individualId}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default InitialLiabilitiesPage;
