import Button from "components/Button/BtnOrange";
import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useRef, useState } from "react";
import "../../../Liabilities/liabilities.css";
import InputField from "components/input/InputField";
import upload from "assets/images/liabilities/upload.svg";
import trash from "assets/images/assets/trash-2.svg";
import usePostDomain from "../../data/usePostDomain";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";
import { useNavigate } from "react-router-dom";
import { ASSETS_TYPE_URL } from "constants/routes";

const AddNewDomain = ({
  triggerElement,
  estateType,
  isAddDomain,
  setIsAddDomain,
  categoryId,
  assetId,
  setIsReload
}) => {
  const [postDomainData, postDomainError, postDomainIsLoading, postDomain] = usePostDomain();
  const [currentStep, setCurrentStep] = useState(0);
  const [visitedSteps, setVisitedSteps] = useState([]);
  const [errors, setErrors]= useState({})
  const [isClose, setIsClose]= useState(false)
  const navigate = useNavigate()


const [domainForm, setDomainForm] = useState({
  domain_name: "",
  registrar: "",
  purchase_price: "",
  estimated_sale_value: "",
  purchase_date: "",
  expiry_date: "",
  auto_renewal: false,
  upload_invoice: null,
  set_remainder: null,
  domain_commercial_information: [
    {
      estimated_income: "",
      payout_frequency: "",
      associated_business_name: "",
      agreement: null
    }
  ]
});

const resetState = () => {
  setDomainForm([{
    domain_name: "",
    registrar: "",
    purchase_price: "",
    estimated_sale_value: "",
    purchase_date: "",
    expiry_date: "",
    auto_renewal: false,
    upload_invoice: null,
    set_remainder: null,
    domain_commercial_information: [
      {
        estimated_income: "",
        payout_frequency: "",
        associated_business_name: "",
        agreement: null
      }
    ]
  }])
  setCurrentStep(0);
    setErrors({});
    setVisitedSteps([]);
}

useEffect(()=>{
  if(postDomainData){
   showSuccessToast("Posted Successfully")
   if (window.location.href.includes("other-asset-domain")) {
    setIsReload(true);
  }else{
    navigate(
      `${ASSETS_TYPE_URL.replace(":type", "other-asset-domain")}/?id=${categoryId}`
    ); 
  }
   setIsClose(true)
  }
  if(postDomainError){
   showErrorToast("Error in Posting")
  }
 },[postDomainData,postDomainError])

const handleDomainSubmit = () => {
  if (!validateDomainForm()) return;

  const formData = new FormData();

  // Append simple fields
  formData.append("domain_name", domainForm.domain_name);
  formData.append("registrar", domainForm.registrar);
  formData.append("purchase_price", domainForm.purchase_price);
  formData.append("estimated_sale_value", domainForm.estimated_sale_value);
  formData.append("purchase_date", domainForm.purchase_date);
  formData.append("expiry_date", domainForm.expiry_date);
  formData.append("auto_renewal", domainForm.auto_renewal);
  if(domainForm.set_remainder){
    formData.append("set_remainder", domainForm.set_remainder);
  }

  // Append file field if it exists
  if (domainForm.upload_invoice) {
    formData.append("upload_invoice", domainForm.upload_invoice);
  }

  // Append domain commercial information as array data
  domainForm.domain_commercial_information.forEach((info, index) => {
    formData.append(`domain_commercial_information[${index}]estimated_income`, info.estimated_income);
    formData.append(`domain_commercial_information[${index}]payout_frequency`, info.payout_frequency);
    formData.append(`domain_commercial_information[${index}]associated_business_name`, info.associated_business_name);

    // Append file in domain commercial information if it exists
    if (info.agreement) {
      formData.append(`domain_commercial_information[${index}]agreement`, info.agreement);
    }
  });

  console.log('domainForm', domainForm)
  postDomain(formData, categoryId)
  // Send formData to your API
 
};

const validateDomainForm = () => {
  const newErrors = {};
  const numberPattern = /^[0-9]*$/;
  const filePattern = /\.(pdf|jpg|jpeg|png)$/i;

  if (!domainForm.domain_name) {
    newErrors.domain_name = "Domain name is required.";
  }

  if (!domainForm.registrar) {
    newErrors.registrar = "Registrar is required.";
  }

  if(currentStep === 1){
    if (!domainForm.purchase_price || !numberPattern.test(domainForm.purchase_price)) {
      newErrors.purchase_price = "Purchase price is required and should be a number.";
    }
  
    if (!domainForm.estimated_sale_value || !numberPattern.test(domainForm.estimated_sale_value)) {
      newErrors.estimated_sale_value = "Estimated sale value is required and should be a number.";
    }
  
    if (!domainForm.purchase_date) {
      newErrors.purchase_date = "Purchase date is required.";
    }
  
    if (!domainForm.expiry_date) {
      newErrors.expiry_date = "Expiry date is required.";
    }
    if(!domainForm.upload_invoice){
      newErrors.upload_invoice="Invoice is Required"
    }else if(!filePattern.test(domainForm.upload_invoice.name)){
      newErrors.upload_invoice="Invoice should be a valid file format (PDF, JPG, or PNG)."
    }
  }
  // Validate each domain commercial information entry
  let hasDomainCommercialErrors = false;
  // if(currentStep === 2){
  //   newErrors.domain_commercial_information = domainForm.domain_commercial_information.map((info, index) => {
  //     const infoErrors = {};
  //     if (!info.estimated_income || !numberPattern.test(info.estimated_income)) {
  //       infoErrors.estimated_income = `Estimated income is required for domain commercial information ${index + 1} and should be a number.`;
  //     }
  
  //     if (!info.payout_frequency) {
  //       infoErrors.payout_frequency = `Payout frequency is required for domain commercial information ${index + 1}.`;
  //     }
  
  //     if (info.agreement && !filePattern.test(info.agreement.name)) {
  //       infoErrors.agreement = `Agreement for domain commercial information ${index + 1} should be a valid file format (PDF, JPG, or PNG).`;
  //     }
  
  //     if (Object.keys(infoErrors).length > 0) {
  //       hasDomainCommercialErrors = true;
  //       return infoErrors;
  //     }
  
  //     return null;
  //   }).filter(Boolean);
  
  //   if (!hasDomainCommercialErrors) {
  //     delete newErrors.domain_commercial_information;
  //   }
  // }
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0 && !hasDomainCommercialErrors;
};

const handleDomainChange = (e) => {
  let { name, value, type, files } = e.target;
  if(type==="radio"){
     const bool = value==="true" ? true : value === "false"? false : null ;
     value = bool 
  }
  setDomainForm((prevForm) => ({
    ...prevForm,
    [name]: type === "file" ? files[0] : value
  }));
};

const handleDomainCommercialChange = (index, e) => {
  const { name, value, files, type } = e.target;
  const fieldName = name.split(".")[1];
  const updatedDomainCommercialInfo = domainForm.domain_commercial_information.map((info, i) => (
    i === index ? { ...info, [fieldName]: type === "file" ? files[0] : value } : info
  ));
  setDomainForm({ ...domainForm, domain_commercial_information: updatedDomainCommercialInfo });
};

const addDomainCommercialInfo = () => {
  setDomainForm((prevForm) => ({
    ...prevForm,
    domain_commercial_information: [
      ...prevForm.domain_commercial_information,
      {
        estimated_income: "",
        payout_frequency: "",
        associated_business_name: "",
        agreement: null
      }
    ]
  }));
};
   

  const progressSteps = [
    {
      label: "Registry",
      active: currentStep === 0,
      visited: visitedSteps.includes(1),
    },
    {
      label: `Status & Value`,
      active: currentStep === 1,
      visited: visitedSteps.includes(2),
    },
    {
      label: `Devices`,
      active: currentStep === 2,
      visited: visitedSteps.includes(3),
    },
  ];
  useEffect(() => {
    if (!visitedSteps.includes(currentStep)) {
      setVisitedSteps((prev) => [...prev, currentStep]);
    }
  }, [currentStep, visitedSteps]);

  const handleNext = () => {
    if(currentStep === 3){
      handleDomainSubmit()
    }
    if (currentStep < 3) {
      if(validateDomainForm()){
        setCurrentStep(currentStep + 1);
      }
    } else {
      console.log("Reached the last step");
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const removeCommercialItem =(index)=>{
    const updatedCommercial= domainForm.domain_commercial_information.filter((el,i)=> i !== index)
    const updatedDomainForm={...domainForm, domain_commercial_information:updatedCommercial}
    setDomainForm(updatedDomainForm)
  }

  const handleViewDocument =()=>{
    window.open(URL.createObjectURL(domainForm.upload_invoice), "_blank")
  }

  const handleViewCommercialPdf=(pdf)=>{
    window.open(URL.createObjectURL(pdf), "_blank")
  }

  const getContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Registry Details
            </h4>

            {/* form */}
            <div className="rounded-md bg-[#F1F5F9] mt-[1rem] mb-[1.5rem]">
              <div className="grid grid-cols-12 px-3 py-2.5 gap-3">
                <div className="flex flex-col gap-1 col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Domain Name
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={domainForm.domain_name}
                    onChange={handleDomainChange}
                    name="domain_name"
                    placeholder="Domain Name"
                  />
                  {errors.domain_name && <p className="text-red-500 text-[0.75rem]">{errors.domain_name}</p>}
                </div>
                <div className="flex flex-col gap-1 col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Registrar
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={domainForm.registrar}
                    onChange={handleDomainChange}
                    name="registrar"
                    placeholder="Registrar"
                  />
                  {errors.registrar && <p className="text-red-500 text-[0.75rem]">{errors.registrar}</p>}
                </div>
              </div>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Status and Financial Value
            </h4>
            <div className="overflow-y-auto max-h-[300px] scrollbar-hide">
              <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
                <div className="grid grid-cols-12 gap-3 mt-2">
                  <div className="col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Purchase Price
                    </label>
                    <div className="w-[100%] rounded-md border-[1px] flex justify-center items-center focus-within:border-customPurple">
                      <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name="purchase_price"
                        value={domainForm.purchase_price}
                        onChange={handleDomainChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                    </div>
                    {errors.purchase_price && <p className="text-red-500 text-[0.75rem]">{errors.purchase_price}</p>}
                  </div>

                  <div className="col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Estimated Sale Value
                    </label>
                    <div className="w-[100%] rounded-md border-[1px] flex justify-center items-center focus-within:border-customPurple">
                      <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name="estimated_sale_value"
                        value={domainForm.estimated_sale_value}
                        onChange={handleDomainChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                    </div>
                    {errors.estimated_sale_value && <p className="text-red-500 text-[0.75rem]">{errors.estimated_sale_value}</p>}
                  </div>
                </div>
                <div onClick={()=>document.getElementById(`file-upload-invoice`).click()} className="mt-[1rem] w-[50%] px-4 py-1.5 cursor-pointer flex items-center justify-center border-[1px] border-[#94A3B8] rounded-lg gap-1.5 bg-white">
                  <img src={upload} alt="uploadPic" />
                  <p className="text-[#334155] m-0 font-[600]">
                    Upload Invoice
                  </p>
                </div>
                {errors.upload_invoice && <p className="text-red-500 text-[0.75rem]">{errors.upload_invoice}</p>}
                <InputField 
                  id={`file-upload-invoice`}
                  type="file"
                  onChange={handleDomainChange}
                  className="hidden"
                  name="upload_invoice"
                />
              </div>
              <div className="rounded-md bg-[#F1F5F9] mt-[1rem] mb-3 p-2">
                <div className="grid grid-cols-12 gap-3 px-3 py-2 mt-1.5">
                  <div className="input-container w-[100%] group col-span-6 flex flex-col gap-1.5">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Purchase Date
                    </label>
                    <InputField
                      type="date"
                      className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[97%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={domainForm.purchase_date}
                      name="purchase_date"
                      onChange={handleDomainChange}
                    />
                    {errors.purchase_date && <p className="text-red-500 text-[0.75rem]">{errors.purchase_date}</p>}
                  </div>
                  <div className="input-container w-[100%] group col-span-6 flex flex-col gap-1.5">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Expiry Date
                    </label>
                    <InputField
                      type="date"
                      className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[97%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={domainForm.expiry_date}
                      name="expiry_date"
                      onChange={handleDomainChange}
                    />
                    {errors.expiry_date && <p className="text-red-500 text-[0.75rem]">{errors.expiry_date}</p>}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-3 px-3">
                  <div className="input-container">
                    <label className="input-label">Auto Renewal</label>
                    <div className="flex gap-3 items-center mt-[0.5rem]">
                      <label className="cursor-pointer input-label focus-within:text-customPurple">
                        <InputField
                          type="radio"
                          value={true}
                          name="auto_renewal"
                          checked={domainForm.auto_renewal===true}
                          onChange={handleDomainChange}
                          className="mr-2 custom-radio cursor-pointer"
                        />
                        Yes
                      </label>
                      <label className="cursor_pointer input-label focus-within:text-customPurple">
                        <InputField
                          type="radio"
                          value={false}
                          name="auto_renewal"
                          checked={domainForm.auto_renewal===false}
                          onChange={handleDomainChange}
                          className="mr-2 custom-radio cursor-pointer"
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div onClick={()=>document.getElementById(`file-upload-reminder`).click()} className="ml-3 cursor-pointer mt-3 w-[37%] px-3 py-1.5 flex items-center border-[1px] border-[#94A3B8] rounded-lg gap-1.5 bg-white">
                  <img src={upload} alt="uploadPic" />
                  <p className="text-[#334155] m-0 font-[600]">Set Reminder</p>
                </div>
                <InputField 
                  id={`file-upload-reminder`}
                  type="file"
                  onChange={handleDomainChange}
                  className="hidden"
                  name="set_remainder"
                />
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1rem]">
              Commercial Information
            </h4>
            {
              domainForm.domain_commercial_information.map((el,i)=>{
                return(
                  <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
                  <div className="flex gap-3 items-center mt-3">
                    <div className="flex flex-col gap-1  w-[45%] group">
                      <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                        Name of Associated Business
                      </label>
                      <InputField
                        type="text"
                        className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                        value={el.associated_business_name}
                        onChange={(e)=>handleDomainCommercialChange(i,e)}
                        name={`domain_commercial_information[${i}].associated_business_name`}
                        placeholder="Business Nature"
                      />
                        {errors.domain_commercial_information && errors.domain_commercial_information[i]?.associated_business_name && (
                          <p className="text-red-500 text-[0.75rem]">
                            {errors.domain_commercial_information[i].associated_business_name}
                          </p>
                        )}
                    </div>
                    <div className="input-container w-[35%] flex flex-col gap-1 group">
                      <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                        Payout Frequency
                      </label>
                      <InputField
                        type="select"
                        className="border-[1px] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                        options={[
                          { value: "", label: "Select Type" },
                          { value: "Annually", label: "Annually" },
                          { value: "Monthly", label: "Monthly" },
                          { value: "Quarterly", label: "Quarterly" },
                          { value: "Biannually", label: "Biannually" },
                        ]}
                        name={`domain_commercial_information[${i}].payout_frequency`}
                        value={el.payout_frequency}
                        onChange={(e)=>handleDomainCommercialChange(i,e)}
                      />
                      {errors.domain_commercial_information && errors.domain_commercial_information[i]?.payout_frequency && (
                          <p className="text-red-500 text-[0.75rem]">
                            {errors.domain_commercial_information[i].payout_frequency}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="flex gap-3 items-center mt-3">
                    <div className="w-[40%] group">
                      <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                        Estimated Income
                      </label>
                      <div className="w-[100%] rounded-md border-[1px] flex justify-center items-center focus-within:border-customPurple">
                        <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                          Rs.
                        </span>
                        <InputField
                          type="number"
                          name={`domain_commercial_information[${i}].estimated_income`}
                          value={el.estimated_income}
                          onChange={(e)=>handleDomainCommercialChange(i,e)}
                          className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                        />
                      </div>
                      {errors.domain_commercial_information && errors.domain_commercial_information[i]?.estimated_income && (
                          <p className="text-red-500 text-[0.75rem]">
                            {errors.domain_commercial_information[i].estimated_income}
                          </p>
                        )}
                    </div>
                    <div className="w-[40%]">
                      <div  onClick={()=>document.getElementById(`file-upload-agreement-certificate-${i}`).click()}  className="px-3 cursor-pointer py-1.5 flex items-center  border-[1px] rounded-lg gap-1.5 bg-white mt-4">
                      <img src={upload} alt="uploadPic" />
                      <p className="text-[#334155] m-0 font-[600] text-[0.78rem]">Upload Agreement</p>
                      </div>
                      {errors.domain_commercial_information && errors.domain_commercial_information[i]?.associated_business_name && (
                          <p className="text-red-500 text-[0.75rem]">
                            {errors.domain_commercial_information[i].associated_business_name}
                          </p>
                        )}
                    </div>
                    <div className="mt-[19px]">
                      <img onClick={()=>removeCommercialItem(i)} src={trash} alt="trashPic" />
                    </div>
                  </div>
                  <InputField 
                      id={`file-upload-agreement-certificate-${i}`}
                      name={`domain_commercial_information[${i}].agreement`}
                      type="file"
                      onChange={(e)=>handleDomainCommercialChange(i,e)}
                      className="hidden"
                    />
                </div>
                )
              })
            }
          </>
        );
      case 3:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Review your details
            </h4>
            <div className="flex justify-between items-center mb-3">
              <div>
                <p className="m-0 text-[#334155] text-[0.9rem] font-[600]">
                  Registry Details
                </p>
              </div>
              <div className="flex gap-2.5">
                <WhiteButton
                  px={"px-[0.8rem]"}
                  py={"py-[0.3rem]"}
                  name="View Document"
                  onClick={handleViewDocument}
                />
                <WhiteButton
                  px={"px-[0.8rem]"}
                  py={"py-[0.3rem]"}
                  name="Edit"
                  onClick={()=>setCurrentStep(0)}
                />
              </div>
            </div>
            <div className="border bg-white rounded-xl p-[1rem]">
              <div className="mt-2.5 flex items-center gap-4">
                <div className="flex flex-col gap-1 w-[30%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Domain Name
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                   {domainForm.domain_name}
                  </p>
                </div>
              </div>
              <div className="mt-3 flex items-center gap-10">
                <div className="flex flex-col gap-1 w-[30%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Registrar
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {domainForm.registrar}
                  </p>
                </div>
                <div className="flex flex-col gap-1 w-[33%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Auto-Renew on?
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {domainForm.auto_renewal?"Yes": "No"}
                  </p>
                </div>
              </div>
              <div className="mt-3 flex items-center gap-10">
                <div className="flex flex-col gap-1 w-[30%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Purchase Date
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {domainForm.purchase_date}
                  </p>
                </div>
                <div className="flex flex-col gap-1 w-[30%]">
                  <p className="text-[#334155] m-0 font-[500] text-[0.75rem]">
                    Expiry Date
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {domainForm.expiry_date}
                  </p>
                </div>
                <div className="flex flex-col gap-1 w-[30%]">
                  <p className="text-[#334155] m-0 font-[500] text-[0.75rem]">
                    Reminder
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {domainForm.set_remainder ? "Added" : "Not Added"}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center mb-3 mt-3">
              <div>
                <p className="m-0 text-[#334155] text-[0.9rem] font-[600]">
                  Status and Value
                </p>
              </div>
              <div className="flex gap-2.5">
                <WhiteButton
                  px={"px-[0.8rem]"}
                  py={"py-[0.3rem]"}
                  name="Edit"
                  onClick={()=>setCurrentStep(1)}
                />
              </div>
            </div>
            <div className="border bg-white rounded-xl p-[1rem]">
              <div className="mt-2.5 flex items-center gap-4">
                <div className="flex flex-col gap-1 w-[30%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Purchase Date
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {domainForm.purchase_date}
                  </p>
                </div>
                <div className="flex flex-col gap-1 w-[30%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Expiry Date
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {domainForm.expiry_date}
                  </p>
                </div>
                <div className="flex flex-col gap-1 w-[30%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Reminder
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                  {domainForm.set_remainder ? "Added" : "Not Added"}
                  </p>
                </div>
              </div>
              <div className="mt-3 flex items-center gap-10">
                <div className="flex flex-col gap-1 w-[30%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Purchase Price
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {domainForm.purchase_price} Rs.
                  </p>
                </div>
                <div className="flex flex-col gap-1 w-[33%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Estimated Sale Value
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {domainForm.estimated_sale_value} Rs.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center mb-3 mt-3">
              <div>
                <p className="m-0 text-[#334155] text-[0.9rem] font-[600]">
                  Commercial Use
                </p>
              </div>
              <div className="flex gap-2.5">
                <WhiteButton
                  px={"px-[0.8rem]"}
                  py={"py-[0.3rem]"}
                  name="Edit"
                  onClick={()=>setCurrentStep(2)}
                />
              </div>
            </div>
             {
              domainForm.domain_commercial_information.map((el,i)=>{
                return(
                  <div key={i} className="border bg-white rounded-xl p-[1rem] mb-4">
                  <div className="mt-2.5 flex items-center gap-4">
                    <div className="flex flex-col gap-1 w-[40%]">
                      <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                        Name of Associated Business
                      </p>
                      <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                        {el.associated_business_name}
                      </p>
                    </div>
                    <div className="flex flex-col gap-1 w-[40%]">
                      <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                        Payout Frequency
                      </p>
                      <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                        {el.payout_frequency}
                      </p>
                    </div>
                  </div>
                  <div className="mt-3 flex items-center gap-7">
                    <div className="flex flex-col gap-1 w-[40%]">
                      <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                        Estimated Income
                      </p>
                      <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                        {el.estimated_income} Rs.
                      </p>
                    </div>
                    <div className="flex items-center">
                    <button onClick={()=>handleViewCommercialPdf(el.agreement)} className="text-lightGray bg-white font-[600] text-[0.9rem] border-1 border py-[0.2rem] px-[0.7rem] rounded-md flex gap-2 items-center">
                      <img src={upload} alt="upload" />
                      View Agreement
                    </button>
                  </div>
                  </div>
                </div>
                )
              })
             }
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <DialogBox
      onClose={resetState}
        resetClose={setIsAddDomain}
        isNotDefault={isAddDomain}
        triggerElement={triggerElement}
        progressSteps={progressSteps}
        heading={
          <h4 className="font-[600] text-xl text-[#334155]">
            Add <span className="text-[#9065B4]">Loans & Advances</span> to your
            Assets
          </h4>
        }
        content={(handleClose) => (
          <>
            {getContent()}
            <div className={`${
                currentStep == 2 ? "flex justify-between" : ""
              }`}>
              {currentStep == 2  ? (
                <WhiteButton
                  onClick={addDomainCommercialInfo}
                  name="Add another expense"
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
              ) : (
                ""
              )}
              <div className="flex justify-end gap-[1rem] ">
                <WhiteButton
                  onClick={handleBack}
                  name="Back"
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
                {currentStep < 3 ? (
                  <Button
                    onClick={handleNext}
                    name={currentStep == 2 ? "Preview & Save" : "Next"}
                    px={"px-[0.6rem]"}
                    py={"py-[0.4rem]"}
                  />
                ) : (
                  <Button
                    onClick={handleNext}
                    name={"Add to Assets"}
                    px={"px-[0.6rem]"}
                    py={"py-[0.4rem]"}
                  />
                )}
              </div>
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
    </div>
  );
};

export default AddNewDomain;
