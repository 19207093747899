import React, { useEffect, useState } from "react";
import SummarySidebar from "./SummarySidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  INCOME_TAX_SUMMARY_URL,
  INCOME_TAX_SUMMARY_USER_DETAILS,
  INCOME_TAX_SUMMARY_USER_PAGE_URL,
  USER_INCOME_SUMMARY_PAGE_URL,
} from "constants/routes";

// images
import forward_arrow from "assets/images/incomeTax/forward.svg";
import update_btn from "assets/images/incomeTax/update.png";
import downloadIcon from "assets/images/incomeTax/downloadIcon.png";
import viewIcon from "assets/images/incomeTax/viewIcon.png";
import pastIcon from "assets/images/incomeTax/pastIcon.png";
import totalIncome from "assets/images/incomeTax/totalIncome.png";
import deductions from "assets/images/incomeTax/deductions.png";
import taxPaid from "assets/images/incomeTax/taxPaid.png";
import taxRefund from "assets/images/incomeTax/taxRefund.png";
import forward_mobile from "assets/images/incomeTax/forward_mobile.png";
import fileNowArrow from "assets/images/incomeTax/fileNowArrow.png";
import previousBtn from "assets/images/incomeTax/previousBtn.svg";

import useGetTotalTaxSummary from "./Data/useGetTotaltaxSummary";

const SummaryUserPage = () => {
  const { income_tax_return_id } = useParams();
  const navigate = useNavigate();
  const navigateToSalary = () => {
    const path = INCOME_TAX_SUMMARY_USER_DETAILS.replace(
      ":detailType",
      "salary"
    ).replace(":income_tax_return_id", income_tax_return_id);
    navigate(path);
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const [getData, getError, getIsLoading, getTotalTaxSummaryData] =
    useGetTotalTaxSummary();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const incomeCards = [
    {
      type: "Total Income",
      amount: "total_income",
      path: "total",
      icon: forward_arrow,
      icon_2: forward_mobile,
      bgColor: "#CCE4FF",
      icon_mobile: totalIncome,
    },
    {
      type: "Deductions",
      amount: "total_deductions",
      path: "deductions",
      icon: forward_arrow,
      icon_2: forward_mobile,
      bgColor: "#EEDEDD",
      icon_mobile: deductions,
    },
    {
      type: "Taxes Paid",
      amount: "total_taxes_paid",
      path: "taxes-paid",
      icon: forward_arrow,
      icon_2: forward_mobile,
      bgColor: "#DCFCE7",
      icon_mobile: taxPaid,
    },
    {
      type: "Tax Refund",
      amount: "total_tax_refund",
      path: "computation",
      icon: forward_arrow,
      icon_2: forward_mobile,
      bgColor: "#FEFBDC",
      icon_mobile: taxRefund,
    },
  ];

  useEffect(() => {
    getTotalTaxSummaryData(income_tax_return_id);
  }, []);

  useEffect(() => {
    if (getData && getData.data) {
      // console.log("getdata:", getData.data);
    }
  }, [getData]);

  const handlePrevious = () => {
    navigate(
      INCOME_TAX_SUMMARY_URL.replace(
        ":income_tax_return_id",
        income_tax_return_id
      )
    );
  };

  return (
    <div className="relative">
      {getIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25  border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <div className="bg-gray-100 md:px-8 px-6 pt-4 md:mb-0 ">
        <div className="flex gap-2">
          <button onClick={handlePrevious}>
            <img src={previousBtn} alt="" className="w-4 h-4 md:hidden " />
          </button>
          <h4 className="text-[#334155] font-[600] text-[1.4rem] leading-[2.7rem] pt-2">
            Tax Summary{" "}
            <span className="text-[#64748B] text-[1.2rem] font-[500]">
              {getData?.data?.income_tax_return_year
                ? getData?.data?.income_tax_return_year
                : ""}
            </span>
          </h4>
        </div>
        <div className="md:bg-white rounded-lg md:shadow md:px-4 md:py-4 md:mt-4">
          <div className="flex items-center justify-between mb-4 ">
            <h4 className="text-[#334155] font-[600] text-[1.4rem] leading-[2.7rem] md:block hidden">
              {user?.business_name ? user.business_name : user?.full_name}
            </h4>
            <button
              style={{ cursor: "default", pointerEvents: "none" }}
              className="font-[400] text-[0.9rem] text-[#64748B] bg-[#F1F5F9] px-[1%] rounded py-1 md:block hidden"
            >
              Detailed Tax Break-up
            </button>
          </div>

          <div className="grid grid-cols-12 gap-4">
            {incomeCards.map((card) => (
              <div
                key={card.id}
                className="col-span-12 md:col-span-3 shadow rounded p-3 md:bg-white"
                style={{
                  backgroundColor:
                    window.innerWidth < 768 ? card.bgColor : "white",
                }}
              >
                <Link
                  to={USER_INCOME_SUMMARY_PAGE_URL.replace(
                    ":income_tax_return_id",
                    income_tax_return_id
                  ).replace(":type", card.path)}
                  className="no-underline"
                >
                  <div className="flex items-center justify-between ">
                    <div>
                      <div className="flex gap-2">
                        <img
                          src={card.icon_mobile}
                          alt={card.type}
                          className="w-5 h-5 md:hidden"
                        />
                        <h4 className="font-[600] text-[1rem] md:text-[#64748B] mb-4">
                          {card.type}
                        </h4>
                      </div>

                      <h4 className="text-[#334155] font-[600] ">
                        {`₹ ${
                          getData?.data?.[card.amount]
                            ? getData?.data?.[card.amount]
                            : 0
                        }`}
                      </h4>
                    </div>

                    <Link
                      to={USER_INCOME_SUMMARY_PAGE_URL.replace(
                        ":income_tax_return_id",
                        income_tax_return_id
                      ).replace(":type", card.path)}
                    >
                      <img
                        src={card.icon}
                        alt={card.type}
                        className="md:block hidden"
                      />
                      <img
                        src={card.icon_2}
                        alt={card.type}
                        className="md:hidden"
                      />
                    </Link>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <h4 className="font-[400] text-[0.9rem] text-[#64748B] mt-3 md:flex hidden ">
            Last import: June 28, 2024 at 04:11 AM
            <img src={update_btn} alt="update button" className="ml-4 mr-2 " />
            Update
          </h4>
        </div>

        <div className="flex items-center justify-between md:my-[3rem] mt-[1rem] ">
          <div className="flex gap-4">
            <h4 className="font-[400] text-[0.9rem] text-[#64748B] md:flex hidden mt-3">
              <img src={pastIcon} alt="update button" className="mr-2" />
              Compare Past Year
            </h4>

            <h4 className="font-[400] text-[0.9rem] text-[#64748B] md:flex hidden mt-3">
              <img src={viewIcon} alt="update button" className="mr-2" />
              View Report
            </h4>

            <h4 className="font-[400] text-[0.9rem] text-[#64748B] md:flex hidden mt-3">
              <img src={downloadIcon} alt="update button" className="mr-2" />
              Download Report
            </h4>
            <img
              src={viewIcon}
              alt="update button"
              className="mr-2 md:hidden"
            />
            <img
              src={pastIcon}
              alt="update button"
              className="mr-2 md:hidden"
            />
            <img
              src={downloadIcon}
              alt="update button"
              className="mr-2 md:hidden"
            />
          </div>

          <button
            className="font-[400] text-[0.9rem] text-white bg-[#94A3B8] px-[2%] rounded py-2 md:block hidden"
            onClick={navigateToSalary}
          >
            Proceed to File
          </button>
          <button className="font-[400] text-[0.9rem] text-[#64748B] bg-[#F1F5F9] px-[1%] rounded py-1 md:hidden">
            Tax Break-up
          </button>
        </div>

        <div className="items-center justify-center flex mt-[2rem]">
          <button
            className="flex items-center font-[400] text-[0.9rem] text-white bg-[#94A3B8] px-[9%] rounded py-2 md:hidden mb-[7rem]"
            onClick={navigateToSalary}
          >
            File now
            <img src={fileNowArrow} alt="file now arrow" className="ml-2" />
          </button>
        </div>

        <div className="md:col-span-4 mt-2 mb-[5rem] hidden md:block">
          <SummarySidebar layout="horizontal" />
        </div>
      </div>
    </div>
  );
};

export default SummaryUserPage;
