import React, { useEffect, useState } from "react";
import Input from "components/input/Input";
import "assets/images/login/Section.svg";
import backIcon from "assets/images/login/backIcon.png";
import NormalLoginSheet from "./NormalLoginSheet";
import MobileOtp from "./MobileOtp";

//API's
import usePostSendOTP from "containers/LoginAndSignUp/Data/usePostSendOTP";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";

const MobileForgotPswd = (props) => {
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState(""); 
  const [submitClicked, setSubmitClicked] = useState(false);
  const [redirectToOtp, setRedirectToOtp] = useState(false);

  const [data, error, isLoading, postOtp] = usePostSendOTP();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (submitClicked && email) {
      postOtp({ email: email });
      setSubmitClicked(false); 
    } 
  }, [submitClicked, email]);
  
  useEffect(() => {
    if (data) {
      showSuccessToast('OTP sent successfully')
      setRedirectToOtp(true);
    }
  }, [data]); 

  useEffect(() => {
    if (error) {
      let desc= error.message || "Something went wrong. Please try again."
      showErrorToast("",desc)
    }
  }, [error]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitClicked(true);
     
  };

  if (redirectToOtp) {
    return <MobileOtp email={email} otpSessionId={data.data.otp_id}/>;
  }

 
  return (
    <div className="grid">
      <div className="">
        <h2 className=" font-[700] leading-[2.1rem] text-[1.4rem]">
          Forgot password
        </h2>
        <Input
          label={"Email"}
          placeholder={"Enter your email"}
          type={"email"}
          value={email}
          setValue={setEmail}
        />
        <p className="font-[400] flex justify-center items-center">or</p>
        <Input
          label={"Mobile Number"}
          placeholder={"Enter your Mobile Number"}
          value={mobileNumber}
          setValue={setMobileNumber}
        />
      </div>
      <br />
      <button
        onClick={handleSubmit}
        className=" purpleBtn p-2 rounded  w-[100%] text-white"
      >
        Reset password
      </button>
      <div className="text-[#667085] flex justify-center my-2 items-center cursor-pointer">
        <div
          onClick={() => {
            setOpen(true);
          }}
        >
          <NormalLoginSheet
            isDialogOpen='fpswd'
            element={
              <p className=" m-0 flex gap-3">
                <img src={backIcon} alt="backIcon" className="py-1" />
                Back to log in
              </p>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MobileForgotPswd;
