import Button from "components/Button/BtnOrange";
import RedButton from "components/Button/RedButton";
import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import RemoveAsset from "../../RemoveAsset";

const EditValue = ({
  deleteId,
  interestRate,
  redemptionDate,
  maturityAmt,
  setinterestRate,
  setmaturityAmt,
  setredemptionDate,
  update,
  assetId,
}) => {
  const { deposit_type } = useParams();
  const removeBtnRef = useRef(null);

  // Error state to track validation errors
  const [errors, setErrors] = useState({});

  const handleRemoveDeposit = () => {
    removeBtnRef.current.click();
  };

  const validate = () => {
    const newErrors = {};

    // Validate Interest Rate
    if (!interestRate || isNaN(interestRate) || interestRate <= 0) {
      newErrors.interestRate =
        "Interest Rate must be a valid number greater than 0.";
    }

    // Validate Redemption Date
    if (!redemptionDate) {
      newErrors.redemptionDate = "Redemption Date is required.";
    }

    // Validate Expected Maturity Amount
    if (!maturityAmt || isNaN(maturityAmt) || maturityAmt <= 0) {
      newErrors.maturityAmt =
        "Maturity Amount must be a valid number greater than 0.";
    }

    return newErrors;
  };

  const handleSave = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      // No validation errors, proceed to update
      update();
    } else {
      // Set validation errors to display
      setErrors(validationErrors);
    }
  };

  return (
    <div>
      <DialogBox
        triggerElement={
          <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Edit" />
        }
        heading={`Edit ${deposit_type} Value`}
        content={(handleClose) => (
          <>
            <div className="rounded-md bg-[#F1F5F9] mt-[0.8rem]">
              <div className="grid grid-cols-3 px-[1rem] py-[0.7rem]">
                <div className="input-container">
                  <label className="input-label">Interest Rate</label>
                  <InputField
                    value={interestRate}
                    type="number"
                    className="input-box border rounded-md bg-white"
                    onChange={(e) => setinterestRate(e.target.value)}
                  />
                  {errors.interestRate && (
                    <span className="text-red-500 text-[0.7rem]">
                      {errors.interestRate}
                    </span>
                  )}
                </div>

                <div className="input-container">
                  <label className="input-label">Redemption Date</label>
                  <InputField
                    value={redemptionDate}
                    type="date"
                    onChange={(e) => setredemptionDate(e.target.value)}
                    className="input-box border rounded-md bg-white"
                  />
                  {errors.redemptionDate && (
                    <span className="text-red-500 text-[0.7rem]">
                      {errors.redemptionDate}
                    </span>
                  )}
                </div>

                <div className="input-container">
                  <label className="input-label">
                    Expected Maturity Amount
                  </label>
                  <InputField
                    type="number"
                    onChange={(e) => setmaturityAmt(e.target.value)}
                    value={maturityAmt}
                    placeholder="Enter Expected Maturity Amt."
                    className="input-box border rounded-md bg-white"
                  />
                  {errors.maturityAmt && (
                    <span className="text-red-500 text-[0.7rem]">
                      {errors.maturityAmt}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="rounded-md bg-[#F1F5F9] mt-[0.8rem] px-[1rem] py-[0.7rem]">
              <h4 className="text-[0.9rem] font-[500] text-darkGray">
                Remove this Assets?
              </h4>
              <h4 className="text-[0.7rem] font-[500] text-lightGray">
                This action is permanent and may affect your financial records.
              </h4>
              <div className="w-[25%]">
                <RedButton
                  onClick={() => handleRemoveDeposit()}
                  name={"Remove"}
                  px={"px-[0.4rem]"}
                  py={"py-[0.3rem]"}
                />
              </div>
            </div>

            <div className="flex justify-end gap-[1rem] mt-[12rem]">
              <WhiteButton
                onClick={handleClose}
                name="Cancel"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
              <Button
                onClick={handleSave} // Call validation and save logic
                name={"Save"}
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
            </div>
          </>
        )}
      />

      <RemoveAsset
        assetId={assetId}
        deleteId={deleteId}
        heading={deposit_type}
        triggerElement={
          <button ref={removeBtnRef} className="hidden">
            Remove
          </button>
        }
      />
    </div>
  );
};

export default EditValue;
