import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DataImportSuccess from "./DataImportSuccess";
import usePostImportITProfileData from "./Data/usePostImportITProfileData";
import PanNumberDialog from "./PanNumberDialog";
import EnterPanDrawer from "./EnterPanDrawer";
import { useNavigate } from "react-router-dom";

// images
import link from "assets/images/incomeTax/link.png";
import file from "assets/images/incomeTax/file.png";
import success from "assets/images/incomeTax/check-circle.png";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";

function IncomeTaxContent(props) {
  const navigate = useNavigate();
  const [showImportSuccessDialog, setShowImportSuccessDialog] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [showPanNo, setShowPanNo] = useState(false);

  const [profileData, profileError, profileIsLoading, PostProfileData] =
    usePostImportITProfileData();

  const user = JSON.parse(localStorage.getItem("user"));
  const pan = props.pan;
  const import_data = props.import_data;

  useEffect(() => {
    if (pan && import_data) {
      setIsClosing(true);
    }
  }, [pan, import_data]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleInputPan = () => {
    setShowPanNo(true);
  };

  const handleImportData = () => {
    if (window.innerWidth < 768) {
      PostProfileData();
      window.location.href = "/income-tax";
    } else {
      PostProfileData();
    }
  };

  useEffect(() => {
    if (profileData) {
      setShowImportSuccessDialog(true);
      showSuccessToast("Income Tax Profile Data imported successfully")
    }
  }, [profileData]);

  useEffect(() => {
    if (profileError) {
      let desc= profileError.message || "Data not Imported"
      showErrorToast("", desc)
    }
  }, [profileError]);

  const handleCloseDialog = () => {
    setShowImportSuccessDialog(false);
    setShowPanNo(false);
  };

  useEffect(() => {
    if (pan && import_data) {
      setIsClosing(true);
    }
  }, [pan, import_data]);

  return (
    <div className="">
      <h4 className="text-[#334155] md:font-[600] font-[500] md:text-[1.4rem] text-[1.2rem] leading-[2.7rem] mt-2">
        Income Tax Dashboard
      </h4>

      <div
        className={`bg-[#FFF7ED] rounded-md md:mt-4  py-2 px-4 transition-all duration-10000 ease-in-out ${
          isClosing ? "closing" : ""
        }`}
        style={isClosing ? { display: "none" } : {}}
      >
        <div className="flex items-center justify-between">
          <div>
            <h4 className="text-[#334155] font-[500] text-[1.2rem] leading-[2.7rem]">
              File Your Taxes in 2 Easy Steps:{" "}
              <span className="text-[#9065B4]">Quick and Simple</span>
            </h4>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-4 mt-4 mb-3">
          <div className="col-span-12 md:col-span-5 ">
            {pan ? (
              <div className="bg-[#ECFDF3] rounded-md md:p-[1.7rem] p-[1rem] shadow mb-4 md:text-center flex md:flex-col gap-3 md:gap-0 items-center ">
                <img src={success} alt="link pan" className="mb-4" />
                <div>
                  <h6 className=" font-[600] text-[1rem] text-[#94A3B8]">
                    Link your PAN
                  </h6>
                  <p className="text-[#94A3B8] font-[400] text-[0.9rem] ">
                    Connect your PAN to automatically import your tax data
                  </p>
                </div>
              </div>
            ) : (
              <>
                <div className="bg-[#FAF8FC] rounded-md md:py-4 pt-3 px-3 shadow mb-4 md:text-center flex md:flex-col gap-3 md:gap-0 items-center">
                  <img src={link} alt="link pan" className="mb-3" />
                  <div>
                    <h6 className=" font-[600] text-[1rem] text-[#334155]">
                      Link your PAN
                    </h6>
                    <p className="text-[#64748B] font-[400] text-[0.9rem] ">
                      Connect your PAN to automatically import your tax data
                    </p>
                  </div>
                </div>
                <button
                  className="bg-[#9065B4] text-white px-4 py-2 rounded-md w-full md:block hidden"
                  onClick={handleInputPan}
                >
                  Link PAN
                </button>
                <div className="bg-[#9065B4] text-white px-4 py-2 rounded-md w-full md:hidden text-center">
                  <EnterPanDrawer element={"Link PAN"} />
                </div>
              </>
            )}
          </div>

          {import_data ? (
            <div className="col-span-12 md:col-span-7">
              <div className="bg-[#ECFDF3] rounded-md mb-3 shadow  md:p-[1.1rem] p-[1rem] flex md:flex-col items-center md:text-center gap-3 md:gap-0">
                <img src={success} alt="file details" className="mb-4" />
                <div>
                  <h6 className="font-[600] text-[1rem] text-[#94A3B8]">
                    Fill your Details & File and e-Verify
                  </h6>
                  <p className="text-[#94A3B8] font-[400] text-[0.9rem]">
                    Enter your details, submit your ITR, and e-verify - all in
                    minutes.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-span-12 md:col-span-7">
              <div className="bg-[#FAF8FC] rounded-md mb-3 shadow  md:px-3 md:pt-4 md:pb-3 px-3 pt-3 flex md:flex-col gap-3 md:gap-0 items-center md:text-center">
                <img src={file} alt="file details" className="mb-4" />
                <div>
                  <h6 className="font-[600] text-[1rem] text-[#334155]">
                    Fill your Details & File and Verify
                  </h6>
                  <p className="text-[#64748B] font-[400] text-[0.9rem]">
                    Enter your details, submit your ITR, and e-verify - all in
                    minutes.
                  </p>
                </div>
              </div>
              {pan ? (
                <button
                  onClick={handleImportData}
                  disabled={profileIsLoading}
                  className="bg-[#9065B4] text-white px-4 py-2 rounded-md w-full"
                >
                  {profileIsLoading ? "Importing data..." : "Import data"}
                </button>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>

      {showImportSuccessDialog && windowWidth > 768 && (
        <DataImportSuccess
          open={showImportSuccessDialog}
          onClose={handleCloseDialog}
        />
      )}

      {showPanNo && (
        <PanNumberDialog
          open={showPanNo}
          handleCloseDialog={handleCloseDialog}
        />
      )}
    </div>
  );
}

export default IncomeTaxContent;
