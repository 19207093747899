import React, { useEffect, useState } from "react";
import "assets/images/login/Section.svg";
import { useNavigate } from "react-router-dom";
import backIcon from "assets/images/login/backIcon.png";
import otpIcon from "assets/images/login/otpIcon.png";
import NormalLoginSheet from "./NormalLoginSheet";
import MobileSetPassword from "./MobileSetPassword";

//API's
import usePostSendOTP from "containers/LoginAndSignUp/Data/usePostSendOTP";
import usePostValidateOtp from "containers/LoginAndSignUp/Data/usePostValidateOtp";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";

const MobileOtp = (props) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [submitClicked, setSubmitClicked] = useState(false);
  const [redirectToSetPaswd, setRedirectToSetPaswd] = useState(false);

  const [data, error, isLoading, postValidateOtp] = usePostValidateOtp();
  const [SendData, SendError, SendisLoading, sendPostOtp] = usePostSendOTP();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (data) {
      showSuccessToast("OTP validated successfully. Email verified")
      setRedirectToSetPaswd(true);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      let desc = error.message || "Something went wrong. Please try again."
      showErrorToast("",desc)
    }
  }, [error]);

  const handleSubmit = () => {
    const validationOtp = {
      otp_id: props.otpSessionId,
      otp: otp.join(""),
    };
    postValidateOtp(validationOtp);
  }; 
  const handleChange = (element, index) => {
    const value = element.value;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value === "") {
      const prevSibling = document.getElementById(`otp-${index - 1}`);
      if (prevSibling !== null) {
        prevSibling.focus();
      }
    } else if (/^[0-9]$/.test(value) && index < 3) {
      const nextSibling = document.getElementById(`otp-${index + 1}`);
      if (nextSibling !== null) {
        nextSibling.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      const prevSibling = document.getElementById(`otp-${index - 1}`);
      if (prevSibling !== null) {
        prevSibling.focus();
      }
    }
  };

  const handleResend = (e) => {
    setSubmitClicked(true);
  };

  useEffect(() => {
    if (submitClicked && props.email) {
      sendPostOtp({ email: props.email });
      setSubmitClicked(false);
    }
  }, [submitClicked, sendPostOtp]);

  useEffect(() => {
    if (SendData) {
      showSuccessToast('OTP resent successfully')
    } else if (SendError) {
      let desc = SendError.message || "Failed to resend OTP. Please try again."
      showErrorToast("",desc)
    }
  }, [SendData, SendError]);

  if (redirectToSetPaswd) {
    return <MobileSetPassword otpSessionId={props.otpSessionId} />;
  }

  return (
    <div className="grid">
      <div className="">
        <div className="flex justify-center items-center">
          <img src={otpIcon} alt="otp icon" className="mb-4 " />
        </div>

        <h2 className=" font-[700] leading-[2.1rem] text-[1.4rem] flex justify-center items-center">
          OTP verification
        </h2>
        <p className=" text-gray-500 font-[400] text-[1rem] leading-[1.5rem] flex justify-center items-center">
          We sent a verification link to <br />
          {props.email}
        </p>
        <div className="flex justify-center mb-4 space-x-2">
          {otp.map((data, index) => (
            <input
              key={index}
              id={`otp-${index}`}
              type="text"
              maxLength="1"
              className="w-20 h-20 text-center border rounded-md text-[1.9rem] font-medium text-[#9065B4] focus:outline-[#D6BBFB]"
              value={data}
              onChange={(e) => handleChange(e.target, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
            />
          ))}
        </div>
      </div>
      <div
        onClick={handleSubmit}
        className=" purpleBtn p-2 rounded  w-[100%] text-white flex justify-center cursor-pointer"
      >
        Verify email
      </div>

      <p className=" text-gray-400 text-center my-2">
        Didn't receive the email? &nbsp;
        <button onClick={handleResend} className="m-2 text-purple-500">
          Click to resend
        </button>
      </p>

      <div className="text-[#667085] flex justify-center my-2 items-center cursor-pointer">
        <div
          onClick={() => {
            setOpen(false);
          }}
        >
          <NormalLoginSheet
            isDialogOpen='fpswd'
            element={
              <p className=" m-0 flex gap-3">
                <img src={backIcon} alt="backIcon" className="py-1" />
                Back to log in
              </p>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MobileOtp;
