import React, { useEffect, useState } from "react";
import Input from "components/input/Input";
import logo from "assets/images/dashboard/logo.svg";
import "assets/images/login/Section.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import backIcon from "assets/images/login/backIcon.png";
import setPswd from "assets/images/login/setPswd.png";

//API's
import usePostSetPswd from "./Data/usePostSetPswd";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";

function SetNewPswd() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);

  const [data, error, isLoading, postSetPswd] = usePostSetPswd();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (data) {
      showSuccessToast("Password reseted")
      navigate("/login");
      console.log("Reset password Data", data);
    }
  }, [data, navigate]);

  useEffect(() => {
    if (submitClicked && password && confirmPassword) {
      setSubmitClicked(false);
      console.log("set pswd Data:", data);
    }
  }, [submitClicked]);

  useEffect(() => {
    if (error) {
      let desc= error.message || "Something went wrong. Please try again.";
      showErrorToast("", desc)
    }
  }, [error]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const validatePswd = {
      otp_id: location.state.otp_id,
      password: password,
      confirm_password: confirmPassword,
    };

    if (password.length < 8) {
      showErrorToast("","Password must be at least 8 characters long.")
      return;
    }
    if (password !== confirmPassword) {
      showErrorToast("","Passwords do not match.")
      return;
    }
    setSubmitClicked(true);
    postSetPswd(validatePswd);
    console.log("validation info :", validatePswd);
  };

  return (
    <div className="slightDarkBlack grid grid-cols-12 bg-white-body h-screen">
      <div className=" col-span-6 p-4">
        <Link to={"/"}>
          {" "}
          <img src={logo} alt="" />
        </Link>
        <div className="flex flex-col items-center h-full justify-center ">
          <img src={setPswd} alt="set pswd" className="mb-4" />
          <div className=" w-[60%] ">
            <h2 className=" font-[700] leading-[2.1rem] text-[1.9rem] mb-4 flex justify-center items-center">
              Set new password
            </h2>
            <p className=" text-gray-500 font-[400] text-[1rem] leading-[1.5rem] flex justify-center items-center mb-4 text-center">
              Your new password must be different to previously used passwords.
            </p>

            <Input
              label={"Password"}
              id={"password"}
              placeholder={"Enter your password"}
              value={password}
              setValue={setPassword}
            />
            <p className="text-[#667085] mb-4">
              Must be at least 8 characters.
            </p>
            <Input
              label={"Confirm Password"}
              id={"confirmPassword"}
              placeholder={"Enter your Confirm Password"}
              type={"password"}
              value={confirmPassword}
              setValue={setConfirmPassword}
            />
          </div>
          <br />
          <button
            onClick={handleSubmit}
            className=" purpleBtn p-2 rounded  w-[60%] text-white"
          >
            Reset password
          </button>
          <div className="flex justify-between my-2 items-center">
            <Link
              to="/login"
              style={{ textDecoration: "none", color: "#667085" }}
            >
              <button className="m-0 flex gap-3">
                <img src={backIcon} alt="backIcon" className="py-1" />
                Back to log in
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className='bg-[url("assets/images/login/Section.png")] w-[100%] bg-cover bg-center rounded-bl-[10%] rounded-tl-[10%] col-span-6'></div>
    </div>
  );
}

export default SetNewPswd;
