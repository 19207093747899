import React, { useEffect, useRef, useState } from "react";
import AssetsHeading from "../AssetsHeading";
import AddNewDeposit from "./AddNewDeposit";
import { Link, useLocation } from "react-router-dom";
import { ASSETS_DEPOSITS_DETAILED_URL } from "constants/routes";
import sukanya from "assets/images/assets/sukanya.svg";
import AddNewDepositType from "./AddNewDepositType";
import useGetSukanyaDetail from "../data/usegetSukanyaDetail";

const SukanyaSamridhi = () => {
  const location = useLocation();
  const addNewBtnRef = useRef(null);
  const [selectedDeposit, setSelectedDeposit] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const queryParams = new URLSearchParams(location.search);
  const [reload, setIsReload] = useState(false);

  const sukanyaId =
    location.state?.sukanyaId || decodeURIComponent(queryParams.get("id"));
    console.log("sukanyaId:", sukanyaId)

  const [getData, getError, getIsLoading, getSukanyaDetails] =
    useGetSukanyaDetail();

  useEffect(() => {
    getSukanyaDetails(sukanyaId);
  }, []);

  useEffect(() => {
    if (reload) {
      getSukanyaDetails(sukanyaId);
    }
  }, [reload]);

  const handleAddNew = (depositType) => {
    setSelectedDeposit(depositType);
    addNewBtnRef.current.click();
  };

  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 h-screen">
      <AssetsHeading
        asset={"Deposits"}
        asset_type={"Sukanya Samriddhi Yojhana"}
      />
      <div className="flex mt-[1.5rem] border bg-white rounded-xl  grid grid-cols-12 divide-x">
        <div className=" p-[1rem] col-span-3">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Total Assets Value
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">
            ₹{getData?.data[0]?.total_SSY_deposit_value}
          </h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Total Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">
            {getData?.data[0]?.total_SSYs}
          </h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Active Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">
            {getData?.data[0]?.active_SSYs}
          </h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Idle Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">
            {getData?.data[0]?.idle_SSYs}
          </h4>
        </div>
        <div className="flex justify-center items-center p-[1rem] col-span-3">
          <AddNewDeposit />
        </div>
      </div>

      {/* sukanya samriddhi yojana*/}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="grid grid-cols-4 gap-2 ">
          {getData?.data ? (
            getData?.data?.map((item) => (
              <Link
                to={`${ASSETS_DEPOSITS_DETAILED_URL.replace(
                  ":deposit_type",
                  "sukanya-samridhi-yojana"
                )}?id=${sukanyaId}/?id=${item.id}`}
                state={{
                  assetId: sukanyaId,
                  itemId: item.id,
                  assetCategory: item.account_holder_name,
                  asset_type: "Sukanya Samriddhi Yojana",
                }}
                className="no-underline"
              >
                <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
                  <img src={sukanya} alt="land" className="w-7 h-7 mb-2" />
                  <h5 className="text-lightGray font-[600] text-[0.9rem]">
                    {item.account_holder_name}
                  </h5>
                  <div className="flex justify-end mt-[1.8rem]">
                    <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                      ₹ {item.total_balance}
                    </h4>
                  </div>{" "}
                </div>
              </Link>
            ))
          ) : (
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={sukanya} alt="land" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
                Sukanya Samriddhi Yojana
              </h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button
                  onClick={() => handleAddNew("Sukanya Samriddhi Yojana")}
                  className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1"
                >
                  Add New
                </button>
              </div>{" "}
            </div>
          )}
        </div>
      </div>

      <AddNewDepositType
        assetId={sukanyaId}
        setIsReload={setIsReload}
        depositType={selectedDeposit}
        triggerElement={
          <button ref={addNewBtnRef} className="hidden">
            Add New
          </button>
        }
      />
    </div>
  );
};

export default SukanyaSamridhi;
