import Button from "components/Button/BtnOrange";
import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useRef, useState } from "react";
import "../../../Liabilities/liabilities.css";
import InputField from "components/input/InputField";
import upload from "assets/images/liabilities/upload.svg";
import usePostIntellectual from "../../data/usePostIntellectual";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";
import { useNavigate } from "react-router-dom";
import { ASSETS_TYPE_URL } from "constants/routes";

const AddNewIntellectual = ({
  triggerElement,
  estateType,
  setIsAddIntellectual,
  isAddIntellectual,
  categoryId,
  assetId,
  setIsReload,
}) => {
  const [
    postIntellectualData,
    postIntellectualError,
    postIntellectualIsLoading,
    postIntellectual,
  ] = usePostIntellectual();
  const [currentStep, setCurrentStep] = useState(0);
  const [visitedSteps, setVisitedSteps] = useState([]);
  const [isClose, setIsClose]=useState(false)
  const [errors, setErrors]=useState({})
  const navigate = useNavigate()

  useEffect(()=>{
    if(postIntellectualData){
     showSuccessToast("Posted Successfully")
     if (window.location.href.includes("other-asset-intellectual")) {
      setIsReload(true);
    }else{
      navigate(
        `${ASSETS_TYPE_URL.replace(":type", "other-asset-intellectual")}/?id=${categoryId}`
      );
    }
     setIsClose(true)
    }
    if (postIntellectualError) {
      showErrorToast("Error in Posting");
    }
  }, [postIntellectualData, postIntellectualError]);

  const [intellectualForm, setIntellectualForm] = useState({
    ip_type: "",
    account_holder: "",
    asset_ip_name: "",
    registration_number: "",
    class_or_type_of_work: "",
    registration_date: "",
    status: false,
    reminder: null,
    document: null,
    amount_invested: "",
    estimated_sale_value: "",
  });

  const resetState = () => {
    setIntellectualForm([
      {
        ip_type: "",
        account_holder: "",
        asset_ip_name: "",
        registration_number: "",
        class_or_type_of_work: "",
        registration_date: "",
        status: false,
        reminder: null,
        document: null,
        amount_invested: "",
        estimated_sale_value: "",
      },
    ]);
    setCurrentStep(0);
    setErrors({});
    setVisitedSteps([]);
  };
  const handleInputChange = (e) => {
    let { name, value, type, files } = e.target;
    if (type === "radio") {
      const bool = value === "true" ? true : value === "false" ? false : null;
      value = bool;
    }
    setIntellectualForm((prevForm) => ({
      ...prevForm,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    // IP Type: Required (dropdown)
    if (!intellectualForm.ip_type) {
      newErrors.ip_type = "IP Type is required.";
    }

    // Account Holder: Required (dropdown)
    if (!intellectualForm.account_holder) {
      newErrors.account_holder = "Account holder is required.";
    }

    // Asset IP Name: Required, only letters and spaces allowed
    const namePattern = /^[a-zA-Z\s]+$/;
    if (!intellectualForm.asset_ip_name) {
      newErrors.asset_ip_name = "Asset IP name is required.";
    } else if (!namePattern.test(intellectualForm.asset_ip_name)) {
      newErrors.asset_ip_name =
        "Asset IP name should only contain letters and spaces.";
    }

    // Registration Number: Optional, only alphanumeric characters allowed
    const registrationPattern = /^[a-zA-Z0-9]+$/;
    if (!intellectualForm.registration_number) {
      newErrors.registration_number = "Registration Number is required";
    } else if (
      intellectualForm.registration_number &&
      !registrationPattern.test(intellectualForm.registration_number)
    ) {
      newErrors.registration_number =
        "Registration number should be alphanumeric.";
    }

    // Class or Type of Work: Required (dropdown)
    if (!intellectualForm.class_or_type_of_work) {
      newErrors.class_or_type_of_work = "Class or type of work is required.";
    }

    // Registration Date: Required, valid date
    if (!intellectualForm.registration_date) {
      newErrors.registration_date = "Registration date is required.";
    } else if (isNaN(new Date(intellectualForm.registration_date).getTime())) {
      newErrors.registration_date = "Registration date should be a valid date.";
    }

    // Reminder: Optional, valid date
    // if (intellectualForm.reminder && isNaN(new Date(intellectualForm.reminder).getTime())) {
    //   newErrors.reminder = "Reminder should be a valid date.";
    // }

    // Document: Required file, must be a valid file format (PDF, JPG, or PNG)
    const filePattern = /\.(pdf|jpg|jpeg|png)$/i;
    if (!intellectualForm.document) {
      newErrors.document = "Document file is required.";
    } else if (!filePattern.test(intellectualForm.document.name)) {
      newErrors.document =
        "Document should be a valid file format (PDF, JPG, or PNG).";
    }

    if (currentStep === 1) {
      // Amount Invested: Required, numeric, and positive
      if (!intellectualForm.amount_invested) {
        newErrors.amount_invested = "Amount invested is required.";
      } else if (
        isNaN(intellectualForm.amount_invested) ||
        intellectualForm.amount_invested <= 0
      ) {
        newErrors.amount_invested =
          "Amount invested should be a positive number.";
      }

      // Estimated Sale Value: Required, numeric, and positive
      if (!intellectualForm.estimated_sale_value) {
        newErrors.estimated_sale_value = "Estimated sale value is required.";
      } else if (
        isNaN(intellectualForm.estimated_sale_value) ||
        intellectualForm.estimated_sale_value <= 0
      ) {
        newErrors.estimated_sale_value =
          "Estimated sale value should be a positive number.";
      }
    }

    // Set error state and return whether there are errors
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    // Use FormData to submit file and form data
    const formData = new FormData();
    formData.append("ip_type", intellectualForm.ip_type);
    formData.append("account_holder", intellectualForm.account_holder);
    formData.append("asset_ip_name", intellectualForm.asset_ip_name);
    formData.append(
      "registration_number",
      intellectualForm.registration_number
    ); // Optional
    formData.append(
      "class_or_type_of_work",
      intellectualForm.class_or_type_of_work
    );
    formData.append("registration_date", intellectualForm.registration_date);
    formData.append("status", intellectualForm.status); // Assuming status is a boolean
    formData.append("reminder", intellectualForm.reminder || ""); // Optional date
    formData.append("document", intellectualForm.document); // File
    formData.append("amount_invested", intellectualForm.amount_invested);
    formData.append(
      "estimated_sale_value",
      intellectualForm.estimated_sale_value
    );

    console.log("Form Data Submitted:", formData);
    console.log("Form in submission:", intellectualForm);

    postIntellectual(formData, categoryId);
    // Example API call function, replace with your API method
  };

  const progressSteps = [
    {
      label: "Registry Details",
      active: currentStep === 0,
      visited: visitedSteps.includes(1),
    },
    {
      label: `Investment Value`,
      active: currentStep === 1,
      visited: visitedSteps.includes(2),
    },
  ];

  useEffect(() => {
    if (!visitedSteps.includes(currentStep)) {
      setVisitedSteps((prev) => [...prev, currentStep]);
    }
  }, [currentStep, visitedSteps]);

  const handleNext = () => {
    if (currentStep === 2) {
      handleSubmit();
    }
    if (currentStep < 2) {
      if (validateForm()) {
        setCurrentStep(currentStep + 1);
      }
    } else {
      console.log("Reached the last step");
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const viewAgreement = () => {
    window.open(URL.createObjectURL(intellectualForm.document), "_blank");
  };

  const getContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              IP Information
            </h4>
            <div className="rounded-md bg-[#F1F5F9] mt-[1rem] mb-3 p-2">
              <div className="grid grid-cols-12 px-3 py-1 gap-3">
                <div className="col-span-4 w-[100%] group flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    IP Type
                  </label>
                  <InputField
                    type="select"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                    options={[
                      { value: "", label: "Select an Option" },
                      { value: "Trademark", label: "Trademark" },
                      { value: "Patent", label: "Patent" },
                      { value: "Copyright", label: "Copyright" },
                      { value: "Design", label: "Design" },
                    ]}
                    name="ip_type"
                    value={intellectualForm.ip_type}
                    onChange={handleInputChange}
                  />
                  {errors.ip_type && (
                    <p className="text-red-500 text-[0.75rem]">
                      {errors.ip_type}
                    </p>
                  )}
                </div>
                <div className="input-container col-span-4 w-[100%] flex flex-col gap-1.5 group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Account Holder
                  </label>
                  <InputField
                    type="select"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                    options={[
                      { value: "", label: "Select an Option" },
                      { value: "Self", label: "Self" },
                      { value: "Company", label: "Company" },
                      { value: "Other", label: "Other" },
                    ]}
                    name="account_holder"
                    value={intellectualForm.account_holder}
                    onChange={handleInputChange}
                  />
                  {errors.account_holder && (
                    <p className="text-red-500 text-[0.75rem]">
                      {errors.account_holder}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="rounded-md bg-[#F1F5F9] mt-[1rem] mb-3 p-2">
              <div className="grid grid-cols-12 px-3 py-2.5 gap-3">
                <div className="flex flex-col gap-1 col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Asset/IP Name
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={intellectualForm.asset_ip_name}
                    onChange={handleInputChange}
                    name="asset_ip_name"
                    placeholder="Asset/IP Name"
                  />
                  {errors.asset_ip_name && (
                    <p className="text-red-500 text-[0.75rem]">
                      {errors.asset_ip_name}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-1 col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Registration Number
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={intellectualForm.registration_number}
                    onChange={handleInputChange}
                    name="registration_number"
                    placeholder="Registration Number"
                  />
                  {errors.registration_number && (
                    <p className="text-red-500 text-[0.75rem]">
                      {errors.registration_number}
                    </p>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-12 px-3 py-1 gap-3">
                <div className="col-span-5 w-[100%] group flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Class/Type of Work
                  </label>
                  <InputField
                    type="select"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                    options={[
                      { value: "", label: "Select an Option" },
                      { value: "Trademark", label: "Trademark" },
                      { value: "Patent", label: "Patent" },
                      { value: "Copyright", label: "Copyright" },
                      { value: "Design", label: "Design" },
                      { value: "Book Right", label: "Book Right" },
                    ]}
                    name="class_or_type_of_work"
                    value={intellectualForm.class_or_type_of_work}
                    onChange={handleInputChange}
                  />
                  {errors.class_or_type_of_work && (
                    <p className="text-red-500 text-[0.75rem]">
                      {errors.class_or_type_of_work}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="rounded-md bg-[#F1F5F9] mt-[1rem] mb-3 p-2">
              <div className="grid grid-cols-12 gap-3 px-3 py-2 mt-1.5">
                <div className="input-container w-[100%] group col-span-6 flex flex-col gap-1.5">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Registration Date
                  </label>
                  <InputField
                    type="date"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[97%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={intellectualForm.registration_date}
                    name="registration_date"
                    onChange={handleInputChange}
                  />
                  {errors.registration_date && (
                    <p className="text-red-500 text-[0.75rem]">
                      {errors.registration_date}
                    </p>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3 px-3">
                <div className="input-container">
                  <label className="input-label">Status</label>
                  <div className="flex gap-3 items-center mt-[0.5rem]">
                    <label className="input-label focus-within:text-customPurple">
                      <InputField
                        type="radio"
                        value={true}
                        name="status"
                        onChange={handleInputChange}
                        checked={intellectualForm.status === true}
                        className="mr-2 custom-radio"
                      />
                      Active
                    </label>
                    <label className="input-label focus-within:text-customPurple">
                      <InputField
                        type="radio"
                        value={false}
                        name="status"
                        onChange={handleInputChange}
                        checked={intellectualForm.status === false}
                        className="mr-2 custom-radio"
                      />
                      Inactive
                    </label>
                  </div>
                </div>
              </div>
              <div
                onClick={() =>
                  document.getElementById("file-upload-reminder").click()
                }
                className="ml-3 mt-2.5 w-[37%] px-3 py-1.5 cursor-pointer flex items-center border-[1px] border-[#94A3B8] rounded-lg gap-1.5 bg-white"
              >
                <img src={upload} alt="uploadPic" />
                <p className="text-[#334155] m-0 font-[600]">Set Reminder</p>
              </div>
              <InputField
                id={`file-upload-reminder`}
                type="file"
                onChange={handleInputChange}
                className="hidden"
                name="reminder"
              />
            </div>
            <div
              onClick={() =>
                document.getElementById("file-upload-document").click()
              }
              className="mt-[1rem] w-[50%] px-4 py-1.5 cursor-pointer flex items-center justify-center border-[1px] border-[#94A3B8] rounded-lg gap-1.5 bg-white"
            >
              <img src={upload} alt="uploadPic" />
              <p className="text-[#334155] m-0 font-[600]">Upload Document</p>
            </div>
            {errors.document && (
              <p className="text-red-500 text-[0.75rem]">{errors.document}</p>
            )}
            <InputField
              id={`file-upload-document`}
              type="file"
              onChange={handleInputChange}
              className="hidden"
              name="document"
            />
          </>
        );
      case 1:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Investment & Value
            </h4>
            <div className="overflow-y-auto max-h-[300px] scrollbar-hide">
              <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
                <div className="grid grid-cols-12 gap-3 mb-3">
                  <div className="col-span-6 w-[100%] group flex flex-col gap-1.5">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Amount Invested
                    </label>
                    <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                      <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name="amount_invested"
                        value={intellectualForm.amount_invested}
                        onChange={handleInputChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                    </div>
                    {errors.amount_invested && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.amount_invested}
                      </p>
                    )}
                  </div>

                  <div className="col-span-6 w-[100%] group flex flex-col gap-1.5">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Estimated Sale Value
                    </label>
                    <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                      <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name="estimated_sale_value"
                        value={intellectualForm.estimated_sale_value}
                        onChange={handleInputChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                    </div>
                    {errors.estimated_sale_value && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.estimated_sale_value}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">Preview</h4>
            <div className="flex justify-between mb-3 mt-3">
              <div>
                <p className="m-0 text-[#334155] text-[0.9rem] font-[600]">
                  Registry Details
                </p>
              </div>
              <div className="flex gap-2.5">
                <WhiteButton
                  px={"px-[0.8rem]"}
                  py={"py-[0.3rem]"}
                  name="View Agreement"
                  onClick={() => viewAgreement()}
                />
                <WhiteButton
                  px={"px-[0.8rem]"}
                  py={"py-[0.3rem]"}
                  name="Edit"
                  onClick={() => setCurrentStep(0)}
                />
              </div>
            </div>
            <div className="border bg-white rounded-xl p-[1rem]">
              <div className="mt-2.5 flex items-center gap-4">
                <div className="flex flex-col gap-1 w-[30%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    IP Type
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {intellectualForm.ip_type}
                  </p>
                </div>
              </div>
              <div className="mt-3 flex items-center gap-10">
                <div className="flex flex-col gap-1 w-[30%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Asset/IP Name
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {intellectualForm.asset_ip_name}
                  </p>
                </div>
                <div className="flex flex-col gap-1 w-[33%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Registration Number
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {intellectualForm.registration_number}
                  </p>
                </div>
                <div className="flex flex-col gap-1 w-[30%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Account Holder
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {intellectualForm.account_holder}
                  </p>
                </div>
              </div>
              <div className="mt-3 flex items-center gap-10">
                <div className="flex flex-col gap-1 w-[30%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Class/Type of Work
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {intellectualForm.class_or_type_of_work}
                  </p>
                </div>
                <div className="flex flex-col gap-1 w-[30%]">
                  <p className="text-[#334155] m-0 font-[500] text-[0.75rem]">
                    Status
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {intellectualForm.status === true ? "Active" : "Inactive"}
                  </p>
                </div>
                <div className="flex flex-col gap-1 w-[30%]">
                  <p className="text-[#334155] m-0 font-[500] text-[0.75rem]">
                    Registration Date
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {intellectualForm.registration_date}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-between mb-3 mt-3">
              <div>
                <p className="m-0 text-[#334155] text-[0.9rem] font-[600]">
                  Trademark Value
                </p>
              </div>
              <div className="flex gap-2.5">
                <WhiteButton
                  px={"px-[0.8rem]"}
                  py={"py-[0.3rem]"}
                  name="Edit"
                  onClick={() => setCurrentStep(1)}
                />
              </div>
            </div>
            <div className="border bg-white rounded-xl p-[1rem] mb-3 w-[80%]">
              <div className="flex items-center gap-20">
                <div className="flex flex-col gap-1">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Amount Invested
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {intellectualForm.amount_invested} Rs.{" "}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Estimated Sale Value
                  </p>
                  <p className="text-[#334155] m-0 text-[1.1rem] font-[500]">
                    {intellectualForm.estimated_sale_value} Rs.
                  </p>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <DialogBox
        onClose={resetState}
        steps="2"
        resetClose={setIsAddIntellectual}
        isNotDefault={isAddIntellectual}
        triggerElement={triggerElement}
        progressSteps={progressSteps}
        heading={
          <h4 className="font-[600] text-xl text-[#334155]">
            Add <span className="text-[#9065B4]">Intellectual Property</span> to
            your Assets
          </h4>
        }
        content={(handleClose) => (
          <>
            {getContent()}
            <div>
              <div className="flex justify-end gap-[1rem] ">
                <WhiteButton
                  onClick={handleBack}
                  name="Back"
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
                {currentStep < 2 ? (
                  <Button
                    onClick={handleNext}
                    name={"Next"}
                    px={"px-[0.6rem]"}
                    py={"py-[0.4rem]"}
                  />
                ) : (
                  <Button
                    onClick={handleNext}
                    name={"Add to Assets"}
                    px={"px-[0.6rem]"}
                    py={"py-[0.4rem]"}
                  />
                )}
              </div>
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
    </div>
  );
};

export default AddNewIntellectual;
