import React, { useEffect, useRef, useState } from "react";
import ChooseAsset from "./ChooseAsset";
import landGray from "assets/images/assets/landGray.svg";
import buildingGray from "assets/images/assets/buildingGray.svg";
import fixedD from "assets/images/assets/fixedD.svg";
import provident from "assets/images/assets/provident.svg";
import termInsurance from "assets/images/assets/termInsurance.svg";
import sukanya from "assets/images/assets/sukanya.svg";
import cash from "assets/images/assets/cashR.svg";
import equity from "assets/images/assets/equity.svg";
import mutual from "assets/images/assets/mutual.svg";
import bonds from "assets/images/assets/bonds.svg";
import art from "assets/images/assets/art.svg";
import vehicle from "assets/images/assets/grayCircle.svg";
import gold from "assets/images/assets/grayBoxRectangle.svg";
import family from "assets/images/assets/family.svg";
import intellectual from "assets/images/assets/intellectual.svg";
import domain from "assets/images/assets/blackAndWhiteDomain.svg"
import software from "assets/images/assets/software.svg";

import AddNewDepositType from "./Deposits/AddNewDepositType";
import AddNewLand from "./RealEstate/Add/AddNewLand";
import WhiteButton from "components/Button/WhiteButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ASSETS_TYPE_URL } from "constants/routes";
import AddNewVehicle from "./Personal Assets/Add/AddNewVehicle";
import AddNewGold from "./Personal Assets/Add/AddNewGold";
import AddNewFurniture from "./Personal Assets/Add/AddNewFurniture";
import AddNewDomain from "./Other Assets/Add/AddNewDomain";
import AddNewIntellectual from "./Other Assets/Add/AddNewIntellectual";
import AddNewLoan from "./Other Assets/Add/AddNewLoan";

const AssetsOverViewPage = ({ assetId, getData }) => {
  const { type } = useParams();
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(true);
  const realEstateBtnRef = useRef(null);
  const depositsBtnRef = useRef(null);
  const vehicleBtnRef = useRef(null);
  const goldBtnRef = useRef(null);
  const artBtnRef = useRef(null);
  const familyLoanBtnRef = useRef(null);
  const domainBtnRef = useRef(null);
  const intellectualBtnRef = useRef(null);
  const [selectedAssetType, setSelectedAssetType] = useState(null);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  // console.log("assetId overview page:", assetId);

  const handleAssetTypeClick = (assetType, assetId) => {
    setSelectedAssetType(assetType);
    setSelectedAssetId(assetId);
    const realEstate = ["Land", "Buildings"];
    const deposits = ["Sukanya Samriddhi Yojana"];
    const vehicle = ["Vehicle"];
    const gold = ["Gold And Jewellery"];
    const art = ["Furniture And Art"];
    const familyLoan = ["Loans To Family/Friends"];
    const intellectual = ["Intellectual Property"];
    const domain = ["Domain"];

    if (realEstate.includes(assetType)) {
      realEstateBtnRef.current.click();
    } else if (deposits.includes(assetType)) {
      depositsBtnRef.current.click();
    } else if (vehicle.includes(assetType)) {
      vehicleBtnRef.current.click();
    } else if (gold.includes(assetType)) {
      goldBtnRef.current.click();
    } else if (art.includes(assetType)) {
      artBtnRef.current.click();
    } else if (familyLoan.includes(assetType)) {
      familyLoanBtnRef.current.click();
    } else if (domain.includes(assetType)) {
      domainBtnRef.current.click();
    } else if (intellectual.includes(assetType)) {
      intellectualBtnRef.current.click();
    }
  };

  return (
    <div className="pt-[2rem] pb-[6rem] px-[1rem] bg-gray-100 ">
      <h4 className=" font-[600] text-darkGray text-[1.5rem] m-0">Assets </h4>
      <div  className="flex mt-[1.5rem] border bg-white rounded-xl  grid grid-cols-12 divide-x">
        <div className=" p-[1rem] col-span-3" style={{ cursor: "default", pointerEvents: "none" }}>
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Total Assets Value
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">
            ₹{getData?.data?.total_assets_amount}
          </h4>
        </div>

        <div className="p-[1rem] col-span-2" style={{ cursor: "default", pointerEvents: "none" }}>
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Total Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">
            {getData?.data?.total_assets}
          </h4>
        </div>

        <div className="p-[1rem] col-span-2" style={{ cursor: "default", pointerEvents: "none" }}>
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Active Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">
            {getData?.data?.total_active_assets}
          </h4>
        </div>

        <div className="p-[1rem] col-span-2" style={{ cursor: "default", pointerEvents: "none" }}>
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Idle Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">
            {getData?.data?.total_idle_assets}
          </h4>
        </div>
        <div className="cursor-pointer flex justify-center items-center p-[1rem] col-span-3" >
          <ChooseAsset />
        </div>
      </div>

      {/* Cash and Banks */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1.1rem] m-0">
              Cash and Banks
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
              0 Rs.
            </h4>
          </div>

          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <div>
              <WhiteButton
                disabled={true}
                px={"px-[0.8rem]"}
                py={"py-[0.3rem]"}
                name="Add New"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1.5rem]">
          <div className="rounded-md border-2 border-dashed p-[1rem]">
            <img src={cash} alt="land" className="w-7 h-7 mb-2" />
            <h5 className="text-lightGray font-[600] text-[0.9rem]">Cash</h5>
            <div className="flex justify-end mt-[1.8rem]">
              <button
                onClick={() =>
                  navigate(ASSETS_TYPE_URL.replace(":type", "cash"), 
                )
                }
                // className={`border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1 ${
                //   isDisabled ? "opacity-50 cursor-not-allowed" : ""
                // }`}
                // disabled={isDisabled}
                className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1"
              >
                Add New
              </button>
            </div>{" "}
          </div>
        </div>
      </div>

      {/* Real Estate */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1.1rem] m-0">
              Real Estate
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
              {getData?.data?.total_real_estate} Rs.
            </h4>
          </div>
          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <div>
              <WhiteButton
                onClick={() =>
                  navigate(
                    ASSETS_TYPE_URL.replace(":type", "real-estate"), 
                    { 
                      state: { landId: assetId.Land, buildingId: assetId.Buildings } 
                    }
                  )
                }
                px={"px-[0.8rem]"}
                py={"py-[0.3rem]"}
                name="Add New"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1.5rem]">
          {/* land */}
          {getData?.data?.total_land_details > 0 ? (
            <Link
              to={`${ASSETS_TYPE_URL.replace(":type", "real-estate-land")}?id=${
                assetId.Land
              }`}
              // state={{ landId: landId, particularLandId: item.id }}
              className="no-underline"
            >
              <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
                <img src={landGray} alt="land" className="w-7 h-7 mb-2" />
                <h5 className="text-lightGray font-[600] text-[0.9rem]">
                  Land
                </h5>
                <div className="flex justify-end mt-[1.8rem]">
                  <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                    ₹ {getData?.data?.total_land_details}
                  </h4>
                </div>{" "}
              </div>
            </Link>
          ) : (
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={landGray} alt="land" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">Land</h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button
                  onClick={() => handleAssetTypeClick("Land", assetId.Land)}
                  className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1"
                >
                  Add New
                </button>
              </div>{" "}
            </div>
          )}

          {/* building */}
          {getData?.data?.total_building_details > 0 ? (
            <Link
              to={`${ASSETS_TYPE_URL.replace(
                ":type",
                "real-estate-building"
              )}?id=${assetId.Buildings}`}
              // // state={{ landId: landId, particularLandId: item.id }}

              className="no-underline"
            >
              <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
                <img src={buildingGray} alt="land" className="w-7 h-7 mb-2" />
                <h5 className="text-lightGray font-[600] text-[0.9rem]">
                  Building
                </h5>
                <div className="flex justify-end mt-[1.8rem]">
                  <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                    ₹ {getData?.data?.total_building_details}
                  </h4>
                </div>{" "}
              </div>
            </Link>
          ) : (
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={buildingGray} alt="building" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
                Building
              </h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button
                  onClick={() =>
                    handleAssetTypeClick("Buildings", assetId.Buildings)
                  }
                  className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1"
                >
                  Add New
                </button>
              </div>{" "}
            </div>
          )}
        </div>
      </div>

      {/* Market */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1.1rem] m-0">
              Market
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
              0 Rs.
            </h4>
          </div>
          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <div>
              <WhiteButton
                disabled={true}
                px={"px-[0.8rem]"}
                py={"py-[0.3rem]"}
                name="Add New"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1.5rem]">
          {/* equity */}
          <div className="rounded-md border-2 border-dashed p-[1rem]">
            <img src={equity} alt="land" className="w-7 h-7 mb-2" />
            <h5 className="text-lightGray font-[600] text-[0.9rem]">Equity</h5>
            <div className="flex justify-end mt-[1.8rem]">
              <button
                className={`border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1 ${
                  isDisabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isDisabled}
              >
                Add New
              </button>
            </div>{" "}
          </div>
          {/* mutual fund */}
          <div className="rounded-md border-2 border-dashed p-[1rem]">
            <img src={mutual} alt="land" className="w-7 h-7 mb-2" />
            <h5 className="text-lightGray font-[600] text-[0.9rem]">
              Mutual Fund
            </h5>
            <div className="flex justify-end mt-[1.8rem]">
              <button
                className={`border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1 ${
                  isDisabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isDisabled}
              >
                Add New
              </button>
            </div>{" "}
          </div>

          {/* Bonds and Debentures */}
          <div className="rounded-md border-2 border-dashed p-[1rem]">
            <img src={bonds} alt="land" className="w-7 h-7 mb-2" />
            <h5 className="text-lightGray font-[600] text-[0.9rem]">
              Bonds and Debentures
            </h5>
            <div className="flex justify-end mt-[1.8rem]">
              <button
                className={`border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1 ${
                  isDisabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isDisabled}
              >
                Add New
              </button>
            </div>{" "}
          </div>
        </div>
      </div>

      {/* deposits */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1.1rem] m-0">
              Deposits
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
              {getData?.data?.total_deposits} Rs.
            </h4>
          </div>
          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <div>
              <WhiteButton
                onClick={() =>
                  navigate(ASSETS_TYPE_URL.replace(":type", "deposits"), 
                  { 
                    state: { sukanyaId: assetId["Sukanya Samriddhi Yojhana"] } 
                  }
                )
                }
                px={"px-[0.8rem]"}
                py={"py-[0.3rem]"}
                name="Add New"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1.5rem]">
          {/* fixed deposit */}
          <div className="rounded-md border-2 border-dashed p-[1rem]">
            <img src={fixedD} alt="land" className="w-7 h-7 mb-2" />
            <h5 className="text-lightGray font-[600] text-[0.9rem]">
              Fixed Deposit
            </h5>
            <div className="flex justify-end mt-[1.8rem]">
              <button
                className={`border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1 ${
                  isDisabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isDisabled}
              >
                Add New
              </button>
            </div>{" "}
          </div>
          {/* Provident fund */}
          <div className="rounded-md border-2 border-dashed p-[1rem]">
            <img src={provident} alt="land" className="w-7 h-7 mb-2" />
            <h5 className="text-lightGray font-[600] text-[0.9rem]">
              Provident Fund
            </h5>
            <div className="flex justify-end mt-[1.8rem]">
              <button
                className={`border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1 ${
                  isDisabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isDisabled}
              >
                Add New
              </button>
            </div>{" "}
          </div>

          {/* Term Insurance*/}
          <div className="rounded-md border-2 border-dashed p-[1rem]">
            <img src={termInsurance} alt="land" className="w-7 h-7 mb-2" />
            <h5 className="text-lightGray font-[600] text-[0.9rem]">
              Term Insurance
            </h5>
            <div className="flex justify-end mt-[1.8rem]">
              <button
                className={`border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1 ${
                  isDisabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isDisabled}
              >
                Add New
              </button>
            </div>{" "}
          </div>

          {/* sukanya samriddhi yojana*/}
          {getData?.data?.total_sukanya_samridhi_yojhana ? (
            <Link
              to={`${ASSETS_TYPE_URL.replace(
                ":type",
                "deposits-sukanya-samridhi"
              )}?id=${assetId["Sukanya Samriddhi Yojhana"]}`}
              className="no-underline"
            >
              <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
                <img src={sukanya} alt="land" className="w-7 h-7 mb-2" />
                <h5 className="text-lightGray font-[600] text-[0.9rem]">
                  Sukanya Samriddhi Yojana
                </h5>
                <div className="flex justify-end mt-[1.8rem]">
                  <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                    ₹ {getData?.data?.total_sukanya_samridhi_yojhana}
                  </h4>
                </div>{" "}
              </div>
            </Link>
          ) : (
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={sukanya} alt="land" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
                Sukanya Samriddhi Yojana
              </h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button
                  onClick={() =>
                    handleAssetTypeClick(
                      "Sukanya Samriddhi Yojana",
                      assetId["Sukanya Samriddhi Yojhana"]
                    )
                  }
                  className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1"
                >
                  Add New
                </button>
              </div>{" "}
            </div>
          )}
        </div>
      </div>

      {/* personal assets */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1.1rem] m-0">
              Personal Assets
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
              {getData?.data?.total_personal_assets} Rs.
            </h4>
          </div>
          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <div>
              <WhiteButton
                onClick={() =>
                  navigate(ASSETS_TYPE_URL.replace(":type", "personal-asset"),
                  { 
                    state: { assetId:assetId} 
                  }
                  ) 
                }
                px={"px-[0.8rem]"}
                py={"py-[0.3rem]"}
                name="Add New"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1.5rem]">
          {/* vehicle */}
          {getData?.data?.total_vehicle_details ? (
            <Link
              to={`${ASSETS_TYPE_URL.replace(
                ":type",
                "personal-asset-vehicle"
              )}/?id=${assetId.Vehicle}`}
              className="no-underline"
            >
              <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
                <img src={vehicle} alt="land" className="w-7 h-7 mb-2" />
                <h5 className="text-lightGray font-[600] text-[0.9rem]">
                  Vehicle
                </h5>
                <div className="flex justify-end mt-[1.8rem]">
                  <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                    ₹ {getData?.data?.total_vehicle_details}
                  </h4>
                </div>{" "}
              </div>
            </Link>
          ) : (
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={vehicle} alt="land" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
                Vehicle
              </h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button
                  onClick={() =>
                    handleAssetTypeClick("Vehicle", assetId.Vehicle)
                  }
                  className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1"
                >
                  Add New
                </button>
              </div>{" "}
            </div>
          )}

          {/* Gold and Jewellery */}
          {getData?.data?.total_gold_and_jewellery ? (
            <Link
              to={`${ASSETS_TYPE_URL.replace(
                ":type",
                "personal-asset-gold"
              )}/?id=${assetId["Gold And Jewellery"]}`}
              className="no-underline"
            >
              <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
                <img src={gold} alt="building" className="w-7 h-7 mb-2" />
                <h5 className="text-lightGray font-[600] text-[0.9rem]">
                  Gold and Jewellery
                </h5>
                <div className="flex justify-end mt-[1.8rem]">
                  <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                    ₹ {getData?.data?.total_gold_and_jewellery}
                  </h4>
                </div>{" "}
              </div>
            </Link>
          ) : (
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={gold} alt="building" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
                Gold and Jewellery
              </h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button
                  onClick={() =>
                    handleAssetTypeClick(
                      "Gold And Jewellery",
                      assetId["Gold And Jewellery"]
                    )
                  }
                  className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1"
                >
                  Add New
                </button>
              </div>{" "}
            </div>
          )}

          {/* art */}
          {getData?.data?.total_art_and_collectibles ? (
            <Link
              to={`${ASSETS_TYPE_URL.replace(
                ":type",
                "personal-asset-furniture"
              )}/?id=${assetId["Furniture And Art"]}`}
              className="no-underline"
            >
              <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
                <img src={art} alt="building" className="w-7 h-7 mb-2" />
                <h5 className="text-lightGray font-[600] text-[0.9rem]">
                  Art and Collectibles
                </h5>
                <div className="flex justify-end mt-[1.8rem]">
                  <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                    ₹ {getData?.data?.total_art_and_collectibles}
                  </h4>
                </div>{" "}
              </div>
            </Link>
          ) : (
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={art} alt="building" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
                Art and Collectibles
              </h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button
                  onClick={() =>
                    handleAssetTypeClick(
                      "Furniture And Art",
                      assetId["Furniture And Art"]
                    )
                  }
                  className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1"
                >
                  Add New
                </button>
              </div>{" "}
            </div>
          )}
        </div>
      </div>

      {/* other assets */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[1rem]">
            {" "}
            <h4 className="text-darkGray font-[500] text-[1.1rem] m-0">
              Other Assets
            </h4>
            <h4 className="text-customPurple font-[500] text-[1rem] m-0 p-1 bg-[#FAF8FC] rounded">
              {getData?.data?.total_other_assets} Rs.
            </h4>
          </div>
          <div className="flex gap-[0.8rem]">
            <button className=" text-darkGray text-[0.9rem] font-[500] px-2 py-1">
              See All
            </button>
            <div>
              <WhiteButton
                onClick={() =>
                  navigate(ASSETS_TYPE_URL.replace(":type", "other-asset"),
                {
                  state:{ assetId:assetId}
                }
                )
                }
                px={"px-[0.8rem]"}
                py={"py-[0.3rem]"}
                name="Add New"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-[1.5rem]">
          {/* loan family */}
          {getData?.data?.total_loan_to_family_or_friends ? (
            <Link
              to={`${ASSETS_TYPE_URL.replace(
                ":type",
                "other-asset-loan"
              )}/?id=${assetId["Loans To Family/Friends"]}`}
              className="no-underline"
            >
              <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
                <img src={family} alt="land" className="w-7 h-7 mb-2" />
                <h5 className="text-lightGray font-[600] text-[0.9rem]">
                  Loan Given to Family/Friends
                </h5>
                <div className="flex justify-end mt-[1.8rem]">
                  <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                    ₹ {getData?.data?.total_loan_to_family_or_friends}
                  </h4>
                </div>{" "}
              </div>
            </Link>
          ) : (
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={family} alt="land" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
                Loan Given to Family/Friends
              </h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button
                  onClick={() =>
                    handleAssetTypeClick(
                      "Loans To Family/Friends",
                      assetId["Loans To Family/Friends"]
                    )
                  }
                  className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1"
                >
                  Add New
                </button>
              </div>{" "}
            </div>
          )}

          {/* Intellectual Property */}
          {getData?.data?.total_intellectual_property ? (
            <Link
              to={`${ASSETS_TYPE_URL.replace(
                ":type",
                "other-asset-intellectual"
              )}/?id=${assetId["Intellectual Property"]}`}
              className="no-underline"
            >
              <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
                <img
                  src={intellectual}
                  alt="building"
                  className="w-7 h-7 mb-2"
                />
                <h5 className="text-lightGray font-[600] text-[0.9rem]">
                  Intellectual Property
                </h5>
                <div className="flex justify-end mt-[1.8rem]">
                  <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                    ₹ {getData?.data?.total_intellectual_property}
                  </h4>
                </div>{" "}
              </div>
            </Link>
          ) : (
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={intellectual} alt="building" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
                Intellectual Property
              </h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button
                  onClick={() => handleAssetTypeClick("Intellectual Property", assetId["Intellectual Property"])}
                  className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1"
                >
                  Add New
                </button>
              </div>{" "}
            </div>
          )}

          {/* domain */}

          {getData?.data?.total_domain_details ? (
            <Link
              to={`${ASSETS_TYPE_URL.replace(
                ":type",
                "other-asset-domain"
              )}/?id=${assetId["Domain"]}`}
              className="no-underline"
            >
              <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
                <img src={domain} alt="building" className="w-7 h-7 mb-2" />
                <h5 className="text-lightGray font-[600] text-[0.9rem]">
                  Domain
                </h5>
                <div className="flex justify-end mt-[1.8rem]">
                  <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                    ₹ {getData?.data?.total_domain_details}
                  </h4>
                </div>{" "}
              </div>
            </Link>
          ) : (
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={domain} alt="building" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
                Domain
              </h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button
                  onClick={() => handleAssetTypeClick("Domain", assetId.Domain)}
                  className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1"
                >
                  Add New
                </button>
              </div>{" "}
            </div>
          )}

          {/* software license */}
          <div className="rounded-md border-2 border-dashed p-[1rem]">
            <img src={software} alt="building" className="w-7 h-7 mb-2" />
            <h5 className="text-lightGray font-[600] text-[0.9rem]">
              Software License
            </h5>
            <div className="flex justify-end mt-[1.8rem]">
              <button className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                Add New
              </button>
            </div>{" "}
          </div>
        </div>
      </div>

      <AddNewLand
        estateType={selectedAssetType}
        assetId={selectedAssetId}
        triggerElement={
          <button ref={realEstateBtnRef} className="hidden">
            Open Loan Dialog
          </button>
        }
      />
      <AddNewDepositType
        depositType={selectedAssetType}
        assetId={selectedAssetId}
        triggerElement={
          <button ref={depositsBtnRef} className="hidden">
            Open Loan Dialog
          </button>
        }
      />

      <AddNewVehicle
        assetId={selectedAssetId}
        triggerElement={
          <button ref={vehicleBtnRef} className="hidden">
            Open Loan Dialog
          </button>
        }
      />
      <AddNewGold
        assetId={selectedAssetId}
        triggerElement={
          <button ref={goldBtnRef} className="hidden">
            Open Loan Dialog
          </button>
        }
      />
      <AddNewFurniture
        assetId={selectedAssetId}
        triggerElement={
          <button ref={artBtnRef} className="hidden">
            Open Loan Dialog
          </button>
        }
      />

      <AddNewDomain
        assetId={selectedAssetId}
        triggerElement={
          <button ref={domainBtnRef} className="hidden">
            Open Loan Dialog
          </button>
        }
      />
      <AddNewIntellectual
        assetId={selectedAssetId}
        triggerElement={
          <button ref={intellectualBtnRef} className="hidden">
            Open Loan Dialog
          </button>
        }
      />
      <AddNewLoan
        assetId={selectedAssetId}
        triggerElement={
          <button ref={familyLoanBtnRef} className="hidden">
            Open Loan Dialog
          </button>
        }
      />
    </div>
  );
};

export default AssetsOverViewPage;
