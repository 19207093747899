import React from "react";
import AssetsHeading from "../AssetsHeading";
import cash from "assets/images/assets/cash.svg";
import flexible from "assets/images/assets/flexible.svg";
import simplified from "assets/images/assets/simplified.svg";
import access from "assets/images/assets/access.svg";

const Cash = () => {
  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 ">
      <AssetsHeading asset={"Cash & Banks"}/>
      <div className="mt-[1.5rem] bg-white rounded-xl pt-[3rem] pb-[6rem] text-center flex flex-col items-center">
        <img src={cash} alt="cash" />
        <h4 className="font-[500] text-darkGray text-[1.5rem]">
          Cash and Bank Accounts will be Available Soon
        </h4>
        <h4 className="font-[500] text-lightGray text-[0.8rem]">
          Soon Beyondtax will let you track all your bank accounts at once place
        </h4>
        <div className="mt-[0.8rem]">
          <div className="flex gap-[0.6rem] items-center">
            <img src={access} alt="access" />
            <h4 className="font-[500] text-lightGray text-[0.9rem] m-0">
              Access all your cash in one place
            </h4>
          </div>
          <div className="flex gap-[0.6rem] items-center my-[0.5rem]">
            <img src={simplified} alt="access" />
            <h4 className="font-[500] text-lightGray text-[0.9rem] m-0">
              Simplified withdrawal management
            </h4>
          </div>
          <div className="flex gap-[0.6rem] items-center">
            <img src={flexible} alt="access" />
            <h4 className="font-[500] text-lightGray text-[0.9rem] m-0">
              Flexible financial analysis
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cash;
