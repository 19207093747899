import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import LogoutDialog from "components/loginProfileNavbar/LogoutDialog";
import { useLocation } from "react-router-dom";
import UserTypeDialog from "./UserTypeDialog";
import useGetProfileNavTabs from "./data/userGetProfileNavTabs";
import {
  ASSETS_URL,
  BANK_ACCOUNTS_URL,
  BUSINESS_INCOME_TAX_PROFILE_INFORMATION_URL,
  BUSINESS_INVITES_URL,
  BUSINESS_PROFILE_URL,
  INCOME_TAX_SUMMARY_URL,
  INVITES_URL,
  LIABILITIES_URL,
  REPORTS_URL,
  STAKEHOLDER_LIST,
} from "constants/routes";

// images
import logo from "assets/images/dashboard/logo.svg";
import logout from "assets/images/dashboard/log-out.svg";
import dashImg from "assets/images/dashboardNav/Icon.svg";
import ordersImg from "assets/images/dashboardNav/Group.svg";
import ITImg from "assets/images/dashboardNav/Vector.svg";
import arrow from "assets/images/navbar/chevron-down.svg";
import assetsImg from "assets/images/assets/assets.svg";
import DialogBox from "components/DialogBox/DialogBox";
import AddYourLiability from "../Liabilities/AddYourLiability";
import RemoveLiability from "../Liabilities/RemoveLiability";
import AddPersonalLoanDialog from "../Liabilities/AddLoanTypeDialog";
import AddLoanTypeDialog from "../Liabilities/AddLoanTypeDialog";
import downArrow from "assets/images/assetsAndLiabilities/downArrowIcon.svg"

function ProfileNav() {
  const [tabsWithChildren, setTabsWithChildren] = useState([]);
  const [isProfileActive, setIsProfileActive] = useState(false);
  const [isBusinessProfileActive, setIsBusinessProfileActive] = useState(false);
  const [getData, getError, getIsLoading, getProfileNavTabs] =
    useGetProfileNavTabs();
  const location = useLocation();
  const { income_tax_return_id } = useParams();
  const [activeItem, setActiveItem] = useState(null);
  const [expandedItem, setExpandedItem] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));

  const handleParentClick = (item) => {
    setActiveItem(item.tab_name);
    if (expandedItem.includes(item.tab_name)) {
      setExpandedItem(expandedItem.filter((name) => name !== item.tab_name));
    } else {
      setExpandedItem([...expandedItem, item.tab_name]);
    }
  };

  const handleChildClick = (item) => {
    setActiveItem(item.tab_name);
    console.log(tabPath[item.tab_name], item.tab_name);
  };

  const tabIcons = {
    Dashboard: dashImg,
    Orders: ordersImg,
    Incometax: ITImg,
    Profile: dashImg,
    "Business Profile": dashImg,
    Assets: assetsImg,
    "Liabilities": downArrow
  };

  const tabPath = {
    Dashboard: "/dashboard",
    Orders: "/orders",
    Incometax: "/income-tax",
    Profile: "/income-tax-profile",
    "Tax Summary": (user) =>
      INCOME_TAX_SUMMARY_URL.replace(
        ":income_tax_return_id",
        user.current_income_tax_return_id
      ),
    Reports: REPORTS_URL,
    "Govt.Ids": "/income-tax-profile/govt-ids",
    "Bank Accounts": BANK_ACCOUNTS_URL,
    Invites: INVITES_URL,
    "Tax Information1": "/income-tax-profile/tax-information",
    "Business Profile": BUSINESS_PROFILE_URL,
    "Members & Invites": BUSINESS_INVITES_URL,
    "Tax Information2": BUSINESS_INCOME_TAX_PROFILE_INFORMATION_URL,
    Liabilities: LIABILITIES_URL,
    Assets: ASSETS_URL
  };

  useEffect(() => {
    getProfileNavTabs();
  }, []);

  useEffect(() => {
    if (getData) {
      const data = getData?.data?.results?.reduce((acc, tab) => {
        if (!tab.parent_tab) {
          acc.push({ ...tab, children: [] });
        } else {
          const parentTab = acc.find((parent) => parent.id === tab.parent_tab);
          if (parentTab) {
            if (
              parentTab.tab_name === "Profile" &&
              tab.tab_name === "Tax Information"
            ) {
              tab.tab_name = "Tax Information1";
            } else if (
              parentTab.tab_name === "Business Profile" &&
              tab.tab_name === "Tax Information"
            ) {
              tab.tab_name = "Tax Information2";
            }
            parentTab.children.push(tab);
          }
        }
        return acc;
      }, []);
      if (data) {
        setTabsWithChildren(data);
      }
    }
  }, [getData]);

  useEffect(() => {
    if (location.pathname.includes("income-tax-profile")) {
      setIsProfileActive(true);
    } else {
      setIsProfileActive(false);
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname.includes("/income-tax-business-profile")) {
      setIsBusinessProfileActive(true);
    } else {
      setIsBusinessProfileActive(false);
    }
  }, [location]);

  useEffect(() => {
    const activeTab = Object.keys(tabPath).find((key) => {
      const path =
        typeof tabPath[key] === "function" ? tabPath[key](user) : tabPath[key];
      return location.pathname === path;
    });
    if (activeTab) {
      setActiveItem(activeTab);
      const parentItem = tabsWithChildren.find((parent) => {
        return parent.children.some((child) => {
          return child.tab_name === activeTab;
        });
      });
      if (parentItem) {
        setExpandedItem([...expandedItem, parentItem.tab_name]);
      }
    }
  }, [location, tabsWithChildren]);

  const [open, setOpen] = useState(false);

  const handleDialogBox = () => {
    setOpen(true);
  };

  return (
    <div className="flex flex-col h-screen fixed justify-between">
      <div className="p-[10%]">
        <Link to={"/"}>
          <img src={logo} alt="" />
        </Link>
        <ul className="p-0 mt-[10%] lg:w-[13rem]">
          {tabsWithChildren.map((item) => {
            const isExpanded = expandedItem.includes(item.tab_name);

            // Initially, only show Dashboard, Orders, and Incometax tabs
            const showInitialTabs = [
              "Dashboard",
              "Orders",
              "Incometax",
              "Liabilities",
              "Assets"
            ].includes(item.tab_name);

            // Now only hiding if the URL includes "income-tax-profile"
            if (isProfileActive && item.tab_name !== "Profile") {
              return null; // Hide all tabs except Profile and its children
            }

            if (
              isBusinessProfileActive &&
              item.tab_name !== "Business Profile"
            ) {
              return null; // Hide all tabs except Business Profile and its children
            }

            // Show only the initial set of tabs
            if (
              !isProfileActive &&
              !isBusinessProfileActive &&
              !showInitialTabs
            ) {
              return null; // Hide all tabs except the initial ones
            }

            return (
              <li key={item.tab_name}>
                <Link
                  className={`${
                    activeItem === item.tab_name
                      ? "border-2 border-[#E2E8F0] bg-[#F1F5F9]"
                      : ""
                  }
                   font-[500]  flex gap-2 justify-between items-center text-[1rem] rounded-md leading-[1.5rem] m-2 p-[4%] no-underline text-[#344054] 
                   `}
                  to={
                    typeof tabPath[item.tab_name] === "function"
                      ? tabPath[item.tab_name](user) // Call function if dynamic path
                      : tabPath[item.tab_name]
                  } // Use static path otherwise
                  onClick={() => handleParentClick(item)}
                >
                  <span className="flex gap-2 items-center">
                    <img src={tabIcons[item.tab_name]} />
                    {item.tab_name}
                  </span>
                  {item.children.length > 0 && (
                    <img
                      src={arrow} // Your arrow image path
                      className={`ml-2 transition-transform ${
                        isExpanded ? "rotate-180" : "rotate-0" // Rotate arrow if expanded
                      }`}
                      alt="Arrow"
                    />
                  )}
                </Link>
                {expandedItem.includes(item.tab_name) &&
                  item.children.length > 0 && (
                    <ul className="border-l-4 border-[#e3e8f1] px-1 ml-6">
                      {item.children.map((child) => {
                        return (
                          <Link
                            key={child.tab_name}
                            to={
                              typeof tabPath[child.tab_name] === "function"
                                ? tabPath[child.tab_name](user) // Call function if dynamic path
                                : tabPath[child.tab_name]
                            } // Use static path otherwise
                            onClick={() => handleChildClick(child)}
                            className={`py-2 px-2.5 m-2  rounded-md font-[600] text-[0.9rem] block no-underline text-[#344054] ${
                              activeItem === child.tab_name
                                ? "bg-[#F1F5F9]"
                                : ""
                            } ${child.tab_name === "Personal" ? "hidden" : ""}`}
                          >
                            {child.tab_name === "Tax Information1" ||
                            child.tab_name === "Tax Information2"
                              ? "Tax Information"
                              : child.tab_name}
                          </Link>
                        );
                      })}
                    </ul>
                  )}
              </li>
            );
          })}
        </ul>
        {/* temporary */}
      

        <Link className="no no-underline text-black" to={STAKEHOLDER_LIST}>
          <li
            className={`${
              location.pathname === "/stakeholder-list/"
                ? "border-2 border-[#E2E8F0] bg-[#F1F5F9]"
                : ""
            } font-[500] flex gap-2 text-[1rem] rounded-md leading-[1.5rem] m-2 p-[4%]`}
          >
            Stakeholder list
          </li>
        </Link>
        <Link className="no no-underline text-black" to={"/sample-stakeholder"}>
          <li
            className={`${
              location.pathname === "/sample-stakeholder"
                ? "border-2 border-[#E2E8F0] bg-[#F1F5F9]"
                : ""
            } font-[500] flex gap-2 text-[1rem] rounded-md leading-[1.5rem] m-2 p-[4%]`}
          >
            Sample Stakeholder
          </li>
        </Link>
      </div>

      <p>
        <UserTypeDialog />
      </p>
      <div>
        <hr className="w-[80%] mx-auto" />
        <a className="flex no-underline font-[500] text-[1rem] leading-[1.5rem] text-black justify-evenly p-[10%]">
          <LogoutDialog />
          <img src={logout} />
        </a>
      </div>
    </div>
  );
}

export default ProfileNav;
