import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useEffect, useState } from "react";
import "../../../Liabilities/liabilities.css";
import Button from "components/Button/BtnOrange";
import RedButton from "components/Button/RedButton";
import upload from "assets/images/liabilities/upload.svg";
import deleteIcon from "assets/images/assets/redDelete.svg";

const EditMandateExpense = ({
  manExpenseDate,
  setmanExpenseDate,
  manExpenseType,
  setmanExpenseType,
  manAmtPaid,
  setmanAmtPaid,
  manUploadCertificate,
  setmanUploadCertificate,
  createMandateData,
  setSelectedMandateItem,
  selectedMandateItem,
  triggerElement,
  updateMandateData,
}) => {
  const [errors, setErrors] = useState({});
  const [formValid, setFormValid] = useState(true);
  const [touched, setTouched] = useState(false); // Track whether user has tried to save

  const resetState = () => {
    setmanExpenseDate("");
    setmanExpenseType("");
    setmanAmtPaid("");
    setmanUploadCertificate("");
    setErrors({});
    setTouched(false);
    setFormValid(true);
  };

  useEffect(() => {
    if (!touched) return;
    const newErrors = {};

    const alphabetRegex = /^[A-Za-z\s]+$/;
    const positiveAmountRegex = /^[+]?\d+(\.\d+)?$/;
    if (!manExpenseType || !alphabetRegex.test(manExpenseType)) {
      newErrors.manExpenseType = "Expense Type can have only alphabets.";
    }
    if (
      !manAmtPaid ||
      !positiveAmountRegex.test(manAmtPaid) ||
      parseFloat(manAmtPaid) <= 0
    ) {
      newErrors.manAmtPaid = "Amount Paid must be greater than 0.";
    }
    if (!manExpenseDate) {
      newErrors.manExpenseDate = "Date is required.";
    }
    if (!manUploadCertificate) {
      newErrors.manUploadCertificate = "Invoice is required.";
    }

    setErrors(newErrors);
    setFormValid(Object.keys(newErrors).length === 0);
  }, [
    manExpenseType,
    manAmtPaid,
    touched,
    manUploadCertificate,
    manExpenseDate,
  ]);

  const handleSaveClick = () => {
    setTouched(true);
    if (formValid) {
      createMandateData();
    }
  };

  return (
    <div>
      <DialogBox
        onClose={resetState}
        triggerElement={
          triggerElement ? (
            triggerElement
          ) : (
            <WhiteButton
              px={"px-[0.8rem]"}
              py={"py-[0.3rem]"}
              name="Add another expense"
            />
          )
        }
        heading={"Edit Mandate Expense"}
        content={(handleClose) => (
          <>
            <div className="rounded-md bg-[#F1F5F9] mt-[0.6rem] mb-[14rem]">
              <div className="grid grid-cols-2 px-[1rem] py-[0.7rem]">
                <div className="input-container">
                  <label className="input-label">Expense Type</label>
                  <InputField
                    value={
                      triggerElement
                        ? selectedMandateItem?.expense_type
                        : manExpenseType
                    }
                    onChange={(e) =>
                      triggerElement
                        ? setSelectedMandateItem({
                            ...selectedMandateItem,
                            expense_type: e.target.value,
                          })
                        : setmanExpenseType(e.target.value)
                    }
                    type="select"
                    className="input-box border rounded-md bg-white"
                    options={[
                      { value: "", label: "Select Type" },
                      {
                        value: "maintenance",
                        label: "Maintenance",
                      },
                      { value: "insurance", label: "Insurance" },
                      { value: "repair", label: "Repair" },
                      { value: "tax", label: "Tax" },
                      { value: "other", label: "Other" },
                    ]}
                  />
                  {touched && errors.manExpenseType && (
                    <p className="text-red-500 text-xs">
                      {errors.manExpenseType}
                    </p>
                  )}
                </div>

                <div className="input-container">
                  <label className="input-label">Amount Paid</label>
                  <InputField
                    value={
                      triggerElement
                        ? selectedMandateItem?.amount_paid
                        : manAmtPaid
                    }
                    onChange={(e) =>
                      triggerElement
                        ? setSelectedMandateItem({
                            ...selectedMandateItem,
                            amount_paid: e.target.value,
                          })
                        : setmanAmtPaid(e.target.value)
                    }
                    type="number"
                    placeholder="Enter Amount Paid"
                    className="input-box border rounded-md bg-white"
                  />
                  {touched && errors.manAmtPaid && (
                    <p className="text-red-500 text-xs">{errors.manAmtPaid}</p>
                  )}
                </div>

                <div className="input-container mt-[0.3rem] ">
                  <label className="input-label">Expense Date</label>
                  <InputField
                    value={
                      triggerElement
                        ? selectedMandateItem?.expense_date
                        : manExpenseDate
                    }
                    onChange={(e) =>
                      triggerElement
                        ? setSelectedMandateItem({
                            ...selectedMandateItem,
                            expense_date: e.target.value,
                          })
                        : setmanExpenseDate(e.target.value)
                    }
                    type="date"
                    className="input-box border rounded-md bg-white "
                  />
                  {touched && errors.manExpenseDate && (
                    <p className="text-red-500 text-xs">
                      {errors.manExpenseDate}
                    </p>
                  )}
                </div>
                {/* in edit upload invoice or view invoice */}
                <div className="flex gap-2 mt-[1.7rem]">
                  <InputField
                    type="file"
                    accept=".pdf"
                    id={`file-input-mandate`}
                    className="hidden"
                    onChange={(e) =>
                      triggerElement
                        ? setSelectedMandateItem({
                            ...selectedMandateItem,
                            upload_certificate: e.target.files[0],
                          })
                        : setmanUploadCertificate(e.target.files[0])
                    }
                  />
                  <button
                    onClick={() =>
                      document.querySelector(`#file-input-mandate`).click()
                    }
                    className="text-lightGray bg-white font-[600] text-[0.9rem] border-1 border py-[0.2rem] px-[0.7rem] rounded-md flex gap-2 items-center"
                  >
                    <img src={upload} alt="upload" />
                    Upload Invoice
                  </button>
                  <button>
                    <img
                      src={deleteIcon}
                      alt="delete icon"
                      className="w-11 h-10"
                    />
                  </button>
                  {touched && errors.manUploadCertificate && (
                    <p className="text-red-500 text-xs">
                      {errors.manUploadCertificate}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <RedButton
                name="Remove Expense"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />

              <div className="flex justify-end gap-[1rem]">
                <WhiteButton
                  onClick={handleClose}
                  name="Cancel"
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
                <Button
                  disabled={!formValid}
                  onClick={triggerElement ? updateMandateData : handleSaveClick}
                  name={"Save"}
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
              </div>
            </div>
          </>
        )}
      />
    </div>
  );
};

export default EditMandateExpense;
