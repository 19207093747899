import React, { useEffect, useState } from "react";
import AssetsHeading from "../AssetsHeading";
import WhiteButton from "components/Button/WhiteButton";
import EditDomain from "./Edit/EditDomain";
import { useLocation } from "react-router-dom";
import useGetDomainDetails from "../data/useGetDomainDetails";

const DomainDetailView = () => {
  const location = useLocation();
  const assetId = location.state?.assetId;
  const itemId = location.state?.itemId;
  const itemName = location.state?.itemName;
  const [isReload, setIsReload]= useState(false)
  const [getDomainData, getDomainError, getDomainIsLoading, getDomain] =
    useGetDomainDetails();
  const [detailData, setDetailData]=useState({})
  useEffect(()=>{
    getDomain(`${assetId}/?domain_id=${itemId}`)
  },[])
  useEffect(()=>{
    if(getDomainData?.data){
      setDetailData(getDomainData.data)
      console.log(getDomainData.data)
    }
  },[getDomainData])

  useEffect(()=>{
    if(isReload){
      getDomain(`${assetId}/?domain_id=${itemId}`)
      setIsReload(false)
    }
  },[isReload])

  const openCommercialPdf=(fileUrl)=>{
    window.open(fileUrl, "_blank")
 }
  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 ">
      <AssetsHeading asset={"Other Assets"} asset_type={"Domain"} asset_detail={itemName} assetId={assetId}/>
      <div className="flex gap-2.5 mt-[1.5rem]">
        <div className="border bg-white rounded-xl p-[1rem] w-[40%]">
          <div className="flex justify-between items-center">
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
              Domain Value
            </h4>
            {/* <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Edit" /> */}
            <EditDomain editValue="domainValue" detailData={detailData} setIsReload={setIsReload}/>
          </div>
          <div className="flex justify-between mt-[1rem]">
            <div className="">
              <h4 className="text-customPurple font-[500] text-[1.5rem] ">
                ₹{detailData.purchase_price} Lakhs
              </h4>
              <div className="p-[0.5rem] border-1 border-customPurple bg-[#FAF8FC] rounded-md">
                <h4 className="text-[0.75rem] font-[600] text-customPurple m-0">
                  10 % of Total Assets (₹50.86 Lakhs)
                </h4>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-8 mt-[1rem]">
            <div className="flex flex-col gap-1">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Purchase Price
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.purchase_price} Rs.
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Estimated Sale Value
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.estimated_sale_value} Rs.
              </p>
            </div>
          </div>
        </div>
        <div className="border bg-white rounded-xl p-[1rem] w-[60%]">
          <div className="flex justify-between items-center">
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
              Registry Details
            </h4>
            <div className="flex items-center gap-2">
              <WhiteButton
                px={"px-[0.8rem]"}
                py={"py-[0.3rem]"}
                name="View Document"
              />
              <EditDomain editValue="domainDetails" detailData={detailData} setIsReload={setIsReload}/>
            </div>
          </div>
          <div className="mt-3">
            <div className="flex flex-col gap-1">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Domain Name
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.domain_name}
              </p>
            </div>
          </div>
          <div className="mt-3 flex items-center gap-10">
            <div className="flex flex-col gap-1 w-[25%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Registrar
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.registrar}
              </p>
            </div>
            <div className="flex flex-col gap-1 w-[25%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Auto-Renew on?
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">{detailData.auto_renewal === true ? "Yes": "No"}</p>
            </div>
          </div>
          <div className="mt-3 flex items-center gap-10">
            <div className="flex flex-col gap-1 w-[25%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Purchase Date
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.purchase_date}
              </p>
            </div>
            <div className="flex flex-col gap-1 w-[25%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Expiry Date
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.expiry_date}
              </p>
            </div>
            <div className="flex flex-col gap-1 w-[25%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Reminder
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.set_remainder ? "Added":"Not Added"}
              </p>
            </div>
          </div>
        </div>
      </div>
      {
        detailData?.domain_commercial_information?.length !== 0 ?       <div className="mt-3 border bg-white rounded-xl p-[1rem]">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
              Commercial Users
            </h4>
          </div>
          <div>
            <WhiteButton
              px={"px-[0.8rem]"}
              py={"py-[0.3rem]"}
              name="Add another Device"
            />
          </div>
        </div>
        <div className="rounded-lg border mt-2.5">
          <table className="min-w-full border-collapse">
            <thead className="text-[#64748B] rounded-tl-lg">
              <tr>
                <th className="p-2.5 border-r border-b rounded-tl-lg bg-[#F8FAFC] font-[500] text-[0.8rem] w-[24%]">
                  Business Name
                </th>
                <th className="p-2.5 border-r border-b bg-[#F8FAFC] font-[500] text-[0.8rem] w-[24%]">
                  Payout Frequency
                </th>
                <th className="p-2.5 border-r border-b bg-[#F8FAFC] font-[500] text-[0.8rem] w-[24%]">
                  Estimated Income
                </th>
                <th className="p-2.5 border-b bg-[#F8FAFC] rounded-tr-lg font-[500] text-[0.8rem] w-[27%]">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {
                detailData.domain_commercial_information?.map((item,i)=>{
                  return(
                    <tr key={i}>
                    <td className={`${i>0 ? "border-t":""} p-2.5 border-r text-[#334155] font-[600]`}>
                      {item.associated_business_name}
                    </td>
                    <td className={`${i>0 ? "border-t":""} p-2.5 border-r text-[#334155] font-[600]`}>
                      {item.payout_frequency}
                    </td>
                    <td className={`${i>0 ? "border-t":""} p-2.5 border-r text-[#334155] font-[600]`}>
                      {item.estimated_income} Rs.
                    </td>
                    <td className={`${i>0 ? "border-t":""} p-2.5 text-[#334155] font-[600] flex items-center gap-4`}>
                      <WhiteButton
                        px={"px-[0.8rem]"}
                        py={"py-[0.3rem]"}
                        name="View Agreement"
                        onClick={()=>openCommercialPdf(item.agreement)}
                      />
                      {/* <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Edit" /> */}
                      <EditDomain editValue="domainCommercial" />
                    </td>
                  </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>:
      <div className="mt-3 border bg-white rounded-xl p-[1rem]">
      <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
              Commercial Users
            </h4>
            <span className="text-[#9065B4] font-[600] text-[0.9rem] bg-[#FAF8FC] rounded-md px-1.5 py-1">
              5,00,000 Rs.
            </span>
          </div>
        </div>
        <div className="flex justify-center items-center mt-2">
            <div className="flex flex-col justify-center items-center gap-1.5">
                <button className="px-[0.8rem] py-[0.3rem] text-[#334155] font-[500] bg-[#F1F5F9] rounded-md w-auto">+  Commercial User</button>
                <p className="m-0 text-[#334155] text-[0.75rem] font-[500]">Add Any Commercial Users of this trademark</p>
            </div>
        </div>
      </div>
      }
    </div>
  );
};

export default DomainDetailView;
