import React, { useContext, useEffect, useState } from "react";
import useGetITReturns from "./Data/useGetITReturns";
import IncomeTaxContent from "./IncomeTaxContent";
import ServicesSlider from "./ServicesSlider";

// images
import closedEye from "assets/images/incomeTax/closedEye.png";
import filedIcon from "assets/images/incomeTax/filedIcon.png";
import prev from "assets/images/blogs/prev.svg";
import next from "assets/images/blogs/naxt.svg";
import know_networth from "assets/images/incomeTax/know-networth.png";
import show from "assets/images/incomeTax/show.svg";

import past_returns from "assets/images/incomeTax/past_returns.png";
import { Link, useLocation, useParams } from "react-router-dom";
import { INCOME_TAX_SUMMARY_URL } from "constants/routes";
import Loader from "components/Loader/Loader";
import { ProfileContext } from "../Profile/Layout";

function IncomeTaxReturns(props) {
  const [data, error, isLoading, getITReturns] = useGetITReturns();
  const [ITReturns, setITReturns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const totalPages = Math.ceil(ITReturns.length / itemsPerPage);
  const [showNetWorthDetails, setShowNetWorthDetails] = useState(false);

  useEffect(() => {
    if (data) {
      setITReturns(data.data.data);
    }
  }, [data]);

  useEffect(() => {
    getITReturns();
  }, []);

  const prevClicked = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextClicked = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const TaxReturns = () => {
    const [showPassword, setShowPassword] = useState(false);
    const { getReturnYear } = useContext(ProfileContext);

    const user = JSON.parse(localStorage.getItem("user"));
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedReturns = ITReturns.slice(
      startIndex,
      startIndex + itemsPerPage
    );

    const storingDateInContext = (year) => {
      getReturnYear(year);
    };

    return (
      <div className=" ">
        <div className=" bg-white-body rounded-xl py-[2%] my-[2%] border-1 border-[#F1F2F5]">
          <h5 className=" font-[500] md:text-[1.4rem] text-[1.1rem] px-[2%] leading-[1.6rem] md:text-gray-400">
            PAN Details
          </h5>
          <hr className=" w-[100%]" />
          <div className=" px-[2%] grid grid-cols-12 items-center flex justify-between">
            <div className="md:col-span-8 col-span-12 md:mb-0 mb-[0.5rem]">
              <h5 className="font-[500] text-[.9rem] leading-[1.4rem] text-[#667085]">
                PAN
              </h5>
              <span className="text-[1rem] font-medium text-[#344054]">
                {user?.pan_no}
              </span>
            </div>
            <div className="md:col-span-4 col-span-12">
              <h5 className="text-[#667085] font-[500] text-[.9rem] leading-[1.4rem] ">
                Income Tax Password
              </h5>
              <div className="flex items-center gap-3">
                <input
                  type={showPassword ? "text" : "password"}
                  className="text-[#344054] text-[.9rem] font-medium py-1 px-2 w-[65%] "
                  value="yourpassword765432"
                  readOnly
                />
                <button
                  onClick={togglePasswordVisibility}
                  className=""
                  aria-label={showPassword ? "Hide password" : "Show password"}
                >
                  {showPassword ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-5 h-5 text-gray-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 4.5C7.755 4.5 4.07 7.32 2.5 12c1.57 4.68 5.255 7.5 9.5 7.5s7.93-2.82 9.5-7.5c-1.57-4.68-5.255-7.5-9.5-7.5zm0 10.5c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"
                      />
                    </svg>
                  ) : (
                    <img src={closedEye} alt="closedEye" className="" />
                  )}
                </button>

                <button className=" text-[#9065B4] py-[0.5rem] rounded-md ">
                  <span className="font-[500] text-[.9rem] leading-[1.4rem]">
                    Reset
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white-body border-1 border-[#F1F2F5] rounded-xl ">
          <div className=" w-[100%] grid grid-cols-12 ">
            <table className=" col-span-12 ">
              <tr className="col-span-12  bg-white-body ">
                <th className="rounded-2xl font-[500] md:text-[1.4rem] text-[1.2rem] leading-[1.6rem] md:text-gray-400 p-[2%]">
                  Tax Returns
                </th>
              </tr>
              <hr className=" w-[100%]" />

              <tr className="col-span-12 bg-white-body ">
                {data && (
                  <>
                    {paginatedReturns.map((item, index) => {
                      return (
                        <td
                          key={index}
                          className="py-[2%] font-[600] text-[0.9rem] leading-[1.5rem] text-[#344054] px-4 rounded-bl-2xl flex justify-between items-center hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out hover:text-[#9065B4] overflow-hidden"
                          style={{ transformOrigin: "center" }}
                        >
                          <span className="">
                            File income tax return for&nbsp;&nbsp;
                            <span className="font-[600] text-[1rem]">
                              {item.name}
                            </span>
                          </span>
                          {item.status === "Not Filed" && (
                              // {/* Not Filed */}
                              <Link
                                onClick={() => storingDateInContext(item.name)}
                                to={`${INCOME_TAX_SUMMARY_URL.replace(
                                  ":income_tax_return_id",
                                  item.id
                                )}?isReturnYear=${item.name}`}
                                className="no-underline text-[#F3EFF8]"
                              >
                              <button className=" text-[#F3EFF8] bg-[#9065B4] rounded-md md:px-[2rem] py-[0.25rem] px-[2rem]">
                                Not Filed
                                </button>
                              </Link>
                          )}

                          {item.status === "Filed" && (
                            <button className="flex flex-row gap-1 bg-[#ECFDF3] text-[#027A48] rounded-md px-[2rem] py-[0.25rem]">
                              Filed
                              <img
                                src={filedIcon}
                                alt="filedIcon"
                                className="py-1"
                              />
                            </button>
                          )}
                          {item.status === "Not filed filing Closed" && (
                            <button className=" text-[#667085] bg-[#F9FAFB] rounded-md px-[1rem] py-[0.25rem]">
                              Not filed filing Closed
                            </button>
                          )}
                        </td>
                      );
                    })}
                  </>
                )}
              </tr>
            </table>
          </div>
        </div>

        {/* pagination */}
        <div className=" py-[2%] md:px-0 flex justify-between">
          <div
            className="flex hover:cursor-pointer"
            onClick={() => {
              prevClicked();
            }}
          >
            <img src={prev} alt="" />
            <p className=" md:mb-0 font-semibold p-2 md:mt-0 mt-3">Previous</p>
          </div>
          <div></div>
          <div
            className="flex hover:cursor-pointer"
            onClick={() => {
              nextClicked();
            }}
          >
            <p className=" md:mb-0 font-semibold p-2 md:mt-0 mt-3">Next</p>
            <img src={next} alt="" />
          </div>
        </div>

        {/* know your networth for mobile screens*/}
        {showNetWorthDetails ? (
          <div className="mb-[5rem] bg-white p-[2rem] rounded-md md:hidden">
            <h4 className="font-[500] text-[1.3rem] leading-[1.4rem] flex justify-center gap-2 text-[#344054] text-center">
              ₹ &middot;&middot;&middot;&middot; <img src={show} alt="" />
            </h4>
            <p className="font-[600] text-[1.1rem] leading-[1.5rem] text-[#344054]">
              My Assets
            </p>
            <div className="flex justify-between">
              <h4 className="font-[600] text-[1.1rem] leading-[1.4rem] flex gap-2 text-[#344054] text-center">
                ₹ &middot;&middot;&middot;&middot;{" "}
              </h4>
              <button className="px-[2rem] py-[.4rem] bg-white rounded">
                <p className="text-[#7C549C] font-[500] text-[.9rem] leading-[1.3rem] m-0">
                  ADD
                </p>
              </button>
            </div>
            <p className="font-[600] text-[1.1rem] leading-[1.5rem] text-[#344054]">
              My Liabilities
            </p>
            <div className="flex justify-between">
              <h4 className="font-[600] text-[1.1rem] leading-[1.4rem] flex gap-2 text-[#344054] text-center">
                ₹ &middot;&middot;&middot;&middot;{" "}
              </h4>
              <button className="px-[2rem] py-[.4rem] bg-white rounded">
                <p className="text-[#7C549C] font-[500] text-[.9rem] leading-[1.3rem] m-0">
                  ADD
                </p>
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="md:hidden bg-white mb-[5rem] p-[2rem] rounded-md text-center flex flex-col items-center">
              <img
                src={know_networth}
                alt="know your networth "
                className="mb-3"
              />
              <h4 className="font-[600] text-[1.1rem] text-[#334155]">
                Know your Networth
              </h4>
              <p className="text-[#64748B] font-[400] text-[0.9rem] mb-[1rem]">
                Enter assets and loans to calculate your net worth.
              </p>

              <button
                className="px-3 py-2 rounded text-[#334155] bg-[#F1F5F9] border-1 border-[#E2E8F0] font-[500] text-[1rem]"
                onClick={() => setShowNetWorthDetails(true)}
              >
                Calculate
              </button>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className=" bg-gray-100 min-h-screen md:px-8 px-3 py-2 relative">
      {data ? (
        <>
          {data && (
            <IncomeTaxContent
              pan={data.data.is_pan_verified}
              import_data={data.data.is_data_imported}
            />
          )}
          {data && data.data.is_pan_verified ? (
            <TaxReturns />
          ) : (
            <>
              <div className="bg-white mt-4 p-[5rem] rounded-md text-center flex flex-col items-center">
                <img
                  src={past_returns}
                  alt="import past returns"
                  className="mb-4"
                />
                <h6 className="font-[600] text-[1.1rem] text-[#334155]">
                  Import Past Returns
                </h6>
                <p className="text-[#64748B] font-[400] text-[1rem] mb-[3rem]">
                  Save time and get accurate results by importing your past
                  return data.
                </p>
                <button className="bg-[#F1F5F9] border-[#E2E8F0] border-1 text-[#334155] font-[500] px-4 py-2 rounded-md">
                  Import your data
                </button>
              </div>
              <div className="py-[2%]">
                <div className="bg-white-body rounded-xl py-[2%]">
                  <ServicesSlider />
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {data == undefined ? (
            <>
              <div className="absolute inset-0 bg-white bg-opacity-25  border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
                <div className="loader mt-48"></div>
              </div>
            </>
          ) : (
            <>
              <IncomeTaxContent />
              <div className="bg-white mt-4 p-[5rem] rounded-md text-center flex flex-col items-center">
                <img
                  src={past_returns}
                  alt="import past returns"
                  className="mb-4"
                />
                <h6 className="font-[600] text-[1.1rem] text-[#334155]">
                  Import Past Returns
                </h6>
                <p className="text-[#64748B] font-[400] text-[1rem] mb-[3rem]">
                  Save time and get accurate results by importing your past
                  return data.
                </p>
                <button className="bg-[#F1F5F9] border-[#E2E8F0] border-1 text-[#334155] font-[500] px-4 py-2 rounded-md">
                  Import your data
                </button>
              </div>
              <div className="py-[2%]">
                <div className="bg-white-body rounded-xl py-[2%]">
                  <ServicesSlider />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default IncomeTaxReturns;
