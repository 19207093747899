import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { myContext } from "App";
import { useState, useContext, useEffect } from "react";
// import attachImg from "assets/images/orders/Vector.png";
import uploadImg from "assets/images/dashboard/upload.svg";
import attachImg from "assets/images/orders/attachment-line.svg";
import usePostDocs from "containers/privateRoutes/Orders/data/usePostDocs.js";
import usePostDocsByBT from "../Orders/data/usePostDocsByBT";
import "./Upload.css";
import UploadComponent from "./FileUpload";
import Upload from "antd/es/upload/Upload";
import { Typography } from "antd";
import done from "assets/images/dashboard/_Checkbox base.svg";
import trash from "assets/images/dashboard/trash-2.svg";
import { showErrorToast, showSuccessToast } from "components/toaster/toastHelper";

export default function UploadDialog(props) {
  // console.log(props);
  const [
    docUploadDataBT,
    docUploadBTErrors,
    docUploadBTIsLoading,
    postDocsByBT,
    setSuccessdocUploadDataBT,
    setdocUploadBTErrors,
  ] = usePostDocsByBT();

  const [byUser, setByUser] = useState(true);
  const [byBT, setByBT] = useState(false);

  // console.log(byUser)
  // console.log(byBT)

  const [
    docUploadData,
    docUploadErrors,
    docUploadIsLoading,
    postDocs,
    setSuccessdocUploadData,
    setdocUploadErrors,
  ] = usePostDocs();
  // console.log("props:", props);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [triggerUseEffect, setTriggerUseEffect]=useState(false);
  const [open, setOpen] = useState(false);
  const [aadhar, setAadhar] = useState(null);
  const [Pan, setPAN] = useState(null);
  const [drivingLicense, setDrivingLicense] = useState(null);
  const [bankDetails, setBankDetails] = useState(null);
  const [addressProof, setaddressProof] = useState(null);
  const [aadhaarReq, setAadharReq] = useState(false);
  const [PANReq, setPANReq] = useState(false);
  const [DLReq, setDLReq] = useState(false);
  const [addressProofReq, setAddressProofReq] = useState(false);
  const [bankDetailsReq, setBankDetailsReq] = useState(false);
  const [showError, setShowError]= useState(false)

  useEffect(() => {
    console.log("before posting.....");
    if (submitClicked && byUser) {
      console.log("in posting.....");
      if(aadhar || Pan || drivingLicense || bankDetails){
        postDocs({
          work_order_id: props.workOrderId,
          "documents[0].document_name": "Aadhaar",
          "documents[1].document_name": "PAN",
          "documents[2].document_name": "Driving License",
          "documents[3].document_name": "Bank Details",
          "documents[0].document_file": aadhar,
          "documents[1].document_file": Pan,
          "documents[2].document_file": drivingLicense,
          "documents[3].document_file": bankDetails,
        });
      }else{
         setShowError(true)
      }
    }
  }, [submitClicked, byUser, triggerUseEffect]);

  console.log("submitClicked", submitClicked)

  useEffect(() => {
    if (docUploadData) {
      showSuccessToast("Documents uploaded successfully");
      handleClose();
    }
  }, [docUploadData]);

  useEffect(() => {
    if (docUploadErrors) {
      showErrorToast("Error in Uploading");
    }
  }, [docUploadErrors]);

  // useEffect(() => {
  //   if (submitClicked && byBT) {
  //     postDocsByBT({
  //       work_order_id: props.workOrderId,
  //       "documents[0].document_name": "Aadhaar",
  //       "documents[1].document_name": "PAN",
  //       "documents[2].document_name": "Driving License",
  //       "documents[3].document_name": "Bank Details",
  //       "documents[0].document_file": aadhar,
  //       "documents[1].document_file": Pan,
  //       "documents[2].document_file": drivingLicense,
  //       "documents[3].document_file": bankDetails,
  //     });
  //   }
  // }, [submitClicked, byBT]);


  useEffect(() => {
    if (props.docList.includes("Aadhaar")) {
      setAadharReq(true);
    }
    if (props.docList.includes("PAN")) {
      setPANReq(true);
    }
    if (props.docList.includes("Driving License")) {
      setDLReq(true);
    }
    if (props.docList.includes("Bank Details")) {
      setBankDetailsReq(true);
    }
    if (props.docList.includes("Address Proof")) {
      setAddressProofReq(true);
    }
  }, [props.docList]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAadharChange = (info) => {
    if (info.file.status === "done") {
      // File uploaded successfully, update state with the uploaded file
      setAadhar(info.aadhar.originFileObj);
    } else if (info.aadhar?.status === "removed") {
      // File removed, reset the state
      setAadhar(null);
    }
  };

  return (
    <>
      <button onClick={handleClickOpen} className="flex gap-2 items-center ">
        <img src={uploadImg} alt="" />
        <span className="text-[#9065B4] ">Upload</span>
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { width: "50%", height: "50%", borderRadius: "4%" },
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
          },
        }}
      >
        <div className="flex justify-between">
          <DialogTitle>
            <h5 className="m-0">Documents Upload</h5>
          </DialogTitle>

          <button onClick={handleClose} className=" px-3">
            x
          </button>
        </div>
        <DialogContent
        //    className='flex justify-center items-center'
        >
          <header className="grid grid-cols-12  mb-4">
            <div className=" col-span-10 grid grid-cols-12">
              <div
                onClick={() => {
                  if (byUser === false) {
                    // setByUser(!byUser);
                    setByUser(true)
                    // setByBT(!byBT);
                    setByBT(false)
                  }
                }}
                className={`${
                  byUser
                    ? "bg-purple-light-shade border-b-2 border-purple-900"
                    : ""
                } col-span-6 hover:cursor-pointer`}
              >
                <h6
                  className=" text-gray-500 text-center pt-3"
                >
                  Uploaded by me
                </h6>
                <hr className=" mt-[1rem] mb-0" />
              </div>
              <div
                onClick={() => {
                  if (byBT === false) {
                    // setByUser(!byUser);
                    setByUser(false)
                    // setByBT(!byBT);
                    setByBT(true)
                  }
                }}
                className={`${
                  byBT
                    ? "bg-purple-light-shade border-b-2 border-purple-900"
                    : ""
                } col-span-6 hover:cursor-pointer`}
              >
                <h6
                  className=" text-gray-500 text-center pt-3"
                >
                  Uploaded by Beyondtax
                </h6>
                <hr className=" mt-[1rem] mb-0" />
              </div>
            </div>
          </header>
           {
            byUser ? (<>
             <form>
            <div className="grid grid-cols-12 gap-2">
              {/* {props.docList && props.docList.map((data,i)=>{
         return(     <div className="flex col-span-8 justify-between">
              <span>{data}</span>
              <input
                className="hidden"
                id="attachFile"
                type="file"
                onChange={(e) => {
                 setUploadedDoc1(e.target.files[0]);
                 
                }}
              />
              <label  for="attachFile" name='cover_image' className=" rounded bg-purple-100 text-purple-900 py-1 flex text-sm px-3  gap-2">
                <img src={attachImg} className=" w-3 h-4 " alt="" /> Attach file
              </label>
            </div>)
            })} */}
              {/* {console.log(aadhar)} */}
              {/* <UploadComponent /> */}
              {aadhaarReq && (
                <>
                  <div className="flex col-span-10 justify-between">
                    {aadhar === null && (
                      <>
                        <span>Aadhar</span>
                        <Upload
                          // className="col-span-12 "
                          // action={setAadhar()}
                          //  onChange={handleAadharChange}
                          listType="picture"
                          maxCount={1}
                          progress={{ strokeColor: "blue", strokeWidth: 4 }}
                          showRemoveIcon={true}
                          onRemove={true}
                          // customRequest={{}}
                          beforeUpload={(file) => {
                            setAadhar(file);
                            console.log(aadhar);
                          }}
                          fileList={
                            aadhar ? [{ uid: "-1", name: aadhar.name }] : []
                          }
                        >
                          <label
                            onClick={() => {
                              // handleUpload();
                            }}
                            // for="aadhar"
                            // name="cover_image"
                            className=" rounded bg-purple-100 text-purple-900 py-1 flex text-sm px-3  gap-2"
                          >
                            <img src={attachImg} className=" w-3 h-4 " alt="" />{" "}
                            Attach file
                          </label>
                        </Upload>
                      </>
                    )}
                    {aadhar && (
                      <>
                        <div className="border-1 w-[100%] border-purple-900 rounded-md p-2">
                          <div className="flex justify-between">
                            {" "}
                            <p className=" m-0 text-[#344054] font-[500] text-[0.8rem] leading-[1.3rem]">
                              {aadhar.name}
                            </p>
                            <img src={done} alt="" />
                          </div>
                          <div className="flex justify-between">
                            {" "}
                            <p className=" m-0 text-gray-500 font-[400] text-[0.8rem] leading-[1.3rem]">
                              100% uploaded
                            </p>
                            <img
                              onClick={() => {
                                setAadhar(null);
                              }}
                              src={trash}
                              alt=""
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {/* <input
                      className="hidden"
                      id="aadhar"
                      type="file"
                      onChange={(e) => {
                        setAadhar(e.target.files[0]);
                        console.log(aadhar);
                      }}
                    />
                    <label
                      onClick={() => {
                        // handleUpload();
                      }}
                      for="aadhar"
                      name="cover_image"
                      className=" rounded bg-purple-100 text-purple-900 py-1 flex text-sm px-3  gap-2"
                    >
                      <img src={attachImg} className=" w-3 h-4 " alt="" />{" "}
                      Attach file
                    </label> */}
                  </div>
                </>
              )}
              {PANReq && (
                <>
                  <div className="flex col-span-10 justify-between">
                    {Pan === null && (
                      <>
                        <span>PAN</span>
                        <Upload
                          listType="picture"
                          className="col-span-12 "
                          // action={setAadhar()}
                          //  onChange={handleAadharChange}
                          beforeUpload={(file) => {
                            setPAN(file);
                            console.log(Pan);
                          }}
                          fileList={Pan ? [{ uid: "-1", name: Pan.name }] : []}
                        >
                          <label
                            onClick={() => {
                              // handleUpload();
                            }}
                            // for="aadhar"
                            // name="cover_image"
                            className=" rounded bg-purple-100 text-purple-900 py-1 flex text-sm px-3  gap-2"
                          >
                            <img src={attachImg} className=" w-3 h-4 " alt="" />{" "}
                            Attach file
                          </label>
                        </Upload>
                      </>
                    )}

                    {Pan && (
                      <>
                        <div className="border-1 w-[100%] border-purple-900 rounded-md p-2">
                          <div className="flex justify-between">
                            {" "}
                            <p className=" m-0 text-[#344054] font-[500] text-[0.8rem] leading-[1.3rem]">
                              {Pan.name}
                            </p>
                            <img src={done} alt="" />
                          </div>
                          <div className="flex justify-between">
                            {" "}
                            <p className=" m-0 text-gray-500 font-[400] text-[0.8rem] leading-[1.3rem]">
                              100% uploaded
                            </p>
                            <img
                              onClick={() => {
                                setPAN(null);
                              }}
                              src={trash}
                              alt=""
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {/* <input
                      className="hidden"
                      id="pan"
                      type="file"
                      onChange={(e) => {
                        setPAN(e.target.files[0]);
                      }}
                    />
                    <label
                      for="pan"
                      name="cover_image"
                      className=" rounded bg-purple-100 text-purple-900 py-1 flex text-sm px-3  gap-2"
                    >
                      <img src={attachImg} className=" w-3 h-4 " alt="" />{" "}
                      Attach file
                    </label> */}
                  </div>
                </>
              )}{" "}
              {DLReq && (
                <>
                  <div className="flex col-span-10 justify-between">
                    {drivingLicense === null && (
                      <>
                        <span>Driving License</span>
                        <Upload
                          listType="picture"
                          className="col-span-12 "
                          // action={setAadhar()}
                          //  onChange={handleAadharChange}
                          beforeUpload={(file) => {
                            setDrivingLicense(file);
                            console.log(drivingLicense);
                          }}
                          fileList={
                            drivingLicense
                              ? [{ uid: "-1", name: drivingLicense.name }]
                              : []
                          }
                        >
                          <label
                            onClick={() => {
                              // handleUpload();
                            }}
                            // for="aadhar"
                            // name="cover_image"
                            className=" rounded bg-purple-100 text-purple-900 py-1 flex text-sm px-3  gap-2"
                          >
                            <img src={attachImg} className=" w-3 h-4 " alt="" />{" "}
                            Attach file
                          </label>
                        </Upload>
                      </>
                    )}

                    {drivingLicense && (
                      <>
                        <div className="border-1 w-[100%] border-purple-900 rounded-md p-2">
                          <div className="flex justify-between">
                            {" "}
                            <p className=" m-0 text-[#344054] font-[500] text-[0.8rem] leading-[1.3rem]">
                              {drivingLicense.name}
                            </p>
                            <img src={done} alt="" />
                          </div>
                          <div className="flex justify-between">
                            {" "}
                            <p className=" m-0 text-gray-500 font-[400] text-[0.8rem] leading-[1.3rem]">
                              100% uploaded
                            </p>
                            <img
                              onClick={() => {
                                setDrivingLicense(null);
                              }}
                              src={trash}
                              alt=""
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {/* <input
                      className="hidden"
                      id="DL"
                      type="file"
                      onChange={(e) => {
                        setDrivingLicense(e.target.files[0]);
                      }}
                    />
                    <label
                      for="DL"
                      name="cover_image"
                      className=" rounded bg-purple-100 text-purple-900 py-1 flex text-sm px-3  gap-2"
                    >
                      <img src={attachImg} className=" w-3 h-4 " alt="" />{" "}
                      Attach file
                    </label> */}
                  </div>
                </>
              )}{" "}
              {addressProofReq && (
                <>
                  <div className="flex col-span-10 justify-between">
                    {addressProof === null && (
                      <>
                        <span>Address Proof</span>
                        <Upload
                          listType="picture"
                          className="col-span-12 "
                          // action={setAadhar()}
                          //  onChange={handleAadharChange}
                          beforeUpload={(file) => {
                            setaddressProof(file);
                            console.log(addressProof);
                          }}
                          fileList={
                            addressProof
                              ? [{ uid: "-1", name: addressProof.name }]
                              : []
                          }
                        >
                          <label
                            onClick={() => {
                              // handleUpload();
                            }}
                            // for="aadhar"
                            // name="cover_image"
                            className=" rounded bg-purple-100 text-purple-900 py-1 flex text-sm px-3  gap-2"
                          >
                            <img src={attachImg} className=" w-3 h-4 " alt="" />{" "}
                            Attach file
                          </label>
                        </Upload>
                      </>
                    )}

                    {addressProof && (
                      <>
                        <div className="border-1 w-[100%] border-purple-900 rounded-md p-2">
                          <div className="flex justify-between">
                            {" "}
                            <p className=" m-0 text-[#344054] font-[500] text-[0.8rem] leading-[1.3rem]">
                              {addressProof.name}
                            </p>
                            <img src={done} alt="" />
                          </div>
                          <div className="flex justify-between">
                            {" "}
                            <p className=" m-0 text-gray-500 font-[400] text-[0.8rem] leading-[1.3rem]">
                              100% uploaded
                            </p>
                            <img
                              onClick={() => {
                                setaddressProof(null);
                              }}
                              src={trash}
                              alt=""
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {/* <input
                      className="hidden"
                      id="AddrProof"
                      type="file"
                      onChange={(e) => {
                        setaddressProof(e.target.files[0]);
                      }}
                    />
                    <label
                      for="AddrProof"
                      name="cover_image"
                      className=" rounded bg-purple-100 text-purple-900 py-1 flex text-sm px-3  gap-2"
                    >
                      <img src={attachImg} className=" w-3 h-4 " alt="" />{" "}
                      Attach file
                    </label> */}
                  </div>
                </>
              )}{" "}
              {bankDetailsReq && (
                <>
                  <div className="flex col-span-10 justify-between">
                    {bankDetails === null && (
                      <>
                        <span>Bank Details</span>
                        <Upload
                          listType="picture"
                          className="col-span-12 "
                          // action={setAadhar()}
                          //  onChange={handleAadharChange}
                          beforeUpload={(file) => {
                            setBankDetails(file);
                            console.log(bankDetails);
                          }}
                          fileList={
                            bankDetails
                              ? [{ uid: "-1", name: bankDetails.name }]
                              : []
                          }
                        >
                          <label
                            onClick={() => {
                              // handleUpload();
                            }}
                            // for="aadhar"
                            // name="cover_image"
                            className=" rounded bg-purple-100 text-purple-900 py-1 flex text-sm px-3  gap-2"
                          >
                            <img src={attachImg} className=" w-3 h-4 " alt="" />{" "}
                            Attach file
                          </label>
                        </Upload>
                      </>
                    )}

                    {bankDetails && (
                      <>
                        <div className="border-1 w-[100%] border-purple-900 rounded-md p-2">
                          <div className="flex justify-between">
                            {" "}
                            <p className=" m-0 text-[#344054] font-[500] text-[0.8rem] leading-[1.3rem]">
                              {bankDetails.name}
                            </p>
                            <img src={done} alt="" />
                          </div>
                          <div className="flex justify-between">
                            {" "}
                            <p className=" m-0 text-gray-500 font-[400] text-[0.8rem] leading-[1.3rem]">
                              100% uploaded
                            </p>
                            <img
                              onClick={() => {
                                setBankDetails(null);
                              }}
                              src={trash}
                              alt=""
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {/* <input
                      className="hidden"
                      id="bankDet"
                      type="file"
                      onChange={(e) => {
                        setBankDetails(e.target.files[0]);
                      }}
                    />
                    <label
                      for="bankDet"
                      name="cover_image"
                      className=" rounded bg-purple-100 text-purple-900 py-1 flex text-sm px-3  gap-2"
                    >
                      <img src={attachImg} className=" w-3 h-4 " alt="" />{" "}
                      Attach file
                    </label> */}
                  </div>
                </>
              )}
              {/* <div className="flex col-span-10 justify-between">
              <span>PAN Card</span>
              <input
                className="hidden"
                id="attachFile"
                type="file"
                onChange={(e) => {
                 setPAN(e.target.files[0]);
                }}
              />
              <label  for="attachFile" className=" rounded-lg forBgcolor text-blue-600 py-1 flex text-sm px-3  gap-2">
                <img src={attachImg} className=" w-3 h-4 " alt="" /> Attach file
              </label>
            </div>
            <div className="flex col-span-10 justify-between">
              <span>Driving license</span>
              <input
                className="hidden"
                id="attachFile"
                type="file"
                onChange={(e) => {
                  setDrivingLicense(e.target.files[0]);
                }}
              />
              <label  for="attachFile" className=" rounded-lg forBgcolor text-blue-600 py-1 flex text-sm px-3  gap-2">
                <img src={attachImg} className=" w-3 h-4 " alt="" /> Attach file
              </label>
            </div>
            <div className="flex col-span-8 justify-between">
              <span>Bank details</span>
              <input
                className="hidden"
                id="attachFile"
                type="file"
                onChange={(e) => {
                  setBankDetails(e.target.files[0]);
                }}
              />
              <label  for="attachFile" className=" rounded-lg forBgcolor text-blue-600 py-1 flex text-sm px-3  gap-2">
                <img src={attachImg} className=" w-3 h-4 " alt="" /> Attach file
              </label>
            </div>
            <div className="flex col-span-8 justify-between">
              <span>Business address Proof</span>
              <input
                className="hidden"
                id="attachFile"
                type="file"
                onChange={(e) => {
                  setaddressProof(e.target.files[0]);
                }}
              />
              <label  for="attachFile" className=" rounded-lg forBgcolor text-blue-600 py-1 flex text-sm px-3  gap-2">
                <img src={attachImg} className=" w-3 h-4 " alt="" /> Attach file
              </label>
            </div> */}
            </div>
          </form>
          {
            showError && <p className="m-0 text-red-500 mt-2">Upload any file to click Submit button.</p>
          }
          <div className="flex justify-end ">
            <button
              className="bg-purple-dark-shade text-white px-[1rem] py-[.5rem] rounded"
              onClick={() => {
                setSubmitClicked(true);
                setTriggerUseEffect(!triggerUseEffect)
                // handleUpload();
              }}
            >
              Submit
            </button>
          </div>
            </>):(<>
            <p>Info from backend.</p>
            </>)
           }
        </DialogContent>
      </Dialog>
    </>
  );
}

// import { useRef } from 'react';
// import './styles.css';
// export const FileUploader = ({handleFile}) => {
//   // Create a reference to the hidden file input element
//   const hiddenFileInput = useRef(null);

//   // Programatically click the hidden file input element
//   // when the Button component is clicked
//   const handleClick = event => {
//     hiddenFileInput.current.click();
//   };
//   // Call a function (passed as a prop from the parent component)
//   // to handle the user-selected file
//   const handleChange = event => {
//     const fileUploaded = event.target.files[0];
//     handleFile(fileUploaded);
//   };
// return (
//     <>
//       <button className="button-upload" onClick={handleClick}>
//         Upload a file
//       </button>
//       <input
//         type="file"
//         onChange={handleChange}
//         ref={hiddenFileInput}
//         style={{display: 'none'}} // Make the file input element invisible
//       />
//     </>
//   );
