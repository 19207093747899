import React, { useEffect, useState, useContext } from "react";
import OrderDialog from "components/loginProfileNavbar/PostOrderDialog";
import useGetUser from "containers/services/Data/useGetUserDetails";
import { myContext } from "App";
import available from "assets/images/orders/available.svg";
import inprocess from "assets/images/orders/inprocess.svg";
import cancelled from "assets/images/orders/canceled.svg";
import useGetOrderDetails from "./data/useGetOrderDetails";
import UploadDialog from "containers/privateRoutes/OrderPage/UploadDialog";
import PayDialog from "./PayDialog";
import ConfirmDialog from "containers/privateRoutes/Orders/ConfirmationDialog";
import useGetDownload from "containers/privateRoutes/Orders/data/useGetDownloadDoc";
import prev from "assets/images/blogs/prev.svg";
import next from "assets/images/blogs/naxt.svg";

function DashboardContent() {
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [phnNo, setPhnNo] = useState("");
  const [downloadClick, setDownloadClick] = useState(false);
  const [workOrderId, setWorkOrderId] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [BusinessType, setBusinessType] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessMobNumber, setBusinessMobNumber] = useState("");
  const [businessProfile, setBusinessProfile] = useState(false);

  const { isLoggedIn } = useContext(myContext);

  const [UserData, userError, userIsLoading, getUser] = useGetUser();
  const [downloadData, downloadError, downloadIsLoading, getDownload] =
    useGetDownload();
  const [orderData, orderError, orderIsLoading, getOrderDetails] =
    useGetOrderDetails();

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if(user?.client_type !== 1){
      setBusinessProfile(true);

    }
  }, [])


  const statusColor = [available, inprocess, cancelled];

  const [orderResults, setOrderResults] = useState([]);

  useEffect(() => {
    if (isLoggedIn) {
      getOrderDetails();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (orderData) {
      setOrderResults(orderData.data.results);
    }
  }, [orderData]);

  useEffect(() => {
    if (downloadClick) {
      getDownload({ woId: workOrderId });
    }
  }, [downloadClick]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const totalPages = Math.ceil(orderResults.length / itemsPerPage);
  const prevClicked = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextClicked = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedReturns = orderResults.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  return (
    <div >
        <div
          className="
     bg-gray-100  px-[4%] pt-[8%] min-h-screen"
        >
          <div className="flex justify-between my-4">
            <h4 className=" font-[500] text-[1.4rem] leading-[2.7rem]">
              Dashboard
            </h4>
            <OrderDialog getOrderDetails={getOrderDetails}/>
          </div>
          <div className=" bg-white-body rounded-xl py-[2%] my-[2%]">
            <h5 className=" font-[500] text-[1.4rem] px-[2%] leading-[1.6rem] text-gray-400">
              Personal Details
            </h5>
            <hr className=" w-[100%]" />
            {businessProfile ? (
              <div className="grid px-[2%] grid-cols-12">
                <div className=" col-span-3">
                  <h6 className="text-gray-400 font-[500] text-[0.8rem] leading-[1rem]">
                    NAME
                  </h6>
                  <p className=" font-[500] text-[1rem] leading-[1.5rem] py-2 text-gray-800 ">
                    {/* {businessName} */}
                    {user?.business_name}
                  </p>
                </div>
                <div className=" col-span-2">
                  <h6 className="text-gray-400 font-[500] text-[0.8rem] leading-[1rem]">
                    DOB
                  </h6>
                  <p className=" font-[500] text-[1rem] leading-[1.5rem] py-2 text-gray-800 ">
                    {user?.date_of_birth}
                  </p>
                </div>
                <div className=" col-span-3">
                  <h6 className="text-gray-400 font-[500] text-[0.8rem] leading-[1rem]">
                    Mobile Number
                  </h6>
                  <p className=" font-[500] text-[1rem] leading-[1.5rem] py-2 text-gray-800 ">
                    {user?.mobile_number}
                  </p>
                </div>
                <div className=" col-span-3 ">
                  <h6 className="text-gray-400 font-[500] text-[0.8rem] leading-[1rem]">
                    EMAIL ID
                  </h6>
                  <p className=" font-[500] text-[1rem] leading-[1.5rem] py-2 text-gray-800 ">
                    {user?.email}
                  </p>
                </div>
              </div>
            ) : (
              <div className="grid px-[2%] grid-cols-12">
                <div className=" col-span-3">
                  <h6 className="text-gray-400 font-[500] text-[0.8rem] leading-[1rem]">
                    NAME
                  </h6>
                  <p className=" font-[500] text-[1rem] leading-[1.5rem] py-2 text-gray-800 ">
                    {user?.full_name}
                  </p>
                </div>
                <div className=" col-span-2">
                  <h6 className="text-gray-400 font-[500] text-[0.8rem] leading-[1rem]">
                    DOB
                  </h6>
                  <p className=" font-[500] text-[1rem] leading-[1.5rem] py-2 text-gray-800 ">
                    {user?.date_of_birth}
                  </p>
                </div>
                <div className=" col-span-3">
                  <h6 className="text-gray-400 font-[500] text-[0.8rem] leading-[1rem]">
                    Mobile Number
                  </h6>
                  <p className=" font-[500] text-[1rem] leading-[1.5rem] py-2 text-gray-800 ">
                    {user?.mobile_number}
                  </p>
                </div>
                <div className=" col-span-3 ">
                  <h6 className="text-gray-400 font-[500] text-[0.8rem] leading-[1rem]">
                    EMAIL ID
                  </h6>
                  <p className=" font-[500] text-[1rem] leading-[1.5rem] py-2 text-gray-800 ">
                    {user?.email}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="pb-4 relative">
              {orderIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25  border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
            <div className="bg-white-body rounded-xl ">
              <div className=" w-[100%] grid grid-cols-12 ">
                <table className=" col-span-12">
                  <tr className=" tableData col-span-12  bg-white-body">
                    <th className=" font-[500] text-[1.4rem] leading-[1.6rem] text-gray-400 whitespace-nowrap  rounded-2xl p-[2%] ">
                      Recent Transactions
                    </th>
                  </tr>
                  <tr className=" bg-gray-400 headingRow col-span-12 lg:w-full md:w-full ">
                    <th className="py-[2%] px-4   ">WO</th>
                    <th className="py-[2%] px-4  ">SERVICE NAME</th>
                    <th className="py-[2%] px-4  ">DATE</th>
                    <th className="py-[2%] px-4  ">AMOUNT</th>
                    <th className="py-[2%] px-4  ">STATUS</th>
                  </tr>

                  {orderResults && (
                    <>
                      {paginatedReturns.map((data) => {
                        return (
                          <>
                            {" "}
                            <tr className="tableData col-span-12 bg-white-body rounded-bl-2xl rounded-br-2xl">
                              <td className="py-[2%] font-[500] text-[0.9rem] leading-[1.5rem] text-gray-800 px-4 rounded-bl-2xl col-span-2">
                                {data.id}
                              </td>
                              <td className="py-[2%] font-[500] text-[0.9rem] leading-[1.5rem] text-gray-800 px-4  col-span-4 ">
                                {data.service_name}
                              </td>
                              <td className="py-[2%] font-[500] text-[0.9rem] leading-[1.5rem] text-gray-800 px-4  col-span-2 ">
                                {" "}
                                {data.created_at}
                              </td>
                              <td className="py-[2%] font-[500] text-[0.9rem] leading-[1.5rem] text-gray-800 px-4  col-span-2">
                                Rs {data.amount_paid}
                              </td>

                              <td className="py-[2%] font-[500] text-[0.9rem] leading-[1.5rem] text-gray-800 px-4 rounded-br-2xl col-span-2">
                                {data.status === 1 && <span>Requested</span>}
                                {data.status === 2 && (
                                  <UploadDialog
                                    workOrderId={data.id}
                                    docList={data.required_documents_list}
                                  />
                                )}
                                {data.status === 3 && (
                                  <>
                                    <div className="flex gap-2">
                                      <img src={statusColor[1]} />
                                      <span className=" whitespace-nowrap">
                                        In Process
                                      </span>
                                    </div>
                                  </>
                                )}
                                {data.status === 4 && <PayDialog />}
                                {data.status === 5 && (
                                  <a
                                    className="text-black no-underline"
                                    href={downloadData?.data?.document_url}
                                  >
                                    <span
                                      onClick={() => {
                                        setDownloadClick(true);
                                        setWorkOrderId(data.id);
                                      }}
                                    >
                                      <ConfirmDialog
                                        href={downloadData?.data?.document_url}
                                      />
                                    </span> 
                                  </a>
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </>
                  )}
                </table>
              </div>
            </div>

            {/* pagination */}
            <div className=" py-[2%] flex justify-between mb-14">
              <div
                className="flex hover:cursor-pointer"
                onClick={() => {
                  prevClicked();
                }}
              >
                <img src={prev} alt="" />
                <p className=" mb-0 font-semibold p-2">Previous</p>
              </div>
              <div></div>
              <div
                className="flex hover:cursor-pointer"
                onClick={() => {
                  nextClicked();
                }}
              >
                <p className=" mb-0 font-semibold p-2">Next</p>
                <img src={next} alt="" />
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default DashboardContent;
