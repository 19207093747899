import React, { useEffect, useRef, useState } from "react";
import Glide from "@glidejs/glide";
import docImg from "assets/images/Serviceimages/Frame 1000000961.svg";
import next from 'assets/images/dashboard/nextDoc.svg'
import prev from 'assets/images/dashboard/prevDoc.svg'

const DocSlider = (props) => {
  const glideRef = useRef(null);

  useEffect(() => {
    // Initialize Glide.js when the component mounts
    const glide = new Glide(glideRef.current, {
      //   type: 'slider', // Type of the slider (carousel, slider, carousel-3d, or slider-3d)
      perView: 1.5, // Number of slides to show per view
      focusAt: "center", // Set where the slider should focus
      gap: 16, // Space between slides (in pixels)
      autoplay: 3000,
    });

    glide.mount(); // Mount the Glide.js instance

    // Clean up the Glide.js instance when the component unmounts
    return () => {
      glide.destroy();
    };
  }); // Run this effect only once when the component mounts

  return (
    <>
      <div className="glide w-[100%]" ref={glideRef}>
        {/* <p>sample</p> */}
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            <li>    <i className="glide__slide ">
              <div className="bg-[#3BC5C599] h-60 px-3 flex flex-col-reverse   mr-[2%] rounded-2xl ">

                <img className=" " src={docImg} />
              </div>
            </i></li>
            <li>    <i className="glide__slide ">
              <div className="bg-[#3BC5C599] h-60 px-3 flex flex-col-reverse   mr-[2%] rounded-2xl ">

                <img className=" " src={docImg} />
              </div>
            </i></li>
            <li>    <i className="glide__slide ">
              <div className="bg-[#3BC5C599] h-60 px-3 flex flex-col-reverse   mr-[2%] rounded-2xl ">

                <img className=" " src={docImg} />
              </div>
            </i></li>
        
          
          </ul>
        </div>
        <div className="glide__arrows flex flex-row-reverse px-[2%] " data-glide-el="controls">
      <button className="glide__arrow glide__arrow--right  mr-[.5rem] my-2" data-glide-dir=">">
      <img src={next} alt=""/>
       </button>
       <button className="glide__arrow glide__arrow--left mr-[.5rem] my-2" data-glide-dir="<">
      <img src={prev} alt=""/>
       </button>
     
     </div>
      </div>
    </>
  );
};

export default DocSlider;

