import Button from "components/Button/BtnOrange";
import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useRef, useState } from "react";

import land from "assets/images/assets/land.svg";
import building from "assets/images/assets/building.svg";
import AddNewLand from "./Add/AddNewLand";
import useGetCategoryAsset from "../data/useGetCategoryAsset";

const AddNewRealEstate = () => {
  const [selectedEstate, setSelectedEstate] = useState(null);
  const addLandBtnRef = useRef(null);
  const [selectedAssetId, setSelectedAssetId] = useState(null);

  const [getData, getError, getIsLoading, getCategoryAsset] =
    useGetCategoryAsset();

  useEffect(() => {
    getCategoryAsset("REAL ESTATE");
  }, []);

  const handleLandClick = (estateType, assetId) => {
    setSelectedEstate(estateType);
    setSelectedAssetId(assetId);
    addLandBtnRef.current.click();
  };

  return (
    <div>
      <DialogBox
        triggerElement={
          <Button
            px={"px-[0.9rem]"}
            py={"py-[0.3rem]"}
            name="+ Add New Real Estate"
          />
        }
        heading={
          <h4 className="text-lightGray font-[500] text-[1rem]">
            Add to your Assets
          </h4>
        }
        content={(handleClose) => (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem] m-0">
              Choose Real Estate type
            </h4>
            <div className="border rounded-md mt-[1rem] mb-[0.6rem]">
              <div className="grid grid-cols-2 gap-[1rem] p-[0.8rem]">
                {getData?.data ? (
                  <>
                    {getData?.data?.map((item) => (
                      <div
                        onClick={() => {
                          handleLandClick(item.asset_name, item.id);
                          handleClose();
                        }}
                        className="cursor-pointer bg-[#FAF8FC] border-1 border-[#EAE1F3] rounded-md flex flex-col justify-center items-center p-[0.8rem] hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                      >
                        <img
                          src={item.asset_image}
                          alt="land"
                          className="w-7 h-7 mb-[0.3rem]"
                        />

                        <h5 className="text-darkGray font-[500] text-[1rem] m-0">
                          {item.asset_name}
                        </h5>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <div className="cursor-pointer bg-[#FAF8FC] border-1 border-[#EAE1F3] rounded-md flex flex-col justify-center items-center p-[0.8rem] hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden">
                      {/* <img
                          src={building}
                          alt="building"
                          className="w-7 h-7 mb-[0.3rem]"
                        /> */}

                      <h5 className="text-darkGray font-[500] text-[1rem] m-0">
                        Land
                      </h5>
                    </div>
                    <div className="cursor-pointer bg-[#FAF8FC] border-1 border-[#EAE1F3] rounded-md flex flex-col justify-center items-center p-[0.8rem] hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden">
                      {/* <img
                          src={building}
                          alt="building"
                          className="w-7 h-7 mb-[0.3rem]"
                        /> */}

                      <h5 className="text-darkGray font-[500] text-[1rem] m-0">
                        Building
                      </h5>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      />

      <AddNewLand
        estateType={selectedEstate}
        assetId={selectedAssetId}
        triggerElement={
          <button ref={addLandBtnRef} className="hidden">
            Add Land
          </button>
        }
      />
    </div>
  );
};

export default AddNewRealEstate;
