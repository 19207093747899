import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useEffect, useRef, useState } from "react";
import "../liabilities.css";
import Button from "components/Button/BtnOrange";
import WhiteButton from "components/Button/WhiteButton";
import { useLocation, useNavigate } from "react-router-dom";
import { LIABILITIES_URL } from "constants/routes";
import usePostBankingLoans from "../data/usePostBankingLoans";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import usePatchBankingLoans from "../data/usePatchBankingLoans";
import RedButton from "components/Button/RedButton";
import datePicker from "assets/images/incomeTax/datePicker.png";


const UnsecuredLoansLoanLender = ({
  triggerElement,
  loanType,
  loanTypeId,
  isClose,
  setIsClose,
}) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [visitedSteps, setVisitedSteps] = useState([]);
  const [errors, setErrors] = useState({});
  const location = useLocation();

  const [lenders, setLenders] = useState([
    {
      id: "",
      loan: { bankName: "", amtBorr: "" },
      purpose: { loanPurpose: "", interestRate: "" },
      repayment: {
        startDate: "",
        endDate: "",
        period: "",
        dueDate: "",
        emi: "",
        amtPaid: "",
        balance: "",
      },
    },
  ]);

  const handleLenders = () => {
    setLenders([
      ...lenders,
      {
        id: "",
        loan: { bankName: "", amtBorr: "" },
        purpose: { loanPurpose: "", interestRate: "" },
        repayment: { startDate: "", endDate: "" },
      },
    ]);
  };

  const resetState = () => {
    setLenders([
      {
        id: "",
        loan: { bankName: "", amtBorr: "" },
        purpose: { loanPurpose: "", interestRate: "" },
        repayment: {
          startDate: "",
          endDate: "",
          period: "",
          dueDate: "",
          emi: "",
          amtPaid: "",
          balance: "",
        },
      },
    ]);
    setCurrentStep(0);
    setErrors({});
    setVisitedSteps([]);
  }

  const handleChange = (e, index, field) => {
    const value = e.target.value;
    const updatedLenders = lenders.map((lender, idx) => {
      if (idx === index) {
        return {
          ...lender,
          loan: {
            ...lender.loan,
            [field]: value,
          },
          purpose: {
            ...lender.purpose,
            [field]: value,
          },
          repayment: {
            ...lender.repayment,
            [field]: value,
          },
        };
      }
      return lender;
    });
    setLenders(updatedLenders);
  };

  const handleRemoveLender = (index) => {
    setLenders((prevLenders) => prevLenders.filter((_, i) => i !== index));
  };

  const [postBankData, postBankError, postBankIsLoading, postBankLoanDetails] =
    usePostBankingLoans();
  const [
    patchBankData,
    patchBankError,
    patchBankIsLoading,
    patchBankLoanDetails,
  ] = usePatchBankingLoans();

  const progressSteps = [
    {
      label: "Loan & Lender Info",
      active: currentStep === 0,
      visited: visitedSteps.includes(1),
    },
    {
      label: "Purpose & Interest",
      active: currentStep === 1,
      visited: visitedSteps.includes(2),
    },
    {
      label: "Repay Info",
      active: currentStep === 2,
      visited: visitedSteps.includes(3),
    },
  ];

  useEffect(() => {
    if (!visitedSteps.includes(currentStep)) {
      setVisitedSteps((prev) => [...prev, currentStep]);
    }
  }, [currentStep, visitedSteps]);

  const validateInputs = () => {
    const newErrors = {};
    const namePattern = /^[a-zA-Z\s]+$/;

    lenders.forEach((lender, index) => {
      if (!lender.loan.bankName) {
        newErrors[`lender${index}_bankName`] = "Bank Name is required";
      } else if (!namePattern.test(lender.loan.bankName)) {
        newErrors[`lender${index}_bankName`] =
          "Bank Name should not contain special characters or numbers";
      }

      if (!lender.loan.amtBorr || !/^\d+$/.test(lender.loan.amtBorr)) {
        newErrors[`lender${index}_amtBorr`] =
          "Valid Amount Borrowed is required (whole numbers only)";
      } else if (lender.loan.amtBorr <= 0) {
        newErrors[`lender${index}_amtBorr`] =
          "Amount Borrowed must be greater than 0";
      }
    });

    if (currentStep === 1) {
      lenders.forEach((lender, index) => {
        if (!lender.purpose.loanPurpose) {
          newErrors[`lender${index}_loanPurpose`] = "Loan Purpose is required";
        } else if (!namePattern.test(lender.purpose.loanPurpose)) {
          newErrors[`lender${index}_loanPurpose`] =
            "Loan Purpose should not contain special characters or numbers";
        }

        if (
          !lender.purpose.interestRate ||
          isNaN(lender.purpose.interestRate) ||
          lender.purpose.interestRate < 0 ||
          lender.purpose.interestRate > 100
        ) {
          newErrors[`lender${index}_interestRate`] =
            "Valid Interest Rate (0-100) is required";
        }
      });
    }

    if (currentStep === 2) {
      lenders.forEach((lender, index) => {
        if (!lender.repayment.startDate)
          newErrors[`lender${index}_startDate`] = "Start Date is required";
        if (!lender.repayment.endDate)
          newErrors[`lender${index}_endDate`] = "End Date is required";

        if (!lender.repayment.period)
          newErrors[`lender${index}_period`] = "Period is required";

        if (!lender.repayment.dueDate)
          newErrors[`lender${index}_dueDate`] = "Due Date is required";

        if (!lender.repayment.emi || !/^\d+$/.test(lender.repayment.emi)) {
          newErrors[`lender${index}_emi`] =
            "Valid EMI Amount is required (whole numbers only)";
        }

        if (
          !lender.repayment.amtPaid ||
          !/^\d+$/.test(lender.repayment.amtPaid)
        ) {
          newErrors[`lender${index}_amtPaid`] =
            "Valid Amount Paid is required (whole numbers only)";
        }

        if (
          !lender.repayment.balance ||
          !/^\d+$/.test(lender.repayment.balance)
        ) {
          newErrors[`lender${index}_balance`] =
            "Valid Balance Amount is required (whole numbers only)";
        }
      });
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (postBankData) {
      showSuccessToast("Bank Details Posted");
      if (location.pathname === LIABILITIES_URL) {
        setCurrentStep(0);
        setVisitedSteps([]);
        setLenders([
          {
            id: "",
            loan: { bankName: "", amtBorr: "" },
            purpose: { loanPurpose: "", interestRate: "" },
            repayment: {
              startDate: "",
              endDate: "",
              period: "",
              dueDate: "",
              emi: "",
              amtPaid: "",
              balance: "",
            },
          },
        ]);
        setIsClose(true);
        navigate(LIABILITIES_URL, { state: { value: true } });
      } else {
        navigate(LIABILITIES_URL);
      }
    }
  }, [postBankData]);

  useEffect(() => {
    if (postBankError) {
      showErrorToast("Bank Details not posted");
    }
    if (patchBankError) {
      showErrorToast("Bank Details not updated");
    }
  }, [postBankError, patchBankError]);

  const createData = () => {
    let createData = lenders.map((lenders, index) => ({
      bank_name: lenders.loan.bankName,
      amount_borrowed: lenders.loan.amtBorr,
      interest_rate: lenders.purpose.interestRate,
      loan_purpose: lenders.purpose.loanPurpose,
      start_date: lenders.repayment.startDate,
      end_date: lenders.repayment.endDate,
      due_date: lenders.repayment.dueDate,
      emi_amount: lenders.repayment.emi,
      amount_paid: lenders.repayment.amtPaid,
      balance_amount: lenders.repayment.balance,
      period: lenders.repayment.period,
      loan_status: "active",
    }));
    postBankLoanDetails(createData, loanTypeId);
  };
  const handleNext = () => {
    const isValid = validateInputs();

    if (isValid) {
      if (currentStep < 3) {
        setCurrentStep(currentStep + 1);
      } else {
        createData();
      }
    } else {
      console.log("Validation failed.");
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const onEditLoanLender = () => {
    setCurrentStep(0);
  };

  const onEditLoanPurpose = () => {
    setCurrentStep(1);
  };
  const onEditRepayment = () => {
    setCurrentStep(2);
  };

  const getContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Loan & Lender Information
            </h4>
            <div className="overflow-y-auto max-h-[300px] scrollbar-hide">
              {lenders.map((item, index) => (
                <div>
                  <div className="rounded-md bg-[#F1F5F9] mt-[1rem]">
                    <div className="grid grid-cols-2 p-[1rem]">
                      <div className="input-container">
                        <label className="input-label">Bank Name</label>
                        <input
                          type="text"
                          className="input-box border rounded-md bg-white"
                          value={item.loan.bankName}
                          onChange={(e) => handleChange(e, index, "bankName")}
                        />
                        {errors[`lender${index}_bankName`] && (
                          <span className="text-red-500 text-[0.7rem]">
                            {errors[`lender${index}_bankName`]}
                          </span>
                        )}
                      </div>

                      <div className="input-container">
                        <label className="input-label ">Amount Borrowed</label>
                        <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                          <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                            Rs.
                          </span>
                          <InputField
                            className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                            type="number"
                            value={item.loan.amtBorr}
                            onChange={(e) => handleChange(e, index, "amtBorr")}
                          />
                        </div>
                        {errors[`lender${index}_amtBorr`] && (
                          <span className="text-red-500 text-[0.7rem]">
                            {errors[`lender${index}_amtBorr`]}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {index > 0 && (
                    <div className="flex justify-end mt-2">
                      <RedButton
                        onClick={() => handleRemoveLender(index)}
                        name=" X Remove"
                        px={"px-[0.6rem]"}
                        py={"py-[0.4rem]"}
                      />
                    </div>
                  )}{" "}
                </div>
              ))}
            </div>

            <div className="flex justify-end mt-3">
              <WhiteButton
                onClick={handleLenders}
                name="+ Add Multiple Lenders"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
            </div>
          </>
        );
      case 1:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Loan Purpose & Interest
            </h4>
            <div className="overflow-y-auto max-h-[300px] scrollbar-hide">
              {lenders.map((item, index) => (
                <div>
                  <h4 className="text-[#334155] font-[500] text-[0.9rem] mt-[0.8rem]">
                    Bank - {index + 1}
                  </h4>
                  <div className="rounded-md bg-[#F1F5F9]">
                    <div className="grid grid-cols-2 gap-2 p-[1rem]">
                      <div className="input-container">
                        <label className="input-label">Loan Purpose</label>
                        <input
                          type="text"
                          className="input-box border rounded-md bg-white"
                          value={item.purpose.loanPurpose}
                          onChange={(e) =>
                            handleChange(e, index, "loanPurpose")
                          }
                        />
                        {errors[`lender${index}_loanPurpose`] && (
                          <span className="text-red-500 text-[0.7rem]">
                            {errors[`lender${index}_loanPurpose`]}
                          </span>
                        )}
                      </div>

                      <div className="input-container">
                        <label className="input-label">Interest Rate</label>
                        <InputField
                          className="input-box border rounded-md bg-white"
                          type="number"
                          value={item.purpose.interestRate}
                          onChange={(e) =>
                            handleChange(e, index, "interestRate")
                          }
                        />
                        {errors[`lender${index}_interestRate`] && (
                          <span className="text-red-500 text-[0.7rem]">
                            {errors[`lender${index}_interestRate`]}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end mt-3"></div>
                </div>
              ))}
            </div>
          </>
        );
      case 2:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Repayment Information
            </h4>
            <div className="overflow-y-auto max-h-[300px] scrollbar-hide">
              {lenders.map((item, index) => (
                <div className="">
                  <h4 className="text-[#334155] font-[500] text-[0.9rem] mt-[0.8rem]">
                    Member - {index + 1}
                  </h4>
                  <div className="rounded-md bg-[#F1F5F9] ">
                    <div className="grid grid-cols-2 gap-2 p-[1rem]">
                      <div className="input-container">
                        <label className="input-label">Start Date</label>
                        <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                          <span className="p-[0.5rem] rounded-l-md">
                            <img src={datePicker} className="w-5 h-5" />
                          </span>
                          <InputField
                            className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                          value={item.repayment.startDate}
                          onChange={(e) => handleChange(e, index, "startDate")}
                          type="date"
                        />
                        </div>
                        {errors[`lender${index}_startDate`] && (
                          <span className="text-red-500 text-[0.7rem]">
                            {errors[`lender${index}_startDate`]}
                          </span>
                        )}
                      </div>
                      <div className="input-container">
                        <label className="input-label">End Date</label>
                        <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                          <span className="p-[0.5rem] rounded-l-md">
                            <img src={datePicker} className="w-5 h-5" />
                          </span>
                          <InputField
                            className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                          value={item.repayment.endDate}
                          onChange={(e) => handleChange(e, index, "endDate")}
                          type="date"
                        />
                        </div>
                        <br></br>
                        {errors[`lender${index}_endDate`] && (
                          <span className="text-red-500 text-[0.7rem]">
                            {errors[`lender${index}_endDate`]}
                          </span>
                        )}
                      </div>

                      <div className="input-container mt-1">
                        <label className="input-label">Period(in months)</label>
                        <InputField
                          value={item.repayment.period}
                          onChange={(e) => handleChange(e, index, "period")}
                          type="select"
                          options={[
                            { value: "", label: "Select Period" },
                            { value: "3 Months", label: "3 Months" },
                            { value: "6 Months", label: "6 Months" },
                            { value: "9 Months", label: "9 Months" },
                            { value: "12 Months", label: "12 Months" },
                            { value: "18 Months", label: "18 Months" },
                            { value: "24 Months", label: "24 Months" },
                          ]}
                          className="input-box border rounded-md bg-white"
                        />
                        {errors[`lender${index}_period`] && (
                          <span className="text-red-500 text-[0.7rem]">
                            {errors[`lender${index}_period`]}
                          </span>
                        )}
                      </div>

                      <div className="input-container mt-1">
                        <label className="input-label">Due Date</label>
                        <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                          <span className="p-[0.5rem] rounded-l-md">
                            <img src={datePicker} className="w-5 h-5" />
                          </span>
                          <InputField
                            className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                          value={item.repayment.dueDate}
                          onChange={(e) => handleChange(e, index, "dueDate")}
                          // value={dueDate}
                          // onChange={(e) => {
                          //   setDueDate(e.target.value);
                          //   setErrors({ ...errors, dueDate: "" }); // Clear error message
                          // }}
                          type="date"
                        />
                        </div>
                        <br></br>
                        {errors[`lender${index}_dueDate`] && (
                          <span className="text-red-500 text-[0.7rem]">
                            {errors[`lender${index}_dueDate`]}
                          </span>
                        )}
                      </div>

                      <div className="input-container mt-1">
                        <label className="input-label">EMI Amount</label>
                        <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                          <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                            Rs.
                          </span>
                          <InputField
                            className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                          type="number"
                          value={item.repayment.emi}
                          onChange={(e) => handleChange(e, index, "emi")}
                          // value={emi}
                          // onChange={(e) => {
                          //   setEmi(e.target.value);
                          //   setErrors({ ...errors, emi: "" }); // Clear error message
                          // }}
                        />
                        </div>
                        {errors[`lender${index}_emi`] && (
                          <span className="text-red-500 text-[0.7rem]">
                            {errors[`lender${index}_emi`]}
                          </span>
                        )}
                      </div>

                      <div className="input-container mt-1">
                        <label className="input-label">Amount Paid</label>
                        <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                          <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                            Rs.
                          </span>
                          <InputField
                            className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                          value={item.repayment.amtPaid}
                          onChange={(e) => handleChange(e, index, "amtPaid")}
                          type="number"
                          // value={amtPaid}
                          // onChange={(e) => {
                          //   setAmtPaid(e.target.value);
                          //   setErrors({ ...errors, amtPaid: "" }); // Clear error message
                          // }}
                        />
                        </div>
                        {errors[`lender${index}_amtPaid`] && (
                          <span className="text-red-500 text-[0.7rem]">
                            {errors[`lender${index}_amtPaid`]}
                          </span>
                        )}
                      </div>

                      <div className="input-container mt-1">
                        <label className="input-label">Balance Amount</label>
                        <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                          <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                            Rs.
                          </span>
                          <InputField
                            className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                          value={item.repayment.balance}
                          onChange={(e) => handleChange(e, index, "balance")}
                          type="number"
                        />
                        </div>
                        {errors[`lender${index}_balance`] && (
                          <span className="text-red-500 text-[0.7rem]">
                            {errors[`lender${index}_balance`]}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>{" "}
                </div>
              ))}
            </div>
          </>
        );
      case 3:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">Preview</h4>

            <div className="overflow-y-auto max-h-[300px] scrollbar-hide">
              {lenders.map((item, index) => (
                <>
                  <h4 className="text-[#334155] font-[500] text-[0.9rem] mt-[0.8rem]">
                    Member - {index + 1}
                  </h4>
                  <div className=" bg-[#F1F5F9] rounded-lg p-[1rem]">
                    {/* loan and lender info */}
                    <div className="flex justify-between items-center">
                      <h4 className="text-[#334155] font-[500] text-[1.1rem] ">
                        Loan & Lender Information
                      </h4>
                      <WhiteButton
                        onClick={onEditLoanLender}
                        name="Edit"
                        px={"px-[0.7rem]"}
                        py={"py-[0.3rem]"}
                      />
                    </div>

                    <div className="bg-white grid grid-cols-2 p-[1rem] border rounded-md mt-1">
                      <div className="input-container">
                        <label className="input-label">Bank Name</label>
                        <InputField
                          readOnly
                          className="input-box border rounded-md bg-white "
                          value={item.loan.bankName}
                        />
                      </div>

                      <div className="input-container">
                        <label className="input-label">Amount Borrowed</label>
                        <InputField
                          readOnly
                          className="input-box border rounded-md bg-white"
                          value={item.loan.amtBorr}
                        />
                      </div>
                    </div>

                    {/* loan purpose & interest */}
                    <div className="mt-[1rem] flex justify-between items-center">
                      <h4 className="text-[#334155] font-[500] text-[1.1rem]">
                        Loan Purpose & Interest
                      </h4>
                      <WhiteButton
                        onClick={onEditLoanPurpose}
                        name="Edit"
                        px={"px-[0.7rem]"}
                        py={"py-[0.3rem]"}
                      />
                    </div>

                    <div className="bg-white grid grid-cols-2 p-[1rem] border rounded-md mt-1">
                      <div className="input-container">
                        <label className="input-label">Loan Purpose</label>
                        <InputField
                          readOnly
                          className="input-box border rounded-md bg-white"
                          value={item.purpose.loanPurpose}
                        />
                      </div>

                      <div className="input-container">
                        <label className="input-label">Interest Rate</label>
                        <InputField
                          readOnly
                          className="input-box border rounded-md bg-white"
                          value={item.purpose.interestRate}
                        />
                      </div>
                    </div>

                    {/* repayment info */}
                    <div className="mt-[1rem] flex justify-between items-center">
                      <h4 className="text-[#334155] font-[500] text-[1.1rem]">
                        Repayment Information
                      </h4>
                      <WhiteButton
                        onClick={onEditRepayment}
                        name="Edit"
                        px={"px-[0.7rem]"}
                        py={"py-[0.3rem]"}
                      />
                    </div>

                    <div className="bg-white grid grid-cols-2 p-[1rem] border rounded-md mt-1">
                      <div className="input-container">
                        <label className="input-label">Start Date</label>
                        <InputField
                          readOnly
                          value={item.repayment.startDate}
                          type="date"
                          className="input-box border rounded-md bg-white"
                        />
                      </div>
                      <div className="input-container">
                        <label className="input-label">End Date</label>
                        <InputField
                          readOnly
                          value={item.repayment.endDate}
                          type="date"
                          className="input-box border rounded-md bg-white"
                        />
                      </div>

                      <div className="input-container mt-2">
                        <label className="input-label">Period</label>
                        <InputField
                          readOnly
                          className="input-box border rounded-md bg-white"
                          value={item.repayment.period}
                        />
                      </div>

                      <div className="input-container mt-2">
                        <label className="input-label">Due Date</label>
                        <InputField
                          readOnly
                          value={item.repayment.dueDate}
                          type="date"
                          className="input-box border rounded-md bg-white"
                        />
                      </div>

                      <div className="input-container mt-2">
                        <label className="input-label">EMI Amount</label>
                        <InputField
                          readOnly
                          value={item.repayment.emi}
                          className="input-box border rounded-md bg-white"
                        />
                      </div>

                      <div className="input-container mt-2">
                        <label className="input-label">Amount Paid</label>
                        <InputField
                          readOnly
                          className="input-box border rounded-md bg-white"
                          value={item.repayment.amtPaid}
                        />
                      </div>

                      <div className="input-container mt-2">
                        <label className="input-label">Balance Amount</label>
                        <InputField
                          readOnly
                          className="input-box border rounded-md bg-white"
                          value={item.repayment.balance}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      <DialogBox
      onClose={resetState}
        triggerElement={triggerElement}
        progressSteps={progressSteps}
        heading={
          <h4 className="font-[600] text-xl text-[#334155]">
            Add <span className="text-[#9065B4]">{loanType}</span> to your
            Liabilities
          </h4>
        }
        content={(handleClose) => (
          <>
            {getContent()}
            <div className="flex justify-end gap-[1rem] mt-[1rem]">
              {currentStep < 3 ? (
                <WhiteButton
                  onClick={handleBack}
                  name="Back"
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
              ) : null}

              {currentStep < 3 ? (
                <Button
                  onClick={handleNext}
                  name={currentStep === 2 ? "Preview & Save" : "Next"}
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
              ) : (
                <Button
                  onClick={handleNext}
                  name="Add to Liability"
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
              )}
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
    </div>
  );
};

export default UnsecuredLoansLoanLender;
