import React, { useEffect, useRef, useState } from "react";
import AssetsHeading from "../AssetsHeading";
import WhiteButton from "components/Button/WhiteButton";
import EditIntellectual from "./Edit/EditIntellectual";
import { useLocation } from "react-router-dom";
import useGetIntellectualDetails from "../data/useGetIntellectualDetails";
import AddNewIntellectualCommercial from "./AddNewIntellectualCommercial";
import { fabClasses } from "@mui/material";

const IntellectualDetailView = () => {
  const location = useLocation();
  const [openIntellectual, setOpenIntellectual] = useState(false)
  const addIntellectualBtnRef = useRef(null)
  const [isReload, setIsReload]= useState(false)
  const [patchData, setPatchData]= useState({value:false, id:""})
  const assetId = location.state?.assetId;
  const itemId = location.state?.itemId;
  const itemName = location.state?.itemName;
  const [getIntellectualData, getIntellectualError, getIntellectualIsLoading, getIntellectual] =
  useGetIntellectualDetails();
  const [detailData, setDetailData]=useState({})
  useEffect(()=>{
    getIntellectual(`${assetId}/?id=${itemId}`)
  },[])
  useEffect(()=>{
    if(getIntellectualData?.data){
      setDetailData(getIntellectualData.data)
    }
  },[getIntellectualData])

  const openHandleForm =(val, idItem)=>{
    if(val === "edit"){
      setPatchData({value:true, id:idItem})
    }
    setOpenIntellectual(true)
  }

  useEffect(()=>{
   if(openIntellectual){
    addIntellectualBtnRef.current.click();
   }
  },[openIntellectual])

  useEffect(()=>{
    if(isReload){
      getIntellectual(`${assetId}/?id=${itemId}`)
      setIsReload(false)
      setPatchData({value:false, id:""})
    }
  },[isReload])

  const openCommercialPdf=(fileUrl)=>{
     window.open(fileUrl, "_blank")
  }

  const openAgreementPdf =()=>{
    window.open(detailData.document, "_blank")
  }
  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 ">
      <AssetsHeading asset={"Other Assets"} asset_type={"Intellectual Property"} asset_detail={itemName} assetId={assetId}/>
      <div className="flex gap-2.5 mt-[1.5rem]">
        <div className="border bg-white rounded-xl p-[1rem] w-[40%]">
          <div className="flex justify-between items-center">
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
            Trademark Value
            </h4>
            {/* <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Edit" /> */}
            <EditIntellectual editValue="intellectualValue" detailData={detailData} setIsReload={setIsReload}/>
          </div>
          <div className="flex justify-between mt-[1rem]">
            <div className="">
              <h4 className="text-customPurple font-[500] text-[1.5rem] ">
                ₹55 Lakhs
              </h4>
              <div className="p-[0.5rem] border-1 border-customPurple bg-[#FAF8FC] rounded-md">
                <h4 className="text-[0.75rem] font-[600] text-customPurple m-0">
                  10 % of Total Assets (₹50.86 Lakhs)
                </h4>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-8 mt-[1rem]">
            <div className="flex flex-col gap-1">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Amount Invested
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.amount_invested} Rs.
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Estimated Sale Value
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.estimated_sale_value} Rs.
              </p>
            </div>
          </div>
        </div>
        <div className="border bg-white rounded-xl p-[1rem] w-[60%]">
          <div className="flex justify-between items-center">
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
              Registry Details
            </h4>
            <div className="flex items-center gap-2">
              <WhiteButton
                px={"px-[0.8rem]"}
                py={"py-[0.3rem]"}
                name="View Agreement"
                onClick={openAgreementPdf}
              />
              {/* <WhiteButton px={"px-[0.8rem]"} py={"py-[0.3rem]"} name="Edit" /> */}
              <EditIntellectual editValue="intellectualInfo" detailData={detailData} setIsReload={setIsReload}/>
            </div>
          </div>
          <div className="mt-3">
            <div className="flex flex-col gap-1">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                IP Type
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.ip_type}
              </p>
            </div>
          </div>
          <div className="mt-3 flex items-center gap-10">
            <div className="flex flex-col gap-1 w-[25%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Asset/IP Name
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.asset_ip_name}
              </p>
            </div>
            <div className="flex flex-col gap-1 w-[25%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Registration Number
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.registration_number}
              </p>
            </div>
            <div className="flex flex-col gap-1 w-[25%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Account Holder
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.account_holder}
              </p>
            </div>
          </div>
          <div className="mt-3 flex items-center gap-10">
            <div className="flex flex-col gap-1 w-[25%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Class/Type of Work
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.class_or_type_of_work}
              </p>
            </div>
            <div className="flex flex-col gap-1 w-[25%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Status
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.status===true ?"Active":"Inactive" }
              </p>
            </div>
            <div className="flex flex-col gap-1 w-[25%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                Registration Date
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.registration_date}
              </p>
            </div>
          </div>
        </div>
      </div>
      {
        detailData?.commercial_information?.length !== 0 ? <div className="mt-3 border bg-white rounded-xl p-[1rem]">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <h4 className="text-darkGray font-[500] text-[1rem] m-0">
              Commercial Users
            </h4>
            <span className="text-[#9065B4] font-[600] text-[0.9rem] bg-[#FAF8FC] rounded-md px-1.5 py-1">
              5,00,000 Rs.
            </span>
          </div>
          <div>
            <WhiteButton
              px={"px-[0.8rem]"}
              py={"py-[0.3rem]"}
              name="Add another user"
              onClick={openHandleForm}
            />
          </div>
        </div>
        <div className="rounded-lg border mt-2.5">
          <table className="min-w-full border-collapse">
            <thead className="text-[#64748B] rounded-tl-lg">
              <tr>
                <th className={`p-2.5 border-r border-b rounded-tl-lg bg-[#F8FAFC] font-[500] text-[0.8rem] w-[24%]`}>
                Business Name
                </th>
                <th className={`p-2.5 border-r border-b bg-[#F8FAFC] font-[500] text-[0.8rem] w-[24%]`}>
                Payout Frequency
                </th>
                <th className={`p-2.5 border-r border-b bg-[#F8FAFC] font-[500] text-[0.8rem] w-[24%]`}>
                Estimated Income
                </th>
                <th className={`p-2.5 border-b bg-[#F8FAFC] rounded-tr-lg font-[500] text-[0.8rem] w-[27%]`}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {
                detailData.commercial_information?.map((item, i)=>{
                    return(
                      <tr key={i}>
                      <td className={`${i>0 ? "border-t":""} p-2.5 border-r text-[#334155] font-[600]`}>
                        {item.associated_business_name}
                      </td>
                      <td className={`${i>0 ? "border-t":""} p-2.5 border-r text-[#334155] font-[600]`}>
                        {item.payout_frequency}
                      </td>
                      <td className={`${i>0 ? "border-t":""} p-2.5 border-r text-[#334155] font-[600]`}>
                        {item.estimated_income} Rs.
                      </td>
                      <td className={`${i>0 ? "border-t":""} p-2.5 text-[#334155] font-[600] flex items-center gap-4`}>
                        <WhiteButton
                          px={"px-[0.8rem]"}
                          py={"py-[0.3rem]"}
                          name="View Invoice"
                          onClick={()=>openCommercialPdf(item.agreement)}
                        />
                        <WhiteButton
                          px={"px-[0.8rem]"}
                          py={"py-[0.3rem]"}
                          name="Edit"
                          onClick={()=>openHandleForm("edit", item.id)}
                        />
                      </td>
                    </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div> :
            <div className="mt-3 border bg-white rounded-xl p-[1rem]">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <h4 className="text-darkGray font-[500] text-[1rem] m-0">
                    Commercial Users
                  </h4>
                  <span className="text-[#9065B4] font-[600] text-[0.9rem] bg-[#FAF8FC] rounded-md px-1.5 py-1">
                    5,00,000 Rs.
                  </span>
                </div>
              </div>
              <div className="flex justify-center items-center mt-2">
                 <div className="flex flex-col justify-center items-center gap-1.5">
                     <button className="px-[0.8rem] py-[0.3rem] text-[#334155] font-[500] bg-[#F1F5F9] rounded-md w-auto" onClick={openHandleForm}>+  Commercial User</button>
                     <p className="m-0 text-[#334155] text-[0.75rem] font-[500]">Add Any Commercial Users of this trademark</p>
                 </div>
              </div>
            </div>
      }
      {openIntellectual && (
        <AddNewIntellectualCommercial
          assetId={assetId}
          itemId={itemId}
          detailData={detailData}
          patchData={patchData}
          setOpenIntellectual={setOpenIntellectual}
          openIntellectual={openIntellectual}
          setIsReload={setIsReload}
          triggerElement={
            <button ref={addIntellectualBtnRef} className="hidden">
              Add Intellectual
            </button>
          }
        />
      )}
    </div>
  );
};

export default IntellectualDetailView;
