import React from "react";
import LoginProfileNav from "components/loginProfileNavbar/LoginProfileNav";
import ProfileNav from "./ProfileNav";
import DashboardContent from "./DashboardContent";
import ProfileAndCertificate from "./ProfileAndCertificate";
import "containers/privateRoutes/Dashboard/Order.css";

function Dashboard() {
  return (
    <div className="grid grid-cols-12">
      {/* <div className="col-span-2 hidden md:block">
        <ProfileNav />
      </div>
      <div className="col-span-12 md:col-span-7">
        <DashboardContent />
      </div>
      <div className="col-span-3 hidden md:block">
        <ProfileAndCertificate />
      </div> */}
      <div className="col-span-12 md:col-span-8">
        <DashboardContent />
      </div>
      <div className="col-span-4 hidden md:block">
        <ProfileAndCertificate />
      </div>
    </div>
  );
}

export default Dashboard;
