import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import { myContext } from "App";
import InputField from "components/input/InputField";

// images
import logo from "assets/images/dashboard/logo.svg";
import googleLogo from "assets/images/login/Social icon.svg";
import loginView from "assets/images/login/LoginFrontView.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";

// APIs
import usePostNewLogin from "./data/usePostNewLogin";
import GoogleLogin from "containers/LoginAndSignUp/GoogleLogin";

const LoginSignUpBusiness = () => {
  const navigate = useNavigate();
  const { setIsLoggedIn } = useContext(myContext);
  const [showPassword, setShowPassword] = useState(false);

  const [emailMobile, setEmailMobile] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");
  const [borderColor, setBorderColor] = useState("");
  const [postData, postError, postIsLoading, postLogin] = usePostNewLogin();

  const handleNavigate = () => {
    navigate("/newSignup");
  };

  useEffect(() => {
    if (postError) {
      const errorMessage =
        postError.err.response?.data?.error?.errors[0]?.detail ||
        "An error occurred";
      setErr(errorMessage);
      setBorderColor("border-red-500");
      showErrorToast("", errorMessage);
    }
  }, [postError]);

  useEffect(() => {
    if (postData && postData.status_code === 200) {
      showSuccessToast("Login Successful");
      setIsLoggedIn(true);
      const { token, expiry, user } = postData.data;
      localStorage.setItem("token", token);
      localStorage.setItem("expiry", expiry);
      localStorage.setItem("user", JSON.stringify(user));
    }
  }, [postData, setIsLoggedIn]);

  const handleLogin = () => {
    if (validateInputs()) {
      postLogin({ email_or_mobile: emailMobile, password: password });
    }
  };

  const validateInputs = () => {
    setErr("");

    if (!emailMobile.trim()) {
      setErr("Mobile Number/ Email is required.");
      return false;
    }

    if (!password.trim()) {
      setErr("Password is required.");
      return false;
    }

    return true;
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="bg-[#FFFFFF] w-full min-h-screen flex">
      <div className="md:w-[50%] w-[100%] min-h-screen flex items-center justify-center">
        <div className="md:w-[60%] w-[80%]">
          <div className="flex flex-col items-center">
            <img src={logo} alt="logo" width="60%" />
            <p className="m-0 text-[#334155] text-[0.9rem]">
              All-in-one financial care
            </p>
          </div>
          <div className="md:w-[90%] p-2 mt-2 flex flex-col justify-center gap-3">
            <div className="flex flex-col gap-1 ">
              <p className="m-0 text-[#334155] text-[0.9rem]">
                E-mail / Mobile No.
              </p>
              <InputField
              onKeyDown={handleKeyDown}
                type="text"
                name="emailMobile"
                value={emailMobile}
                onChange={(e) => setEmailMobile(e.target.value)}
                className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
                placeholder="E-mail / Mobile"
                validation={{ required: true }}
              />
            </div>
            <div className="flex flex-col gap-1 ">
              <p className="m-0 text-[#334155]">Password</p>
              <div className="relative w-full">
                <InputField
                onKeyDown={handleKeyDown}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type={showPassword ? "text" : "password"} // Toggle between text and password
                  className="border-[1px] border-[#D9D9D9] rounded-md w-[100%] focus:outline-none px-2 py-1.5"
                  placeholder="Password"
                  name="password"
                  validation={{ required: true }}
                />

                {/* Eye toggle icon */}
                <span
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <FaEyeSlash size={18} />
                  ) : (
                    <FaEye size={18} />
                  )}
                </span>
              </div>
            </div>
            <p className="text-center text-[0.9rem] text-red-600 m-0">{err}</p>
            <div className="">
              <button
                onClick={handleLogin}
                className="text-[#FFFFFF] bg-[#94A3B8] rounded-md px-2 py-1.5 w-[100%]"
              >
                Log In
              </button>
            </div>

            <div className="flex items-center justify-center">
              <p className="m-0 text-[#334155] text-[0.9rem]">OR</p>
            </div>
            <div className="">
              <GoogleLogin />
            </div>
            <div className="flex flex-col items-center">
              <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
                Forgotten your password?
              </p>
              <hr className="w-[80%] border-[2px] border-[#E2E8F0]" />
              <p className="m-0 text-[#667085] text-[0.9rem]">
                Don't have an account?
                <span
                  className="text-[#9065B4] ml-2 cursor-pointer"
                  onClick={handleNavigate}
                >
                  Sign Up
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[50%] min-h-screen border-l-[1px] border-[#FEE2E2] bg-[#FAF8FC] rounded-tl-2xl rounded-bl-2xl hidden md:block">
        <div className="flex flex-col items-center justify-center h-full">
          <div>
            <img src={loginView} alt="loginView" />
          </div>
          <div className="flex flex-col items-center">
            <p className="m-0 text-[#334155] text-[1rem] font-[600]">
              Simplify Your Finances with Beyondtax
            </p>
            <p className="m-0 text-[#334155] text-[0.9rem]">
              Manage your finances, tax, and compliance all in one place.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSignUpBusiness;
