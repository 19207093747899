import googleLogo from "assets/images/Serviceimages/googleLogo.svg";

import { GOOGLE_REDIRECT_URL } from "constants/apis";

const onGoogleLoginSuccess = () => {
  const GOOGLE_AUTH_URL = "https://accounts.google.com/o/oauth2/v2/auth";
  // const GOOGLE_AUTH_URL = "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount";

  const scope = [
    "https://www.googleapis.com/auth/userinfo.email",
    "https://www.googleapis.com/auth/userinfo.profile",
  ].join(" ");
  const clientId =
  // "617719846999-tl7ep6u0j6356pf1g38t3nvrtrd4v1vg.apps.googleusercontent.com";  //old
  "579934184784-vmlfht9upqpf18a83f9366147ctc55vf.apps.googleusercontent.com";
      
  const params = {
    response_type: "code",
    client_id: clientId,
    redirect_uri: GOOGLE_REDIRECT_URL,
    prompt: "select_account",
    access_type: "offline",
    scope,
  };

  const urlParams = new URLSearchParams(params).toString();
  window.location = `${GOOGLE_AUTH_URL}?${urlParams}`;
};

const GoogleLogin = () => {
  return (
    <button
      onClick={onGoogleLoginSuccess}
      className="border-solid flex justify-center my-3 border-gray-300 border-1 rounded-2 p-1 w-full"
    >
      <img className=" w-8 mx-1" src={googleLogo} />
      <span className=" p-1">Continue with Google</span>{" "}
    </button>
  );
};

export default GoogleLogin;
