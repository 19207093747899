import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button/BtnOrange";
import {
  INCOME_TAX_SUMMARY_URL,
  INCOME_TAX_SUMMARY_USER_DETAILS,
  INCOME_TAX_SUMMARY_USER_PAGE_URL,
  USER_INCOME_SUMMARY_PAGE_URL,
} from "constants/routes";
import CustomizableDrawer from "containers/privateRoutes/Reports/Drawer";

// images
import previousBtn from "assets/images/incomeTax/previousBtn.svg";
import Clock from "assets/images/incomeTax/Clock.svg";
import download from "assets/images/incomeTax/download.svg";
import info from "assets/images/incomeTax/info.svg";
import switch_icon from "assets/images/incomeTax/arrow-left-right.svg";
import edit_icon from "assets/images/incomeTax/edit.svg";
import arrow from "assets/images/incomeTax/chevron-down.svg";
import sidebar from "assets/images/incomeTax/more-vertical.svg";

// APIs
import useGetOldRegime from "./Data/useGetOldRegime";
import useGetNewRegime from "./Data/useGetNewRegime";
import usePostComputation from "./Data/usePostComputation";
import useGetDownloadOld from "./Data/useGetDownloadOld";
import useGetDownloadNew from "./Data/useGetDownloadNew";
import InputField from "components/input/InputField";

const Computation = ({ isDisabled }) => {
  const navigate = useNavigate();
  const { income_tax_return_id } = useParams();

  const [visibleSections, setVisibleSections] = useState({});
  const [isOldRegime, setIsOldRegime] = useState(true);
  const excludedIds = [7, 11, 12, 13];

  const handleSwitchRegime = () => {
    setIsOldRegime((prev) => !prev);
  };

  const [getOldData, getOldError, getOldIsLoading, getOldRegime] =
    useGetOldRegime();
  const [getNewData, getNewError, getNewIsLoading, getNewRegime] =
    useGetNewRegime();
  const [postData, postError, postIsLoading, postComputation] =
    usePostComputation();
  const [
    downloadOldData,
    downloadOldError,
    downloadOldIsLoading,
    getDownloadOldComputation,
  ] = useGetDownloadOld();
  const [
    downloadNewData,
    downloadNewError,
    downloadNewIsLoading,
    getDownloadNewComputation,
  ] = useGetDownloadNew();

  useEffect(() => {
    getOldRegime(income_tax_return_id);
    getNewRegime(income_tax_return_id);
  }, []);

  const toggleSection = (id) => {
    setVisibleSections((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  useEffect(() => {
    if (downloadOldData) {
      const blob = new Blob([downloadOldData], { type: "application/pdf" });
      const pdfUrl = window.URL.createObjectURL(blob);
      window.open(pdfUrl);
    }
  }, [downloadOldData]);

  useEffect(() => {
    if (downloadNewData) {
      const blob = new Blob([downloadNewData], { type: "application/pdf" });
      const pdfUrl = window.URL.createObjectURL(blob);
      window.open(pdfUrl);
    }
  }, [downloadNewData]);

  const handleEditClick = (path, id) => {
    let destinationPath;

    if (id === 8 || id === 9 || id === 10) {
      destinationPath = USER_INCOME_SUMMARY_PAGE_URL.replace(
        ":income_tax_return_id",
        income_tax_return_id
      ).replace(":type", path);
    } else {
      destinationPath = INCOME_TAX_SUMMARY_USER_DETAILS.replace(
        ":income_tax_return_id",
        income_tax_return_id
      ).replace(":detailType", path);
    }

    navigate(destinationPath);
  };

  const refundType = [
    {
      id: 1,
      type: "Income from Salaries",
      path: "salary",
      total_name: "Total Income",
      total_amount: isOldRegime
        ? getOldData?.data?.old_regime?.total_income_from_salaries
        : getNewData?.data?.new_regime?.total_income_from_salaries,
      total_color: "#9065B4",
      total_field_width: "45%",
      field_name:
        (isOldRegime
          ? getOldData?.data?.old_regime?.salary_incomes
          : getNewData?.data?.new_regime?.salary_incomes
        )?.map((salary_income) => ({
          name: salary_income.employer_name,
          list: [
            {
              name: "Salary, allowances and perquisites",
              amount: salary_income.gross_salary,
            },
            {
              name: "Less: Standard Deductionsu/s 16[ia]",
              amount: salary_income.standard_deduction,
              color: "#15803D",
            },
            {
              name: "Income Chargable Under the Head 'Salaries'",
              amount: salary_income.total_income,
              color: "#9065B4",
              field_width: "45%",
            },
          ],
        })) || [],
    },
    {
      id: 2,
      type: "Income From House Property",
      path: "rental",
      field_name:
        (isOldRegime
          ? getOldData?.data?.old_regime?.rental_incomes
          : getNewData?.data?.new_regime?.rental_incomes
        )?.map((rental) => ({
          name: rental.occupancy_status,
          list:
            rental.occupancy_status === "Self-occupied"
              ? [
                  {
                    name: "Less: Interest on borrowed capital u/s 24(b)",
                    amount: rental["Interest on Borrowed Capital u/s 24(b)"],
                    color: "#15803D",
                  },
                ]
              : [
                  {
                    name: "Gross Annual Rent",
                    amount: rental.annual_rent,
                  },
                  {
                    name: "Less: Municipal Taxes Paid",
                    amount: rental.property_tax_paid,
                    color: "#15803D",
                  },
                  {
                    name: "Less: Interest on borrowed capital u/s 24(b)",
                    amount: rental["Interest on Borrowed Capital u/s 24(b)"],
                    color: "#15803D",
                  },
                  {
                    name: "Income Chargable: Under the Head 'House Proerty'",
                    amount: rental.total_rental_income,
                    color: "#9065B4",
                    field_width: "45%",
                  },
                  {
                    name: "Carried Forward: Current year set off not allowed",
                    amount: "",
                    color: "#DC2626",
                  },
                ],
        })) || [],
    },
    {
      id: 3,
      type: "Profits and gains of Business or Profession",
      path: "business",
      total_name: "Total Income",
      total_amount: isOldRegime
        ? getOldData?.data?.old_regime?.total_income_from_business
        : getNewData?.data?.new_regime?.total_income_from_business,
      total_color: "#9065B4",
      total_field_width: "45%",

      field_name: [
        {
          name: "",

          list:
            (isOldRegime
              ? getOldData?.data?.old_regime?.business_incomes
              : getNewData?.data?.new_regime?.business_incomes
            )?.map((income) => ({
              name: income.business_income_type,
              amount: income["Profit from Business"],
            })) || [],
        },
        // {
        //   name: "F&O Business Income",
        //   list: [
        //     {
        //       name: "Profit from Business",
        //       amount: "2",
        //     },
        //   ],
        // },
        // {
        //   name: "Income From Partnership Firm",
        //   list: [
        //     {
        //       name: "Remuneration",
        //       amount: "2",
        //     },
        //     {
        //       name: "Interest on Capital",
        //       amount: "2",
        //     },
        //     {
        //       name: "Share of Profit 50%",
        //       amount: "2",
        //     },
        //     {
        //       name: "Income Chargable: Under the Head 'Business or Profession'",
        //       amount: "2",
        //       color: "#9065B4",
        //       field_width: "45%"
        //     },
        //     {
        //       name: "Carried Forward: Current year set off not allowed",
        //       amount: "2",
        //       color: "#DC2626",
        //     },
        //   ],
        // },
      ],
    },
    {
      id: 4,
      type: "Capital Gains",
      path: "capital-gains",
      field_name: [
        {
          name: "",
          list: [
            {
              name: "Long Term Capital Gain u/s 112A",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.long_term_capital_gain_u_s_112a
                : getNewData?.data?.new_regime?.long_term_capital_gain_u_s_112a,
            },
            {
              name: "Long Term Capital Gain-Others",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.long_term_capital_gain_others
                : getNewData?.data?.new_regime?.long_term_capital_gain_others,
            },
            {
              name: "Short Term Capital Gain",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.short_term_capital_gain
                : getNewData?.data?.new_regime?.short_term_capital_gain,
            },
            {
              name: "Income Chargable: Under the Head 'Capital Gains'",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.total_capital_gains_income
                : getNewData?.data?.new_regime?.total_capital_gains_income,
              color: "#9065B4",
              field_width: "45%",
            },
            {
              name: "Carried Forward: Current year set off not allowed",
              amount: "",
              color: "#DC2626",
            },
          ],
        },
      ],
    },
    {
      id: 5,
      type: "Income From Other Sources",
      path: "others",
      field_name: [
        {
          name: "",
          list: [
            {
              name: "Interest Income",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.interest_income
                : getNewData?.data?.new_regime?.interest_income,
            },
            {
              name: "Dividend",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.dividend_income
                : getNewData?.data?.new_regime?.dividend_income,
            },
            {
              name: "Winnings: Lotteries, Games, Bettings",
              amount: isOldRegime
                ? getOldData?.data?.old_regime
                    ?.winnings_lotteries_games_bettings
                : getNewData?.data?.new_regime
                    ?.winnings_lotteries_games_bettings,
            },
            {
              name: "Income Chargable: Under the Head 'Other Sources'",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.total_of_other_incomes
                : getNewData?.data?.new_regime?.total_of_other_incomes,
              color: "#9065B4",
              field_width: "45%",
            },
          ],
        },
      ],
    },
    {
      id: 6,
      type: "Agricultural & Exempt Income",
      path: "agri-exmpt",
      field_name: [
        {
          name: "",
          list: [
            {
              name: "Exempt Income",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.exempt_income
                : getNewData?.data?.new_regime?.exempt_income,
            },
            {
              name: "Net Income from Agriculture",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.net_income_from_agriculture
                : getNewData?.data?.new_regime?.net_income_from_agriculture,
            },
            {
              name: "Total Exempt Income",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.total_exempt_income
                : getNewData?.data?.new_regime?.total_exempt_income,
              color: "#9065B4",
              text_color: "#9065B4",
              field_width: "45%",
            },
          ],
        },
      ],
    },
    {
      id: 13,
      type: "Less: Previous Unabsorbed Losses - Carried Forward",
      amount: "",
      color: "#15803D",
      field_width: "45%",
    },
    {
      id: 7,
      type: "Gross Total Income",
      amount: isOldRegime
        ? getOldData?.data?.old_regime?.gross_total_income
        : getNewData?.data?.new_regime?.gross_total_income,
      field_width: "45%",
    },
    {
      id: 8,
      type: "Deductions",
      path: "deductions",
      type_para: "Under Chapter VI-A",
      field_name: [
        {
          name: "",
          list: [
            // {
            //   name: "Life Insurance Premium",
            //   amount: "",
            // },
            {
              name: "Others u/s 80C",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.deduction_u_s_80c
                : getNewData?.data?.new_regime?.deduction_u_s_80c,
            },
            {
              name: "NPS Contribution u/s CCD",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.nps_contribution_u_s_ccd
                : getNewData?.data?.new_regime?.nps_contribution_u_s_ccd,
            },
            {
              name: "80D: Medical Insurance Premium",
              amount: isOldRegime
                ? getOldData?.data?.old_regime["80d_medical_insurance_premium"]
                : getNewData?.data?.new_regime["80d_medical_insurance_premium"],
            },
            {
              name: "80TTA: Interest on Saving A/c",
              amount: isOldRegime
                ? getOldData?.data?.old_regime["80tta_interest_on_savings_acc"]
                : getNewData?.data?.new_regime["80tta_interest_on_savings_acc"],
            },
            {
              name: "Total Amount Deductible",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.total_deduction_amount
                : getNewData?.data?.new_regime?.total_deduction_amount,
              color: "#9065B4",
              text_color: "#9065B4",
              field_width: "45%",
            },
            {
              name: "Total Income",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.total_income
                : getNewData?.data?.new_regime?.total_income,
              text_size: "1.1rem",
              text_color: "#334155",
              text_weight: "500",
              field_width: "45%",
              background_color: "#F3EFF8",
            },
          ],
        },
      ],
    },
    {
      id: 9,
      type: "Tax Liability",
      field_name: [
        {
          name: "",
          list: [
            {
              name: "Rebate u/s 87A",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.tax_rebate
                : getNewData?.data?.new_regime?.tax_rebate,
            },
            {
              name: "Tax on Total Income",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.balance_tax_to_be_paid
                : getNewData?.data?.new_regime?.balance_tax_to_be_paid,
            },
            {
              name: "Surcharge",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.surcharge
                : getNewData?.data?.new_regime?.surcharge,
            },
            {
              name: "Cess",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.cess
                : getNewData?.data?.new_regime?.cess,
            },
            {
              name: "Interest u/s 234A,B & C",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.interest_u_s_234a_b_c
                : getNewData?.data?.new_regime?.interest_u_s_234a_b_c,
            },
            {
              name: "Penality u/s 234F",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.penalt_u_s_234f
                : getNewData?.data?.new_regime?.penalt_u_s_234f,
            },
            {
              name: "Tax Payable",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.net_tax_payable
                : getNewData?.data?.new_regime?.net_tax_payable,
              color: "#9065B4",
              text_color: "#9065B4",
              field_width: "45%",
            },
          ],
        },
      ],
    },
    {
      id: 10,
      type: "Taxes Details",
      path: "taxes-paid",
      field_name: [
        {
          name: "",
          list: [
            {
              name: "TDS/TCS",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.tds_or_tcs
                : getNewData?.data?.new_regime?.tds_or_tcs,
            },
            {
              name: "Set Assessment Tax",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.self_assessment_tax
                : getNewData?.data?.new_regime?.self_assessment_tax,
            },
            {
              name: "Advance Tax",
              amount: isOldRegime
                ? getOldData?.data?.old_regime?.advance_tax
                : getNewData?.data?.new_regime?.advance_tax,
            },
            {
              name: "Taxes Paid",
              amount: "",
              // isOldRegime
              //   ? getOldData?.data?.old_regime?.total_income
              //   : getNewData?.data?.new_regime?.total_income,
              color: "#9065B4",
              text_color: "#9065B4",
              field_width: "45%",
            },
          ],
        },
      ],
    },
    {
      id: 11,
      type: "Refund Due",
      type_para: "Balance Receivable",
      amount: "",
      color: "#15803D",
      field_width: "45%",
    },
    {
      id: 12,
      type: "Tax Payable",
      type_para: "Balance Payable",
      amount: isOldRegime
        ? getOldData?.data?.old_regime?.tax_payable
        : getNewData?.data?.new_regime?.tax_payable,
      color: "#DC2626",
      field_width: "45%",
    },
  ];

  const handleGoBack = () => {
    navigate(
      INCOME_TAX_SUMMARY_URL.replace(
        ":income_tax_return_id",
        income_tax_return_id
      )
    );
  };

  const handleDownloadComputation = () => {
    if (isOldRegime) {
      getDownloadOldComputation(income_tax_return_id);
    } else {
      getDownloadNewComputation(income_tax_return_id);
    }
  };

  const refund = isOldRegime
    ? getOldData?.data?.old_regime
    : getNewData?.data?.new_regime;

  const dataToPost = (refund) => {
    let computationData = {
      regime_type: isOldRegime ? "old" : "new",
      regime_json_data: {
        salary_incomes: refund?.salary_incomes?.map((item) => ({
          employer_name: item.employer_name,
          gross_salary: item.gross_salary,
          standard_deduction: item.standard_deduction,
          total_income: item.total_income,
        })),
        total_income_from_salaries: refund?.total_income_from_salaries,
        rental_incomes: refund?.rental_incomes?.map((item) => ({
          occupancy_status: item.occupancy_status,
          annual_rent: item.annual_rent,
          "Interest on Borrowed Capital u/s 24(b)":
            item["Interest on Borrowed Capital u/s 24(b)"],
          total_rental_income: item.total_rental_income,
          property_tax_paid: item.property_tax_paid,
        })),
        long_term_capital_gain_u_s_112a: refund.long_term_capital_gain_u_s_112a,
        long_term_capital_gain_others: refund.long_term_capital_gain_others,
        short_term_capital_gain: refund.short_term_capital_gain,
        total_capital_gains_income: refund.total_capital_gains_income,
        business_incomes: refund.business_incomes?.map((item) => ({
          business_income_type: item.business_income_type,
          ["Profit from Business"]: item["Profit from Business"],
        })),
        total_income_from_business: refund.total_income_from_business,
        interest_income: refund.interest_income,
        dividend_income: refund.dividend_income,
        winnings_lotteries_games_bettings:
          refund.winnings_lotteries_games_bettings,
        total_of_other_incomes: refund.total_of_other_incomes,
        exempt_income: refund.exempt_income,
        net_income_from_agriculture: refund.net_income_from_agriculture,
        total_exempt_income: refund.total_exempt_income,
        deduction_u_s_80c: refund.deduction_u_s_80c,
        nps_contribution_u_s_ccd: refund.nps_contribution_u_s_ccd,
        ["80d_medical_insurance_premium"]:
          refund["80d_medical_insurance_premium"],
        ["80tta_interest_on_savings_acc"]:
          refund["80tta_interest_on_savings_acc"],
        total_deduction_amount: refund.total_deduction_amount,
        tds_or_tcs: refund.tds_or_tcs,
        self_assessment_tax: refund.self_assessment_tax,
        advance_tax: refund.advance_tax,
        gross_total_income: refund.gross_total_income,
        total_income: refund.total_income,
        tax_rebate: refund.tax_rebate,
        surcharge: refund.surcharge,
        cess: refund.cess,
        net_tax_payable: refund.net_tax_payable,
        balance_tax_to_be_paid: refund.balance_tax_to_be_paid,
        interest_u_s_234a_b_c: refund.interest_u_s_234a_b_c,
        penalt_u_s_234f: refund.penalt_u_s_234f,
        tax_payable: refund.tax_payable,
      },
    };

    postComputation(income_tax_return_id, computationData);
  };

  const handleSaveAndNext = () => {
    dataToPost(refund);

    navigate(
      USER_INCOME_SUMMARY_PAGE_URL.replace(
        ":income_tax_return_id",
        income_tax_return_id
      ).replace(":type", "proceed-to-file")
    );
  };

  const handlePrevious = () => {
    navigate(
      INCOME_TAX_SUMMARY_USER_PAGE_URL.replace(
        ":income_tax_return_id",
        income_tax_return_id
      )
    );
  };

  return (
    <>
      <div className="flex gap-2 md:hidden">
        <button onClick={handlePrevious}>
          <img src={previousBtn} alt="" className="w-4 h-4 mb-2" />
        </button>
        <h4 className=" text-[#334155] font-[500] text-[1.2rem] leading-[2.7rem]">
          Computation
        </h4>
      </div>
      <hr className="mt-0 mx-0 md:hidden" />

      <div className="md:bg-white md:border md:border-[#E2E8F0] rounded md:p-4 mb-[6rem] ">
        <div className="flex justify-between">
          <h4 className="font-[500] text-[1.2rem] md:text-[1.1rem] text-[#334155] m-0">
            Tax Computation {JSON.parse(localStorage.getItem("returnIdYear"))}
          </h4>
          <CustomizableDrawer
            triggerElement={
              <img src={sidebar} alt="sidebar icon" className="md:hidden" />
            }
            heading={"Options"}
            content={
              <>
                <div className="my-[1rem] ">
                  <div className="mb-[1.4rem] text-[#64748B] flex items-center justify-center gap-3 rounded border-1 py-[0.8rem]  w-full">
                    <img src={Clock} alt="Clock Icon" />
                    <h4 className="text-[1.1rem] font-[500] m-0">
                      Compare Past Year
                    </h4>
                  </div>
                  <div className="text-[#64748B] flex items-center justify-center gap-3 rounded border-1  py-[0.8rem] w-full">
                    <img src={download} alt="Download Icon" />
                    <h4 className="text-[1.1rem] font-[500] m-0">
                      Download Computation
                    </h4>
                  </div>
                </div>
              </>
            }
            placement="bottom"
          />
        </div>
        <div className="flex w-full items-center justify-between my-[1.2rem]">
          <div className="md:flex hidden gap-4">
            <div className="text-[#64748B] flex items-center gap-1">
              <img src={Clock} alt="Clock Icon" />
              <h4 className="text-[0.9rem] font-[500] m-0">
                Compare Past Year
              </h4>
            </div>

            <InputField
              type="file"
              name="computationFile"
              downloadHandler={handleDownloadComputation}
              fileDownloadForLink={true}
              fileDownload={true}
              imgSrc={download} // Pass different image for Component 1
            />
          </div>

          <div className="flex gap-4">
            <div className="text-[#64748B] flex items-center gap-1 bg-[#F3EFF8] rounded px-2 py-1">
              <img src={info} alt="info Icon" />
              <h4 className="md:text-[0.9rem] text-[0.8rem] font-[500] m-0 text-[#334155]">
                {isOldRegime ? "Opted For Old Regime" : "Opted For New Regime"}
              </h4>
            </div>
            <div
              className="text-[#64748B]  flex items-center gap-1 px-2 py-1"
              onClick={handleSwitchRegime}
              style={{ cursor: "pointer" }}
            >
              <img src={switch_icon} alt="switch Icon" />
              <h4 className="md:text-[0.9rem] text-[0.8rem] font-[500] m-0 text-[#64748B]">
                Switch Regime
              </h4>
            </div>
          </div>
        </div>

        {refundType.map((item, index) => (
          <div
            key={item.id}
            className={`bg-white border-1 border-[#E2E8F0] rounded-lg ${
              item.id !== 8 && item.id !== 7 ? "mt-[1rem]" : "mt-0"
            }`}
          >
            {!excludedIds.includes(item.id) ? (
              <div>
                <div className="flex justify-between items-center bg-[#F8FAFC] px-3 py-4 ">
                  <div>
                    <h4
                      onClick={() => toggleSection(item.id)}
                      className=" md:font-[500] font-[600] text-[1.1rem] md:text-[1.1rem] text-[#334155] cursor-pointer"
                    >
                      {item.type}
                    </h4>
                    <h4 className=" font-[500] text-[0.9rem] text-[#64748B]">
                      {item.type_para}
                    </h4>
                  </div>
                  <div className="flex">
                    <button onClick={() => handleEditClick(item.path, item.id)}>
                      {item.id !== 9 && <img src={edit_icon} alt="edit icon" />}
                    </button>
                    <div onClick={() => toggleSection(item.id)} className="cursor-pointer">
                      <img
                        src={arrow}
                        className={`${
                          visibleSections[item.id]
                            ? ""
                            : "transform -rotate-180 duration-200"
                        } ml-6`}
                        alt="arrow"
                      />
                    </div>
                  </div>
                </div>
                {visibleSections[item.id] && (
                  <>
                    <hr className="m-0" />

                    {item.field_name.map((field) => (
                      <div className="px-3 " key={field.index}>
                        <h6 className="font-[600]  md:text-[1rem] text-[0.9rem] text-[#64748B] my-[1rem]">
                          {field.name}
                        </h6>

                        {field.list.map((list_items) => (
                          <div
                            className="md:flex justify-between my-[0.4rem]"
                            style={{
                              backgroundColor: list_items.background_color,
                            }}
                            key={list_items.index}
                          >
                            <h6
                              className="font-[400] text-[0.9rem] text-[#64748B]"
                              style={{
                                color: list_items.text_color,
                                fontSize: list_items.text_size,
                                fontWeight: list_items.text_weight,
                              }}
                            >
                              {list_items.name}
                            </h6>
                            <div
                              className="flex gap-2 text-center mb-[0.5rem] items-center w-[50%] "
                              style={{ width: list_items.field_width }}
                            >
                              <h4
                                className="text-[#64748B] text-[0.9rem] md:hidden"
                                style={{ color: list_items.color }}
                              >
                                Rs.
                              </h4>
                              <h4
                                className="text-[1.1rem] text-[#334155]"
                                style={{ color: list_items.color }}
                              >
                                {list_items.amount}
                              </h4>
                              <h4
                                className="text-[0.9rem] md:block hidden text-[#64748B]"
                                style={{ color: list_items.color }}
                              >
                                Rs.
                              </h4>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}

                    <div
                      className="px-3 md:flex justify-between my-[0.4rem]"
                      key={item.index}
                    >
                      <h6 className="font-[400] text-[0.9rem] text-[#64748B]">
                        {item.total_name}
                      </h6>
                      {item.total_name && (
                        <div
                          className="flex gap-2 text-center mb-[0.5rem] items-center w-[50%] "
                          style={{ width: item.total_field_width }}
                        >
                          <h4
                            className="text-[#64748B] text-[0.9rem] md:hidden"
                            style={{ color: item.total_color }}
                          >
                            Rs.
                          </h4>
                          <h4
                            className="text-[1.1rem] text-[#334155]"
                            style={{ color: item.total_color }}
                          >
                            {item.total_amount}
                          </h4>
                          <h4
                            className="text-[0.9rem] md:block hidden text-[#64748B]"
                            style={{ color: item.total_color }}
                          >
                            Rs.
                          </h4>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div
                className={`flex justify-between items-center px-3 py-4 border-[#E2E8F0] ${
                  item.id === 7 || item.id === 13
                    ? "md:bg-[#F8FAFC] bg-[#F1F5F9]"
                    : "bg-[#F3EFF8]"
                }`}
              >
                <div>
                  <h4
                    className={`${
                      item.id === 13
                        ? " font-[500] text-[0.9rem] text-[#64748B]"
                        : "md:font-[500] font-[600] text-[1.1rem] md:text-[1.1rem] text-[#334155]"
                    }`}
                  >
                    {item.type}
                  </h4>
                  <h4 className=" font-[500] text-[0.9rem] text-[#64748B]">
                    {item.type_para}
                  </h4>
                </div>
                <div
                  className=" md:flex gap-4 text-center mb-[0.5rem] items-center w-[50%] "
                  style={{ width: item.field_width }}
                >
                  <div className="flex gap-2 text-center">
                    <h4
                      className="text-[#64748B] text-[0.9rem] md:hidden "
                      style={{ color: item.color }}
                    >
                      Rs.
                    </h4>
                    <h4
                      className="text-[1.1rem] text-[text-[#334155]]"
                      style={{ color: item.color }}
                    >
                      {item.amount}
                    </h4>
                    <h4
                      className="text-[#64748B] text-[0.9rem] md:block hidden"
                      style={{ color: item.color }}
                    >
                      Rs.
                    </h4>
                  </div>
                  {item.id === 12 && (
                    <Button
                      px={"px-[1rem]"}
                      py={"py-[0.4rem]"}
                      name="Pay Now"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        ))}

        <div className="flex flex-row-reverse gap-4 mt-[1rem]">
          <Button
            px={"px-[1rem]"}
            py={"py-[0.5rem]"}
            name="Save and next"
            disabled={isDisabled}
            onClick={handleSaveAndNext}
            // onClick={handleSubmit(handleSaveAndNext)}
          />

          <button
            onClick={handleGoBack}
            className="font-[500] md:px-[2%] px-[3%] py-[0.5%] rounded-md bg-[#E8EAED]"
          >
            <span className="block md:hidden">Back</span>
            <span className="hidden md:block">Back to tax Summary</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Computation;
