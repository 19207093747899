import useAPICall from "hooks/useAPICall";
import { POST_OTP_API_URL } from "constants/apis";

const usePostOtp = () => {
  const [otpData, otpError, otpIsLoading, callPostOtp, setOtpSuccessData, setOtpError] =
    useAPICall(undefined, "");

    

  const defaultFallback = () => {
    // setOtpError(en.something_went_wrong);
    setOtpSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const otpData = res;
        if (otpData && typeof otpData === "object") {
          setOtpSuccessData(otpData);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const postOtp = (body) => {
    const url = POST_OTP_API_URL;
    // console.log(body)
    callPostOtp({
      url,
      method: "POST",
      statusObj,
      defaultFallback,
      body
    });
  };
  return [otpData, otpError, otpIsLoading, postOtp, setOtpSuccessData, setOtpError];
};

export default usePostOtp;
