import Button from "components/Button/BtnOrange";
import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useEffect, useState } from "react";
import usePostCredit from "../data/usePostCredit";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import RedButton from "components/Button/RedButton";
import download from "assets/images/liabilities/cloud-download.svg";
import { LIABILITIES_URL } from "constants/routes";
import { useLocation, useNavigate } from "react-router-dom";
import datePicker from "assets/images/incomeTax/datePicker.png";

const CreditCard = ({
  triggerElement,
  loanType,
  loanTypeId,
  setIsClose,
  isClose,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(0); // State for the current step
  const [visitedSteps, setVisitedSteps] = useState([]);
  const [availBalance, setavailBalance] = useState("");
  const [errors, setErrors] = useState({});

  const [postData, postError, postIsLoading, postCreditDetails] =
    usePostCredit();

  const [creditCardDebts, setCreditCardDebts] = useState([
    {
      id: "",
      card: {
        cardTitle: "",
        cardIssuer: "",
        issueDate: "",
        creditLimit: "",
        amtUtilised: "",
        availBalance: "",
      },
      statement: {
        statementRadio: "",
        statementDate: "",
        paymentDueDate: "",
        amtLastPaid: "",
        amtPayable: "",
      },
      // finalise: {},
    },
  ]);

  const handleCards = () => {
    setCreditCardDebts([
      ...creditCardDebts,
      {
        id: "",
        card: {
          cardTitle: "",
          cardIssuer: "",
          issueDate: "",
          creditLimit: "",
          amtUtilised: "",
          availBalance: "",
        },
        statement: {
          statementRadio: "",
          statementDate: "",
          paymentDueDate: "",
          amtLastPaid: "",
          amtPayable: "",
        },
        // finalise: {},
      },
    ]);
  };

  const resetState = () => {
    setCreditCardDebts([
      {
        id: "",
        card: {
          cardTitle: "",
          cardIssuer: "",
          issueDate: "",
          creditLimit: "",
          amtUtilised: "",
          availBalance: "",
        },
        statement: {
          statementRadio: "",
          statementDate: "",
          paymentDueDate: "",
          amtLastPaid: "",
          amtPayable: "",
        },
        // finalise: {},
      },
    ]);
    setCurrentStep(0);
    setErrors({});
    setVisitedSteps([]);
  };

  const handleChange = (e, index, field) => {
    const value = e.target.value;
    const updatedCards = creditCardDebts.map((lender, idx) => {
      if (idx === index) {
        return {
          ...lender,
          card: {
            ...lender.card,
            [field]: value,
          },
          statement: {
            ...lender.statement,
            [field]: value,
          },
        };
      }
      return lender;
    });
    setCreditCardDebts(updatedCards);
    // validateInputs();
  };

  const progressSteps = [
    {
      label: "Card Details",
      active: currentStep === 0,
      visited: visitedSteps.includes(1),
    },
    {
      label: "Statement & Payment",
      active: currentStep === 1,
      visited: visitedSteps.includes(2),
    },
    {
      label: "Finalise & Link Mandate",
      active: currentStep === 2,
      visited: visitedSteps.includes(3),
    },
  ];

  useEffect(() => {
    if (!visitedSteps.includes(currentStep)) {
      setVisitedSteps((prev) => [...prev, currentStep]);
    }
  }, [currentStep, visitedSteps]);

  const validateInputs = () => {
    const newErrors = {};
    const namePattern = /^[a-zA-Z\s]+$/; // Allows only letters and spaces

    creditCardDebts.forEach((lender, index) => {
      if (!lender.card.cardTitle) {
        newErrors[`lender${index}_cardTitle`] = "Card Title is required";
      } else if (!namePattern.test(lender.card.cardTitle)) {
        newErrors[`lender${index}_cardTitle`] =
          "Card Title should not contain special characters or numbers";
      }

      // Validate card issuer: no special characters or numbers
      if (!lender.card.cardIssuer) {
        newErrors[`lender${index}_cardIssuer`] = "Card Issuer is required";
      } else if (!namePattern.test(lender.card.cardIssuer)) {
        newErrors[`lender${index}_cardIssuer`] =
          "Card Issuer should not contain special characters or numbers";
      }

      // Validate issue date
      if (!lender.card.issueDate) {
        newErrors[`lender${index}_issueDate`] = "Issue Date is required";
      }

      // Validate credit limit: only whole numbers and must be >= 0
      if (!lender.card.creditLimit || !/^\d+$/.test(lender.card.creditLimit)) {
        newErrors[`lender${index}_creditLimit`] =
          "Valid Credit Limit is required (whole numbers only)";
      } else if (lender.card.creditLimit < 0) {
        newErrors[`lender${index}_creditLimit`] =
          "Credit Limit must be greater than or equal to 0";
      }

      // Validate amount utilized: only whole numbers and must be >= 0
      if (!lender.card.amtUtilised || !/^\d+$/.test(lender.card.amtUtilised)) {
        newErrors[`lender${index}_amtUtilised`] =
          "Valid Amount Utilised is required (whole numbers only)";
      } else if (lender.card.amtUtilised < 0) {
        newErrors[`lender${index}_amtUtilised`] =
          "Amount Utilised must be greater than or equal to 0";
      }

      // Validate available balance: only whole numbers and must be >= 0
      if (
        !lender.card.availBalance ||
        !/^\d+$/.test(lender.card.availBalance)
      ) {
        newErrors[`lender${index}_availBalance`] =
          "Valid Available Balance is required (whole numbers only)";
      } else if (availBalance < 0) {
        newErrors[`lender${index}_availBalance`] =
          "Available Balance must be greater than or equal to 0";
      }
    });

    if (currentStep === 1) {
      creditCardDebts.forEach((lender, index) => {
        // Validate statement radio selection
        if (!lender.statement.statementRadio) {
          newErrors[`lender${index}_statementRadio`] =
            "Please select a statement option";
        }

        if (
          lender.statement.statementRadio == "true" &&
          !lender.statement.statementDate
        ) {
          newErrors[`lender${index}_statementDate`] =
            "Statement Date is required";
        }

        // if (!lender.statement.statementDate) {
        //   newErrors[`lender${index}_statementDate`] =
        //     "Statement Date is required";
        // }

        // Validate payment due date
        if (!lender.statement.paymentDueDate) {
          newErrors[`lender${index}_paymentDueDate`] =
            "Payment Due Date is required";
        }

        // Validate amount last paid: only whole numbers and must be >= 0
        if (
          !lender.statement.amtLastPaid ||
          !/^\d+$/.test(lender.statement.amtLastPaid)
        ) {
          newErrors[`lender${index}_amtLastPaid`] =
            "Valid Amount Last Paid is required (whole numbers only)";
        } else if (lender.statement.amtLastPaid < 0) {
          newErrors[`lender${index}_amtLastPaid`] =
            "Amount Last Paid must be greater than or equal to 0";
        }

        // Validate amount payable: only whole numbers and must be >= 0
        if (
          !lender.statement.amtPayable ||
          !/^\d+$/.test(lender.statement.amtPayable)
        ) {
          newErrors[`lender${index}_amtPayable`] =
            "Valid Amount Payable is required (whole numbers only)";
        } else if (lender.statement.amtPayable < 0) {
          newErrors[`lender${index}_amtPayable`] =
            "Amount Payable must be greater than or equal to 0";
        }
      });
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // returns true if there are no errors
  };

  const handleRemoveCard = (index) => {
    setCreditCardDebts((prevLenders) =>
      prevLenders.filter((_, i) => i !== index)
    );
  };

  useEffect(() => {
    if (postData) {
      showSuccessToast("Credit Card Details posted");
      if (location.pathname === LIABILITIES_URL) {
        setCurrentStep(0);
        setVisitedSteps([]);
        setCreditCardDebts([
          {
            id: "",
            card: {
              cardTitle: "",
              cardIssuer: "",
              issueDate: "",
              creditLimit: "",
              amtUtilised: "",
              availBalance: "",
            },
            statement: {
              statementRadio: "",
              statementDate: "",
              paymentDueDate: "",
              amtLastPaid: "",
              amtPayable: "",
            },
            // finalise: {},
          },
        ]);
        setIsClose(true); // Set this to trigger closing of dialog
        // if(setIsReloadOverview){
        //   setIsReloadOverview(true)
        // }
        navigate(LIABILITIES_URL, { state: { value: true } });
      } else {
        navigate(LIABILITIES_URL); // Otherwise, navigate to the liabilities page
      }
    }
  }, [postData]);

  useEffect(() => {
    if (postError) {
      showErrorToast("Credit Card Details not posted");
    }
  }, [postError]);

  const createCreditData = () => {
    let createData = creditCardDebts.map((lenders, index) => ({
      card_type: lenders.card.cardTitle,
      card_issuer: lenders.card.cardIssuer,
      issue_date: lenders.card.issueDate,
      credit_limit: lenders.card.creditLimit,
      amount_utilised: lenders.card.amtUtilised,
      available_balance: lenders.card.availBalance,
      statement_generated: lenders.statement.statementRadio,
      // statement_date: lenders.statement.statementDate,
      ...(lenders.statement.statementRadio == "true" && {
        statement_date: lenders.statement.statementDate,
      }),
      payment_due_date: lenders.statement.paymentDueDate,
      amount_last_paid: lenders.statement.amtLastPaid,
      amount_payable: lenders.statement.amtPayable,
      // "upload_statement": null,
      // "download_statement": null,
      // "credit_report": null,
    }));
    postCreditDetails(createData, loanTypeId);
  };

  const handleNext = () => {
    const isValid = validateInputs();

    if (isValid) {
      if (currentStep < 3) {
        setCurrentStep(currentStep + 1);
      } else {
        createCreditData();
      }
    } else {
      console.log("Validation failed.");
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const onEditCreditCard = () => {
    setCurrentStep(0);
  };

  const onEditLoanPurpose = () => {
    setCurrentStep(1);
  };
  const onEditRepayment = () => {
    setCurrentStep(2);
  };

  const handleDownloadStatement = () => {};

  const getContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem] ">
              Enter Your Credit Card Details
            </h4>
            <div className="overflow-y-auto max-h-[300px] scrollbar-hide">
              {creditCardDebts.map((item, index) => (
                <div key={index}>
                  <div className="rounded-md bg-[#F1F5F9] mt-[1rem]">
                    <div className="grid grid-cols-2 gap-2 p-[1rem]">
                      <div className="input-container">
                        <label className="input-label">Card Title</label>
                        <InputField
                          type="text"
                          className="input-box border rounded-md bg-white"
                          value={item.card.cardTitle}
                          onChange={(e) => handleChange(e, index, "cardTitle")}
                        />
                        {errors[`lender${index}_cardTitle`] && (
                          <span className="text-red-500 text-[0.7rem]">
                            {errors[`lender${index}_cardTitle`]}
                          </span>
                        )}
                      </div>

                      <div className="input-container">
                        <label className="input-label">Card Issuer</label>
                        <InputField
                          className="input-box border rounded-md bg-white"
                          value={item.card.cardIssuer}
                          onChange={(e) => handleChange(e, index, "cardIssuer")}
                        />
                        {errors[`lender${index}_cardIssuer`] && (
                          <span className="text-red-500 text-[0.7rem]">
                            {errors[`lender${index}_cardIssuer`]}
                          </span>
                        )}
                      </div>

                      <div className="input-container">
                        <label className="input-label">Issue Date</label>
                        <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                          <span className="p-[0.5rem] rounded-l-md">
                            <img src={datePicker} className="w-5 h-5" />
                          </span>
                          <InputField
                            className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                            type="date"
                            value={item.card.issueDate}
                            onChange={(e) =>
                              handleChange(e, index, "issueDate")
                            }
                          />
                        </div>
                        {errors[`lender${index}_issueDate`] && (
                          <span className="text-red-500 text-[0.7rem]">
                            {errors[`lender${index}_issueDate`]}
                          </span>
                        )}
                      </div>

                      <div className="input-container">
                        <label className="input-label">Credit Limit</label>
                        <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                          <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                            Rs.
                          </span>
                          <InputField
                            className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                            value={item.card.creditLimit}
                            onChange={(e) =>
                              handleChange(e, index, "creditLimit")
                            }
                          />
                        </div>
                        {errors[`lender${index}_creditLimit`] && (
                          <span className="text-red-500 text-[0.7rem]">
                            {errors[`lender${index}_creditLimit`]}
                          </span>
                        )}
                      </div>

                      <div className="input-container">
                        <label className="input-label">Amount Utilised</label>
                        <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                          <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                            Rs.
                          </span>
                          <InputField
                            className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                            value={item.card.amtUtilised}
                            onChange={(e) =>
                              handleChange(e, index, "amtUtilised")
                            }
                          />
                        </div>
                        {errors[`lender${index}_amtUtilised`] && (
                          <span className="text-red-500 text-[0.7rem]">
                            {errors[`lender${index}_amtUtilised`]}
                          </span>
                        )}
                      </div>

                      <div className="input-container">
                        <label className="input-label">Available Balance</label>

                        <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                          <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                            Rs.
                          </span>
                          <InputField
                            className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                            value={item.card.availBalance}
                            onChange={(e) =>
                              handleChange(e, index, "availBalance")
                            }
                          />
                        </div>

                        {errors[`lender${index}_availBalance`] && (
                          <span className="text-red-500 text-[0.7rem]">
                            {errors[`lender${index}_availBalance`]}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {index > 0 && (
                    <div className="flex justify-end mt-2">
                      <RedButton
                        onClick={() => handleRemoveCard(index)}
                        name=" X Remove"
                        px={"px-[0.6rem]"}
                        py={"py-[0.4rem]"}
                      />
                    </div>
                  )}{" "}
                </div>
              ))}
            </div>
            <div className="flex justify-end mt-3">
              <WhiteButton
                onClick={handleCards}
                name="+ Add Multiple Lenders"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />
            </div>
          </>
        );
      case 1:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Statement and Payment Details
            </h4>
            <div className="overflow-y-auto max-h-[300px] scrollbar-hide">
              {creditCardDebts.map((item, index) => (
                <div key={index}>
                  <h4 className="text-[#334155] font-[500] text-[0.9rem] mt-[0.8rem]">
                    Credit Card - {index + 1}
                  </h4>
                  <div className="rounded-md bg-[#F1F5F9] ">
                    <div className="grid grid-cols-2 p-[1rem]">
                      <div className="input-container">
                        <label className="input-label">
                          Statement Generated
                        </label>

                        <div className="flex gap-3">
                          <label className="input-label focus-within:text-customPurple">
                            <InputField
                              type="radio"
                              value="true"
                              checked={item.statement.statementRadio == "true"}
                              className="mr-2 custom-radio"
                              onChange={(e) =>
                                handleChange(e, index, "statementRadio")
                              }
                            />
                            Yes
                          </label>

                          <label className="input-label focus-within:text-customPurple">
                            <InputField
                              type="radio"
                              value="false"
                              checked={item.statement.statementRadio == "false"}
                              className="mr-2 custom-radio"
                              onChange={(e) =>
                                handleChange(e, index, "statementRadio")
                              }
                            />
                            No
                          </label>

                          {errors[`lender${index}_statementRadio`] && (
                            <span className="text-red-500 text-[0.7rem]">
                              {errors[`lender${index}_statementRadio`]}
                            </span>
                          )}
                        </div>
                        {item.statement.statementRadio === "false" ? (
                          ""
                        ) : (
                          <div className="bg-white py-[0.3rem] my-[0.5rem] rounded-md border flex justify-center">
                            <InputField
                              fileName={"Download Statement"}
                              type="file"
                              downloadHandler={handleDownloadStatement}
                              fileDownloadForLink={true}
                              fileDownload={true}
                              imgSrc={download}
                              // disabled={item.statement.statementRadio === "false"}
                              className={`${
                                item.statement.statementRadio === "false"
                                  ? "cursor-not-allowed opacity-50"
                                  : ""
                              }`}
                            />
                          </div>
                        )}
                      </div>

                      <div className="input-container"></div>

                      <div className="input-container ">
                        <label className="input-label">Statement Date</label>
                        <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                          <span className="p-[0.5rem] rounded-l-md">
                            <img src={datePicker} className="w-5 h-5" />
                          </span>
                          <InputField
                            className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                            disabled={item.statement.statementRadio === "false"} // Disable if "No" is selected
                            type="date"
                            value={item.statement.statementDate}
                            placeholder={
                              item.statement.statementRadio === "false"
                                ? ""
                                : "dd-mm-yyyy"
                            }
                            onChange={(e) =>
                              handleChange(e, index, "statementDate")
                            }
                          />
                        </div>
                        {item.statement.statementRadio == "true" &&
                          errors[`lender${index}_statementDate`] && (
                            <span className="text-red-500 text-[0.7rem]">
                              {errors[`lender${index}_statementDate`]}
                            </span>
                          )}
                      </div>

                      <div className="input-container ">
                        <label className="input-label">Payment Due Date</label>
                        <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                          <span className="p-[0.5rem] rounded-l-md">
                            <img src={datePicker} className="w-5 h-5" />
                          </span>
                          <InputField
                            className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                            type="date"
                            value={item.statement.paymentDueDate}
                            onChange={(e) =>
                              handleChange(e, index, "paymentDueDate")
                            }
                          />
                        </div>
                        {errors[`lender${index}_paymentDueDate`] && (
                          <span className="text-red-500 text-[0.7rem]">
                            {errors[`lender${index}_paymentDueDate`]}
                          </span>
                        )}
                      </div>

                      <div className="input-container mt-[0.5rem]">
                        <label className="input-label">Amount Last paid</label>
                        <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                          <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                            Rs.
                          </span>
                          <InputField
                            className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                            type="number"
                            value={item.statement.amtLastPaid}
                            onChange={(e) =>
                              handleChange(e, index, "amtLastPaid")
                            }
                          />
                        </div>

                        {errors[`lender${index}_amtLastPaid`] && (
                          <span className="text-red-500 text-[0.7rem]">
                            {errors[`lender${index}_amtLastPaid`]}
                          </span>
                        )}
                      </div>

                      <div className="input-container mt-[0.5rem]">
                        <label className="input-label">Amount Payable</label>
                        <div className="group focus-within:border-customPurple flex items-center border-1 border-[#94A3B8] rounded-md w-[91%]">
                          <span className="group-focus-within:text-customPurple p-[0.5rem] bg-[#F1F5F9] text-lightGray text-[0.9rem] rounded-l-md">
                            Rs.
                          </span>
                          <InputField
                            className="text-[#334155] text-[0.9rem] bg-white focus:outline-none p-[0.4rem] w-[90%] rounded-r"
                            type="number"
                            value={item.statement.amtPayable}
                            onChange={(e) =>
                              handleChange(e, index, "amtPayable")
                            }
                          />
                        </div>

                        {errors[`lender${index}_amtPayable`] && (
                          <span className="text-red-500 text-[0.7rem]">
                            {errors[`lender${index}_amtPayable`]}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        );
      case 2:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Finalise & Link Mandate
            </h4>
            <div className="rounded-md bg-[#F1F5F9] mt-[1rem]">
              <div className="flex justify-center p-[1rem]">
                <WhiteButton
                  name="Link to Mandate"
                  px={"px-[0.7rem]"}
                  py={"py-[0.3rem]"}
                />
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">Preview</h4>
            <div className="overflow-y-auto max-h-[300px] scrollbar-hide">
              {creditCardDebts.map((item, index) => (
                <>
                  <h4 className="text-[#334155] font-[500] text-[0.9rem] mt-[0.8rem]">
                    Credit Card - {index + 1}
                  </h4>
                  <div className="bg-[#F1F5F9] p-[1rem] rounded-md">
                    {/* loan and lender info */}
                    <div className="flex justify-between items-center">
                      <h4 className="text-[#334155] font-[500] text-[1.1rem] ">
                        Enter Your Credit Card Details
                      </h4>
                      <WhiteButton
                        onClick={onEditCreditCard}
                        name="Edit"
                        px={"px-[0.7rem]"}
                        py={"py-[0.3rem]"}
                      />
                    </div>

                    <div className="bg-white grid grid-cols-2 p-[1rem] border rounded-md mt-1">
                      <div className="input-container">
                        <label className="input-label">Card Title</label>
                        <InputField
                          readOnly
                          type="text"
                          className="input-box border rounded-md bg-white"
                          value={item.card.cardTitle}
                        />
                      </div>

                      <div className="input-container">
                        <label className="input-label">Card Issuer</label>
                        <InputField
                          value={item.card.cardIssuer}
                          readOnly
                          className="input-box border rounded-md bg-white"
                        />
                      </div>

                      <div className="input-container">
                        <label className="input-label">Issue Date</label>
                        <InputField
                          value={item.card.issueDate}
                          readOnly
                          type="date"
                          className="input-box border rounded-md bg-white"
                        />
                      </div>
                      <div className="input-container">
                        <label className="input-label">Credit Limit</label>
                        <InputField
                          value={item.card.creditLimit}
                          readOnly
                          className="input-box border rounded-md bg-white"
                        />
                      </div>

                      <div className="input-container">
                        <label className="input-label">Amount Utilised</label>
                        <InputField
                          value={item.card.amtUtilised}
                          readOnly
                          className="input-box border rounded-md bg-white"
                        />
                      </div>

                      <div className="input-container">
                        <label className="input-label">Available Balance</label>
                        <InputField
                          value={item.card.availBalance}
                          readOnly
                          className="input-box border rounded-md bg-white"
                        />
                      </div>
                    </div>

                    {/* loan purpose & interest */}
                    <div className="mt-[1rem] flex justify-between items-center">
                      <h4 className="text-[#334155] font-[500] text-[1.1rem]">
                        Statement and Payment Details
                      </h4>
                      <WhiteButton
                        onClick={onEditLoanPurpose}
                        name="Edit"
                        px={"px-[0.7rem]"}
                        py={"py-[0.3rem]"}
                      />
                    </div>

                    <div className="bg-white grid grid-cols-2 p-[1rem] border rounded-md mt-1">
                      <div className="input-container">
                        <label className="input-label">
                          Statement Generated
                        </label>

                        <div className="flex gap-3">
                          <label className="input-label focus-within:text-customPurple">
                            <InputField
                              // name="senior_citizen_self"
                              type="radio"
                              value="true"
                              checked={item.statement.statementRadio === "true"}
                              className="mr-2 custom-radio"
                            />
                            Yes
                          </label>

                          <label className="input-label focus-within:text-customPurple">
                            <InputField
                              // name="senior_citizen_self"
                              type="radio"
                              value="false"
                              checked={
                                item.statement.statementRadio === "false"
                              }
                              className="mr-2 custom-radio"
                            />
                            No
                          </label>
                        </div>
                        <input type="file" className="" />
                      </div>

                      <div className="input-container"></div>

                      <div className="input-container">
                        <label className="input-label">Statement Date</label>
                        <InputField
                          readOnly
                          type="date"
                          className="input-box border rounded-md bg-white"
                          value={item.statement.statementDate}
                        />
                      </div>

                      <div className="input-container">
                        <label className="input-label">Payment Due Date</label>
                        <InputField
                          readOnly
                          value={item.statement.paymentDueDate}
                          type="date"
                          className="input-box border rounded-md bg-white"
                        />
                      </div>

                      <div className="input-container">
                        <label className="input-label">Amount Last paid</label>
                        <InputField
                          readOnly
                          value={item.statement.amtLastPaid}
                          type="number"
                          className="input-box border rounded-md bg-white"
                        />
                      </div>

                      <div className="input-container">
                        <label className="input-label">Amount Payable</label>
                        <InputField
                          readOnly
                          value={item.statement.amtPayable}
                          type="number"
                          className="input-box border rounded-md bg-white"
                        />
                      </div>
                    </div>

                    {/* repayment info */}
                    <div className="mt-[1rem] flex justify-between items-center">
                      <h4 className="text-[#334155] font-[500] text-[1.1rem]">
                        Finalise & Link Mandate
                      </h4>
                      <WhiteButton
                        onClick={onEditRepayment}
                        name="Edit"
                        px={"px-[0.7rem]"}
                        py={"py-[0.3rem]"}
                      />
                    </div>

                    <div className="bg-white grid grid-cols-2 p-[1rem] border rounded-md mt-1">
                      <div className="flex justify-center p-[1rem]">
                        <WhiteButton
                          name="Link to Mandate"
                          px={"px-[0.7rem]"}
                          py={"py-[0.3rem]"}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      <DialogBox
        onClose={resetState}
        triggerElement={triggerElement}
        progressSteps={progressSteps}
        heading={
          <h4 className="font-[600] text-xl text-[#334155]">
            Add <span className="text-[#9065B4]">{loanType}</span> to your
            Liabilities
          </h4>
        }
        content={(handleClose) => (
          <>
            {getContent()}
            <div className="flex justify-end gap-[1rem] mt-[1rem]">
              {currentStep < 3 ? (
                <WhiteButton
                  onClick={handleBack}
                  name="Back"
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
              ) : null}

              {currentStep < 3 ? (
                <Button
                  onClick={handleNext}
                  name={currentStep === 2 ? "Preview & Save" : "Next"}
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
              ) : (
                <Button
                  onClick={handleNext}
                  name="Add to Liability"
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
              )}
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
    </div>
  );
};

export default CreditCard;
