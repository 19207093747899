import React, { useEffect, useRef, useState } from "react";
import landingPic from "assets/images/assetsAndLiabilities/liabilitiesLandingPic.svg";
import trackingPic from "assets/images/assetsAndLiabilities/TrackLiabilities.svg";
import alarmPic from "assets/images/assetsAndLiabilities/alarm-check.svg";
import rocketPic from "assets/images/assetsAndLiabilities/rocket.svg";
import homePic from "assets/images/assetsAndLiabilities/Home.svg";
import carPic from "assets/images/assetsAndLiabilities/Car.svg";
import loanProperty from "assets/images/assetsAndLiabilities/Loan Property.svg";
import goldPic from "assets/images/assetsAndLiabilities/Gold.svg";
import medicalVanPic from "assets/images/assetsAndLiabilities/MedicalVan.svg";
import emergency from "assets/images/assetsAndLiabilities/Emergency.svg";
import heartPic from "assets/images/assetsAndLiabilities/Heart.svg";
import businessLoanPic from "assets/images/assetsAndLiabilities/BusinessLoan.svg";
import educationPic from "assets/images/assetsAndLiabilities/Education.svg";
import friendshipPic from "assets/images/assetsAndLiabilities/Friendship.svg";
import diamondPic from "assets/images/assetsAndLiabilities/Diamond.svg";
import useGetLoanTypes from "./data/useGetLoanTypes";
import AddLoanTypeDialog from "./AddLoanTypeDialog";
import AddYourLiability from "./AddYourLiability";

const LiabilitiesLandingPage = ({setIndividualId}) => {
  const [getData, getError, getIsLoading, getLoanTypes] =
  useGetLoanTypes();

  const [securedLoans, setSecuredLoans]=useState([]);
  const [unSecuredLoans,setUnSecuredLoans]= useState([]);
  const [emergencyLoans,setEmergencyLoans] = useState([]);
  const [familyLoans, setFamilyLoans]=useState([]);
  const [revolvingCredit,setRevolvingCredit]= useState([]);
  const [selectedLoan, setSelectedLoan]=useState(null);
  const [loanTypeId, setLoanTypeId]=useState(null)

  const loanButtonRef=useRef(null)
  useEffect(()=>{
   getLoanTypes()
  },[])

  const handleLoanClick = (loanType, id) => {
    setLoanTypeId(id)
    setSelectedLoan(loanType);
    loanButtonRef.current.click();
  };

  const images={
    "Car Loan":carPic,
    "Loan Against Property":loanProperty,
    "Gold Loan":goldPic,
    "Home Loan":homePic,
    "Personal Loan":heartPic,
    "Business Loan":businessLoanPic,
    "Education Loan":educationPic,
    "Medical Loan":medicalVanPic,
    "Emergency Personal Loan":emergency,
    "Family Loan":friendshipPic,
    "Loan From Friends":friendshipPic,
    "Loan For Wedding Expense":diamondPic,
    "Credit Card Debt":homePic
  }

  useEffect(()=>{
    if(getData?.data){
      const typeWithId={}
      const secured = [];
      const unsecured = [];
      const emergency = [];
      const family = [];
      const revolving = [];
         // Categorize items in the data
    getData.data.forEach((item) => {
      typeWithId[item.loan_name] = item.id; 
      if (item.loan_category === "SECURED") {
        item.img=images[item.loan_name]
        secured.push(item);
      } else if (item.loan_category === "UNSECURED") {
        item.img=images[item.loan_name]
        unsecured.push(item);
      } else if (item.loan_category === "EMERGENCY") {
        item.img=images[item.loan_name]
        emergency.push(item);
      } else if (item.loan_category === "FAMILY_INFORMAL") {
        item.img=images[item.loan_name]
        family.push(item);
      } else {
        item.img=images[item.loan_name]
        revolving.push(item);
      }
    });

    // Set state once for each category
    setSecuredLoans(secured);
    setUnSecuredLoans(unsecured);
    setEmergencyLoans(emergency);
    setFamilyLoans(family);
    setRevolvingCredit(revolving);
    setIndividualId(typeWithId)
  }
  },[getData])
  
  // const securedLoans = [
  //   {
  //     img: homePic,
  //     text: "Home Loan",
  //   },
  //   {
  //     img: carPic,
  //     text: "Car Loan",
  //   },
  //   {
  //     img: loanProperty,
  //     text: "Loan against Property",
  //   },
  //   {
  //     img: goldPic,
  //     text: "Gold Loan",
  //   },
  // ];
  // const unSecuredLoans = [
  //   {
  //     img: heartPic,
  //     text: "Personal Loan",
  //   },
  //   {
  //     img: businessLoanPic,
  //     text: "Business Loan",
  //   },
  //   {
  //     img: educationPic,
  //     text: "Education Loan",
  //   },
  // ];
  // const emergencyLoans = [
  //   {
  //     img: medicalVanPic,
  //     text: "Medical Loan",
  //   },
  //   {
  //     img: emergency,
  //     text: "Emergency Personal Loan",
  //   },
  // ];
  // const familyLoans = [
  //   {
  //     img: friendshipPic,
  //     text: "Family Loan",
  //   },
  //   {
  //     img: friendshipPic,
  //     text: "Loan from Friends",
  //   },
  //   {
  //     img: diamondPic,
  //     text: "Loan for Wedding Expense",
  //   },
  // ];
  // const revolvingCredit = [
  //   {
  //     img: homePic,
  //     text: "Credit Card Debt",
  //   },
  // ];
  return (
    <div className="bg-gray-100 md:px-8 px-6 pt-4 md:mb-0 h-screen">
      <h4 className="text-[#334155]">Liabilities</h4>
      <div className="bg-[#FFFFFF] border-[1px] border-[#D9D9D9] rounded-lg p-1.5 mt-8 pb-4 flex justify-between">
        <div className="w-[40%] flex flex-col justify-center items-center">
          <div className="pb-4">
            <img src={landingPic} alt="landingPic" height="170px" width='160px'/>
          </div>
          <div className="flex flex-col items-center justify-center gap-2">
            <h4 className="m-0 text-[#334155] mb-1 font-[600] text-[1.3rem]">
              Track all Liabilities in one place
            </h4>
            <div className="flex gap-1.5 w-[90%]">
              <img src={trackingPic} alt="trackingPic" />
              <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
                Track all liabilities in one place
              </p>
            </div>
            <div className="flex gap-1.5 w-[90%]">
              <img src={alarmPic} alt="alarmPic" />
              <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
                Get timely Payment reminders
              </p>
            </div>
            <div className="flex gap-1.5 mb-1.5 w-[90%]">
              <img src={rocketPic} alt="rocketPic" />
              <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
                Manage documentation with ease
              </p>
            </div>
            {/* <button className="rounded-md bg-[#9065B4] text-[#FFFFFF] text-[0.9rem] py-1 px-1.5 ">
              + Add Your First Liability
            </button> */}

              <AddYourLiability initial={true}/>

          </div>
        </div>
        <div className="w-[55%]">
          <h6 className="m-0 text-[#334155] mt-2 mb-2">
            Start managing your liabilities effectively by adding your first
            liability
          </h6>
          <div className="flex gap-x-3 mt-3">
            <div className="flex flex-col gap-y-3">
              <div className="rounded-md border-[0.5px] border-[#D9D9D9] p-2 flex flex-col gap-1.5">
                <p className="m-0 text-[#64748B] text-[0.9rem] font-[600]">Secured Loans</p>
                {securedLoans.map((el, i) => {
                  return (
                    <div
                      onClick={()=>handleLoanClick(el.loan_name, el.id)}
                      className="flex gap-1 p-[0.15rem] rounded-md shadow-sm bg-[#FAF8FC] border-[1px] border-[#EAE1F3] cursor-pointer hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                      key={i} 
                    >
                      <img src={el.img} alt="homePic" />
                      <p className="m-0 text-[#334155] text-[0.9rem]">
                        {el.loan_name}
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className="rounded-md border-[0.5px] border-[#D9D9D9] p-2 flex flex-col gap-1.5">
                <p className="m-0 text-[#64748B] text-[0.9rem] font-[600]">Emergency loans</p>
                {emergencyLoans.map((el, i) => {
                  return (
                    <div
                    onClick={()=>handleLoanClick(el.loan_name, el.id)}
                      className="flex gap-1 p-[0.15rem] rounded-md shadow-sm bg-[#FAF8FC] border-[1px] border-[#EAE1F3] cursor-pointer hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                      key={i}
                    >
                      <img src={el.img} alt="homePic" />
                      <p className="m-0 text-[#334155] text-[0.9rem]">
                        {el.loan_name}
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className="rounded-md border-[0.5px] border-[#D9D9D9] p-2 flex flex-col gap-1.5">
                <p className="m-0 text-[#64748B] text-[0.9rem] font-[600]">Revolving Credit</p>
                {revolvingCredit.map((el, i) => {
                  return (
                    <div
                    onClick={()=>handleLoanClick(el.loan_name, el.id)}
                      className="flex gap-1 p-[0.15rem] rounded-md shadow-sm bg-[#FAF8FC] border-[1px] border-[#EAE1F3] cursor-pointer hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                      key={i}
                    >
                      <img src={el.img} alt="homePic" />
                      <p className="m-0 text-[#334155] text-[0.9rem]">
                        {el.loan_name}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex flex-col gap-y-3">
              <div className="rounded-md border-[0.5px] border-[#D9D9D9] p-2 flex flex-col gap-1.5">
                <p className="m-0 text-[#64748B] text-[0.9rem] font-[600]">Unsecured Loans</p>
                {unSecuredLoans.map((el, i) => {
                  return (
                    <div
                    onClick={()=>handleLoanClick(el.loan_name, el.id)}
                      className="flex gap-1 p-[0.15rem] rounded-md shadow-sm bg-[#FAF8FC] border-[1px] border-[#EAE1F3] cursor-pointer hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                      key={i}
                    >
                      <img src={el.img} alt="homePic" />
                      <p className="m-0 text-[#334155] text-[0.9rem]">
                        {el.loan_name}
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className="rounded-md border-[0.5px] border-[#D9D9D9] p-2 flex flex-col gap-1.5">
                <p className="m-0 text-[#64748B] text-[0.9rem] font-[600]">Family & Informal Loans</p>
                {familyLoans.map((el, i) => {
                  return (
                    <div
                    onClick={()=>handleLoanClick(el.loan_name, el.id)}
                      className="flex gap-1 p-[0.15rem] rounded-md shadow-sm bg-[#FAF8FC] border-[1px] border-[#EAE1F3] cursor-pointer hover:border-customPurple hover:bg-white hover:shadow-md hover:scale-[1.02] transform transition-transform duration-200 ease-in-out overflow-hidden"
                      key={i}
                    >
                      <img src={el.img} alt="homePic" />
                      <p className="m-0 text-[#334155] text-[0.9rem]">
                        {el.loan_name}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <AddLoanTypeDialog
          loanType={selectedLoan}
          loanTypeId={loanTypeId}
          triggerElement={
            <button ref={loanButtonRef} className="hidden">
              Open Loan Dialog
            </button>
          }
        />
        </div>
      </div>
    </div>
  );
};

export default LiabilitiesLandingPage;
