import React, { useEffect, useRef, useState } from "react";
import AssetsHeading from "../AssetsHeading";
import { Link, useLocation } from "react-router-dom";
import { ASSETS_OTHER_ASSET_DETAILED_URL} from "constants/routes";
import intellectualPic from "assets/images/assets/intellectual.svg";
import AddOtherAsset from "./AddOtherAsset";
import useGetIntellectualDetails from "../data/useGetIntellectualDetails";
import AddNewIntellectual from "./Add/AddNewIntellectual";
import Button from "components/Button/BtnOrange";

const Intellectual = () => {
  const location = useLocation();
  const [isAddIntellectual, setIsAddIntellectual] = useState(false);
  const [reload, setIsReload]= useState(false)
  const addIntellectualBtnRef= useRef(null)
  const queryParams = new URLSearchParams(location.search);

  const intellectualId =
    location.state?.landId || decodeURIComponent(queryParams.get("id"));

  const [getIntellectualData, getIntellectualError, getIntellectualIsLoading, getIntellectual] =
    useGetIntellectualDetails();


  useEffect(() => {
    getIntellectual(intellectualId);
  }, []);

  useEffect(()=>{
    if(reload){
      getIntellectual(intellectualId)
    }
  },[reload])

  useEffect(()=>{
    if(isAddIntellectual){
      addIntellectualBtnRef.current.click()
    }
  },[isAddIntellectual])

  const openHandleForm=()=>{
     setIsAddIntellectual(true)
  }
  
  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 h-screen">
      <AssetsHeading asset={"Other Assets"} asset_type={"Intellectual Property"}/>
      <div className="flex mt-[1.5rem] border bg-white rounded-xl  grid grid-cols-12 divide-x">
        <div className=" p-[1rem] col-span-3">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
          Total Intellectual Property Value
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">₹{getIntellectualData?.data[0]?.total_intellectual_property_value}</h4>
        </div>  

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Total Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">{getIntellectualData?.data[0]?.total_assets}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Active Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">{getIntellectualData?.data[0]?.active_assets}</h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Idle Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">{getIntellectualData?.data[0]?.idle_assets}</h4>
        </div>
        <div className="flex justify-center items-center p-[1rem] col-span-3">
        <Button onClick={openHandleForm} name={"+ Add New IP"} px={"px-[0.6rem]"} py={"py-[0.4rem]"} />
        </div>
      </div>

      {/* lands */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
       
        <div className="grid grid-cols-4 gap-2 ">
          {getIntellectualData ? (
            getIntellectualData?.data?.map((item, index) => (
              <Link
                to={`${ASSETS_OTHER_ASSET_DETAILED_URL.replace(
                  ":other_asset_type",
                  "IntellectualProperty"
                )}?id=${intellectualId}/?loan_id=${item.id}`}
                state={{ assetId: intellectualId, itemId: item.id, itemName: item?.ip_type }}
                key={item.id}
                className="no-underline"
              >
                <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
                  <img src={intellectualPic} alt="land" className="w-7 h-7 mb-2" />
                  <h5 className="text-lightGray font-[600] text-[0.9rem]">
                   {item?.ip_type}
                  </h5>
                  <div className="flex justify-end mt-[1.8rem]">
                    <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                      ₹ {item?.amount_invested}
                    </h4>
                  </div>{" "}
                </div>
              </Link>
            ))
          ) : (
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={intellectualPic} alt="land" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">Loans and Advances</h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                  Add New
                </button>
              </div>{" "}
            </div>
          )}
        </div>
      </div>
      {isAddIntellectual && (
        <AddNewIntellectual
          categoryId={intellectualId}
          setIsReload={setIsReload}
          setIsAddIntellectual={setIsAddIntellectual}
          isAddIntellectual={isAddIntellectual}
          triggerElement={
            <button ref={addIntellectualBtnRef} className="hidden">
              Add Intellectual
            </button>
          }
        />
      )}
    </div>
  );
};

export default Intellectual;
