import * as React from "react";
import { useState, useEffect, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import successLogo from "assets/images/Serviceimages/Success Icon.svg";
import { MuiOtpInput } from "mui-one-time-password-input";
import { myContext } from "App";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Input from "components/input/Input";
import GoogleLogin from "containers/LoginAndSignUp/GoogleLogin";

import usePostLogin from "./Data/usePostLogin";
import usePostRegistration from "./Data/usePostRegistration";
import usePostOtp from "./Data/usePostOtp";
import useGetUser from "./Data/useGetUserDetails";
import usePostWorkOrder from "containers/privateRoutes/Orders/data/usePostWorkOrder";
import MobileForgotPswd from "components/BottomSheet/MobileForgotPswd";
import usePostNewLogin from "containers/BusinessLoginSignUp.js/data/usePostNewLogin";

export default function FormDialog(props, serviceData) {
  const [sendLogin, setSendLogin] = useState(false);
  const [sendRegistration, setSendRegistration] = useState(false);
  const [sendOtp, setSendOtp] = useState(false);
  const [applyNowClicked, setApplyNowClicked] = useState(false);

  const [workOrderData, workOrderError, workOrderIsLoading, postWorkOrder] =
    usePostWorkOrder();

  const { isLoggedIn, setIsLoggedIn } = useContext(myContext);
  const [data, error, isLoading, postLogin, setSuccessData, setError] =
    usePostLogin();
  // const [data, error, isLoading, postLogin] = usePostNewLogin();
  const [
    registrationData,
    registrationError,
    registrationIsLoading,
    postRegistration,
  ] = usePostRegistration();

  const [UserData, userError, userIsLoading, getUser] = useGetUser();

  const [open, setOpen] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [login, setLogin] = useState();
  const [signup, setSignup] = useState(false);
  const [otp, setOtp] = useState("");
  const [loginDisabled, setLoginDisabled] = useState(true);
  const [RegisterPassword, setRegisterPassword] = useState("");
  const [err, setErr] = useState("");

  const [registrationSuccess, setRegistrationSuccess] = useState();

  const [type, setType] = useState("Individual");
  const [typeClicked, setTypeClicked] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [BusinessMobNumber, setBusinessMobNumber] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessTypeCode, setBusinessTypeCode] = useState(1);
  const [borderColor, setBorderColor] = useState("");
  const [busNameErr, setBusNameErr] = useState(false);
  const [busConPersonErr, setBusConPersonErr] = useState(false);
  const [busMobNoErr, setBusMobNoErr] = useState(false);
  const [busDOBErr, setBusDOBErr] = useState(false);
  const [MobNoErr, setMobNoErr] = useState(false);
  const [passwordErr, setpasswordErr] = useState(false);
  const [stateErr, setstateErr] = useState(false);
  const [emailErr, setemailErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);

  useEffect(() => {
    if (registrationError) {
      setSendRegistration(false);
      console.log(registrationError.err.response.data.data);
      if (registrationError.err.response.data.data?.full_name?.length > 0) {
        setNameErr(true);
      }
      if (registrationError.err.response.data.data?.business_name?.length > 0) {
        setBusNameErr(true);
      }
      if (
        registrationError.err.response.data.data?.business_contact_person
          ?.length > 0
      ) {
        setBusConPersonErr(true);
      }
      if (
        registrationError.err.response.data.data?.business_mobile_number
          ?.length > 0
      ) {
        setBusMobNoErr(true);
      }
      if (registrationError.err.response.data.data?.date_of_birth?.length > 0) {
        setBusDOBErr(true);
      }
      if (registrationError.err.response.data.data?.mobile_number?.length > 0) {
        setMobNoErr(true);
      }
      if (registrationError.err.response.data.data?.password?.length > 0) {
        setpasswordErr(true);
      }
      if (registrationError.err.response.data.data?.state?.length > 0) {
        setstateErr(true);
      }
      if (registrationError.err.response.data.data?.email?.length > 0) {
        setemailErr(true);
      }

      // setemailErr(true);
    }
  }, [registrationError]);
  const clientTypeArr = [
    "Individual",
    "Proprietorship (Business)",
    "HUF",
    "Firm",
    "LLP",
    "Private Limited Company",
    "Public Limited Company",
    "Trust",
  ];

  useEffect(() => {
    if (isLoggedIn === undefined) {
      setLogin(true);
    } else if (isLoggedIn === false) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }, []);

  const [showErr, setShowErr] = useState();

  const [password, setPassword] = useState();
  const [registerMobileNo, setRegisterMobileNo] = useState("");
  const [registerName, setRegisterName] = useState("");
  const [DOB, setDOB] = useState("");
  const [state, setState] = useState();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const location = useLocation();
  // const showFormDialog = location.state?.setOpen || false;
  const showFormDialog = props.state?.setOpen || false;

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  useEffect(() => {
    if (showFormDialog) {
      setOpen(true);
    }
  }, [showFormDialog]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowForgotPassword(false);
  };
  // doubt
  const handleSubmit = () => {
    if (mobileNumber.length > 8) {
      setLoginDisabled(false);
    } else {
      setLoginDisabled(true);
    }
  };

  var loginStatus;
  var token;
  var expiryDate;
  var user;

  useEffect(() => {
    // if (sendLogin) {
    //   postLogin({ email_or_mobile: mobileNumber, password: password });
    // }
    if (sendLogin) {
      if (validateInputs()) {
        // setIsLoadingLogin(true)
        postLogin({ email_or_mobile: mobileNumber, password: password });
      }
      setSendLogin(false);
    }
  }, [sendLogin]);

  useEffect(() => {
    if (error) {
      const errorMessage =
        error.err.response?.data?.error?.errors[0]?.detail ||
        "An error occurred";
      setErr(errorMessage);
      console.log(errorMessage);
      setBorderColor("border-red-500");
      // setIsLoadingLogin(false)
      // toast.error(errorMessage);
    }
  }, [error]);

  useEffect(() => {
    if (data || error) {
      console.log("uuuu");
      if (data?.status_code === 200) {
        setLogin(false);
        setIsLoggedIn(true);
        if (props.fileNowPath) {
          navigate("/income-tax-profile");
        } else {
          if (props.serviceData && props.serviceData.sendData) {
            postWorkOrder({
              service_id: props.serviceData.sendData.id,
              service_name: props.serviceData.sendData.service_title,
            });
          }
        }
      } else {
        setShowErr(
          error?.err?.response?.data?.error?.errors[0]?.detail ||
            "An error occurred"
        );
        setSendLogin(false);
      }
    }
  }, [data, error]);

  const userName = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (isLoggedIn) {
      // applyNow();
    }
  }, [isLoggedIn]);

  const applyNow = () => {
    if (props.serviceData && props.serviceData.sendData) {
      postWorkOrder({
        service_id: props.serviceData.sendData.id,
        service_name: props.serviceData.sendData.service_title,
      });
    }
  };

  if (data) {
    if (data.status_code === 200) {
      loginStatus = data.status_code;
      token = data.data.token;
      expiryDate = data.data.expiry;
      user = data.data.user;
      localStorage.setItem("token", token);
      localStorage.setItem("expiry", expiryDate);
      localStorage.setItem("user", JSON.stringify(user));
      setIsLoggedIn(true);
    }
  }
  const fullName = registerName ? registerName.trim() : "";

  useEffect(() => {
    if (sendRegistration) {
      if (type === "Individual") {
        postRegistration({
          mobile_number: registerMobileNo,
          full_name: fullName,
          // date_of_birth: DOB,
          // state: state,
          email: email,
          password: RegisterPassword,
          client_type: "1",
        });
      } else {
        postRegistration({
          mobile_number: registerMobileNo,
          // full_name: registerName,
          // date_of_birth: DOB,
          // state: state,
          email: email,
          password: RegisterPassword,
          client_type: "3",
          business_name: businessName,
          // business_contact_person: contactPerson,
          business_mobile_number: BusinessMobNumber,
          // business_email: businessEmail,
        });
      }
    }
  }, [sendRegistration]);

  var registrationStatusCode;

  useEffect(() => {
    if (registrationStatusCode === 201) {
      setSignup(false);
      // setIsOtp(true);
      setRegistrationSuccess(true);
    }
  }, [registrationData]);

  if (registrationData) {
    if (registrationData.status_code === 201) {
      registrationStatusCode = registrationData.status_code;
    }
  }

  const validateInputs = () => {
    setBorderColor("");
    setErr("");

    if (!mobileNumber) {
      setErr("Mobile Number/ Email is required.");
      setBorderColor("border-red-500");
      return false;
    }

    if (!password) {
      setErr("Password is required.");
      setBorderColor("border-red-500");
      return false;
    }

    return true;
  };

  useEffect(() => {
    // if (applyNowClicked && data) {
    //   postWorkOrder({
    //     service_id: props.serviceData.sendData.id,
    //     service_name: props.serviceData.sendData.service_title,
    //   });
    // }
  }, [data, applyNowClicked]);

  return (
    <>
      <button className="" onClick={handleClickOpen}>
        <span
          // className="purpleBtn w-[100%] lg:px-[2.5rem] md:px-[1rem] px-[7.8rem] py-[0.8rem] text-white rounded-md"
          className="purpleBtn  md:px-[1rem] px-[7.8rem] py-[0.7rem] text-white rounded-md"
          onClick={() => {
            setApplyNowClicked(true);
            applyNow();
          }}
        >
          {props.btnName}
        </span>
      </button>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          component: "form",
          style: { width: "50%", maxWidth: "80%", height: "90%" },
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            // const email = formJson.email;
            // console.log(email);
            handleClose();
          },
        }}
      >
        <div className="modalDialog flex justify-center h-full items-center">
          {!showForgotPassword ? (
            login && (
              <div className="w-[50%]">
                <h2 className="font-[700] leading-[2.1rem] text-[1.9rem]">
                  Welcome back
                </h2>
                <p className="text-gray-500 mb-4 font-[400] text-[1rem] leading-[1.5rem]">
                  Welcome back! Please enter your details.
                </p>
                <Input
                  border={borderColor}
                  label={"Mobile Number"}
                  placeholder={"Mobile Number"}
                  value={mobileNumber}
                  setValue={setMobileNumber}
                />
                <Input
                  border={borderColor}
                  label={"Password"}
                  placeholder={"Enter your password"}
                  value={password}
                  type={"password"}
                  setValue={setPassword}
                />
                <div className="flex justify-between my-2 items-center">
                  <div>
                    <input type={"checkbox"} /> Remember me
                  </div>
                  <p
                    className="text-purple-500 m-0 hover:cursor-pointer"
                    onClick={() => setShowForgotPassword(true)}
                  >
                    Forgot password
                  </p>
                </div>
                <br />
                <p className="text-center text-red-600 m-0">{err}</p>
                <p
                  onClick={() => setSendLogin(true)}
                  className="py-2 rounded-md text-white text-center hover:cursor-pointer my-1 purpleBtn font-medium"
                >
                  Login
                </p>
                <GoogleLogin />
                <p className="text-gray-400 text-center">
                  No account?{" "}
                  <span
                    className="hover:cursor-pointer text-purple-500"
                    onClick={() => {
                      setLogin(false);
                      setSignup(!signup);
                    }}
                  >
                    Create one
                  </span>
                </p>
              </div>
            )
          ) : (
            <MobileForgotPswd />
          )}

          {/* udyam services */}
          {isLoggedIn && (
            <>
              <div className="grid grid-cols-12 w-96">
                <div className=" col-span-full ">
                  <img
                    className="col-span-full mx-auto"
                    src={successLogo}
                    alt="successLogo"
                  />
                  <div className="flex my-2">
                    {" "}
                    <p className=" text-center font-[500] text-[1.5rem] leading[1.6rem] text-xl">
                      {userName?.full_name}, Your order has been placed for
                      <span className="font-[700] text-[1.4rem] leading-[2rem]">
                        {" "}
                        Udyam Registration
                      </span>
                    </p>
                  </div>
                  <p className="text-center text-[1rem] font-[400] leading-[1.3] text-gray-500">
                    Our representative will give you a call back soon
                  </p>

                  <div className="grid grid-cols-12 gap-2 justify-between">
                    <Link
                      className=" no-underline bg-purple-light-shade rounded-lg col-span-6"
                      to="/dashboard"
                    >
                      {" "}
                      <button className=" w-[100%] whitespace-nowrap   p-2 text-purple-700  no-underline rounded">
                        Check your order
                      </button>
                    </Link>
                    <button className="p-2 rounded-md  text-center hover:cursor-pointer bg-purple-dark-shade text-white font-medium  col-span-6">
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}

          {signup && (
            <>
              <div className="w-[50%] h-[30rem] overflow-y-scroll scrollbar-hide">
                <div className=" flex justify-between">
                  <h5 className=" font-[600] text-[1.8rem] leading-[2.5rem]">
                    Sign up
                  </h5>

                  <button
                    className="font-[500] text-[1rem] leading-[2.5rem] "
                    onClick={handleClose}
                  >
                    X
                  </button>
                </div>

                <label
                  className="font-[500] text-[0.9rem] slightDarkBlack"
                  for="type"
                >
                  {" "}
                  User Type
                </label>
                <br />
                <div className=" relative text-center">
                  <input
                    onClick={() => {
                      setTypeClicked(true);
                    }}
                    style={{ fontFamily: "" }}
                    value={type}
                    className=" border-1 rounded px-3 py-2  w-[100%] my-1 tracking-[0.05em]"
                  />
                </div>
                {typeClicked && (
                  <div className=" absolute bg-white-body drop-shadow-md p-2 md:w-[16rem] rounded-lg lg:w-[20rem]">
                    {clientTypeArr.map((data) => {
                      return (
                        <>
                          {/* <p onClick={()=>{console.log('evfc')}}>injve</p> */}
                          <p
                            key={data}
                            onClick={() => {
                              setType(data);
                              setTypeClicked(false);
                              setBusinessTypeCode(businessTypeCode + 1);
                            }}
                            className=" hover:cursor-pointer m-0"
                          >
                            {data}
                          </p>
                        </>
                      );
                    })}
                  </div>
                )}
                {type === "Individual" ? (
                  <>
                    <Input
                      border={`${nameErr ? "border-red-500" : ""}`}
                      label={"Name"}
                      placeholder={"Enter your name"}
                      value={registerName}
                      // type={"email"}
                      setValue={setRegisterName}
                    />
                    {nameErr && (
                      <>
                        {" "}
                        <span className=" text-red-500 text-[0.8rem]">
                          Name is required
                        </span>
                        <br />
                      </>
                    )}
                    {/* {  busNameErr,busConPersonErr,busMobNoErr,busDOBErr,MobNoErr,passwordErr,stateErr,emailErr} */}
                    <Input
                      border={`${MobNoErr ? "border-red-500" : ""}`}
                      label={"Mobile Number"}
                      placeholder={"Enter mobile number"}
                      value={registerMobileNo}
                      // type={"email"}
                      setValue={setRegisterMobileNo}
                    />
                    {MobNoErr && (
                      <>
                        {" "}
                        <span className=" text-red-500 text-[0.8rem]">
                          Mobile Number is required
                        </span>
                        <br />
                      </>
                    )}
                    <Input
                      border={`${emailErr ? "border-red-500" : ""}`}
                      label={"Email(Optional)"}
                      placeholder={"Enter your email"}
                      value={email}
                      type={"email"}
                      setValue={setEmail}
                    />{" "}
                    {emailErr && (
                      <>
                        {" "}
                        <span className=" text-red-500 text-[0.8rem]">
                          Email is required
                        </span>
                        <br />
                      </>
                    )}
                    <Input
                      border={`${passwordErr ? "border-red-500" : ""}`}
                      label={"Password"}
                      placeholder={"Create a password"}
                      value={RegisterPassword}
                      type={"password"}
                      setValue={setRegisterPassword}
                    />
                    <p className=" text-sm text-gray-500 m-0">
                      Must be at least 8 chracters
                    </p>
                    {passwordErr && (
                      <>
                        {" "}
                        <span className=" text-red-500 text-[0.8rem]">
                          Password is required
                        </span>
                        <br />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Input
                      border={`${busNameErr ? "border-red-500" : ""}`}
                      label={"Business Name"}
                      placeholder={"Enter your Business name"}
                      value={businessName}
                      // type={"email"}
                      setValue={setBusinessName}
                    />
                    {busNameErr && (
                      <>
                        {" "}
                        <span className=" text-red-500 text-[0.8rem]">
                          Business Name is required
                        </span>
                        <br />
                      </>
                    )}
                    {/* <Input
                      border={`${busConPersonErr ? "border-red-500" : ""}`}
                      label={"Contact Person Name"}
                      placeholder={"Enter Representative Name"}
                      value={contactPerson}
                      // type={"email"}
                      setValue={setContactPerson}
                    />
                    {busConPersonErr && (
                      <>
                        {" "}
                        <span className=" text-red-500 text-[0.8rem]">
                          This field is required
                        </span>
                        <br />
                      </>
                    )} */}
                    <Input
                      border={`${busMobNoErr ? "border-red-500" : ""}`}
                      label={"Business Mobile Number"}
                      placeholder={"Enter mobile number"}
                      value={BusinessMobNumber}
                      type={"email"}
                      setValue={setBusinessMobNumber}
                    />{" "}
                    {busMobNoErr && (
                      <>
                        {" "}
                        <span className=" text-red-500 text-[0.8rem]">
                          Business Mobile Number is required
                        </span>
                        <br />
                      </>
                    )}
                    <Input
                      border={`${busMobNoErr ? "border-red-500" : ""}`}
                      label={"Business Email"}
                      placeholder={"Enter your email"}
                      value={email}
                      type={"email"}
                      setValue={setEmail}
                    />
                    {busMobNoErr && (
                      <>
                        {" "}
                        <span className=" text-red-500 text-[0.8rem]">
                          Business Email is required
                        </span>
                        <br />
                      </>
                    )}
                    <Input
                      border={`${passwordErr ? "border-red-500" : ""}`}
                      label={"Password"}
                      placeholder={"Create a password"}
                      value={RegisterPassword}
                      type={"password"}
                      setValue={setRegisterPassword}
                    />
                    <p className=" text-sm text-gray-500 m-0">
                      Must be at least 8 chracters
                    </p>
                    {passwordErr && (
                      <>
                        {" "}
                        <span className=" text-red-500 text-[0.8rem]">
                          Password is required
                        </span>
                        <br />
                      </>
                    )}
                    <Input
                      border={`${MobNoErr ? "border-red-500" : ""}`}
                      label={"Mobile Number"}
                      placeholder={"Enter mobile number"}
                      value={registerMobileNo}
                      // type={"email"}
                      setValue={setRegisterMobileNo}
                    />
                    {MobNoErr && (
                      <>
                        {" "}
                        <span className=" text-red-500 text-[0.8rem]">
                          Mobile Number is required
                        </span>
                        <br />
                      </>
                    )}
                  </>
                )}

                <p
                  onClick={() => {
                    setSendRegistration(true);
                    setNameErr(false);
                    setBusNameErr(false);
                    // setBusConPersonErr(false);
                    setemailErr(false);
                    setBusMobNoErr(false);
                    setBusDOBErr(false);
                    setMobNoErr(false);
                    setpasswordErr(false);
                    setstateErr(false);
                  }}
                  className=" py-2 rounded-md text-white text-center hover:cursor-pointer my-2 purpleBtn font-medium"
                >
                  Create account
                </p>
                <GoogleLogin />
                <p className=" text-gray-400 text-center">
                  Already have an account?{" "}
                  <span
                    className="hover:cursor-pointer text-purple-500"
                    onClick={() => {
                      setLogin(!login);
                      setSignup(!signup);
                      // handleClose();
                    }}
                  >
                    Log in
                  </span>
                </p>
              </div>
            </>
          )}

          {registrationSuccess && (
            <>
              <div className=" flex justify-between"></div>
              <div className=" px-16 py-8  grid grid-cols-12 w-96">
                <div className=" col-span-full ">
                  <img
                    className="col-span-full mx-auto"
                    src={successLogo}
                    alt="successLogo"
                  />
                  <div className="flex my-2">
                    {" "}
                    {/* <b className=" text-center  text-xl">
                      Our team will reach you soon for verification 
                    </b> */}
                  </div>
                  <p className="text-center">
                    Our representative will give you a call back soon for
                    verification
                  </p>
                  <p
                    // disabled={loginDisabled}
                    onClick={() => {
                      setLogin(true);
                      setRegistrationSuccess(false);
                    }}
                    className=" py-2 rounded-md  text-center hover:cursor-pointer my-1 bg-purple-dark-shade text-white font-medium"
                  >
                    Great!
                  </p>

                  {/* <div className="grid grid-cols-12 justify-between">
                    <p className="py-2 rounded-md  text-center hover:cursor-pointer my-1 bg-button-orange font-medium col-start-2 col-span-4">
                      Continue
                    </p>
                    <Link
                      className=" no-underline col-start-8 col-span-4"
                      to="/orders"
                    >
                      {" "}
                      <p className=" col-start-8 border-2 p-2 text-orange-300 border-orange-300 no-underline rounded">
                        Check your order
                      </p>
                    </Link>
                  </div> */}
                </div>
              </div>
            </>
          )}

          {isOtp && (
            <>
              <div className=" flex justify-between">
                <DialogTitle>Sign up</DialogTitle>
                <DialogActions>
                  <button className=" pr-4" onClick={handleClose}>
                    X
                  </button>
                </DialogActions>
              </div>

              <div className=" px-16 py-8 justify-center grid grid-cols-12 w-96">
                <div className="col-span-full">
                  <h3>OTP Verification</h3>
                  <p>
                    ENter OTP sent to {registerMobileNo} <span>EDIT</span>
                  </p>
                  <MuiOtpInput length={4} value={otp} onChange={handleChange} />

                  <p
                    onClick={() => {
                      setSendOtp(true);
                    }}
                    className="m-0 Btn my-2 text-center w-full"
                  >
                    Create Account
                  </p>
                </div>
              </div>
            </>
          )}

          {}
        </div>
      </Dialog>
    </>
  );
}
