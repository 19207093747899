import React, { useEffect, useState } from "react";
import Tooltip from "./Tooltip";
import CustomizableDrawer from "./Drawer";
import BarChartComponent from "./Chart";
import ConfirmDialog from "../Orders/ConfirmationDialog";
import { REPORTS_URL } from "constants/routes";

// images
import arrowUp from "assets/images/reports/arrow-up.svg";
import arrowDown from "assets/images/reports/arrow-down.svg";
import demand from "assets/images/reports/demand.png";
import proceedings from "assets/images/reports/proceedings.png";
import tax_refund from "assets/images/reports/tax-refund.png";
import verification from "assets/images/reports/verification.png";
import info_red from "assets/images/reports/info-red.png";
import form26 from "assets/images/reports/form26.png";
import info_black from "assets/images/reports/info_black.png";
import AIS from "assets/images/reports/AIS.png";
import TIS from "assets/images/reports/TIS.png";
import flag from "assets/images/reports/in.svg";
import closedEye from "assets/images/reports/closedEye.svg";
import previousBtn from "assets/images/incomeTax/previousBtn.svg";

// API's
import useGetReports from "./data/useGetReports";
import useGetReportsYearWise from "./data/useGetReportsYearWise";
import useGetDownload26AS from "./data/useGetDownload26AS";
import useGetDownloadAIS from "./data/useGetDownloadAIS";
import useGetDownloadTIS from "./data/useGetDownloadTIS";
import { useNavigate } from "react-router-dom";

const Reports = () => {
  const navigate = useNavigate();
  const [year, setYear] = useState("");
  const [yearToGet, setYearToGet] = useState("");
  const [income, setIncome] = useState("");
  const [taxPaid, setTaxPaid] = useState("");
  const [contributionPerc, setContributionPerc] = useState("");
  const [panVerified, setPanVerified] = useState("");
  const [returnStatus, setReturnStatus] = useState("");
  const [aisPdf, setAisPdf] = useState("");
  const [tdsPdf, setTdsPdf] = useState("");
  const [tisPdf, setTisPdf] = useState("");

  const [getData, getError, getIsLoading, getReports] = useGetReports();
  const [getYearData, getYearError, getYearIsLoading, getReportsYearWise] =
    useGetReportsYearWise();
  const [
    download26ASData,
    download26ASError,
    download26ASIsLoading,
    getDownload26AS,
  ] = useGetDownload26AS();
  const [
    downloadAISData,
    downloadAISError,
    downloadAISIsLoading,
    getDownloadAIS,
  ] = useGetDownloadAIS();
  const [
    downloadTISData,
    downloadTISError,
    downloadTISIsLoading,
    getDownloadTIS,
  ] = useGetDownloadTIS();

  useEffect(() => {
    getReports();
  }, []);

  useEffect(() => {
    if(year){
      getDownload26AS(year);
      getDownloadAIS(year);
      getDownloadTIS(year);
    }
  }, [year]); 


  useEffect(() => {
    if (getData && getData.data) {
      setYear(
        getData.data.income_tax_return_year_data.income_tax_return_year_name
      );
      setYearToGet(
        getData.data.income_tax_return_year_data.income_tax_return_year_name
      );
      setIncome(getData.data.income_tax_return_year_data.total_income_earned);
      setTaxPaid(getData.data.income_tax_return_year_data.total_tax_paid);
      setContributionPerc(
        getData.data.income_tax_return_year_data.contribution_percentage
      );
      setPanVerified(getData.data.income_tax_return_year_data.is_pan_verified);
      setReturnStatus(
        getData.data.income_tax_return_year_data.income_tax_return_status
      );
      setAisPdf(getData.data.income_tax_return_year_data.ais_pdf_url);
      setTdsPdf(getData.data.income_tax_return_year_data.tds_pdf_url);
      setTisPdf(getData.data.income_tax_return_year_data.tis_pdf_url);
    }
  }, [getData]);

  useEffect(() => {
    if (getYearData && getYearData.data) {
      setYear(getYearData.data.income_tax_return_year_name);
      setIncome(getYearData.data.total_income_earned);
      setTaxPaid(getYearData.data.total_tax_paid);
      setContributionPerc(getYearData.data.contribution_percentage);
      setPanVerified(getYearData.data.is_pan_verified);
      setReturnStatus(getYearData.data.income_tax_return_status);
      setAisPdf(getYearData.data.ais_pdf_url);
      setTdsPdf(getYearData.data.tds_pdf_url);
      setTisPdf(getYearData.data.tis_pdf_url);
    } 
  }, [getYearData]);

  const [options, setOptions] = useState([
    "Apr'24 - Apr'25",
    "Apr'23 - Apr'24",
    "Apr'22 - Apr'23",
    // "Apr'21 - Apr'22",
    // "Apr'20 - Apr'21",
  ]);

  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setYear(selectedYear);
    const baseURL = REPORTS_URL;
    const url = `${baseURL}?year=${selectedYear}`;

    if (selectedYear == yearToGet) {
      getReports();
    } else {
      getReportsYearWise(selectedYear);
    }
  };

  const handlePrevious = () => {
    navigate();
  };

  return (
    <div className="bg-gray-100 md:px-8 py-4 px-3">
       {(getIsLoading || getYearIsLoading) && (
        <div className="absolute inset-0 bg-white bg-opacity-25 border border-[#E2E8F0] rounded p-3 flex justify-center items-start z-50">
          <div className="loader mt-48"></div>
        </div>
      )}
      <div className="flex gap-2">
        <button onClick={handlePrevious}>
          <img src={previousBtn} alt="" className="w-4 h-4 mb-2 md:hidden" />
        </button>
        <h4 className="text-[#334155] font-[600] text-[1.4rem] leading-[2.7rem] ">
          Reports
        </h4>
      </div>
      <div className="grid grid-cols-12 gap-3 md:mt-[1rem]">
        {/* Income Stats */}
        <div className="md:col-span-6 col-span-12 bg-white border shadow-md rounded-lg md:p-4 p-3">
          <div className="flex justify-between items-center">
            <h4 className="text-[#64748B] font-[500] text-[1.2rem]">
              Income Stats
            </h4>
            <select
              className="md:bg-[#F1F5F9] font-[500] text-[1rem] md:p-2 rounded focus:outline-none"
              value={year}
              onChange={handleYearChange}
            >
              {options.map((option, index) => (
                <option
                  key={index}
                  value={option}
                  className="md:font-[500] font-[400]"
                >
                  Till FY {option}
                </option>
              ))}
            </select>
          </div>
          <hr />
          {/* income earned card*/}
          <div className="flex grid grid-cols-6 md:p-3 p-1 gap-3">
            <div className="md:col-span-3 col-span-6 bg-[#EAE1F3] rounded-lg px-2 py-3">
              <div className="flex gap-2 ">
                <img src={arrowDown} className="w-5 h-5" />
                <h4 className="font-[600] text-[1rem] text-[#334155]">
                  Income Earned
                </h4>
              </div>
              <div className="flex gap-3 mt-[0.5rem] md:justify-center justify-end">
                <h4 className="font-[600] text-[1.4rem] text-[#334155]">
                  ₹{income}
                </h4>
                <img src={closedEye} className="w-4 h-4 mt-1" />
              </div>
            </div>

            {/* tax paid card */}
            <div className="md:col-span-3 col-span-6 bg-[#F3EFF8] rounded-lg px-2 py-3">
              <div className="flex gap-2 ">
                <img src={arrowUp} className="w-5 h-5" />
                <h4 className="font-[600] text-[1rem] text-[#334155]">
                  Tax Paid
                </h4>
              </div>
              <div className="flex gap-3 mt-[0.5rem]  md:justify-center justify-end">
                <h4 className="font-[600] text-[1.4rem] text-[#334155]">
                  ₹{taxPaid}
                </h4>
                <img src={closedEye} className="w-4 h-4 mt-1" />
              </div>
            </div>

            <div className=" col-span-6 bg-[#FAF8FC] p-3 border border-[#E2E8F0]  rounded-lg">
              <h4 className="md:flex md:gap-2 md:items-center md:text-center text-[1.3rem] font-[500] text-[#334155]">
                {contributionPerc} %
                <span className=" text-[1rem] font-[500] text-[#334155]">
                  Direct Contribution to Government of India
                </span>
                {/* <img src={flag} className="w-4 h-4 ml-2 transform skew-x-12"/> */}
              </h4>
            </div>
            <h4 className="col-span-6 flex flex-row-reverse text-[#64748B] font-[500] text-[0.9rem]">
              <i>*Reported as per Income tax Dept.</i>
            </h4>
          </div>
        </div>

        {/* Income vs Tax Graph */}
        <div className="md:col-span-6 col-span-12 bg-white border shadow-md rounded-lg md:p-4 p-3 ">
          <h4 className="text-[#64748B] font-[500] text-[1.2rem] ">
            Income vs Tax Graph
          </h4>
          <hr className="mt-[2.2rem]" />
          <div className="">
            <BarChartComponent /> 
          </div>
        </div>

        {/* Financial Year */}
        <div className="col-span-12 bg-white border shadow-md rounded-lg md:p-4 p-3 md:mb-0 mb-[5rem]">
          <div className="flex gap-4 items-center">
            <h4 className="text-[#64748B] font-[500] text-[1.2rem]">
              Financial Year
            </h4>

            <select
              value={year}
              onChange={handleYearChange}
              className="bg-[#F1F5F9] font-[500] text-[1rem] p-2 rounded focus:outline-none"
            >
              {options.map((option, index) => (
                <option
                  key={index}
                  value={option}
                  className="md:font-[500] font-[400]"
                >
                  {option}
                </option>
              ))}
            </select>
          </div>
          <hr />

          <div className="grid grid-cols-12 gap-3">
            {/* status */}
            <div className="col-span-12 md:col-span-6 shadow-md rounded-lg ">
              <h4 className="mb-0 font-[600] text-[1rem] text-[#64748B] bg-[#F8FAFC] p-3">
                Status
              </h4>
              <div className="grid grid-cols-6 gap-1">
                <div className="col-span-6 md:col-span-3 flex md:flex-col md:justify-center md:items-center md:text-center bg-[#FFF7ED] p-[1rem]">
                  <img
                    src={verification}
                    alt="e-verification"
                    className="md:w-12 md:h-12 md:mb-[1rem] w-8 h-8"
                  />

                  <div className="md:ml-0 ml-2">
                    <h4 className="font-[600] text-[1rem] text-[#334155]">
                      E-Verification -{" "}
                      <span className="text-[#EA580C]">
                        {returnStatus ? returnStatus : "In Progress"}
                      </span>
                    </h4>
                    <h4 className="font-[400] text-[0.9rem] text-[#64748B]">
                      Your documents are under review
                    </h4>
                  </div>
                </div>

                <div className="col-span-6 md:col-span-3 flex md:flex-col md:justify-center md:items-center md:text-center bg-[#FFF7ED] p-[1rem]">
                  <img
                    src={tax_refund}
                    alt="tax refund"
                    className="md:w-12 md:h-12 md:mb-[1rem] w-8 h-8"
                  />
                  <div className="md:ml-0 ml-2">
                    <h4 className="font-[600] text-[1rem] text-[#334155]">
                      Tax Refund -{" "}
                      <span className="text-[#EA580C]">In Progress</span>
                    </h4>
                    <h4 className="font-[400] text-[0.9rem] text-[#64748B]">
                      24/11/24
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            {/* notice */}
            <div className="md:col-span-6 col-span-12 shadow-md rounded-lg ">
              <h4 className="mb-0 font-[600] text-[1rem] text-[#64748B] bg-[#F8FAFC] p-3">
                Notice
              </h4>
              <div className="grid grid-cols-6 gap-1">
                <div className="col-span-6 md:col-span-3 bg-[#FEF2F2] md:px-[1rem] md:pt-[1rem] md:pb-[2rem] p-[1rem]">
                  <div className="md:flex justify-end items-end hidden">
                    <img src={info_red} alt="info" className="w-5 h-5 " />
                  </div>
                  <div className="flex md:flex-col md:justify-center md:items-center justify-between md:text-center gap-2 md:gap-0">
                    <img
                      src={proceedings}
                      alt="proceedings"
                      className="md:w-12 md:h-12 md:mb-[1rem] w-8 h-8"
                    />
                    <h4 className="font-[600] text-[1rem] text-[#334155] md:ml-0 ml-2">
                      E-Proceedings{" "}
                    </h4>
                    <img
                      src={info_red}
                      alt="info"
                      className="w-5 h-5 md:hidden"
                    />
                  </div>
                </div>

                <div className="col-span-6 md:col-span-3  text-refund bg-[#FEF2F2] md:px-[1rem] md:pt-[1rem] p-[1rem]">
                  <div className="hidden md:flex justify-end items-end">
                    <img src={info_red} alt="info" className="w-5 h-5 " />
                  </div>
                  <div className="flex md:flex-col md:justify-center items-center justify-between   text-center gap-2 md:gap-0">
                    <img
                      src={demand}
                      alt="demand"
                      className="md:w-12 md:h-12 md:mb-[1rem] w-8 h-8"
                    />
                    <h4 className="font-[600] text-[1rem] text-[#334155] md:ml-0 ml-2">
                      Outstanding Demand
                    </h4>
                    <img
                      src={info_red}
                      alt="info"
                      className="w-5 h-5 md:hidden"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* form 26As */}
            <div className="md:col-span-4 col-span-12 p-[1rem] shadow-md rounded-lg bg-[#F8FAFC] mt-[1rem]">
              <div className="flex justify-end items-end">
                <Tooltip
                  position="top"
                  content="Focuses solely on tax credits and payments, unlike the broader financial scope of AIS and TIS."
                >
                  <CustomizableDrawer
                    triggerElement={
                      <img src={info_black} alt="info" className="w-5 h-5" />
                    }
                    heading={"Form 26 AS"}
                    content={
                      "Focuses solely on tax credits and payments, unlike the broader financial scope of AIS and TIS."
                    }
                    placement="bottom"
                  />
                </Tooltip>
              </div>
              <div className="flex">
                <img src={form26} className="w-9 h-9" alt="form26" />
                <div className="pl-3">
                  <h4 className="font-[600] text-[1rem] text-[#334155]">
                    Form 26 AS
                  </h4>
                  <h4 className="font-[400] text-[0.9rem] text-[#64748B]">
                    Your annual tax summary showing all taxes deducted at source
                    and tax credits
                  </h4>
                  <div className="flex mt-[4rem]">
                    <button
                      disabled={!tdsPdf}
                      onClick={() => {
                        if (tdsPdf) {
                          window.open(tdsPdf, "_blank");
                        } 
                      }}
                      className={`font-[600] text-[1rem] text-[#64748B] bg-white rounded-md shadow-md py-2 px-4 md:mr-[1rem] mr-[0.5rem] border border-[#E2E8F0] ${
                        !tdsPdf ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                    >
                      View
                    </button>
                    <ConfirmDialog
                      href_reports={download26ASData?.data?.["26as_pdf_url"]}
                      type="tds"
                    />
                    
                  </div>
                </div>
              </div>
            </div>

            {/* AIS */}
            <div className="md:col-span-4 col-span-12 p-[1rem] shadow-md rounded-lg bg-[#F8FAFC] mt-[1rem]">
              <div className="flex justify-end items-end">
                <Tooltip
                  showOnScreenSize="md"
                  position="top"
                  content="Covers all financial transactions, offering a more detailed view than the tax-specific Form 26AS and the simplified TIS."
                >
                  <CustomizableDrawer
                    triggerElement={
                      <img src={info_black} alt="info" className="w-5 h-5" />
                    }
                    heading={"AIS (Annual Information Statement)"}
                    content={
                      "Covers all financial transactions, offering a more detailed view than the tax-specific Form 26AS and the simplified TIS."
                    }
                    placement="bottom"
                  />
                </Tooltip>
              </div>
              <div className="flex">
                <img src={AIS} className="w-9 h-9" alt="AIS" />
                <div className="pl-3">
                  <h4 className="font-[600] text-[1rem] text-[#334155]">
                    AIS <br />
                    (Annual Information Statement)
                  </h4>
                  <h4 className="font-[400] text-[0.9rem] text-[#64748B]">
                    A comprehensive report of all your financial transactions
                    reported to the Income tax Department.
                  </h4>
                  <div className="flex mt-[3rem]">
                    <button
                      disabled={!aisPdf}
                      onClick={() => {
                        if (aisPdf) {
                          window.open(aisPdf, "_blank");
                        }
                      }}
                      className={`font-[600] text-[1rem] text-[#64748B] bg-white rounded-md shadow-md py-2 px-4 md:mr-[1rem] mr-[0.5rem] border border-[#E2E8F0] ${
                        !aisPdf ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                    >
                      View
                    </button>
                    <ConfirmDialog href_reports={downloadAISData?.data?.ais_pdf_url} type="ais" />
                  </div>
                </div>
              </div>
            </div>

            {/* TIS */}
            <div className="md:col-span-4 col-span-12 p-[1rem] shadow-md rounded-lg bg-[#F8FAFC] mt-[1rem]">
              <div className="flex justify-end items-end">
                <Tooltip
                  position="left"
                  content="Simplifies and categorizes the detailed data from AIS, focusing on what's relevant for tax filing, unlike the comprehensive AIS and tax-focused Form 26AS."
                >
                  <CustomizableDrawer
                    triggerElement={
                      <img src={info_black} alt="info" className="w-5 h-5" />
                    }
                    heading={"TIS (Taxpayer Information Statement)"}
                    content={
                      "Simplifies and categorizes the detailed data from AIS, focusing on what's relevant for tax filing, unlike the comprehensive AIS and tax-focused Form 26AS."
                    }
                    placement="bottom"
                  />
                </Tooltip>
              </div>
              <div className="flex">
                <img src={TIS} className="w-9 h-9" alt="TIS" />
                <div className="pl-3">
                  <h4 className="font-[600] text-[1rem] text-[#334155]">
                    TIS <br />
                    (Taxpayer Information Statement)
                  </h4>
                  <h4 className="font-[400] text-[0.9rem] text-[#64748B]">
                    A categorized overview of your financial data used for tax
                    filing, derived from AIS.
                  </h4>
                  <div className="flex mt-[2rem]">
                    <button
                      disabled={!tisPdf}
                      onClick={() => {
                        if (tisPdf) {
                          window.open(tisPdf, "_blank");
                        }
                      }}
                      className={`font-[600] text-[1rem] text-[#64748B] bg-white rounded-md shadow-md py-2 px-4 md:mr-[1rem] mr-[0.5rem] border border-[#E2E8F0] ${
                        !tisPdf ? "opacity-50 cursor-not-allowed" : ""
                      }`} 
                    >
                      View
                    </button>
                    <ConfirmDialog href_reports={downloadTISData?.data?.pdf_url} type="tis" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </div>
  );
};

export default Reports;
