import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import InputField from "components/input/InputField";
import React, { useEffect, useRef, useState } from "react";
import "../../../Liabilities/liabilities.css";
import Button from "components/Button/BtnOrange";
import RedButton from "components/Button/RedButton";
import upload from "assets/images/liabilities/upload.svg";
import deleteIcon from "assets/images/assets/redDelete.svg";
import RemoveAsset from "../../RemoveAsset";

const EditCapitalExpense = ({
  triggerElement,
  selectedItem,
  updateLandData,
  handleInputChange,
  setselectedItem,
  capExpenseDate,
  setcapExpenseDate,
  capExpenseType,
  setcapExpenseType,
  capAmtPaid,
  setcapAmtPaid,
  capUploadInvoice,
  setcapUploadInvoice,
  createLandData,
}) => {
  const removeBtnRef = useRef(null);
  const handleRemoveExpense = () => {
    removeBtnRef.current.click();
  };

  const [errors, setErrors] = useState({});
  const [formValid, setFormValid] = useState(true);
  const [touched, setTouched] = useState(false); // Track whether user has tried to save

  const resetState = () => {
    setcapExpenseDate("");
    setcapExpenseType("");
    setcapAmtPaid("");
    setcapUploadInvoice("");
    setErrors({});
    setTouched(false);
    setFormValid(true);
  };

  useEffect(() => {
    if (!touched) return;
    const newErrors = {};

    const alphabetRegex = /^[A-Za-z\s]+$/;
    const positiveAmountRegex = /^[+]?\d+(\.\d+)?$/;
    if (!capExpenseType || !alphabetRegex.test(capExpenseType)) {
      newErrors.capExpenseType = "Expense Type can have only alphabets.";
    }
    if (
      !capAmtPaid ||
      !positiveAmountRegex.test(capAmtPaid) ||
      parseFloat(capAmtPaid) <= 0
    ) {
      newErrors.capAmtPaid = "Amount Paid must be greater than 0.";
    }
    if (!capExpenseDate) {
      newErrors.capExpenseDate = "Date is required.";
    }
    if (!capUploadInvoice) {
      newErrors.capUploadInvoice = "Invoice is required.";
    }

    setErrors(newErrors);
    setFormValid(Object.keys(newErrors).length === 0);
  }, [capExpenseType, capAmtPaid, touched, capUploadInvoice, capExpenseDate]);

  const handleSaveClick = () => {
    setTouched(true);
    if (formValid) {
      createLandData();
    }
  };

  return (
    <div>
      <DialogBox
        onClose={resetState}
        triggerElement={
          triggerElement ? (
            triggerElement
          ) : (
            <WhiteButton
              px={"px-[0.8rem]"}
              py={"py-[0.3rem]"}
              name="Add another expense"
            />
          )
        }
        heading={"Edit Capital Expense"}
        content={(handleClose) => (
          <>
            <div className="rounded-md bg-[#F1F5F9] mt-[0.6rem] mb-[14rem]">
              <div className="grid grid-cols-2 px-[1rem] py-[0.7rem]">
                <div className="input-container">
                  <label className="input-label">Expense Type</label>
                  <InputField
                    value={
                      triggerElement
                        ? selectedItem?.expense_type
                        : capExpenseType
                    }
                    onChange={(e) =>
                      triggerElement
                        ? setselectedItem({
                            ...selectedItem,
                            expense_type: e.target.value,
                          })
                        : setcapExpenseType(e.target.value)
                    }
                    type="select"
                    className="input-box border rounded-md bg-white"
                    options={[
                      { value: "", label: "Select Type" },
                      {
                        value: "maintenance",
                        label: "Maintenance",
                      },
                      { value: "renovation", label: "Renovation" },
                      { value: "taxes", label: "Taxes" },
                      { value: "utilities", label: "Utilities" },
                      { value: "other", label: "Other" },
                    ]}
                  />
                  {touched && errors.capExpenseType && (
                    <p className="text-red-500 text-xs">
                      {errors.capExpenseType}
                    </p>
                  )}
                </div>

                <div className="input-container">
                  <label className="input-label">Amount Paid</label>
                  <InputField
                    value={
                      triggerElement ? selectedItem?.amount_paid : capAmtPaid
                    }
                    onChange={(e) =>
                      triggerElement
                        ? setselectedItem({
                            ...selectedItem,
                            amount_paid: e.target.value,
                          })
                        : setcapAmtPaid(e.target.value)
                    }
                    type="number"
                    placeholder="Enter Amount Paid"
                    className="input-box border rounded-md bg-white"
                  />
                  {touched && errors.capAmtPaid && (
                    <p className="text-red-500 text-xs">{errors.capAmtPaid}</p>
                  )}
                </div>

                <div className="input-container mt-[0.3rem] ">
                  <label className="input-label">Expense Date</label>
                  <InputField
                    onChange={(e) =>
                      triggerElement
                        ? setselectedItem({
                            ...selectedItem,
                            expense_date: e.target.value,
                          })
                        : setcapExpenseDate(e.target.value)
                    }
                    value={
                      triggerElement
                        ? selectedItem?.expense_date
                        : capExpenseDate
                    }
                    type="date"
                    className="input-box border rounded-md bg-white "
                  />
                  {touched && errors.capExpenseDate && (
                    <p className="text-red-500 text-xs">
                      {errors.capExpenseDate}
                    </p>
                  )}
                </div>

                <div className="flex gap-2 mt-[1.7rem]">
                  <InputField
                    type="file"
                    accept=".pdf"
                    id={`file-input-capital`}
                    className="hidden"
                    onChange={(e) =>
                      triggerElement
                        ? setselectedItem({
                            ...selectedItem,
                            upload_invoice: e.target.files[0],
                          })
                        : setcapUploadInvoice(e.target.files[0])
                    }
                  />
                  <button
                    onClick={() =>
                      document.querySelector(`#file-input-capital`).click()
                    }
                    className="text-lightGray bg-white font-[600] text-[0.9rem] border-1 border py-[0.2rem] px-[0.7rem] rounded-md flex gap-2 items-center"
                  >
                    <img src={upload} alt="upload" />
                    Upload Invoice
                  </button>
                  {touched && errors.capUploadInvoice && (
                    <p className="text-red-500 text-xs">
                      {errors.capUploadInvoice}
                    </p>
                  )}
                  <button>
                    <img
                      src={deleteIcon}
                      alt="delete icon"
                      className="w-11 h-10"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <RedButton
                onClick={handleRemoveExpense}
                name="Remove Expense"
                px={"px-[0.6rem]"}
                py={"py-[0.4rem]"}
              />

              <div className="flex justify-end gap-[1rem]">
                <WhiteButton
                  onClick={handleClose}
                  name="Cancel"
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
                <Button
                  disabled={!formValid}
                  onClick={triggerElement ? updateLandData : handleSaveClick}
                  name={"Save"}
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
              </div>
            </div>
          </>
        )}
      />

      <RemoveAsset
        heading={"expense"}
        triggerElement={
          <button ref={removeBtnRef} className="hidden">
            Remove
          </button>
        }
      />
    </div>
  );
};

export default EditCapitalExpense;
