import React, { useEffect, useState } from "react";
import AddYourLiability from "../AddYourLiability";

// images
import personalLoanHeart from "assets/images/PersonalLoan/personalLoanHeart.svg";
import filePersonalLoan from "assets/images/PersonalLoan/filePersonalLoan.svg";
import {
  Link,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { 
  LIABILITIES_PERSONAL_LOAN_DETAILS,
  LIABILITIES_REVOLVING_LOAN_DETAILED_URL,
} from "constants/routes";
import useGetBankingLoans from "../data/useGetBankingLoans";
import LiabilitiesHeading from "../LiabilitiesHeading";
import useGetCreditCard from "../data/useGetCreditCards";

const RevolvingLoan = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const loan_type = location.state?.loan_type;

  const [getCreditData, getCreditError, getCreditIsLoading, getCreditCardDetails] =
    useGetCreditCard();

  useEffect(() => {
    getCreditCardDetails(id);
  }, []);

  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 h-screen">
      <LiabilitiesHeading loan_type={loan_type} />
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between">
          <h4 className="text-darkGray text-[1.2rem] font-[600]">Credit Cards</h4>
          <AddYourLiability />
        </div>
        

        <div className="grid grid-cols-4 gap-2 mt-[1.5rem]">
          {getCreditData?.data?.length > 0  ? (
            getCreditData?.data?.map((item, index) => (
              <div>
                <Link
                  className="no-underline"
                  state={{ loan_type: loan_type, loanTypeID: id }}
                  to={`${LIABILITIES_REVOLVING_LOAN_DETAILED_URL}?id=${id}/?credit_card_id=${item.id}`} // Dynamic URL with bankName
                  key={index} 
                >
                  <div className="rounded-md border p-[1rem]">
                    <img
                      src={filePersonalLoan}
                      alt="heart"
                      className="w-10 h-10"
                    />
                    <h5 className="text-darkGray font-[500] text-[1rem] mt-1">
                      {item.card_type}
                    </h5>
                    <div className="flex justify-end mt-[1.2rem]">
                      <h4 className="text-customPurple font-[600] text-[1.3rem]">
                        ₹ {item.amount_utilised}
                      </h4>
                    </div>{" "}
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={personalLoanHeart} alt="heart" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">
                Personal Loan
              </h5>
              <div className="flex justify-end mt-[2.5rem]">
                <button className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                  Add New
                </button>
              </div>{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RevolvingLoan;
