import React, { useEffect, useState } from "react";
import Button from "components/Button/BtnOrange";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  INCOME_TAX_SUMMARY_URL,
  INCOME_TAX_SUMMARY_USER_DETAILS,
  INCOME_TAX_SUMMARY_USER_PAGE_URL,
  USER_INCOME_SUMMARY_PAGE_URL,
} from "constants/routes";

// images
import close from "assets/images/incomeTax/close.png";
import salary from "assets/images/incomeTax/salary.png";
import rental from "assets/images/incomeTax/rental.png";
import Details from "assets/images/incomeTax/Details.png";
import capital from "assets/images/incomeTax/capital.png";
import business from "assets/images/incomeTax/business.png";
import agriculture from "assets/images/incomeTax/agriculture.png";
import otherIncome from "assets/images/incomeTax/otherIncome.png";
import previousBtn from "assets/images/incomeTax/previousBtn.svg";

// API's
import useGetTotalIncome from "./Data/useGetTotalIncome";

const TotalIncome = () => {
  const [showAdditionalIncome, setShowAdditionalIncome] = useState(false);
  const navigate = useNavigate();
  const { income_tax_return_id } = useParams();

  const [getData, getError, getIsLoading, getTotalIncomeData] =
    useGetTotalIncome();

  const IncomeType = [
    {
      id: 1,
      type: "Salary Income",
      icon: salary,
      desc: "Add details yourself for complete control or Auto-fill for quick and accurate input",
      inputBox: "employer_name",
      inputBoxIcon: Details,
      income_type: "gross_salary",
      detail_type: "salary_details",
      amount: "total_salary_income",
      path: "salary",
      field: "is_salary_income_edited",
    },
    {
      id: 2,
      type: "Rental Income",
      icon: rental,
      desc: "Enter any rental income from properties and home loan interest payments for accurate tax calculation and potential deductions.",
      inputBox: "tenant_name",
      inputBoxIcon: Details,
      detail_type: "rental_details",
      income_type: "net_rental_income",
      amount: "total_rental_income",
      path: "rental",
      field: "is_rental_income_edited",
    },
    {
      id: 3,
      type: "Capital Gains",
      icon: capital,
      desc: "Add interest, dividends, online gaming winnings, and gifts received.",
      inputBoxIcon: Details,
      inputBox:"gain_or_loss",
      // income_type: "gross_salary",
      detail_type: "capital_details",
      amount: "total_capital_gains",
      path: "capital-gains",
      field: "is_capital_gains_edited",
    },
    {
      id: 4,
      type: "Business Income",
      icon: business,
      desc: "For professionals and entrepreneurs: doctors, lawyers, freelancers, traders, business owners, educators, influencers, and more.",
      amount: "total_business_income",
      inputBoxIcon: Details,
      path: "business",
      field: "is_business_income_edited",
      inputBox:"business_name",
      income_type: "gross_receipt_cash_upi_profit",
      detail_type: "business_details",
    },
    {
      id: 5,
      type: "Agriculture Income",
      icon: agriculture,
      desc: "Special Income Categories: Agricultural Earnings, Exempt Income, and More.",
      inputBoxIcon: Details,
      amount: "total_agriculture_and_exempt_income",
      path: "agri-exmpt",
      field: "is_agriculture_and_exempt_income_edited",
      inputBox:"type",
      income_type: "gross_receipts",
      detail_type: "agriculture_and_exempt_details",
    },
    {
      id: 6,
      type: "Other Income",
      icon: otherIncome,
      desc: "Miscellaneous Revenue Sources and Their Tax Implications",
      inputBoxIcon: Details,
      inputBox:"interest",
      // income_type: "gross_receipt_cash_upi_profit",
      detail_type: "other_income_details",
      amount: "total_others_income",
      path: "others",
      field: "is_others_income_edited",
    },
  ];

  useEffect(() => {
    getTotalIncomeData(income_tax_return_id);
  }, []);

  const handleAddIncomeClick = () => {
    setShowAdditionalIncome(true);
  };

  const handleGoBack = () => {
    navigate(
      INCOME_TAX_SUMMARY_URL.replace(
        ":income_tax_return_id",
        income_tax_return_id
      )
    );
  };
  const handleSaveAndNext = () => {
    navigate(
      USER_INCOME_SUMMARY_PAGE_URL.replace(
        ":income_tax_return_id",
        income_tax_return_id
      ).replace(":type", "deductions")
    );
  };

  const [collapsed, setCollapsed] = useState(1);

  const toggleCollapse = (id) => {
    setCollapsed((prev) => (prev === id ? null : id));
  };

  const handlePrevious = () => {
    navigate(
      INCOME_TAX_SUMMARY_USER_PAGE_URL.replace(
        ":income_tax_return_id",
        income_tax_return_id
      )
    );
  }

  return (
    <div className="relative">
      {getIsLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-25  border border-[#E2E8F0] rounded p-3 flex justify-center items-start">
          <div className="loader mt-48"></div>
        </div>
      )}

      <div className="flex gap-2  md:hidden">
        <button onClick={handlePrevious}>
          <img src={previousBtn} alt="" className="w-4 h-4 mb-2" />
        </button>
        <h4 className=" text-[#334155] font-[500] text-[1.2rem] leading-[2.7rem]">
          Total Income
        </h4>
      </div>
      <div className="md:bg-white md:border md:border-[#E2E8F0] rounded md:p-3 mb-[6rem] ">
        {IncomeType.filter(
          (item) =>
            item.id <= 4 ||
            (showAdditionalIncome && (item.id === 5 || item.id === 6))
        ).map((item) => (
          <div
            key={item.id}
            className="bg-white border border-[#E2E8F0] rounded p-3 mb-3"
          >
            <div className="flex justify-between items-start">
              <div className="md:flex gap-3 md:items-center">
                <div className="flex">
                  <img src={item.icon} alt="" className="" />
                  <button
                    onClick={() => toggleCollapse(item.id)}
                    className="font-[600] text-[1rem] text-[#334155] md:hidden"
                    data-collapse-target="collapse"
                  >
                    {item.type}
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => toggleCollapse(item.id)}
                    className="font-[600] text-[1rem] text-[#334155] md:block hidden"
                    data-collapse-target="collapse"
                  >
                    {item.type}
                  </button>
                  <p className="font-[400] text-[0.8rem] text-[#334155]">
                    {item.desc}
                  </p>
                </div>
              </div>
              <div className="text-right">
                <button className="">
                  <img src={close} alt="" className="" />
                </button>
                <Link
                  to={INCOME_TAX_SUMMARY_USER_DETAILS.replace(
                    ":income_tax_return_id",
                    income_tax_return_id
                  ).replace(":detailType", item.path)}
                  className="no-underline"
                >
                  <div className="flex gap-3">
                    <button className="text-[#9065B4] font-[400] text-[0.8rem] md:block hidden ">
                      {getData?.data[item.field] ? "Edit" : "Add Details"}
                    </button>

                    <p className="text-[#334155] font-[400] text-[0.8rem] mt-3">
                      {getData?.data[item.field]
                        ? `₹${getData?.data[item.amount]}`
                        : ""}
                    </p>
                  </div>
                </Link>
              </div>
            </div>

            {getData?.data[item.field] &&
              collapsed === item.id &&
              getData?.data[item.detail_type]?.map((x, index) => (
                <div
                  key={index}
                  className="bg-[#F1F5F9] py-1 px-2 md:ml-[4rem] rounded-md flex justify-between items-center mb-2"
                >
                  <p className="text-[#64748B] font-[500] text-[1rem] flex gap-2 items-center">
                    {x[item.inputBox]}
                    <img src={item.inputBoxIcon} alt={item.inputBox} />
                  </p>
                  <p className="text-[#334155] font-[500] text-[0.9rem]">
                    {`₹ ${x[item.income_type]}`}
                  </p>
                </div>
              ))}
          </div>
        ))}
        {/* buttons for desktop view */}
        <div className="md:flex justify-between hidden">
          <button
            onClick={handleGoBack}
            className="font-[500] px-[2%] py-[0.5%] rounded-md bg-[#E8EAED] "
          >
            Go back to tax summary
          </button>
          <div className="flex flex-row-reverse gap-4">
            <Button
              px={"px-[1rem]"}
              py={"py-[0.5rem]"}
              name="Save and next"
              onClick={handleSaveAndNext}
            />

            <Button
              px={"px-[1rem]"}
              py={"py-[0.5rem]"}
              name="+ Add Income"
              onClick={handleAddIncomeClick}
            />
          </div>
        </div>

        {/* buttons for mobile view*/}
        <div className="flex justify-between md:hidden">
          <button
            onClick={handleAddIncomeClick}
            className="py-[0.5rem] px-[1rem] text-[#9065B4] bg-white border border-[#E2E8F0] rounded-md font-[500] text-[1rem]"
          >
            + Add
          </button>
          <Button
            px={"px-[1rem]"}
            py={"py-[0.5rem]"}
            name="Save"
            onClick={handleSaveAndNext}
          />
        </div>
      </div>
    </div>
  );
};

export default TotalIncome;
