import Button from "components/Button/BtnOrange";
import WhiteButton from "components/Button/WhiteButton";
import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useRef, useState } from "react";
import "../../../Liabilities/liabilities.css";
import InputField from "components/input/InputField";
import upload from "assets/images/liabilities/upload.svg";
import deleteIcon from "assets/images/assets/delete.svg";
import info1 from "assets/images/incomeTax/info1.svg";
import { type } from "@testing-library/user-event/dist/type";
import usePostVehicle from "../../data/usePostVehicle";
import {
  showErrorToast,
  showSuccessToast,
} from "components/toaster/toastHelper";
import { useNavigate } from "react-router-dom";
import { ASSETS_TYPE_URL } from "constants/routes";

const AddNewVehicle = ({
  triggerElement,
  estateType,
  isAddLand,
  setIsAddLand,
  categoryId,
  assetId,
  setIsReload,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [visitedSteps, setVisitedSteps] = useState([]);
  const [assetType, setAssetType] = useState("active"); // Default state
  const [capitalExpense, setCapitalExpense] = useState(false);
  const [errors, setErrors] = useState({});
  const [isClose, setIsClose] = useState(false);
  const navigate = useNavigate();
  const [postVehicleData, postVehicleError, postVehicleIsLoading, postVehicle] =
    usePostVehicle();

  const [vehicleForm, setVehicleForm] = useState({
    vehicle_number: "",
    vehicle_type: "",
    model: "",
    manufacturer: "",
    year_of_manufacture: "",
    purchase_date: "",
    purchase_price: "",
    registration_fee: "",
    road_tax: "",
    other_expenses: "",
    total_cost: "",
    registration_certificate: null,
    invoice: null,
    insurance_taken: false,
    loan_taken: false,
    mandates: [
      {
        expense_type: "",
        amount_paid: "",
        expense_date: "",
        upload_certificate: null,
      },
    ],
  });

  const resetState = () => {
    setVehicleForm([
      {
        vehicle_number: "",
        vehicle_type: "",
        model: "",
        manufacturer: "",
        year_of_manufacture: "",
        purchase_date: "",
        purchase_price: "",
        registration_fee: "",
        road_tax: "",
        other_expenses: "",
        total_cost: "",
        registration_certificate: null,
        invoice: null,
        insurance_taken: false,
        loan_taken: false,
        mandates: [
          {
            expense_type: "",
            amount_paid: "",
            expense_date: "",
            upload_certificate: null,
          },
        ],
      },
    ]);

    setCurrentStep(0);
    setErrors({});
    setVisitedSteps([]);
  };

  useEffect(() => {
    if (postVehicleData) {
      showSuccessToast("Posted Successfully");
      if (window.location.href.includes("personal-asset-vehicle")) {
        setIsReload(true);
      } else {
        navigate(
          `${ASSETS_TYPE_URL.replace(
            ":type",
            "personal-asset-vehicle"
          )}/?id=${categoryId}`
        );
      }
      setIsClose(true);
    }
    if (postVehicleError) {
      showErrorToast("Error in Posting");
    }
  }, [postVehicleData, postVehicleError]);

  const handleSubmit = () => {
    if (!validateVehicleForm()) return;

    // Create a new FormData object
    const formData = new FormData();

    // Append simple fields
    formData.append("vehicle_number", vehicleForm.vehicle_number);
    formData.append("vehicle_type", vehicleForm.vehicle_type);
    formData.append("model", vehicleForm.model);
    formData.append("manufacturer", vehicleForm.manufacturer);
    formData.append("year_of_manufacture", vehicleForm.year_of_manufacture);
    formData.append("purchase_date", vehicleForm.purchase_date);
    formData.append("purchase_price", vehicleForm.purchase_price);
    formData.append("registration_fee", vehicleForm.registration_fee);
    formData.append("road_tax", vehicleForm.road_tax);
    formData.append("other_expenses", vehicleForm.other_expenses);
    formData.append("total_cost", vehicleForm.total_cost);
    formData.append("insurance_taken", vehicleForm.insurance_taken);
    formData.append("loan_taken", vehicleForm.loan_taken);

    // Append file fields if they exist
    if (vehicleForm.registration_certificate) {
      formData.append(
        "registration_certificate",
        vehicleForm.registration_certificate
      );
    }

    if (vehicleForm.invoice) {
      formData.append("invoice", vehicleForm.invoice);
    }

    // Append mandates as array data
    vehicleForm.mandates.forEach((mandate, index) => {
      formData.append(`mandates[${index}]expense_type`, mandate.expense_type);
      formData.append(`mandates[${index}]amount_paid`, mandate.amount_paid);
      formData.append(`mandates[${index}]expense_date`, mandate.expense_date);
  
      // Append file in mandate if it exists
      if (mandate.upload_certificate) {
        formData.append(`mandates[${index}]upload_certificate`, mandate.upload_certificate);
      }
    });

    console.log("form in vehicle", vehicleForm);
    postVehicle(formData, categoryId);
  };

  const validateVehicleForm = () => {
    const newErrors = {};
    const yearPattern = /^\d{4}$/;
    const numberPattern = /^[A-Z0-9\s-]+$/;

    if (!vehicleForm.vehicle_number) {
      newErrors.vehicle_number = "Vehicle number is required.";
    } else if (!numberPattern.test(vehicleForm.vehicle_number)) {
      newErrors.vehicle_number =
        "Vehicle number should only contain Capital letters, numbers, spaces, or dashes.";
    }

    if (!vehicleForm.vehicle_type) {
      newErrors.vehicle_type = "Vehicle type is required.";
    }

    if (!vehicleForm.model) {
      newErrors.model = "Model is required.";
    }

    if (!vehicleForm.manufacturer) {
      newErrors.manufacturer = "Manufacturer is required.";
    }

    if (!vehicleForm.year_of_manufacture) {
      newErrors.year_of_manufacture = "Year of manufacture is required.";
    } else if (!yearPattern.test(vehicleForm.year_of_manufacture)) {
      newErrors.year_of_manufacture =
        "Year of manufacture should be a valid 4-digit year.";
    }

    if (currentStep === 1) {
      if (!vehicleForm.purchase_date) {
        newErrors.purchase_date = "Purchase date is required.";
      } else if (isNaN(new Date(vehicleForm.purchase_date).getTime())) {
        newErrors.purchase_date = "Purchase date should be a valid date.";
      }

      if (!vehicleForm.purchase_price || isNaN(vehicleForm.purchase_price)) {
        newErrors.purchase_price =
          "Purchase price is required and should be a number.";
      } else if (vehicleForm.purchase_price <= 0) {
        newErrors.purchase_price = "Purchase price must be greater than 0.";
      }

      if (
        !vehicleForm.registration_fee ||
        isNaN(vehicleForm.registration_fee)
      ) {
        newErrors.registration_fee =
          "Registration fee is required and should be a number.";
      } else if (vehicleForm.registration_fee <= 0) {
        newErrors.registration_fee = "Registration fee must be greater than 0.";
      }

      if (!vehicleForm.road_tax || isNaN(vehicleForm.road_tax)) {
        newErrors.road_tax = "Road tax is required and should be a number.";
      } else if (vehicleForm.road_tax <= 0) {
        newErrors.road_tax = "Road tax must be greater than 0.";
      }

      if (!vehicleForm.other_expenses || isNaN(vehicleForm.other_expenses)) {
        newErrors.other_expenses =
          "Other expenses are required and should be a number.";
      } else if (vehicleForm.other_expenses < 0) {
        newErrors.other_expenses = "Other expenses must be 0 or greater.";
      }

      if (!vehicleForm.total_cost || isNaN(vehicleForm.total_cost)) {
        newErrors.total_cost = "Total cost is required and should be a number.";
      } else if (vehicleForm.total_cost <= 0) {
        newErrors.total_cost = "Total cost must be greater than 0.";
      }

      const filePattern = /\.(pdf|jpg|jpeg|png)$/i;
      if (!vehicleForm.registration_certificate) {
        newErrors.registration_certificate = `RC is Required`;
      } else if (!filePattern.test(vehicleForm.registration_certificate.name)) {
        newErrors.registration_certificate = `RC should be a valid file format (PDF, JPG, or PNG).`;
      }

      if (!vehicleForm.invoice) {
        newErrors.invoice = `Invoice is Required`;
      } else if (!filePattern.test(vehicleForm.invoice.name)) {
        newErrors.invoice = `Invoice should be a valid file format (PDF, JPG, or PNG).`;
      }
    }
    let hasMandateErrors=false;
    if(currentStep ===2){
      newErrors.mandates = vehicleForm.mandates.map((mandate, index) => {
        const mandateErrors = {};
        if (!mandate.expense_type) {
          mandateErrors.expense_type = `Expense type is required for mandate ${index + 1}.`;
        }
  
        if (!mandate.amount_paid || isNaN(mandate.amount_paid)) {
          mandateErrors.amount_paid = `Amount paid is required for mandate ${index + 1} and should be a number.`;
        } else if (mandate.amount_paid <= 0) {
          mandateErrors.amount_paid = `Amount paid for mandate ${index + 1} must be greater than 0.`;
        }
  
        if (!mandate.expense_date) {
          mandateErrors.expense_date = `Expense date is required for mandate ${index + 1}.`;
        } else if (isNaN(new Date(mandate.expense_date).getTime())) {
          mandateErrors.expense_date = `Expense date for mandate ${index + 1} should be a valid date.`;
        }
  
        const filePattern = /\.(pdf|jpg|jpeg|png)$/i;
        if (!mandate.upload_certificate) {
          mandateErrors.upload_certificate = `Upload certificate is required for mandate ${index + 1}.`;
        } else if (!filePattern.test(mandate.upload_certificate.name)) {
          mandateErrors.upload_certificate = `Upload certificate for mandate ${index + 1} should be a valid file format (PDF, JPG, or PNG).`;
        }
      // Only add mandate errors to the newErrors.mandates array if there are any errors
      if (Object.keys(mandateErrors).length > 0) {
        hasMandateErrors = true;
        return mandateErrors;
      }

      return null; 
      }).filter(Boolean);
      hasMandateErrors = newErrors.mandates.length > 0;
      if(!hasMandateErrors){
        delete newErrors.mandates
      }
    }
    console.log("newErrors", newErrors)
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0 && !hasMandateErrors;
  };

  const handleChange = (e) => {
    let { name, value, type, files } = e.target;
    if (type === "radio") {
      const bool = value === "true" ? true : value === "false" ? false : null;
      value = bool;
    }
    setVehicleForm((prevForm) => ({
      ...prevForm,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  const handleMandateChange = (index, e) => {
    const { name, value, files, type } = e.target;
    const fieldName = name.split(".")[1];
    const updatedMandates = vehicleForm.mandates.map((mandate, i) =>
      i === index
        ? { ...mandate, [fieldName]: type === "file" ? files[0] : value }
        : mandate
    );
    setVehicleForm({ ...vehicleForm, mandates: updatedMandates });
  };

  const addMandate = () => {
    setVehicleForm((prevForm) => ({
      ...prevForm,
      mandates: [
        ...prevForm.mandates,
        {
          expense_type: "",
          amount_paid: "",
          expense_date: "",
          upload_certificate: null,
        },
      ],
    }));
  };

  const toggleAssetType = (type) => {
    setAssetType(type);
  };
  const progressSteps = [
    {
      label: "Vehicle Information",
      active: currentStep === 0,
      visited: visitedSteps.includes(1),
    },
    {
      label: `Vehicle Value`,
      active: currentStep === 1,
      visited: visitedSteps.includes(2),
    },
    {
      label: `Expenses`,
      active: currentStep === 2,
      visited: visitedSteps.includes(3),
    },
  ];

  useEffect(() => {
    if (!visitedSteps.includes(currentStep)) {
      setVisitedSteps((prev) => [...prev, currentStep]);
    }
  }, [currentStep, visitedSteps]);

  const handleNext = () => {
    if (currentStep === 3) {
      handleSubmit();
    }
    if (currentStep < 3) {
      if (validateVehicleForm()) {
        setCurrentStep(currentStep + 1);
      }
    } else {
      console.log("Reached the last step");
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleCapitalExpense = () => {
    setCapitalExpense(true);
    setVehicleForm((prevForm) => ({
      ...prevForm,
      mandates: [
        {
          expense_type: "",
          amount_paid: "",
          expense_date: "",
          upload_certificate: null,
        },
      ],
    }));
  };

  const removeCapitalExpense = (index) => {
    // Create a new copy of the mandates array and remove the item at the specified index
    const updatedMandates = vehicleForm.mandates.filter((el, i) => i !== index);

    // Create a new vehicleForm object with the updated mandates array
    const updatedVehicleForm = { ...vehicleForm, mandates: updatedMandates };

    // Update the state with the new vehicleForm
    setVehicleForm(updatedVehicleForm);
  };

  // Generate the options array for years from 2005 to 2031
  const yearOptions = Array.from({ length: 2031 - 2005 + 1 }, (_, index) => {
    const year = 2005 + index;
    return { value: year.toString(), label: year.toString() };
  });

  // Add a default "Select an Option" entry at the beginning
  yearOptions.unshift({ value: "", label: "Select an Option" });

  const handleViewMandatePdf = (pdfUrl) => {
    window.open(URL.createObjectURL(pdfUrl), "_blank");
  };

  const viewRcPdf = () => {
    window.open(
      URL.createObjectURL(vehicleForm.registration_certificate),
      "_blank"
    );
  };

  const viewInvoicePdf = () => {
    window.open(URL.createObjectURL(vehicleForm.invoice, "_blank"));
  };

  const getContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Vehicle Information
            </h4>

            {/* form */}
            <div className="rounded-md bg-[#F1F5F9] px-3 py-3 mt-[1rem] mb-[1rem]">
              <div className="flex items-end gap-3">
                <div className="input-container w-[47.5%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Vehicle Number
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={vehicleForm.vehicle_number}
                    onChange={handleChange}
                    name="vehicle_number"
                    placeholder="Enter Vehicle No."
                  />
                  {errors.vehicle_number && (
                    <p className="text-red-500 text-[0.75rem]">
                      {errors.vehicle_number}
                    </p>
                  )}
                </div>
                <div className="">
                  <Button
                    name={"Get Details"}
                    px={"px-[0.6rem]"}
                    py={"py-[0.4rem]"}
                  />
                </div>
              </div>
            </div>
            <div className="rounded-md bg-[#F1F5F9] mt-[1rem] mb-[1.5rem]">
              <div className="grid grid-cols-12 px-3 py-1 gap-3">
                <div className="input-container col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Vehicle Type
                  </label>
                  <InputField
                    type="select"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                    options={[
                      { value: "", label: "Select Type" },
                      { value: "car", label: "Car" },
                      { value: "bike", label: "Bike" },
                      { value: "truck", label: "Truck" },
                    ]}
                    name="vehicle_type"
                    value={vehicleForm.vehicle_type}
                    onChange={handleChange}
                  />
                  {errors.vehicle_type && (
                    <p className="text-red-500 text-[0.75rem]">
                      {errors.vehicle_type}
                    </p>
                  )}
                </div>

                <div className="input-container col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Model
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={vehicleForm.model}
                    onChange={handleChange}
                    name="model"
                    placeholder="Model"
                  />
                  {errors.model && (
                    <p className="text-red-500 text-[0.75rem]">
                      {errors.model}
                    </p>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-12 px-3 py-2.5 gap-3">
                <div className="input-container col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Manufacturer
                  </label>
                  <InputField
                    type="text"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                    value={vehicleForm.manufacturer}
                    onChange={handleChange}
                    name="manufacturer"
                    placeholder="Manufacturer"
                  />
                  {errors.manufacturer && (
                    <p className="text-red-500 text-[0.75rem]">
                      {errors.manufacturer}
                    </p>
                  )}
                </div>
                <div className="col-span-6 w-[100%] group">
                  <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                    Year of Manufacture
                  </label>
                  <InputField
                    type="select"
                    className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                    options={yearOptions}
                    name="year_of_manufacture"
                    value={vehicleForm.year_of_manufacture}
                    onChange={handleChange}
                  />
                  {errors.year_of_manufacture && (
                    <p className="text-red-500 text-[0.75rem]">
                      {errors.year_of_manufacture}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Vehicle Value
            </h4>
            <div className="overflow-y-auto max-h-[300px] scrollbar-hide">
              {/* type of asset */}
              <div className="rounded-md bg-[#F1F5F9] mt-[0.8rem] mb-[1rem] px-[1rem] py-[0.7rem]">
                <div className="flex justify-between items-start">
                  <div>
                    <label className="input-label text-[0.75rem]">
                      Type of Asset
                    </label>
                    <div className="p-1 flex items-center border rounded-md overflow-hidden bg-white w-fit">
                      <button
                        onClick={() => toggleAssetType("active")}
                        className={`px-2 py-1.5 text-[0.8rem] font-medium ${
                          assetType === "active"
                            ? "bg-gray-800 text-white"
                            : "bg-white text-gray-500"
                        } rounded-md transition-all`}
                        style={{ minWidth: "100px" }} // ensures both buttons have a consistent width
                      >
                        Active Asset
                      </button>
                      <button
                        onClick={() => toggleAssetType("idle")}
                        className={`px-2 py-1.5 text-[0.8rem] font-medium ${
                          assetType === "idle"
                            ? "bg-gray-800 text-white"
                            : "bg-white text-gray-500"
                        } rounded-md transition-all`}
                        style={{ minWidth: "100px" }} // ensures both buttons have a consistent width
                      >
                        Idle Asset
                      </button>
                    </div>
                    <label className="text-[0.7rem] font-[500] text-customPurple">
                      {assetType === "active"
                        ? "This asset is generating income."
                        : "This asset is idle and not generating income."}
                    </label>
                  </div>

                  <div>
                    <WhiteButton
                      name="Upload Registration Certificate"
                      px={"px-[0.6rem]"}
                      py={"py-[0.4rem]"}
                      text={"text-[0.75rem]"}
                      mt={"mt-[12px]"}
                      onClick={() =>
                        document.getElementById("file-upload").click()
                      }
                    />
                    {errors.registration_certificate && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.registration_certificate}
                      </p>
                    )}
                  </div>
                  <InputField
                    id={`file-upload`}
                    type="file"
                    onChange={handleChange}
                    className="hidden"
                    name="registration_certificate"
                  />
                </div>
              </div>
              {/* purchase details */}
              <div className="rounded-md bg-[#F1F5F9] mb-[2rem] px-[1rem] py-[0.7rem]">
                <h4 className="text-[#334155] font-[600] text-[0.9rem]">
                  Purchase Details
                </h4>
                <div className="grid grid-cols-12 mt-1.5">
                  <div className="input-container w-[100%] group col-span-6 gap-3">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Purchase Date
                    </label>
                    <InputField
                      type="date"
                      className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[97%] p-[0.4rem] focus:outline-none focus:border-[#9065B4]"
                      value={vehicleForm.purchase_date}
                      name="purchase_date"
                      onChange={handleChange}
                    />
                    {errors.purchase_date && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.purchase_date}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-3 mt-2">
                  <div className="col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Purchase Price
                    </label>
                    <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                      <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name="purchase_price"
                        value={vehicleForm.purchase_price}
                        onChange={handleChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                    </div>
                    {errors.purchase_price && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.purchase_price}
                      </p>
                    )}
                  </div>

                  <div className="col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Registration Fee
                    </label>
                    <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                      <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name="registration_fee"
                        value={vehicleForm.registration_fee}
                        onChange={handleChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                    </div>
                    {errors.registration_fee && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.registration_fee}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-3 mt-1.5">
                  <div className="col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Road Tax
                    </label>
                    <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                      <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name="road_tax"
                        value={vehicleForm.road_tax}
                        onChange={handleChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                    </div>
                    {errors.road_tax && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.road_tax}
                      </p>
                    )}
                  </div>

                  <div className="col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Other Expenses
                    </label>
                    <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                      <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name="other_expenses"
                        value={vehicleForm.other_expenses}
                        onChange={handleChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                    </div>
                    {errors.other_expenses && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.other_expenses}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-3 mt-1.5">
                  <div className="col-span-6 w-[100%] group">
                    <label className="group-focus-within:text-customPurple font-[500] text-[#64748B] text-[0.75rem]">
                      Total Cost
                    </label>
                    <div className="w-[100%] rounded-md border-[1px] border-[#94A3B8] flex justify-center items-center focus-within:border-customPurple">
                      <span className=" text-[#64748B] bg-[#F1F5F9] rounded-l-md w-[20%] p-[0.40rem] group-focus-within:text-customPurple">
                        Rs.
                      </span>
                      <InputField
                        type="number"
                        name="total_cost"
                        value={vehicleForm.total_cost}
                        onChange={handleChange}
                        className="rounded-r-md border-[1px] border-[white] bg-white text-[0.9rem] w-[100%]  p-[0.4rem] focus:outline-none"
                      />
                    </div>
                    {errors.total_cost && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.total_cost}
                      </p>
                    )}
                  </div>
                  <div className="col-span-4">
                    <WhiteButton
                      name="Upload Invoice"
                      px={"px-[0.6rem]"}
                      py={"py-[0.52rem]"}
                      text={"text-[0.9rem]"}
                      mt={"mt-[1.5rem]"}
                      border={"!border-[1px] !border-[#94A3B8]"}
                      onClick={() =>
                        document.getElementById("file-upload-invoice").click()
                      }
                    />
                    {errors.invoice && (
                      <p className="text-red-500 text-[0.75rem]">
                        {errors.invoice}
                      </p>
                    )}
                    <InputField
                      id={`file-upload-invoice`}
                      type="file"
                      onChange={handleChange}
                      className="hidden"
                      name="invoice"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-3">
                  <div className="input-container mt-[0.6rem]">
                    <label className="input-label">
                      Insurance taken for this Vehicle?
                    </label>
                    <div className="flex gap-3 items-center mt-[0.5rem]">
                      <label className="input-label cursor-pointer focus-within:text-customPurple">
                        <InputField
                          type="radio"
                          name="insurance_taken"
                          value={true}
                          checked={vehicleForm.insurance_taken === true}
                          onChange={handleChange}
                          className="mr-2 custom-radio cursor-pointer"
                        />
                        Yes
                      </label>
                      <label className="input-label cursor-pointer focus-within:text-customPurple">
                        <InputField
                          type="radio"
                          name="insurance_taken"
                          value={false}
                          checked={vehicleForm.insurance_taken === false}
                          onChange={handleChange}
                          className="mr-2 custom-radio cursor-pointer"
                        />
                        No
                      </label>
                    </div>
                  </div>
                  <div className="input-container mt-[0.6rem]">
                    <label className="input-label">
                      Loan taken for this Vehicle?
                    </label>
                    <div className="flex gap-3 items-center mt-[0.5rem]">
                      <label className="input-label cursor-pointer focus-within:text-customPurple">
                        <InputField
                          type="radio"
                          value={true}
                          name="loan_taken"
                          checked={vehicleForm.loan_taken === true}
                          onChange={handleChange}
                          className="mr-2 custom-radio cursor-pointer"
                        />
                        Yes
                      </label>
                      <label className="input-label cursor-pointer focus-within:text-customPurple">
                        <InputField
                          type="radio"
                          value={false}
                          name="loan_taken"
                          checked={vehicleForm.loan_taken === false}
                          onChange={handleChange}
                          className="mr-2 custom-radio cursor-pointer"
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1rem]">
              Any expense on your Vehicle?
            </h4>
            {capitalExpense ? (
              <div className="overflow-y-auto max-h-[300px] scrollbar-hide">
                <h4 className="text-[#334155] font-[500] text-[1rem] mt-[1rem]">
                  Mandate Expenses
                </h4>

                {vehicleForm.mandates.map((item, index) => (
                  <div className={`rounded-md bg-[#F1F5F9] mt-[0.6rem] mb-2.5`}>
                    <div className="grid grid-cols-2 px-[1rem] py-[0.7rem]">
                      <div className="input-container">
                        <label className="input-label">Expense Type</label>
                        {/* <InputField
                          value={item.expense_type}
                          type="text"
                          name={`mandates[${index}].expense_type`}
                          placeholder="Enter Expense Type"
                          className="input-box border rounded-md bg-white"
                          onChange={(e) => handleMandateChange(index, e)}
                        /> */}
                          <InputField
                            type="select"
                            className="border-[1px] border-[#94A3B8] rounded-md bg-white text-[0.9rem] w-[100%] p-[0.48rem] focus:outline-none focus:border-[#9065B4]"
                            options={[
                              { value: "", label: "Select an Option" },
                              { value: "maintenance", label: "Maintenance" },
                              { value: "insurance", label: "Insurance" },
                              { value: "repair", label: "Repair" },
                              { value: "tax", label: "Tax" },
                              {value:"other", label:'Other'},
                            ]}
                            name={`mandates[${index}].expense_type`}
                            value={item.expense_type}
                            onChange={(e) => handleMandateChange(index, e)}
                          />
                        {errors.mandates && errors.mandates[index]?.expense_type && (
                          <p className="text-red-500 text-[0.75rem]">
                            {errors.mandates[index].expense_type}
                          </p>
                        )}
                      </div>

                      <div className="input-container">
                        <label className="input-label">Amount Paid</label>
                        <InputField
                          value={item.amount_paid}
                          type="number"
                          name={`mandates[${index}].amount_paid`}
                          placeholder="Enter Amount Paid"
                          className="input-box border rounded-md bg-white"
                          onChange={(e) => handleMandateChange(index, e)}
                        />
                        {errors.mandates &&
                          errors.mandates[index]?.amount_paid && (
                            <p className="text-red-500 text-[0.75rem]">
                              {errors.mandates[index].amount_paid}
                            </p>
                          )}
                      </div>

                      <div className="input-container mt-[0.3rem] ">
                        <label className="input-label">Expense Date</label>
                        <InputField
                          value={item.expense_date}
                          name={`mandates[${index}].expense_date`}
                          type="date"
                          className="input-box border rounded-md bg-white "
                          onChange={(e) => handleMandateChange(index, e)}
                        />
                        {errors.mandates &&
                          errors.mandates[index]?.expense_date && (
                            <p className="text-red-500 text-[0.75rem]">
                              {errors.mandates[index].expense_date}
                            </p>
                          )}
                      </div>

                      <div className="mt-[1.7rem] flex gap-2">
                        <div>
                          <button
                            onClick={() =>
                              document
                                .getElementById(
                                  `file-upload-array-certificate-${index}`
                                )
                                .click()
                            }
                            className="text-lightGray bg-white font-[600] text-[0.9rem] border-1 border py-[0.2rem] px-[0.7rem] rounded-md flex gap-2 items-center"
                          >
                            Upload Certificate
                          </button>
                          {errors.mandates &&
                            errors.mandates[index]?.upload_certificate && (
                              <p className="text-red-500 text-[0.75rem]">
                                {errors.mandates[index].upload_certificate}
                              </p>
                            )}
                        </div>
                        <InputField
                          id={`file-upload-array-certificate-${index}`}
                          name={`mandates[${index}].upload_certificate`}
                          type="file"
                          onChange={(e) => handleMandateChange(index, e)}
                          className="hidden"
                        />
                        <button
                          onClick={() => {
                            if (vehicleForm.mandates.length === 1) {
                              setCapitalExpense(false);
                            }
                            removeCapitalExpense(index);
                          }}
                        >
                          <img
                            src={deleteIcon}
                            alt="delete icon"
                            className="w-11 h-10"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <h4 className="text-[#334155] font-[500] text-[1rem] mt-[1rem]">
                  Mandate Expenses
                </h4>
                <div
                  className={`flex justify-center rounded-md bg-[#F1F5F9] mt-[0.8rem] py-[1.5rem] mb-2.5`}
                >
                  <button
                    onClick={handleCapitalExpense}
                    className="text-lightGray text-[0.9rem] border rounded-md font-[500] py-1 px-3 hover:bg-white"
                  >
                    Add mandate expense
                  </button>
                </div>
              </>
            )}
          </>
        );
      case 3:
        return (
          <>
            <h4 className="text-[#334155] font-[500] text-[1.1rem]">
              Review your details
            </h4>
            <div className="flex justify-between mb-3">
              <div>
                <p className="m-0 text-[#334155] text-[0.9rem] font-[600]">
                  Vehicle Details
                </p>
              </div>
              <div className="flex gap-2.5">
                <WhiteButton
                  px={"px-[0.8rem]"}
                  py={"py-[0.3rem]"}
                  name="View RC"
                  onClick={viewRcPdf}
                />
                <WhiteButton
                  px={"px-[0.8rem]"}
                  py={"py-[0.3rem]"}
                  name="Edit"
                  onClick={() => setCurrentStep(0)}
                />
              </div>
            </div>
            <div className="border bg-white rounded-xl p-[1rem] mb-3 w-[80%]">
              <div className="mt-2.5">
                <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                  Vehicle Number
                </p>
                <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                  {vehicleForm.vehicle_number}
                </p>
              </div>
              <div className="flex gap-3 mt-2.5">
                <div>
                  <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                    Type
                  </p>
                  <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                    {vehicleForm.vehicle_type}
                  </p>
                </div>
                <div>
                  <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                    Manufacturer
                  </p>
                  <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                    {vehicleForm.manufacturer}
                  </p>
                </div>
                <div>
                  <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                    Model
                  </p>
                  <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                    {vehicleForm.model}
                  </p>
                </div>
                <div>
                  <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                    Year of Manufacture
                  </p>
                  <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                    {vehicleForm.year_of_manufacture}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center mb-3">
              <h4 className="text-[#334155] font-[600] text-[0.9rem] m-0">
                Vehicle Value
              </h4>
              <WhiteButton
                px={"px-[0.8rem]"}
                py={"py-[0.3rem]"}
                name="Edit"
                onClick={() => setCurrentStep(1)}
              />
            </div>
            <div className="bg-[#F8FAFC] border rounded-md w-[40%] mb-3">
              <div className="p-[1rem]">
                <h4 className="text-lightGray font-[500] text-[0.8rem]">
                  Asset Type
                </h4>
                <div className="flex gap-1">
                  <p className="text-darkGray font-[500] text-[1.1rem] m-0">
                    Idle Asset
                  </p>
                  <img src={info1} alt="info" />
                </div>
              </div>
            </div>
            <div className="border bg-white rounded-xl p-[1rem] mb-3 w-[80%]">
              <div className="flex justify-between">
                <div>
                  <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
                    Purchase Details
                  </p>
                </div>
                <div className="flex gap-2.5">
                  <WhiteButton
                    px={"px-[0.8rem]"}
                    py={"py-[0.3rem]"}
                    name="View Invoice"
                    onClick={viewInvoicePdf}
                  />
                  <WhiteButton
                    px={"px-[0.8rem]"}
                    py={"py-[0.3rem]"}
                    name="Edit"
                    onClick={() => setCurrentStep(1)}
                  />
                </div>
              </div>
              <div className="flex gap-[5rem] mt-3">
                <div className="w-[33%]">
                  <p className="text-[#9065B4] m-0 font-[500] text-[0.75rem]">
                    Total Cost
                  </p>
                  <p className="text-[#9065B4] m-0 text-[1rem] font-[500]">
                    {vehicleForm.total_cost}{" "}
                    <span className="text-[0.8rem]">Rs.</span>
                  </p>
                </div>
                <div>
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Purchase Date
                  </p>
                  <p className="text-[#64748B] m-0 text-[1rem] font-[500]">
                    {vehicleForm.purchase_date}
                  </p>
                </div>
              </div>
              <div className="flex gap-[5rem] mt-3">
                <div className="w-[33%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Insurance taken?
                  </p>
                  <p className="text-[#64748B] m-0 text-[1rem] font-[500]">
                    {vehicleForm.insurance_taken ? "Yes" : "No"}
                  </p>
                </div>
                <div>
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Loan taken?
                  </p>
                  <p className="text-[#64748B] m-0 text-[1rem] font-[500]">
                    {vehicleForm.loan_taken ? "Yes" : "No"}
                  </p>
                </div>
              </div>
              <div className="flex gap-[5rem] mt-3">
                <div className="w-[33%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Road Tax
                  </p>
                  <p className="text-[#64748B] m-0 text-[1rem] font-[500]">
                    {vehicleForm.road_tax}
                    <span className="text-[0.8rem]">Rs.</span>
                  </p>
                </div>
                <div>
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Registration Fee
                  </p>
                  <p className="text-[#64748B] m-0 text-[1rem] font-[500]">
                    {vehicleForm.registration_fee}{" "}
                    <span className="text-[0.8rem]">Rs.</span>
                  </p>
                </div>
              </div>
              <div className="flex gap-[5rem] mt-3">
                <div className="w-[33%]">
                  <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                    Other Expenses
                  </p>
                  <p className="text-[#64748B] m-0 text-[1rem] font-[500]">
                    {vehicleForm.other_expenses}{" "}
                    <span className="text-[0.8rem]">Rs.</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-between mb-2.5">
              <div>
                <p className="m-0 text-[#334155] font-[600] text-[0.9rem]">
                  Mandate Expenses on Vehicle
                </p>
              </div>
              <div className="">
                <WhiteButton
                  px={"px-[0.8rem]"}
                  py={"py-[0.3rem]"}
                  name="Edit"
                  onClick={() => setCurrentStep(2)}
                />
              </div>
            </div>
            {vehicleForm.mandates.map((el, i) => {
              return (
                <div
                  key={i}
                  className="border bg-white rounded-xl p-[1rem] mb-3 w-[80%]"
                >
                  <div className="flex gap-[5rem] mt-3">
                    <div className="w-[33%]">
                      <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                        Expense Type
                      </p>
                      <p className="text-[#64748B] m-0 text-[1rem] font-[500]">
                        {el.expense_type}
                      </p>
                    </div>
                    <div className="">
                      <p className="text-[#9065B4] m-0 font-[500] text-[0.75rem]">
                        Amount Paid
                      </p>
                      <p className="text-[#9065B4] m-0 text-[1rem] font-[500]">
                        {el.amount_paid}{" "}
                        <span className="text-[0.8rem]">Rs.</span>
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-[5rem] mt-3">
                    <div className="w-[33%]">
                      <p className="text-[#64748B] m-0 font-[500] text-[0.75rem]">
                        Date
                      </p>
                      <p className="text-[#64748B] m-0 text-[1rem] font-[500]">
                        {el.expense_date}
                      </p>
                    </div>
                    <div className="flex items-center">
                      <button
                        onClick={() =>
                          handleViewMandatePdf(el.upload_certificate)
                        }
                        className="text-lightGray bg-white font-[600] text-[0.9rem] border-1 border py-[0.2rem] px-[0.7rem] rounded-md flex gap-2 items-center"
                      >
                        <img src={upload} alt="upload" />
                        View Invoice
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <DialogBox
        onClose={resetState}
        resetClose={setIsAddLand}
        isNotDefault={isAddLand}
        triggerElement={triggerElement}
        progressSteps={progressSteps}
        heading={
          <h4 className="font-[600] text-xl text-[#334155]">
            Add <span className="text-[#9065B4]">Vehicle</span> to your Assets
          </h4>
        }
        content={(handleClose) => (
          <>
            {getContent()}
            <div
              className={`${
                currentStep == 2 && capitalExpense ? "flex justify-between" : ""
              }`}
            >
              {currentStep == 2 && capitalExpense ? (
                <WhiteButton
                  onClick={addMandate}
                  name="Add another expense"
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
              ) : (
                ""
              )}

              <div className="flex justify-end gap-[1rem] ">
                <WhiteButton
                  onClick={handleBack}
                  name="Back"
                  px={"px-[0.6rem]"}
                  py={"py-[0.4rem]"}
                />
                {currentStep < 3 ? (
                  <Button
                    onClick={handleNext}
                    name={currentStep == 2 ? "Preview & Save" : "Next"}
                    px={"px-[0.6rem]"}
                    py={"py-[0.4rem]"}
                  />
                ) : (
                  <Button
                    onClick={handleNext}
                    name={"Add to Assets"}
                    px={"px-[0.6rem]"}
                    py={"py-[0.4rem]"}
                  />
                )}
              </div>
            </div>
            {isClose && handleClose()}
          </>
        )}
      />
    </div>
  );
};

export default AddNewVehicle;
