import React, { useState } from "react";
// import bg from "assets/images/home/title_bg.svg";
import search from "assets/images/home/search.svg";
import Button from "components/Button/BtnOrange";
import HeaderImg from "assets/images/home/image1.png";
// import specular from 'assets/images/home/Specular.svg'
import { GET_SERVICE_URL } from "constants/routes";
import { Link } from "react-router-dom";
function HomeTitle() {
  const [searchClicked, setSearchedClick] = useState(false);
  const [reqListState, setRequiredListState] = useState([]);
  const [req, setReq] = useState("");

  const servicesArr = [
    {
      name: "Udyam - MSME Registration",
      link: `${GET_SERVICE_URL}/udyam-msme-registration`,
    },
    { link: ``, name: "Trade License" },
    { link: ``, name: "Shop & Establishment Registration" },
    { link: ``, name: "Labour License" },
    { link: ``, name: "Food License" },
    { link: ``, name: "Import Export Code" },
    { link: ``, name: "Startup India" },
    { link: ``, name: "Digital Signatures" },
    { link: ``, name: "Agreements" },
    { link: ``, name: "PAN Registration" },
    { link: ``, name: "TAN Registration" },
    { link: ``, name: "GST Registration" },
    { link: ``, name: "Professional Tax Registration" },
    { link: ``, name: "PF Registration" },
    { link: ``, name: "ESI Registration" },
    { link: ``, name: "HUF Registration" },
    { link: ``, name: "Firm Registration" },
    { link: ``, name: "LLP Registration" },
    { link: ``, name: "Company Incorporation" },
  ];
  // console.log(servicesArr.name);

  var reqList = [];
  const change = (e) => {
    // console.log(e)
    servicesArr.map((data) => {
      if (data.name.toLowerCase().includes(e)) {
        // console.log(data)
        reqList.push(data);
        // console.log(reqList)
      } else {
        // console.log('no')
      }
    });
    // reqList = servicesArr.filter((data) => {
    //   console.log(data);
    //   return data && data.toLowerCase().includes(e);
    // });
    // console.log(reqList);
    // console.log(req)

    setRequiredListState(reqList);
  };
  // console.log(req.length)
  // console.log(reqListState);
  return (
    <>
      <div className='bg-[url("assets/images/home/background.png")] p-[8%] bg-center bg-cover w-screen max-h-screen h-fit relative overflow-hidden flex flex-col items-center '>
        <div className="HomeTitle  flex    justify-center">
          <div>
            {" "}
            <h1 className="titleHeading lg:leading-[5rem] lg:font-[700] lg:text-[4rem] lg:px-[8%] lg:py-0  md:leading-[5rem] md:font-[700] md:text-[4rem] md:px-[8%] md:py-0 pt-[25%] text-center  text-[2.1rem] font-[600] px-[4%]">
              More than Your Tax Needs
            </h1>
            <p className=" lg:px-[8%] md:px-[8%] py-4 text-[1rem] lg:font-[400]  text-gray[400 lg:text-[#4D4D4D] leading-[1.5rem] text-center">
              Discover easy, all-in-one financial care for your business
              journey, a partner who makes every penny count.
            </p>
            <div className="  z-2 relative mb-[20%] justify-center w-[100%]">
              <div className=" relative text-center">
                <i
                  // className="fa-solid fa-magnifying-glass absolute top-3 ml-4"
                  class={`fa-solid fa-magnifying-glass absolute  ${
                    searchClicked ? "top-3 ml-4" : "top-3 ml-[6rem]"
                  } transition-all duration[500]`}
                  style={{ color: "#ebebeb" }}
                />
                <input
                  style={{ fontFamily: "" }}
                  onClick={() => {
                    setSearchedClick(true);
                  }}
                  onChange={(e) => {
                    change(e.target.value);
                    setReq(e.target.value);
                  }}
                  value={req}
                  className="py-[0.5rem] pl-[3rem] pr-[2rem] font-[400] text-[1rem] leading-[1.5rem] text-white rounded-lg placeholder:text-center placeholder:text-white whitespace-nowrap  bg-purple-dark-shade outline-none decoration-0"
                  placeholder={` ${searchClicked ? "" : "Search"}`}
                />
              </div>

              {req.length > 0 && (
                <div className="absolute left-60 rounded-xl bg-white p-[4%]">
                  {reqListState.length === 0 ? (
                    <p>No Services found</p>
                  ) : (
                    reqListState.map((data) => {
                      return (
                        <>
                          <Link
                            to={data.link}
                            className="no-underline text-black"
                          >
                            {" "}
                            <p
                              onClick={() => {
                                // setServiceId(data.id);
                                setReq(data.name);
                              }}
                              className="m-0 hover:cursor-pointer font-[400] text-[1rem] leading-[1.5rem]"
                            >
                              {data.name}
                            </p>
                          </Link>
                        </>
                      );
                    })
                  )}
                </div>
              )}

              {/* <img src={specular}/> */}
              {/* <button className="Btn" style={{display:'flex'}}><img src={search}/> &nbsp; &nbsp; <span>Search</span></button> */}
              {/* <div class="input-with-icon flex items-center relative">
              <img src={search}/> 
    <input type="text" placeholder="Username" />
</div> */}
            </div>
          </div>
        </div>
        <img
          src={HeaderImg}
          className="up-down-animation absolute z-1 lg:-bottom-[50%] lg:w-[70%] mx-auto w-[100%] md:-bottom-[50%] md:w-[70%] -bottom-[25%]"
          alt=""
        />
      </div>
    </>
  );
}

export default HomeTitle;
