import React, { useEffect, useRef, useState } from "react";
import AssetsHeading from "../AssetsHeading";
import AddNewRealEstate from "./AddNewRealEstate";
import landGray from "assets/images/assets/landGray.svg";
import { Link, useLocation } from "react-router-dom";
import { ASSETS_REAL_ESTATE_DETAILED_URL } from "constants/routes";
import AddNewLand from "./Add/AddNewLand";
import useGetLandDetails from "../data/usegetLandDetails";
import Button from "components/Button/BtnOrange";

const Land = () => {
  const location = useLocation();
  const addNewBtnRef = useRef(null);
  const [selectedEstate, setSelectedEstate] = useState("");
  const [selectedAssetId, setSelectedAssetId] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const [reload, setIsReload]=useState(false)

  const landId =
    location.state?.landId || decodeURIComponent(queryParams.get("id"));

  const [getLandData, getLandError, getLandIsLoading, getLand] =
    useGetLandDetails();

  useEffect(() => {
    getLand(landId);
  }, []);

  useEffect(()=>{
    if(reload){
      getLand(landId);
    }
   },[reload])

  const handleAddNew = (estateType, assetId) => {
    setSelectedEstate(estateType);
    setSelectedAssetId(assetId);
    addNewBtnRef.current.click();
  };

  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 h-screen">
      <AssetsHeading asset={"Real Estate"} asset_type={"Lands"} />
      <div className="flex mt-[1.5rem] border bg-white rounded-xl  grid grid-cols-12 divide-x">
        <div className=" p-[1rem] col-span-3">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Total Assets Value
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">
            ₹{getLandData?.data[0]?.total_land_assets_value}
          </h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Total Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">
            {getLandData?.data[0]?.total_land_assets}
          </h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Active Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">
            {getLandData?.data[0]?.active_land_assets}
          </h4>
        </div>

        <div className="p-[1rem] col-span-2">
          <h4 className="text-darkGray font-[500] text-[1.1rem]">
            Idle Assets
          </h4>
          <h4 className="text-customPurple font-[500] text-[1.7rem] m-0">
            {getLandData?.data[0]?.idle_land_assets}
          </h4>
        </div>
        <div className="flex justify-center items-center p-[1rem] col-span-3">
          <Button
            onClick={() => handleAddNew("Land", landId)}
            px={"px-[0.9rem]"}
            py={"py-[0.3rem]"}
            name="+ Add New Land"
          />
        </div>
      </div>

      {/* lands */}
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="grid grid-cols-4 gap-2 ">
          {getLandData ? (
            getLandData?.data?.map((item, index) => (
              <Link
                to={`${ASSETS_REAL_ESTATE_DETAILED_URL.replace(
                  ":estate_type",
                  "land"
                )}?id=${landId}/?land_id=${item.id}`}
                state={{
                  assetId: landId,
                  itemId: item.id,
                  assetCategory: item.survey_number,
                  asset_type: "Lands",
                }}
                key={item.id}
                className="no-underline"
              >
                <div className="rounded-md border p-[1rem] bg-[#F0FDF4]">
                  <img src={landGray} alt="land" className="w-7 h-7 mb-2" />
                  <h5 className="text-lightGray font-[600] text-[0.9rem]">
                    {item.survey_number}
                  </h5>
                  <div className="flex justify-end mt-[1.8rem]">
                    <h4 className="text-darkGray font-[600] text-[1.3rem] m-0">
                      ₹ {item.total_cost}
                    </h4>
                  </div>{" "}
                </div>
              </Link>
            ))
          ) : (
            <div className="rounded-md border-2 border-dashed p-[1rem]">
              <img src={landGray} alt="land" className="w-7 h-7 mb-2" />
              <h5 className="text-lightGray font-[600] text-[0.9rem]">Land</h5>
              <div className="flex justify-end mt-[1.8rem]">
                <button className="border rounded-md text-lightGray text-[0.8rem] font-[500] px-2 py-1">
                  Add New
                </button>
              </div>{" "}
            </div>
          )}
        </div>
      </div>

      <AddNewLand
      setIsReload={setIsReload}
        assetId={selectedAssetId}
        estateType={selectedEstate}
        triggerElement={
          <button ref={addNewBtnRef} className="hidden">
            Add New
          </button>
        }
      />
    </div>
  );
};

export default Land;
