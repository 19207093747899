import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import previousBtn from "assets/images/taxProfile/arrow-left.svg";
import { ASSETS_TYPE_URL, ASSETS_URL } from "constants/routes";

const AssetsHeading = ({ asset, asset_type, asset_detail, assetId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isDetailedView = location.pathname.includes("detailed-view");

  const handleAssetsClicked = () => {
    navigate(ASSETS_URL);
  };

  const handleAssetTypeClicked = () => {
    if (location.pathname.includes("land")) {
      navigate(
        `${ASSETS_TYPE_URL.replace(":type", "real-estate-land")}?id=${assetId}`
      );
    } else if (location.pathname.includes("building")) {
      navigate(
        `${ASSETS_TYPE_URL.replace(":type", "real-estate-building")}?id=${assetId}`
      );
    } else if (location.pathname.includes("sukanya")) {
      navigate(
        `${ASSETS_TYPE_URL.replace(":type", "deposits-sukanya-samridhi")}?id=${assetId}`
      );
    }
    else if (location.pathname.includes("Vehicle")) {
      navigate(
        `${ASSETS_TYPE_URL.replace(":type", "personal-asset-vehicle")}?id=${assetId}`
      );
    }
    else if (location.pathname.includes("GoldAndJewellery")) {
      navigate(
        `${ASSETS_TYPE_URL.replace(":type", "personal-asset-gold")}?id=${assetId}`
      );
    }
    else if (location.pathname.includes("FurnitureAndArt")) {
      navigate(
        `${ASSETS_TYPE_URL.replace(":type", "personal-asset-furniture")}?id=${assetId}`
      );
    }
    else if (location.pathname.includes("LoansToFamilyFriends")) {
      navigate(
        `${ASSETS_TYPE_URL.replace(":type", "other-asset-loan")}?id=${assetId}`
      );
    }
    else if (location.pathname.includes("IntellectualProperty")) {
      navigate(
        `${ASSETS_TYPE_URL.replace(":type", "other-asset-intellectual")}?id=${assetId}`
      );
    }
    else if (location.pathname.includes("Domain")) {
      navigate(
        `${ASSETS_TYPE_URL.replace(":type", "other-asset-domain")}?id=${assetId}`
      );
    }
  };

  const handlePrevious = () => {
    if (isDetailedView) {
      handleAssetTypeClicked();
    } else {
      navigate(ASSETS_URL);
    }
  };

  return (
    <div className="pt-[2rem] flex gap-[1rem] items-center">
      <button onClick={handlePrevious}>
        <img src={previousBtn} alt="Previous" className="w- h-" />
      </button>
      <h4 className="font-[600] text-darkGray text-[1.5rem] m-0">
        <span onClick={handleAssetsClicked} className="cursor-pointer">
          Assets
        </span>
        {asset && ` / ${asset}`}
        {asset && asset_type && (
          <span onClick={handleAssetTypeClicked} className="cursor-pointer">
            {` / ${asset_type}`}
          </span>
        )}
        {isDetailedView && asset && asset_type && asset_detail && ` / ${asset_detail}`}
      </h4>
    </div>
  );
};

export default AssetsHeading;

