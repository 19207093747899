import React, { useEffect, useState } from "react";
import AssetsHeading from "../AssetsHeading";
import WhiteButton from "components/Button/WhiteButton";
import info1 from "assets/images/incomeTax/info1.svg";
import EditVehicle from "./Edit/EditVehicle";
import EditGold from "./Edit/EditGold";
import { useLocation } from "react-router-dom";
import useGetGoldDetails from "../data/useGetGoldDetails";

const GoldDetailedView = () => {
  const location = useLocation();
  const assetId = location.state?.assetId;
  const itemId = location.state?.itemId;
  const itemName = location.state?.itemName;
  const [isReload, setIsReload]= useState(false);
  const [getGoldData, getGoldError, getGoldIsLoading, getGold] =
  useGetGoldDetails();
  const [detailData, setDetailData]=useState({})
  useEffect(()=>{
    getGold(`${assetId}/?id=${itemId}`)
  },[])
  useEffect(()=>{
    if(getGoldData?.data){
      setDetailData(getGoldData.data)
      console.log("getGoldData", getGoldData)
    }
  },[getGoldData])

  useEffect(()=>{
   if(isReload){
    getGold(`${assetId}/?id=${itemId}`)
    setIsReload(false)
   }
  },[isReload])

  const handleOpenPdf=()=>{
    if(detailData.invoice){
      window.open(detailData.invoice, "_blank")
    }
  }
  return (
    <div className="pb-[6rem] px-[1rem] bg-gray-100 ">
      <AssetsHeading asset={"Personal Asset"} asset_type={"Gold & Jewellery"} asset_detail={itemName} assetId={assetId}/>
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem]">
        <div className="flex justify-between items-center">
          <h4 className="text-darkGray font-[500] text-[1rem] m-0">
            Gold Value
          </h4>
         <EditGold editValue={"goldValue"} detailData={detailData} setIsReload={setIsReload}/>
        </div>
        <div className="flex justify-between mt-[1rem]">
          <div className="">
            <h4 className="text-customPurple font-[500] text-[1.7rem] ">
              ₹55 Lakhs
            </h4>
            <div className="p-[0.5rem] border-1 border-customPurple bg-[#FAF8FC] rounded-md">
              <h4 className="text-[0.8rem] font-[600] text-customPurple m-0">
                10 % of Total Assets (₹50.86 Lakhs)
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[1.5rem] border bg-white rounded-xl p-[1rem] flex justify-between">
        <div className="border bg-white rounded-xl p-[1rem] w-[40%]">
          <div className="flex justify-between">
            <div>
              <p className="m-0 text-[#334155] font-[500]">Item Details</p>
            </div>
            <div className="flex gap-2.5">
               <EditGold editValue="goldDetails" detailData={detailData} setIsReload={setIsReload}/>
            </div>
          </div>
          <div className="flex gap-[10.7rem] mt-2.5">
            <div className="w-[28%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Type
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
                {detailData.type_of_metal}
              </p>
            </div>
            <div>
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Item Name
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
              {detailData.item_name}
              </p>
            </div>
          </div>
          <div className="flex gap-[10.7rem] mt-2.5">
            <div className="w-[28%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Purity
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">{detailData.purity}</p>
            </div>
            <div>
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Weight
              </p>
              <p className="text-[#9065B4] m-0 text-[1.2rem] font-[500]">
              {detailData.weight}
              </p>
            </div>
          </div>
        </div>
        <div className="border bg-white rounded-xl p-[1rem] w-[58.5%] h-[180px]">
          <div className="flex justify-between">
            <div>
              <p className="m-0 text-[#334155] font-[500]">Purchase Details</p>
            </div>
            <div className="flex gap-2.5">
              <WhiteButton
                px={"px-[0.8rem]"}
                py={"py-[0.3rem]"}
                name="View Invoice"
                onClick={handleOpenPdf}
              />
              <EditGold editValue="goldPurchaseDetails" detailData={detailData} setIsReload={setIsReload}/>
            </div>
          </div>
          <div className="flex gap-16 mt-2.5">
            <div className="w-[30%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Purchase Date
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
              {detailData.purchase_date}
              </p>
            </div>
            <div>
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Purchase Price
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
              {detailData.purchase_price} <span className="text-[0.8rem]">Rs.</span>
              </p>
            </div>
          </div>
          <div className="flex gap-16 mt-2.5">
            <div className="w-[30%]">
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Estimated Sale Value
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">
              {detailData.estimated_sale_value} <span className="text-[0.8rem]">Rs.</span>
              </p>
            </div>
            <div>
              <p className="text-[#64748B] m-0 font-[500] text-[0.8rem]">
                Loan Taken?
              </p>
              <p className="text-[#334155] m-0 text-[1.2rem] font-[500]">{detailData.loan_taken===true ? "Yes":"No"}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <button className="text-[#334155] bg-[#F1F5F9] px-3.5 py-2 rounded-md font-[600] text-[0.9rem]">
          Back To Assets
        </button>
      </div>
    </div>
  );
};

export default GoldDetailedView;
