import React, { useEffect, useContext, useState } from "react";
import useGetBlogsList from "./Data/useGetBlogs";
import arrowUpRight from "assets/images/blogs/arrow-up-right.svg";
import tableAndChairs from "assets/images/blogs/tableAndChairs.svg";
import prev from "assets/images/blogs/prev.svg";
import next from "assets/images/blogs/naxt.svg";
import { useNavigate } from "react-router-dom";
import { loaderContext } from "App";
import { BLOG_DETAILS_URL } from "constants/routes";

function BlogsList() {
  const navigate = useNavigate();
  // console.log(navigate)
  const [totalBlogs, setTotalBlogs] = useState(null);
  const noOfBlogsInPage = 3;
  const totalPages = Math.ceil(totalBlogs / noOfBlogsInPage);
  const pages = [...Array(totalPages + 1).keys()].slice(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [blogsArr, setBlogsArr] = useState([]);
  const { loading, setLoading } = useContext(loaderContext);

  const [
    BlogsData,
    BlogsError,
    BlogsIsLoading,
    getBlogsList,
    setSuccessBlogsData,
    setBlogsError,
  ] = useGetBlogsList();

  if (BlogsIsLoading) {
    setLoading(true);
  }
  useEffect(() => {
    getBlogsList({ page_size: noOfBlogsInPage, page: currentPage });
  }, [currentPage]);

  useEffect(() => {
    if (BlogsData) {
      setLoading(false);
      if (BlogsData.status_code === 200) {
        setBlogsArr(BlogsData.data.results);
        setTotalBlogs(BlogsData.data.total_count);

        // console.log(totalBlogs);
      }
    }
  }, [BlogsData]);
  // if(blogsArr){
  //   var toResponsive = document.getElementById('toResponsive')
  //   if( toResponsive.classList.contains('col-span-12')){
  //    console.log('rnirvjin')
  //   }else{
  //    console.log('workkk')

  //   }}

  const prevClicked = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextClicked = () => {
    if (currentPage !== totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <div className=" lg:p-[6%] md:p-[6%] px-[6%] py-[40%]">
        <h5 className="my-[2%] font-[700] text-[1.3rem] leading-[1.8rem]">
          All blog posts
        </h5>
        <div className="grid grid-cols-12 lg:gap-[4%] md:gap-[4%] gap-[1%]">
          {blogsArr &&
            blogsArr.map((data, i) => {
              return (
                <>
                  <div
                    key={i}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate(
                        `${BLOG_DETAILS_URL.replace(":blogId", data.id)}`,
                        {
                          state: {
                            id: data.id,
                            page: currentPage,
                            noOfBlogs: noOfBlogsInPage,
                          },
                        }
                      );
                    }}
                    id="toResponsive"
                    className=" h-[28rem] hover:cursor-pointer lg:col-span-4 md:col-span-4 col-span-12"
                  >
                    <img src={tableAndChairs} alt="" className=" w-[100%]" />
                    <div className="my-[2%] grid grid-cols-12 pt-[5%]">
                      <h5 className="  col-span-11">{data.title}</h5>
                      <img
                        className=" col-start-12 col-span-1 pl-[40%]"
                        src={arrowUpRight}
                        alt=""
                      />
                      <p className=" leading-[130%] col-span-11 text-gray-600 font-light">
                        {data.description}
                      </p>
                      <p className=" lg:col-span-7 md:col-span-8 col-span-12">
                        <span className=" whitespace-nowrap p-2 rounded-2xl text-purple-800 font-semibold bg-purple-100">
                          Business Registration
                        </span>
                      </p>

                      <p className=" lg:col-span-7 md:col-span-8 col-span-12">
                        <span className=" p-2 rounded-2xl text-pink-800 font-semibold bg-pink-100">
                          {" "}
                          Small Business
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
        <div className=" pt-[4%]">
          <hr />
          <div className=" py-[2%] flex justify-between">
            <div
              className="flex hover:cursor-pointer"
              onClick={() => {
                prevClicked();
              }}
            >
              <img src={prev} alt="" />
              <p className=" mb-0 font-semibold p-2">Previos</p>
            </div>
            <div>
              {pages &&
                pages.map((i) => {
                  return (
                    <span
                      className={`${
                        currentPage === i ? " bg-amber-100 text-amber-900" : ""
                      }  p-3  rounded-md hover:cursor-pointer`}
                      onClick={() => {
                        setCurrentPage(i);
                      }}
                    >
                      {i}
                    </span>
                  );
                })}
            </div>
            <div
              className="flex hover:cursor-pointer"
              onClick={() => {
                nextClicked();
              }}
            >
              <p className=" mb-0 font-semibold p-2 ">Next</p>
              <img src={next} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogsList;
